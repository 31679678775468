import dayjs from "dayjs";

const getDownstreamRefinedDates = (dates: string[]): [Date, Date | null] => {
  let newDates: any = dates;

  // Downstream represents the data coming down from the server
  const datesAreSame = dayjs(newDates[0]).isSame(dayjs(newDates[1]), "day");
  // If detect that second value is auto-assigned, assign null to it
  if (datesAreSame) {
    newDates[1] = null;
  }

  // Convert string values to dates
  newDates = newDates.map((d: string) => (d ? new Date(d) : null));

  return newDates;
};

export default getDownstreamRefinedDates;
