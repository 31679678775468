import { colors, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import CardGrid from "components/material/Card/CardGrid";
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";

const styles = {
  description: {
    gridRow: 2,
    gridColumn: "1 /4"
  },
  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
};

const PayoutAccountShowLayout = () => {
  const t = useTranslate();
  const record = useRecordContext();
  const payoutAccountContent =
    record?.accountName === "BANK"
      ? [
          { [`${t("payout.country")}`]: record?.countryCode },
          {
            [`${t("payout.financial_provider")}`]: record?.accountName
          },
          {
            [`${t("payout.iban_number")}`]: record?.accountNumber
          },
          {
            [`${t("payout.account_holder_name")}`]: record?.accountHolderName
          },
          {
            [`${t("payout.swift_code")}`]: record?.accountSwiftCode
          },
          {
            [`${t("payout.address")}`]: record?.address
          },
          {
            [`${t("payout.state")}`]: record?.state
          },
          {
            [`${t("payout.city")}`]: record?.city
          },
          {
            [`${t("payout.other_information")}`]: record?.information
          }
        ]
      : [
          { [`${t("payout.country")}`]: record?.countryCode },
          {
            [`${t("payout.financial_provider")}`]: record?.accountName
          },
          {
            [`${t("payout.mobile_number")}`]: record?.accountNumber
          },
          {
            [`${t("payout.account_holder_name")}`]: record?.accountHolderName
          },
          {
            [`${t("payout.other_information")}`]: record?.information
          }
        ];

  return (
    <Grid container spacing={3} style={{ padding: 10 }}>
      <Grid item xs={12}>
        <CardGrid
          cardTitle={t("payout.account_details")}
          content={payoutAccountContent}
          variant="outlined"
        ></CardGrid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PayoutAccountShowLayout);
