import { Box, colors, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { RaRecord, useTranslate } from "react-admin";

const styles = {
  noDataWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0"
  },
  noDataText: {
    color: colors.grey[500]
  }
};

const CardNoData = ({ classes }: RaRecord) => {
  const t = useTranslate();
  return (
    <Box className={classes.noDataWrapper}>
      <Typography className={classes.noDataText} variant="h6">
        {t("cc.card.empty")}
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(CardNoData);
