/*eslint-disable */

import { Box, Button, colors, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import config from "config";

import { AttachFileOutlined } from "@mui/icons-material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Card from "components/material/Card/CardBase";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { AIRTIME, MOBILEMONEY } from "constants/bulkPaymentServiceTypes";
import {
  Form,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";
// import { Form } from "react-final-form";
import { useMutation } from "react-query";
import { UPLOAD_ATTACHMENT } from "../../../constants/dataProviderTypes";

import TooltipHelp from "components/material/Tooltip/TooltipHelp";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    color: colors.common.black,
    textTransform: "none",
    fontWeight: "bold",
    backgroundColor: colors.blue[100]
  },
  stepTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottom: `1px solid ${colors.cyan[600]}`,
    marginBottom: 32,
    paddingBottom: 24,
    fontWeight: 700,
    fontSize: "1.5rem"
  },
  inputLabels: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold"
  },
  upload: {
    color: colors.cyan[600],
    fontSize: 18,
    fontWeight: 700,
    marginTop: 12,
    textTransform: "none",
    marginLeft: "8px"
  },
  input: {
    display: "none"
  },
  listIcon: {
    minWidth: 8,
    paddingRight: 12,
    color: colors.blue[300]
  },
  icon: {
    fontSize: 8
  },
  buttonHere: {
    color: colors.common.white,
    borderRadius: 3,
    opacity: 0.4,
    margin: "0 5px",
    fontSize: 18,
    height: 25,
    fontWeight: 700,
    backgroundColor: colors.grey[600],
    "&:hover": {
      color: colors.common.black,
      backgroundColor: colors.grey[600]
    }
  }
});
const serviceTypeList = [
  { mpay_serviceId: MOBILEMONEY, name: "Mobile Money" },
  { mpay_serviceId: AIRTIME, name: "Airtime" }
];

const templateUrl = `${config.REACT_APP_PROXY_BASE_URL}/template/bulk_collection`;

const BulkCollectionCreateForm = (permissions, flow, record, version, ...rest) => {
  const classes = useStyles();

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const [uploadFile, setUploadFile] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [touched, setTouched] = useState(false);
  const backToListPage = () => redirect("/bulk_collection", rest.basePath);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [mutateUploadAttachment, { loading: loadingUploadAttachment }] = useMutation();
  const [createBulkCollection, { loading: loadingCreateBulkCollection }] = useCreate();

  const username = JSON.parse(localStorage.getItem("username"));
  const userId = JSON.parse(localStorage.getItem("MerchantCoreCompanyId"));

  function checkFile(sender) {
    let validExts = [".xlsx", ".xls", ".csv"];
    let fileName = sender[0].name;
    fileName = fileName.substring(fileName.lastIndexOf("."));
    if (validExts.indexOf(fileName) < 0) {
      notify("bulk_collection.notify.errorExtUpload", { type: "error" });
      return false;
    } else return setUploadFile(sender);
  }

  const saveTemplate = () => {
    saveAs(templateUrl, `template.xlsx`);
  };

  const uploadAttachment = ({ id, files }) => {
    return mutateUploadAttachment(
      {
        type: UPLOAD_ATTACHMENT,
        resource: "bulk_collection",
        payload: {
          id,
          data: { files }
        }
      },

      {
        onSuccess: () => {
          notify("bulk_collection.notify.bulk_collection_created", "success");
          backToListPage();
        },

        onFailure: (err) => {
          notify(err?.body?.message, { type: "error" });
        }
      }
    );
  };
  const processCreateBulkCollection = async (values) => {
    const { mpay_serviceId, ref, desciption } = values;
    createBulkCollection(
      "bulk_collection",
      {
        data: {
          mpay_created_by_name: username,
          mpay_created_by_uuid: userId,
          mpay_serviceId,
          ref,
          desciption
        }
      },
      {
        onSuccess: async (data) => {
          uploadAttachment({ files: uploadFile, id: data?.id });
        },
        onFailure: (err) => {
          notify(err?.body?.message, { type: "error" });
        }
      }
    );
  };

  const instructions = [
    {
      id: 1,
      label: translate("bulk_collection.alertInstructions.telNumber"),
      desc: translate("bulk_collection.alertInstructions.telNumberContent")
    },
    {
      id: 2,
      label: translate("bulk_collection.alertInstructions.amount"),
      desc: translate("bulk_collection.alertInstructions.amountContent")
    },
    {
      id: 3,
      label: translate("bulk_collection.alertInstructions.description"),
      desc: translate("bulk_collection.alertInstructions.descriptionContent")
    },
    {
      id: 4,
      label: translate("bulk_collection.alertInstructions.firstName"),
      desc: translate("bulk_collection.alertInstructions.firstNameContent")
    },
    {
      id: 5,
      label: translate("bulk_collection.alertInstructions.lastName"),
      desc: translate("bulk_collection.alertInstructions.lastNameContent")
    }
  ];

  const submit = (values) => {
    if (uploadFile.length > 0) {
      setTouched(false);
      processCreateBulkCollection(values);
    }
  };
  return (
    <>
      {/* <AlertBlock
        title={"bulk_collection.alertTitle.instructions"}
        icon={<InfoIcon style={{ fontSize: 45, color: colors.blue[300] }} />}
        type={"info"}
      >
        <Typography>{translate("bulk_collection.alertSubtitleInstructions")}</Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {instructions.map((item) => (
              <ListItem key={item.id}>
                <ListItemIcon className={classes.listIcon}>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <Typography>
                  <span style={{ color: colors.blue[300], fontWeight: 700 }}>{item.label}</span>
                  {item.desc}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <Button className={classes.button} size="small" onClick={handleExpandClick}>
          {expanded
            ? translate("bulk_collection.alertInstructions.showLessButton")
            : translate("bulk_collection.alertInstructions.showMoreButton")}
        </Button>
      </AlertBlock>
      <AlertBlock
        title={"bulk_collection.alertTitle.tips"}
        icon={
          <WbIncandescentIcon
            style={{
              fontSize: 45,
              transform: "rotate(180deg)",
              color: colors.common.black
            }}
          />
        }
        type={"warning"}
      >
        <Typography style={{ color: colors.common.black }}>
          {translate("bulk_collection.click")}

          <Button className={classes.buttonHere} onClick={saveTemplate}>
            {translate("bulk_collection.hereButton")}
          </Button>
          {translate("bulk_collection.alertSubtitleTips")}
        </Typography>
      </AlertBlock> */}
      <Form {...rest} defaultValues={record} onSubmit={submit}>
        <Card cardTitle={translate("bulk_collection.bulk_collection_request")} variant="outlined">
          <Grid container direction={"column"} style={{ paddingBottom: 80 }}>
            <Grid item>
              <Typography className={classes.stepTitle}>
                {translate("bulk_collection.sectionTitle.stepOne")}
              </Typography>
              <Typography className={classes.inputLabels}>
                {translate("bulk_collection.inputTitle.service_type")}*
              </Typography>
              <SelectInput
                label={"bulk_collection.inputTitle.service_type"}
                choices={serviceTypeList}
                optionText="name"
                optionValue="mpay_serviceId"
                style={{ width: "40%" }}
                validate={[required()]}
                source="mpay_serviceId"
                resource="bulk"
              />
            </Grid>
            <Grid item>
              <Box style={{ display: "flex" }}>
                <Typography className={classes.inputLabels}>
                  {translate("bulk_collection.inputTitle.collection_ref")}
                  <span style={{ color: colors.red[500] }}>
                    {translate("bulk_collection.inputTitle.unique")}
                  </span>
                  *
                </Typography>
                <TooltipHelp
                  content={"bulk_collection.tooltip.collectionReferenceHelpText"}
                  style={{ marginTop: 10, marginLeft: 8 }}
                />
              </Box>
              <TextInput
                validate={[required()]}
                label="bulk_collection.collection_ref_label"
                source="ref"
                style={{ width: "40%" }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.inputLabels}>
                {translate("bulk_collection.notification_message")}
              </Typography>
              <TextInput
                label="bulk_collection.notification_message"
                source="message"
                multiline
                minRows={4}
                style={{ width: "40%" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.stepTitle}>
              {translate("bulk_collection.sectionTitle.stepTwo")}
            </Typography>
            <Grid container direction={"column"} alignItems={"flex-start"} style={{ paddingBottom: 36 }}>
              <Grid item style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Typography style={{ paddingTop: 12 }}>
                  {translate("bulk_collection.alertInstructions.selectFile")}
                </Typography>

                {uploadFile && uploadFile.length > 0 ? (
                  <Box className={classes.upload}>{uploadFile[0].name}</Box>
                ) : (
                  <Grid container direction={"row"} style={{ width: "auto" }}>
                    <input
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => {
                        checkFile(e.target.files);
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        onClick={() => setTouched(true)}
                        className={classes.upload}
                        variant="text"
                        component="span"
                      >
                        <AttachFileOutlined /> {translate("bulk_collection.alertInstructions.attach")}
                      </Button>
                    </label>
                  </Grid>
                )}
                <IconButton className={classes.upload} onClick={() => setUploadFile([])}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
                <TooltipHelp content={"bulk_collection.tooltip.uploadHelpText"} style={{ marginTop: 12 }} />
              </Grid>
              {touched && uploadFile.length < 1 ? (
                <Grid>
                  <Typography color={"error"}>
                    {translate("bulk_collection.validation.attachment_required")}
                  </Typography>
                  {/*<FormControlLabel*/}
                  {/*    control={<Checkbox name="checkedA"/>}*/}
                  {/*    label="Send description as SMS notification"*/}
                  {/*/>*/}
                  {/*<TooltipHelp content={"bulk_collection.tooltip.test"} style={{marginTop: 10}}/>*/}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Toolbar>
            <Box display="flex" justifyContent="end" width="100%">
              {/* <BackButton variant="outlined" color="secondary">
                {translate("ra.action.cancel")}
              </BackButton> */}
              <SaveButton
                onClick={() => setTouched(true)}
                style={{ margin: "0px 15px" }}
                color="secondary"
                label={translate("bulk_collection.submit")}
                submitOnEnter={true}
                saving={loadingCreateBulkCollection || loadingUploadAttachment}
                // handleSubmitWithRedirect={formProps.handleSubmit}
              />
            </Box>
          </Toolbar>
        </Card>
      </Form>
    </>
  );
};
export default BulkCollectionCreateForm;
