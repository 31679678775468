import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Paper, Toolbar, Typography, colors } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import Tabs from "components/material/Tabs";
import { REMIT_REPORT_ALL, REMIT_REPORT_OWN } from "constants/permissionsNames";
import React, { useState } from "react";
import { usePermissions, useTranslate, useGetOne } from "react-admin";
import findPermission from "utils/findPermission";
import RemittanceCollectorCompany from "./RemittanceCollectorCompany";
import RemittanceManagementReports from "./RemittanceManagementReports";
import RemittanceRspReports from "./RemittanceRspReports";
import CardButton from "./components/CardButton";
import RemittanceTransactionReports from "./RemittanceTransactionReports";
import { getStorageMerchantCCId } from "utils/storageUtils";

const RemittanceReports = () => {
  const translate = useTranslate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleChange = (event: Event, newValue: number) => {
    setActiveTabIndex(newValue);
  };
  const t = useTranslate();
  const { permissions } = usePermissions();
  const remitReportOwnPermission = findPermission(permissions, REMIT_REPORT_OWN);
  const remitReportAllPermission = findPermission(permissions, REMIT_REPORT_ALL);
  const [currentSubtab, setCurrentSubTab] = useState(0);
  const merchantCcId = getStorageMerchantCCId();

  type ISubTab = { view: number; component: React.ReactElement };

  const { data: merchantData } = useGetOne(
    "merchant",
    {
      id: merchantCcId
    },
    { enabled: !!merchantCcId, refetchOnWindowFocus: false }
  );

  const DefaultView = () => (
    <>
      <Box mb={2}>
        <Typography fontWeight={700} fontSize={20}>
          {t(`resources.${["remittance"]}.fields.detailed_management_report`)}
        </Typography>
        <Typography fontSize={16}>
          {t(`resources.${["remittance"]}.fields.detailed_management_report_description`)}
        </Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        {remitReportAllPermission && (
          <>
            <CardButton text="Transaction Report" onClick={() => setCurrentSubTab(4)} />
            <CardButton text="Maviance Internal Report" onClick={() => setCurrentSubTab(1)} />
          </>
        )}
        {remitReportOwnPermission && (
          <CardButton
            text={`${merchantData?.name || ""} - ${t(`resources.${["remittance"]}.fields.reports`)}`}
            onClick={() => setCurrentSubTab(2)}
          />
        )}
        {remitReportAllPermission && <CardButton text="RSP Report" onClick={() => setCurrentSubTab(3)} />}
      </Box>
    </>
  );

  const subTabs: ISubTab[] = [
    {
      view: 0,
      component: <DefaultView />
    },
    {
      view: 1,
      component: <RemittanceManagementReports />
    },
    {
      view: 2,
      component: <RemittanceCollectorCompany />
    },
    {
      view: 3,
      component: <RemittanceRspReports />
    },
    {
      view: 4,
      component: <RemittanceTransactionReports />
    }
  ];

  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"remittance"} page={"reports"} />
      </Toolbar>
      <Box>
        <Tabs
          tabLabels={[
            `${translate("cc.tab.management_reports")}`
            // `${translate("cc.tab.regulatory_reports")}`
          ]}
          onChange={handleChange}
          activeTabIndex={activeTabIndex}
        >
          <>
            {currentSubtab > 0 && (
              <Button
                onClick={() => setCurrentSubTab(0)}
                sx={{ marginBottom: 2, fontWeight: 600 }}
                startIcon={<ChevronLeft />}
              >
                {t("ra.action.back")}
              </Button>
            )}
            <Paper sx={{ bgcolor: colors.grey["50"], height: "40vh", padding: 2, position: "relative" }}>
              {subTabs[currentSubtab].component}
            </Paper>
          </>
          {/**
           * @ this second tab is not handled for now because it's not part of the current goal
           */}
          {false && (
            <Paper sx={{ bgcolor: colors.grey["50"], height: "40vh", padding: 2 }}>
              <Box mb={2}>
                <Typography fontWeight={700} fontSize={20}>
                  {t(`resources.${["remittance"]}.fields.detailed_management_report`)}
                </Typography>
                <Typography fontSize={16}>
                  {t(`resources.${["remittance"]}.fields.detailed_management_report_description`)}
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <CardButton text="Remittance Overview Report" />
                <CardButton text="Report on Senders" />
                <CardButton text="Report on Corridors" />
              </Box>
            </Paper>
          )}
        </Tabs>
      </Box>
    </>
  );
};

export default RemittanceReports;
