import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  IconButton,
  MobileStepper,
  Paper,
  Typography
} from "@mui/material";
import { PropsOfStyles, withStyles } from "@mui/styles";
import { FC, useState } from "react";
import { useTranslate } from "react-admin";
import formatDate from "../../../utils/formatDate";
import Card from "./CardBase";
import { CardDocumentProps } from "./types/cardTypes";

const styles: PropsOfStyles<string | object> = {
  root: {
    flexGrow: 1
  },
  slider: {
    width: "100%"
  },
  sliderItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%"
  },
  cardMedia: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain"
  },
  detail: {
    backgroundColor: colors.grey[300],
    padding: 20
  },
  loadingWrapper: {
    height: 350,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  imgTitle: {
    display: "block",
    padding: 10,
    textAlign: "center"
  },
  docImages: {
    height: 450,
    overflow: "hidden",
    display: "block",
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  }
};

const CardDocuments: FC<CardDocumentProps> = ({ classes, content = [], loading, ...cardProps }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeDoc, setActiveDoc] = useState(0);
  const translate = useTranslate();

  const handleNext = () => {
    setActiveStep((nextObj) => nextObj + 1);
  };

  const handleBack = () => {
    setActiveStep((prevObj) => prevObj - 1);
  };

  const handleNextDoc = () => {
    setActiveStep(0);
    setActiveDoc((nextDoc) => nextDoc + 1);
  };

  const handlePrevDoc = () => {
    setActiveDoc((prevDoc) => prevDoc - 1);
  };

  return (
    <Card {...cardProps} isEmpty={content?.length === 0 && !loading}>
      {!loading ? (
        <div className={classes.root}>
          {
            <div className={classes.root}>
              <div
                style={{ backgroundImage: `url(${content[activeDoc]?.imgData[activeStep]?.url})` }}
                className={classes.docImages}
              ></div>
              <span className={classes.imgTitle}>
                {content[activeDoc]?.imgData[activeStep]?.dataObjectType}
              </span>
              <MobileStepper
                steps={content[activeDoc]?.imgData.length}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    color={activeStep === 0 ? "secondary" : "inherit"}
                    onClick={handleNext}
                    variant="contained"
                    disabled={activeStep === content[activeDoc]?.imgData.length - 1}
                  >
                    {translate("ra.navigation.next")}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    color={activeStep === content[activeDoc]?.imgData.length - 1 ? "secondary" : "inherit"}
                    onClick={handleBack}
                    variant="contained"
                    disabled={activeStep === 0}
                  >
                    {translate("ra.navigation.prev")}
                  </Button>
                }
              />
              <Paper square elevation={0} className={classes.detail}>
                <Typography variant="h6">{content[activeDoc]?.metaData?.idType}</Typography>
                <Typography>{content[activeDoc]?.metaData?.idNumber}</Typography>
                <Typography>{formatDate(content[activeDoc]?.metaData?.idIssued)}</Typography>
                <Typography>{content[activeDoc]?.metaData?.country}</Typography>
              </Paper>
            </div>
          }
          <MobileStepper
            steps={content?.length}
            position="static"
            variant="text"
            activeStep={activeDoc}
            nextButton={
              <IconButton
                size="large"
                onClick={handleNextDoc}
                disabled={activeDoc === content?.length - 1}
                color={activeDoc === 0 ? "secondary" : "inherit"}
              >
                <NavigateNextIcon style={{ fontSize: 30 }} />
              </IconButton>
            }
            backButton={
              <IconButton
                onClick={handlePrevDoc}
                disabled={activeDoc === 0}
                color={activeDoc === content?.length - 1 ? "secondary" : "inherit"}
                size="large"
              >
                <NavigateBeforeIcon style={{ fontSize: 30 }} />
              </IconButton>
            }
          />
        </div>
      ) : (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
          <Typography variant="body1" style={{ marginTop: 15 }}>
            {`${translate("resources.entity.fields.loading")}...`}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default withStyles(styles)(CardDocuments);
