import {
  APPROVED,
  AWAITING_STATUS_UPDATES,
  CREATED,
  ENDED,
  ERROR,
  ERRORED,
  IMPORTING_DATA,
  PARTIAL_SUCCESS,
  PAYMENT_ITEM_ID_RETRIEVAL_DONE,
  PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS,
  PROCESSING_PAYMENTS,
  READY,
  READY_FOR_PAYMENT_ID_RETRIEVAL,
  REJECTED,
  SUCCESS
} from "constants/status";
import { Translate } from "react-admin";
import getStatusByStatusCode from "./getStatusByStatusCode";

const getStatusTitleByStatusCode = ({ statusCode, t }: { statusCode: string | number; t: Translate }) => {
  const formatTitle = (key: string) => {
    return t(`cc.status.${key}`).toUpperCase();
  };

  switch (getStatusByStatusCode(statusCode)) {
    case CREATED:
      return formatTitle("created");
    case READY:
      return formatTitle("ready");
    case ENDED:
      return formatTitle("ended");

    case APPROVED:
      return formatTitle("approved");
    case SUCCESS:
      return formatTitle("success");
    case PARTIAL_SUCCESS:
      return formatTitle("partial_success");
    case IMPORTING_DATA:
    case READY_FOR_PAYMENT_ID_RETRIEVAL:
    case PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS:
    case PAYMENT_ITEM_ID_RETRIEVAL_DONE:
    case PROCESSING_PAYMENTS:
    case AWAITING_STATUS_UPDATES:
      return formatTitle("processing");
    case ERRORED:
    case ERROR:
      return formatTitle("error");
    case REJECTED:
      return formatTitle("rejected");

    default:
      return formatTitle("open");
  }
};

export default getStatusTitleByStatusCode;
