import React from "react";
import { Divider as DividerMui, DividerProps } from "@mui/material";

interface Props extends DividerProps {
  sx?: React.CSSProperties;
  isDashed?: boolean;
  children?: React.ReactNode;
}

const Divider: React.FC<Props & DividerProps> = ({ isDashed = false, sx = {}, children, ...rest }) => {
  return (
    <DividerMui
      sx={[sx, { paddingVertical: 1, borderStyle: isDashed ? "dashed" : "inherit" }]}
      {...rest}
      data-testid="test-divider"
    >
      {children}
    </DividerMui>
  );
};

export default Divider;
