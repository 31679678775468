import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import { useTranslate } from "react-admin";
import { useController } from "react-hook-form";

const AutocompleteProduct = ({
  onSelect,
  label,
  loading,
  fetchMoreOptions,
  selectedValues,
  onCloseClick,
  name,
  ...props
}) => {
  const {
    isRequired,
    field: { onChange, value, ...inputProps },
    fieldState: { error }
  } = useController({ name });

  const translate = useTranslate();

  const filter = createFilterOptions();

  const handleOnChange = (e, newValue) => {
    onChange(newValue.name);
    onSelect(newValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <Autocomplete
        {...inputProps}
        loading={loading}
        id="autocomplete-product"
        data-testid="autocomplete-product"
        onChange={handleOnChange}
        disableClearable
        clearOnBlur
        // isOptionEqualToValue={(option, value) => option.name === value}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.label) {
            return option.label;
          }
          // Regular option
          return option.name;
        }}
        filterOptions={(options, params) => {
          let filtered;

          // IDs of the already selected products
          const selectedProductsIDs = selectedValues
            ?.map((item) => {
              return item?.productUuid;
            })
            .filter((item) => item);

          filtered = filter(options, params);
          // Filter already selected product (filtering performed by productUuid)
          filtered = filtered?.filter((item) => {
            return !selectedProductsIDs.includes(item?.productUuid);
          });

          // In case that input value does not match any of pre loaded options, fetch more
          if (
            (filtered.length === 0 || !filtered.find((item) => item.name.match(params.inputValue))) &&
            params.inputValue !== ""
          ) {
            fetchMoreOptions(params.inputValue);
          }

          // Suggest the creation of a new value
          const isExisting = options.some((option) => params.inputValue === option.name);
          if (params.inputValue !== "" && !isExisting) {
            filtered.push({
              name: params.inputValue,
              label: `${translate("cc.button.create_product", { smart_count: 1 })} "${params.inputValue}"`
            });
          }

          return filtered;
        }}
        value={value}
        style={{ width: "100%" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={isRequired}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Box>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Box>
                )
              }}
              size="small"
              label={label}
              variant="filled"
            />
          );
        }}
        {...props}
      />

      <Tooltip title={translate("prl.clear")} placement="bottom">
        <IconButton
          data-testid="product-autocomplete-clear-button"
          size="small"
          onClick={() => onCloseClick(value)}
          style={{ marginLeft: 7, marginTop: 10 }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AutocompleteProduct;
