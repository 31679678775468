import { colors } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { ChipStatusTypes } from "components/types/otherComponentTypes";
import { isBoolean } from "lodash";
import * as React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import getColorByStatus from "utils/getColorByStatus";

const styles = () => ({
  root: {
    display: "inline",
    padding: "5px 10px",
    borderRadius: 5
  },

  success: {
    backgroundColor: colors.green[50],
    color: colors.green[500]
  },

  warning: {
    backgroundColor: colors.yellow[50],
    color: colors.yellow[900]
  },

  neutral: {
    background: colors.brown[50],
    color: colors.brown[500]
  },
  error: {
    backgroundColor: colors.red[50],
    color: colors.red[500]
  },
  info: {
    backgroundColor: colors.blue[50],
    color: colors.blue[500]
  },

  hidden: {
    display: "none"
  }
});

const StatusField: React.FC<ChipStatusTypes> = (props) => {
  const { classes, source, dataTestId } = props;
  const record = useRecordContext(props) || {};
  const t = useTranslate();

  const data: string | boolean = source ? record[source] : record?.status;
  const finalData: string | boolean = data && !isBoolean(data) ? data.toUpperCase() : data;

  const renderClass = () => {
    if (classes.hasOwnProperty(getColorByStatus(finalData))) {
      return classes[getColorByStatus(finalData)];
    }
  };

  const renderTitle = () => {
    if (data !== null) {
      switch (data) {
        case true:
          return `${t("ra.message.yes")}`;
        case false:
          return `${t("ra.message.no")}`;
        default:
          return `${t(`cc.status.${data}`.toLowerCase())}`.toUpperCase();
      }
    } else {
      return null;
    }
  };

  // Render component only if status exist
  return renderTitle() ? (
    <div className={classNames(classes.root, renderClass())} data-testid={dataTestId}>
      {renderTitle()}
    </div>
  ) : null;
};

export default withStyles(styles)(StatusField);
