import { withStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import React from "react";
import { Show, useTranslate } from "react-admin";
import BulkCollectionDataShowLayout from "./BulkCollectionDataShowLayout";
import { Toolbar } from "@mui/material";

const styles = {
  card: {
    boxShadow: "none"
  }
};

const BulkCollectionDataShow = ({ classes }) => {
  const t = useTranslate();
  return (
    <>
      <Toolbar>
        <Breadcrumbs />
      </Toolbar>
      <Show title={t("bom.bulk_collection_title")} classes={{ card: classes.card }}>
        <BulkCollectionDataShowLayout />
      </Show>
    </>
  );
};

export default withStyles(styles)(BulkCollectionDataShow);
