import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { FieldTitle, InputHelperText, useInput } from "react-admin";
import dayjs from "dayjs";
import { TextField, TextFieldProps } from "@mui/material";

type FilterDatePickerProps = Omit<DatePickerProps<any, any>, "renderInput" | "value" | "onChange">;
type FilterTextFieldProps = Omit<TextFieldProps, "label">;

type IDatePicker = {
  source: string;
  onBlur?: () => void;
  label?: string;
  validate?: any;
};

type MergeType = FilterDatePickerProps & FilterTextFieldProps & IDatePicker;

const DatePickerInput = (props: MergeType) => {
  const { source, size, label, validate, ...rest } = props;

  const {
    field: { onChange, value },
    fieldState: { isTouched, error, invalid },
    formState: { isSubmitted },
    isRequired
  } = useInput({ validate, source });

  const handleOnChange = (val: any) => {
    const str = dayjs(val)?.format("YYYY-MM-DD");
    onChange(str);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label && <FieldTitle label={label} source={source} isRequired={isRequired} />}
          inputFormat="DD/MM/YYYY"
          renderInput={(params: any) => (
            <TextField
              variant={"outlined"}
              size={size}
              helperText={
                <InputHelperText
                  touched={(isTouched || isSubmitted) && invalid}
                  error={error?.message}
                  // helperText={helperText}
                />
              }
              error={(isTouched || isSubmitted) && invalid}
              fullWidth
              {...params}
            />
          )}
          onChange={handleOnChange}
          value={value ?? null}
          {...rest}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePickerInput;
