import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { colors } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.cyan[600],
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

interface TableProps {
  renderSecondaryHeader?: () => JSX.Element;
  headers: string[];
  size: "small" | "medium";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  rows: {
    [key: string]: string | number;
  }[];
}

const Table = ({ headers, rows, align, size, renderSecondaryHeader, ...props }: TableProps) => {
  return (
    <TableContainer component={Paper}>
      <MuiTable aria-label="customized table" size={size} {...props} data-testid={"table"}>
        <TableHead>
          {renderSecondaryHeader && renderSecondaryHeader()}
          <TableRow>
            {headers.map((header) => (
              <StyledTableCell align={align}>{header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              {Object.values(row).map((value) => (
                <StyledTableCell align={align}>{value}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
