import { Typography, Box, colors, BoxProps, Tooltip } from "@mui/material";
import { isArray, isString } from "lodash";

export interface IKeyValueText extends BoxProps {
  title: string;
  value: string | React.ReactNode;
  isVertical?: boolean;
  titleStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
}

const KeyValueText = ({
  title,
  value,
  isVertical = false,
  titleStyles,
  valueStyles,
  ...rest
}: IKeyValueText) => {
  const renderItems = (value: string | React.ReactNode) => {
    if (value) {
      if (isString(value)) {
        return (
          <Tooltip title={value} placement="left-start">
            <Typography data-testid="value-text-testid" sx={{ ...valueStyles }} color={"#000"}>
              {value}
            </Typography>
          </Tooltip>
        );
      }
      if (isArray(value)) {
        return value?.map((itemValue, index) => (
          <Tooltip title={itemValue} placement="left-start" key={index}>
            <Typography data-testid="value-text-testid" sx={{ ...valueStyles }} color={"#000"}>
              {itemValue}
            </Typography>
          </Tooltip>
        ));
      }
      return value;
    }
  };

  return (
    <Box
      {...rest}
      display={isVertical ? "block" : "flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      data-testid="box-test-id"
    >
      <Typography data-testid="key-text-testid" sx={{ ...titleStyles }} color={colors.grey[600]}>
        {title}:
      </Typography>
      {renderItems(value)}
    </Box>
  );
};

export default KeyValueText;
