const attachmentDataProvider = (apiUrl, httpClient) => ({
  uploadAttachment: (resource, { id, data: { files } }) => {
    const url = `${apiUrl}/${resource}/${id}/confirmation-attachments`;
    const requests = [];

    files.forEach((file) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("label", file.label);

      requests.push(
        httpClient(url, {
          method: "POST",
          body: formData
        })
      );
    });

    return Promise.all(requests).then(({ json }) => ({ data: json }));
  },

  updateAttachment: (resource, { id, data }) => {
    const { file, label } = data;
    const url = `${apiUrl}/${resource}/${id}/confirmation-attachments/${file.id}`;

    const d = { label };

    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify(d)
    }).then(({ json }) => ({ data: json }));
  },
  deleteAttachment: (resource, { id, data: { file } }) => {
    const url = `${apiUrl}/${resource}/${id}/confirmation-attachments/${file.id}`;

    return httpClient(url, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json }));
  }
});

export default attachmentDataProvider;
