import React from "react";
import { Edit } from "react-admin";
import BusinessListEditForm from "../Form/BusinessListCreateEditForm";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { withStyles } from "@mui/styles";

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};

const BusinessListEdit = (props) => {
  return (
    <>
      <Breadcrumbs />
      <Edit {...props}>
        <BusinessListEditForm />
      </Edit>
    </>
  );
};

export default withStyles(styles)(BusinessListEdit);
