import React from "react";
import {
  colors,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip
} from "@mui/material";
import CardBase from "components/material/Card/CardBase";
import GoogleMap from "components/other/GoogleMap";
import { useDataProvider, useNotify, useRecordContext, useTranslate } from "react-admin";
import renderMimeTypeIcon from "utils/renderMimeTypeIcon";
import { Download } from "@mui/icons-material";
import { DataObject, IdentityDocumentObject } from "api/types/kycTypes";
import { useMutation } from "react-query";
import { DOWNLOAD_ATTACHMENT } from "constants/dataProviderTypes";
import queryErrorHandler from "api/queryErrorHandler";

const KycDocumentsTab = () => {
  const record = useRecordContext();
  const t = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const iconSx = {
    fontSize: 130,
    alignSelf: "center",
    justifySelf: "center",
    color: colors.grey[400],
    marginTop: 1
  };

  const { mutate: mutateDownloadDocument, isLoading } = useMutation(
    ["entity", DOWNLOAD_ATTACHMENT],
    ({ dataObjectId, dataObjectType }: { dataObjectId: string; dataObjectType: string }) => {
      return dataProvider.downloadDocument("entity", {
        dataObjectId,
        dataObjectType,
        id: record?.id
      });
    },
    {
      onSuccess: () => {
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );
  const renderImages = (data: DataObject[]) => (
    <ImageList sx={{ width: "100%", height: "auto", margin: 0 }} cols={5} gap={8}>
      {data.map((item: DataObject) => (
        <ImageListItem key={item.dataObjectId} data-testid={"image-list-item"} sx={{ minHeight: 220 }}>
          {renderMimeTypeIcon(item.contentType, iconSx)}

          <ImageListItemBar
            title={item.dataObjectType}
            actionIcon={
              <Tooltip title={t("cc.button.download")}>
                <IconButton
                  data-testid={"download-button"}
                  sx={{ color: "white" }}
                  onClick={() =>
                    mutateDownloadDocument({
                      dataObjectId: item.dataObjectId,
                      dataObjectType: String(item.dataObjectType)
                    })
                  }
                >
                  <Download />
                </IconButton>
              </Tooltip>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardBase
          cardTitle={t("cc.card.identification_documents")}
          isLoading={isLoading}
          isEmpty={record?.data?.length === 0 || record?.identityDocs?.length === 0}
        >
          <>
            {renderImages(record?.data)}
            {record?.identityDocs?.map((idDoc: IdentityDocumentObject) => {
              return (
                <>
                  <Divider sx={{ margin: "30px 0" }}>
                    {t(`resources.entity.fields.${String(idDoc.idType).toLowerCase()}`)}
                  </Divider>
                  {renderImages(idDoc?.data || [])}
                </>
              );
            })}
          </>
        </CardBase>
      </Grid>
      <Grid item xs={12}>
        <CardBase cardTitle={t("cc.card.localization")} isEmpty={!record?.longitude || !record?.latitude}>
          <GoogleMap
            width={"100%"}
            apiKey={"AIzaSyCIPHKwjSUGk6fdR0U10QCjs-KSpDwfzYA"}
            longitude={record?.longitude}
            latitude={record?.latitude}
          />
        </CardBase>
      </Grid>
    </Grid>
  );
};

export default KycDocumentsTab;
