import React from "react";
import { useFormState, useFormContext } from "react-hook-form";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useNotify } from "react-admin";
import queryErrorHandler from "api/queryErrorHandler";
import {
  DOWNLOAD_COLLECTOR_COMPANY_REPORTS,
  DOWNLOAD_INTERNAL_REPORTS,
  DOWNLOAD_RSP_REPORTS,
  DOWNLOAD_TRANSACTION_REPORTS
} from "constants/dataProviderTypes";
import remittanceDataProvider from "api/dataProvider/remittanceDataProvider";
import { saveAs } from "file-saver";

export type IDownloadButton = {
  name: string;
  icon: React.ReactElement;
  format: "CSV" | "EXCEL";
  data?: any;
  type: "TRANSACTION" | "INTERNAL" | "CC" | "RSP";
};

export const DownloadButton = ({ name, icon, format, data, type }: IDownloadButton) => {
  const { isValid, isDirty } = useFormState();
  const { getValues } = useFormContext();
  const notify = useNotify();
  const formValues = getValues();

  const { mutate: downloadInternalReports, isLoading } = useMutation<any>(
    ["remittance", DOWNLOAD_INTERNAL_REPORTS],
    () =>
      remittanceDataProvider.downloadInternalReports({
        query: JSON.stringify({
          createdAt_start: `${formValues?.createdAt_start} 00:00:00`,
          createdAt_end: `${formValues?.createdAt_end} 23:59:59`
        }),
        data: {
          companies: data,
          format
        }
      }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const { mutate: downloadTransactionReports, isLoading: isLoadingTransaction } = useMutation<any>(
    ["remittance", DOWNLOAD_TRANSACTION_REPORTS],
    () =>
      remittanceDataProvider.downloadTransactionReports({
        query: JSON.stringify({
          createdAt_start: `${formValues?.createdAt_start} 00:00:00`,
          createdAt_end: `${formValues?.createdAt_end} 23:59:59`
        }),
        data: {
          format
        }
      }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const { mutate: downloadCollectorCompanyReports, isLoading: isLoadingCC } = useMutation<any>(
    ["remittance", DOWNLOAD_COLLECTOR_COMPANY_REPORTS],
    () =>
      remittanceDataProvider.downloadCollectorCompanyReports({
        query: JSON.stringify({
          createdAt_start: `${formValues?.createdAt_start} 00:00:00`,
          createdAt_end: `${formValues?.createdAt_end} 23:59:59`
        }),
        data: {
          format
        }
      }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const { mutate: downloadRspReports, isLoading: isLoadingRsp } = useMutation<any>(
    ["remittance", DOWNLOAD_RSP_REPORTS],
    () =>
      remittanceDataProvider.downloadRspReports({
        query: JSON.stringify({
          createdAt_start: `${formValues?.createdAt_start} 00:00:00`,
          createdAt_end: `${formValues?.createdAt_end} 23:59:59`
        }),
        data: {
          rspIds: data?.rspIds,
          months: data?.months,
          years: data?.years,
          format
        }
      }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const isDisabled = !isValid || !isDirty || isLoading || isLoadingTransaction || isLoadingCC || isLoadingRsp;

  const types = {
    TRANSACTION: "TRANSACTION",
    INTERNAL: "INTERNAL",
    CC: "CC",
    RSP: "RSP"
  };

  return (
    <Button
      disabled={isDisabled}
      sx={{ fontWeight: 600, mr: 2 }}
      variant="outlined"
      startIcon={icon}
      onClick={() => {
        if (type === types.INTERNAL) {
          downloadInternalReports();
        } else if (type === types.TRANSACTION) {
          downloadTransactionReports();
        } else if (type === types.CC) {
          downloadCollectorCompanyReports();
        } else if (type === types.RSP) {
          downloadRspReports();
        } else {
          return;
        }
      }}
    >
      {name}
    </Button>
  );
};
