import { RaRecord, useRecordContext, UseRecordContextParams } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const PaymentAmount = (props: UseRecordContextParams<RaRecord>) => {
  const record = useRecordContext(props);

  return <span style={{ fontSize: 14 }}>{formatCurrency(record?.amount)}</span>;
};

export default PaymentAmount;
