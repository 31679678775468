import { Breadcrumbs as MuiBreadcrumbs, colors, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

interface BreadcrumbsProps {
  service: string;
  page: string;
}

const Breadcrumbs = ({ service, page }: BreadcrumbsProps) => {
  const [items, setItems] = useState<Array<string>>([]);
  const t = useTranslate();
  useEffect(() => {
    const bc = [];

    //Push resource
    bc.push(t(`cc.menu.${service}`, { smart_count: 0 }));

    // Push page
    // bc.push(t(`ra.action.${page}`));
    bc.push(t(`cc.breadcrumbs.${page}`));

    setItems(bc);
  }, [page, service, t]);
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" data-testid={"breadcrumbs"}>
      {items.map((label) => (
        <Typography sx={{ color: colors.cyan[700] }}>{label}</Typography>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
