import { Box, colors, Divider, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import Card from "components/material/Card/CardBase";
import Dialog from "components/material/Dialog";
import BackButton from "components/other/ButtonBack";
import React from "react";
import {
  Button,
  Form,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useCreate,
  useGetList,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";

const validateAmount = [required()];
const validateAccount = [required()];
const validateCountry = [required()];

const Styles = {
  label: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold"
  },
  balanceText: {
    fontWeight: "bold"
  },
  topContainer: {
    padding: `10px 25px`
  }
};

const PayoutRequestCreateEditForm = ({ record, classes, ...rest }) => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const backToListPage = () => redirect("/payout-request", rest.basePath);
  const [createPayoutRequest, { loading: loadingCreatePayoutRequest }] = useCreate();
  // eslint-disable-next-line
  // const [addAttachmentEnabled, setAddAttachmentEnabled] = React.useState(true);
  const [openNoteDialog, setOpenNoteDialog] = React.useState(false);
  const collectionCompanyId = JSON.parse(localStorage.getItem("MerchantCoreCompanyId"));
  // eslint-disable-next-line
  const [accountList, setAccountList] = React.useState([]);
  const [autoFill, setAutoFill] = React.useState({});

  const countryChoice = [{ id: "+237", name: "Cameroon" }];

  const submit = async (values) => {
    if (Object.keys(record).length === 0) {
      await processCreateRequest(values);
    } else {
    }
  };

  const handleAccountChange = (event) => {
    const data = accountList[0]?.find((item) => item?.id === event.target.value);
    setAutoFill((item) => ({ ...item, data }));
  };

  const addNote = () => {
    setOpenNoteDialog(false);
  };

  const { data: requestAccountData, isLoading } = useGetList("request-account", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "DESC" }
  });

  const processCreateRequest = async (values) => {
    const amount = {
      amount: parseInt(values.amount)
    };
    const {
      countryCode,
      accountName,
      accountNumber,
      accountHolderName,
      accountSwiftCode,
      address,
      state,
      city,
      requestAccountId
    } = values;

    const formData = {
      coreCompanyId: collectionCompanyId,
      currencyCode: "CFA",
      countryCode,
      accountName,
      accountNumber,
      accountHolderName,
      accountSwiftCode,
      address,
      state,
      city,
      requestAccountId,
      additionalInstructions: autoFill?.data?.information,
      ...amount
    };

    await createPayoutRequest(
      "payout-request",
      { data: formData },
      {
        onSuccess: () => {
          notify("Created successfully", { type: "success" });
          backToListPage();
        },
        onFailure: (err) => {
          notify(err?.body?.message, { type: "error" });
        }
      }
    );
  };

  return (
    <Box p={2}>
      <Breadcrumbs />
      <Box {...rest} mt={3} mb={3}>
        <Grid className={classes.topContainer} container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Typography color="secondary" className={classes.balanceText}>
              {t("payout.current_balance")}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.balanceText}>1 000 000 XAF</Typography>
          </Grid>
        </Grid>
        <Divider />
      </Box>

      <Form {...rest} initialValues={record} onSubmit={submit}>
        <Card cardTitle={t("payout.payout_information")} variant="outlined">
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.amount")}*</Typography>
              <TextInput
                source="amount"
                label={t("payout.amount")}
                type="text"
                fullWidth
                placeholder={t("payout.amount_placeholder")}
                validate={validateAmount}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.country")}*</Typography>
              <SelectInput
                label={t("payout.country")}
                source="country"
                resource="payout-account"
                choices={countryChoice}
                validate={validateCountry}
                initialValue={"+237"}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.select_account")}*</Typography>
              <SelectInput
                label={t("payout.select_account")}
                source="requestAccountId"
                resource="payout-account"
                choices={Object.values(requestAccountData)?.filter(
                  (item) => item?.coreCompanyId === collectionCompanyId
                )}
                validate={validateAccount}
                fullWidth
                optionValue="id"
                optionText="accountName"
                onChange={handleAccountChange}
                loading={isLoading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.iban_number")}*</Typography>
              <TextInput
                source="accountNumber"
                label={t("payout.iban_number")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.accountNumber}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.account_holder_name")}*</Typography>
              <TextInput
                source="accountHolderName"
                label={t("payout.account_holder_name")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.accountHolderName}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.address")}*</Typography>
              <TextInput
                source="address"
                label={t("payout.address")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.address}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.state_province")}*</Typography>
              <TextInput
                source="state"
                label={t("payout.state_province")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.state}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.city")}*</Typography>
              <TextInput
                source="city"
                label={t("payout.city")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.city}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.label}>{t("payout.swift_code")}*</Typography>
              <TextInput
                source="accountSwiftCode"
                label={t("payout.swift_code")}
                type="text"
                fullWidth
                initialValue={autoFill?.data?.accountSwiftCode}
                disabled
              />
            </Grid>
          </Grid>
        </Card>
        <Box mt={3} />
        <Dialog
          title={t("payout.add_note")}
          type="input"
          open={openNoteDialog}
          onClose={() => setOpenNoteDialog(false)}
          onSubmit={(e) => addNote(e)}
          inputProps={{
            multiline: true,
            minRows: 4
          }}
        ></Dialog>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Card cardTitle={t("payout.payout_attachments")} variant="outlined">
              {/* <ArrayInput source="attachments">
                    <SimpleFormIterator
                      disableAdd={!addAttachmentEnabled}
                      disableRemove={!addAttachmentEnabled}
                      initialValue={0}
                      disableReordering={true}
                      getItemLabel={(index) => getAttachmentArrayIndex(index)}
                    >
                      <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                          return (
                            <Grid container spacing={3} style={{ width: "100%" }}>
                              <Grid item xs={2}>
                                <Box mt="1em" />
                                <Typography className={classes.label} style={{ paddingTop: 0 }}>
                                  {t("prl.attachment_label")}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Box mt="1em" />
                                <TextInput
                                  source={getSource("documentLabel")}
                                  record={scopedFormData}
                                  label={t("prl.attachment_label")}
                                  resource="prl"
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <FileInput
                                  source={getSource("documentUrl")}
                                  record={scopedFormData}
                                  label=""
                                  accept="application/pdf"
                                >
                                  <FileField source="src" title="title" />
                                </FileInput>
                              </Grid>
                            </Grid>
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput> */}
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card
              headerActionContent={
                <Button
                  variant="contained"
                  color="secondary"
                  label={t("payout.add_note")}
                  onClick={() => setOpenNoteDialog(true)}
                ></Button>
              }
              cardTitle={t("payout.payout_instructions")}
              variant="outlined"
            ></Card>
          </Grid>
        </Grid>
        <Toolbar>
          <Box mt={3} display="flex" justifyContent="end" width="100%">
            <BackButton variant="outlined" color="secondary">
              {t("ra.action.cancel")}
            </BackButton>
            <SaveButton
              style={{ margin: "0px 15px" }}
              color="secondary"
              label={t("ra.action.save")}
              saving={loadingCreatePayoutRequest}
              redirect="/payout-request"
              // handleSubmitWithRedirect={formProps.handleSubmit}
            />
          </Box>
        </Toolbar>
      </Form>
    </Box>
  );
};

export default withStyles(Styles)(PayoutRequestCreateEditForm);
