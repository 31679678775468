import { useRecordContext } from "react-admin";
import { LocalizedFieldObject } from "api/types/prlTypes";

const LangField = ({ source }: { source: string }): JSX.Element => {
  const record = useRecordContext();
  const value = record[source]?.find((p: LocalizedFieldObject) => p.lang === "en");

  return <div>{value?.text}</div>;
};
export default LangField;
