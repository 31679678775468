import { ChevronLeft } from "@mui/icons-material";
import { ButtonGroup, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BackButton from "components/other/ButtonBack";
import { APPROVED, REJECTED } from "constants/status";
import { Show, usePermissions, useRecordContext, useTranslate } from "react-admin";
import findPermission from "utils/findPermission";
import ApproveRejectButton from "components/other/ApproveRejectButton";
import PdfButton from "./components/PdfButton";
import KycDataShowLayout from "components/ra/Show/kyc/KycDataShowLayout";
import { KYC_MARK_AS_APPROVED, KYC_MARK_AS_REJECTED } from "constants/permissionsNames";

const ListActions = ({ permissions }: { permissions: string[] }) => {
  const permissionMarkAsApproved = findPermission(permissions, KYC_MARK_AS_APPROVED);
  const permissionMarkAsRejected = findPermission(permissions, KYC_MARK_AS_REJECTED);
  const t = useTranslate();
  const record = useRecordContext();
  return (
    <Box sx={{ marginBottom: 1, display: "flex", justifyContent: "space-between" }}>
      <Box>
        <BackButton startIcon={<ChevronLeft />}>{t("ra.action.back")}</BackButton>

        <ButtonGroup sx={{ marginLeft: 5 }}>
          {permissionMarkAsApproved && record?.status !== APPROVED && (
            <ApproveRejectButton buttonType="approve" title={t("cc.dialog.approve_text_kyc")} />
          )}
          {permissionMarkAsRejected && record?.status !== REJECTED && (
            <ApproveRejectButton buttonType="reject" title={t("cc.dialog.reject_text_kyc")} />
          )}
        </ButtonGroup>
      </Box>
      <ButtonGroup size="medium">
        <PdfButton variant="outlined" type="internal" />
        <PdfButton variant="contained" type="external" />
      </ButtonGroup>
    </Box>
  );
};

const KycDataShow = () => {
  const { permissions } = usePermissions();
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"entity"} page={"show"} />
      </Toolbar>
      <Show resource="entity" component="div" actions={<ListActions permissions={permissions} />}>
        <KycDataShowLayout />
      </Show>
    </>
  );
};

export default KycDataShow;
