import { Box, Grid, Paper, Typography } from "@mui/material";
import CardGrid from "components/material/Card/CardGrid";
import Chip from "components/material/Chip/Chip";
import StackList from "components/material/custom/StackList/StackList";
import useGetTranslation from "hooks/utils/useGetTranslation";
import formatCurrency from "utils/formatCurrency";

interface RemintanceDetailsProps {
  moneyInformationCardFields: {
    total_amount: number | undefined;
    statusTextLabel: string;
    paymentStatus: string;
    list: any;
  };
  deliveryChannelFields: {
    list: any;
    addtionalInfo: any;
  };
  recipientDetailFields: {
    content: any;
    children: JSX.Element;
  };
  senderDetailFields: {
    content: any;
    children: JSX.Element;
  };
  additionalInformationfields: {
    content: any;
  };
  attachmentFields: {
    content: any;
  };
}

const RemittanceDetailsLayout = (props: RemintanceDetailsProps) => {
  const {
    moneyInformationCardFields,
    deliveryChannelFields,
    recipientDetailFields,
    senderDetailFields,
    additionalInformationfields,
    attachmentFields
  } = props;
  const getTranslation = useGetTranslation();

  const getContent = (vals: any) => {
    return Object.keys(vals)?.map((key) => ({ [key]: vals[key] }));
  };

  return (
    <>
      <Grid container mt={3} columnSpacing={1}>
        <Grid item xs={6} sx={{ padding: "24px 0" }}>
          <Paper>
            <Box
              sx={{ padding: "0 24px" }}
              display={"flex"}
              marginBottom={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography variant="body1" fontSize={32} fontWeight={"bold"}>
                  {formatCurrency(moneyInformationCardFields?.total_amount)}
                </Typography>
              </Box>
              <Chip
                label={moneyInformationCardFields?.statusTextLabel}
                variant="filled"
                status={moneyInformationCardFields?.paymentStatus}
              />
            </Box>
            <StackList
              list={moneyInformationCardFields?.list}
              spacing={1}
              sx={{
                padding: "10px 24px"
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              padding: "10px 24px 0px"
            }}
          >
            {getTranslation("delivery_information")}
          </Typography>
          <StackList
            list={deliveryChannelFields?.list}
            spacing={1}
            sx={{
              padding: "10px 24px"
            }}
          />
          {deliveryChannelFields?.addtionalInfo}
        </Grid>
      </Grid>

      <CardGrid
        resource={"remittance"}
        cardTitle={getTranslation("recipient_details")}
        content={getContent(recipientDetailFields?.content)}
        variant="classic-dashed"
      >
        {recipientDetailFields?.children}
        {/* <Tooltip title={recipient_country ?? ""} placement="left">
          <Box component="div">
            <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
              {getTranslation("recipient_country")} :
            </Typography>
            <Typography>{recipient_country}</Typography>
          </Box>
        </Tooltip> */}
      </CardGrid>
      <CardGrid
        resource={"remittance"}
        cardTitle={getTranslation("sender_details")}
        content={getContent(senderDetailFields?.content)}
        variant="classic-dashed"
      >
        {senderDetailFields?.children}
        {/* <Tooltip title={sender_country ?? ""} placement="left">
          <Box component="div">
            <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
              {getTranslation("sender_country")} :
            </Typography>
            <Typography>{sender_country}</Typography>
          </Box>
        </Tooltip> */}
      </CardGrid>
      <CardGrid
        resource={"remittance"}
        cardTitle={getTranslation("additional_information")}
        content={getContent(additionalInformationfields?.content)}
        variant="classic-dashed"
      />
      <CardGrid
        cardTitle={getTranslation("attachment")}
        resource="remittance"
        content={getContent(attachmentFields?.content)}
        variant="classic-dashed"
      />
    </>
  );
};

export default RemittanceDetailsLayout;
