import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { getStorageToken } from "../../utils/storageUtils";
import createAttachmentDataProvider from "./utils/createPayoutRequestAttachmentDataProvider";

import config from "config";
import { stringify } from "query-string";

const httpClient = (url, options = {}) => {
  options.headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getStorageToken()}`
  });

  return fetchUtils.fetchJson(url, options);
};

const proxyUrl = config.REACT_APP_PROXY_BASE_URL;
const dataProvider = simpleRestProvider(proxyUrl, httpClient);
const attachmentDataProvider = createAttachmentDataProvider(proxyUrl, httpClient);

const pssDataProvider = {
  ...dataProvider,
  ...attachmentDataProvider,
  confirmMerchantPayment: (resource, params) => {
    const { id, confirmedAt } = params;
    const url = `${proxyUrl}/${resource}/${id}/confirm`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ confirmedAt })
    }).then(({ json }) => ({ data: json }));
  },
  getFsp: (resource) => {
    const query = {};
    const url = `${proxyUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json || [],
      total: json.max
    }));
  },
  addNote: (resource, params) => {
    const { id, note } = params;
    const url = `${proxyUrl}/${resource}/${id}/confirmation-notes`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ note })
    }).then(({ json }) => ({ data: json }));
  },
  approvePayoutRequest: (resource, params) => {
    const { id, reason } = params;
    const url = `${proxyUrl}/${resource}/${id}/approve`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ reason })
    }).then(({ json }) => ({ data: json }));
  },
  rejectPayoutRequest: (resource, params) => {
    const { id, reason } = params;
    const url = `${proxyUrl}/${resource}/${id}/reject`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ reason })
    }).then(({ json }) => ({ data: json }));
  }
};

export default pssDataProvider;
