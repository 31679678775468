import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import React from "react";
import { Edit } from "react-admin";
import PayoutAccountCreateEditForm from "../Form/PayoutAccountCreateEditForm";
import { withStyles } from "@mui/styles";

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};

const PayoutAccountEdit = ({ permissions, ...rest }) => {
  return (
    <>
      <Breadcrumbs />
      <Edit {...rest}>
        <PayoutAccountCreateEditForm permissions={permissions} />
      </Edit>
    </>
  );
};

export default withStyles(styles)(PayoutAccountEdit);
