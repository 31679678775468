const merchantMockData = {
  merchant: [
    {
      id: "CM10007",
      merchantId: 245,
      name: "Test Merchant",
      phonenumber: "+1-865-212",
      email: "ramona.hammes@christiansen.net",
      address: "60522 Helena Villages Suite 705Nayelimouth",
      coreCompanyId: "CM10007",
      apiUserId: "CM10124 - MABS TEST 2",
      apiKey: "A4652C55-3ABA-64BF-5158-3F1A64715288",
      createdAt: "2022-03-15 03:05:43",
      updatedAt: "2022-03-15 15:08:50",
      deletedAt: "",
      createdBy: "9fda8487-f074-319c-b36f-30d496647a07",
      updatedBy: "TEST smobilpay Ltd Administrator",
      deletedBy: ""
    }
  ]
};

export default merchantMockData;
