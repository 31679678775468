import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from "@mui/material";
import CardBase from "components/material/Card/CardBase";
import React from "react";
import { useRecordContext } from "react-admin";
import { BulkPaymentTableHead } from "../components/BulkPaymentTableHead";
import { getComparator, stableSort } from "../components/utils/BulkPaymentHistoryTableSort";
import { headCells } from "../components/utils/OderColumnsBulkPaymentHistoryTable";

const BulkPaymentHistoryTab = () => {
  const record = useRecordContext();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ptn");
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - record?.payments?.length) : 0;
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CardBase noPadding variant="classic-white">
      <TableContainer>
        <Table size="small" data-testid="payment-history-table">
          <BulkPaymentTableHead
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? stableSort(record?.payments, getComparator(order, orderBy))?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : stableSort(record?.payments, getComparator(order, orderBy))
            )?.map((payment) => (
              <TableRow>
                <TableCell align="left" data-testid="ptn-table-cell">
                  {payment?.ptn}
                </TableCell>
                <TableCell align="left" data-testid="status-table-cell">
                  {payment?.status}
                </TableCell>
                <TableCell align="left" data-testid="amount-table-cell">
                  {payment?.amount}
                </TableCell>
                <TableCell align="left" data-testid="customer_name-table-cell">
                  {payment?.customer_name}
                </TableCell>
                <TableCell align="left" data-testid="customer_reference-table-cell">
                  {payment?.customer_reference}
                </TableCell>
                <TableCell align="left" data-testid="trid-table-cell">
                  {payment?.trid}
                </TableCell>
                <TableCell align="left" data-testid="service_number-table-cell">
                  {payment?.service_number}
                </TableCell>
                <TableCell align="left" data-testid="service_id-table-cell">
                  {payment?.mpay_service_label}{" "}
                  <span>{payment?.mpay_serviceId && `(${payment?.mpay_serviceId})`}</span>
                </TableCell>
                <TableCell align="left">{payment?.error}</TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                count={record?.payments?.length ? record?.payments?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page"
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonText="Previous"
                nextIconButtonText="Next"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </CardBase>
  );
};

export default BulkPaymentHistoryTab;
