import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Create, CreateProps } from "react-admin";
import PrlCreateEditForm from "components/ra/Form/PrlCreateEditForm";
import { Toolbar } from "@mui/material";
import { FORM_TYPE_CREATE } from "constants/formTypes";

const PrlCreate = (props: CreateProps) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"prl"} page={"create"} />
      </Toolbar>
      <Create {...props} component="div" resource="prl">
        <PrlCreateEditForm formType={FORM_TYPE_CREATE} />
      </Create>
    </>
  );
};

export default PrlCreate;
