import { useEffect, useState } from "react";
import ReactDatepicker from "react-datepicker";
import { useInput, useResourceContext, useTranslate } from "react-admin";
import { rest } from "lodash";
import { TextField } from "@mui/material";
import getUpstreamRefinedDates from "components/other/Datepicker/utils/getUpstreamRefinedDates";
import getDownstreamRefinedDates from "components/other/Datepicker/utils/getDownstreamRefinedDates";

const DatepickerRange = ({ source, dataTestId }: { source: string; dataTestId: string }) => {
  const resource = useResourceContext();
  const t = useTranslate();
  const {
    field: { onChange, value, name }
  } = useInput({ source, ...rest });
  const [startDate, setStartDate] = useState<Date | null>(value ? getDownstreamRefinedDates(value)[0] : null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleOnChange = (dates: [Date, Date | null]) => {
    const refinedDates = getUpstreamRefinedDates(dates);

    onChange(refinedDates);
  };

  useEffect(() => {
    if (value) {
      const refinedDates = getDownstreamRefinedDates(value);
      setStartDate(refinedDates[0]);
      setEndDate(refinedDates[1]);
    }
  }, [value]);

  return (
    <ReactDatepicker
      name={name}
      selected={startDate}
      onChange={handleOnChange}
      startDate={startDate}
      customInput={
        <TextField
          data-testid={dataTestId}
          label={t(`resources.${resource}.fields.${source}`)}
          inputProps={{ autoComplete: "off" }}
        />
      }
      endDate={endDate}
      selectsRange
    />
  );
};

export default DatepickerRange;
