import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "@mui/material";

const BackButton = ({ children, ...props }: ButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button {...props} onClick={() => navigate(-1)} data-testid="back-button">
      {children}
    </Button>
  );
};

export default BackButton;
