import { Box, colors, Button, Divider, BoxProps } from "@mui/material";
import { useTranslate } from "react-admin";
export interface IGridSelectionPageData {
  options: { id: number; isChecked: boolean; label: string; value: string | number }[];
  name: string;
  isSelectAll: boolean;
}

interface IGridSelectionActions extends BoxProps {
  data: IGridSelectionPageData[];
  onClearSelection?: () => void;
  activeTab?: any;
  setActiveTab?: any;
}

const GridSelectionActions = ({
  data,
  onClearSelection,
  onClick,
  activeTab,
  setActiveTab,
  ...rest
}: IGridSelectionActions) => {
  const t = useTranslate();

  return (
    <Box display={"flex"} alignItems={"center"} {...rest}>
      <Button
        onClick={() => {
          onClearSelection && onClearSelection();
        }}
        variant="outlined"
        sx={{ marginRight: 2, fontWeight: 600 }}
      >
        {t(`resources.${["remittance"]}.fields.clear_selection`)}
      </Button>
      <Divider orientation="vertical" flexItem />
      {data?.map((item, index) => (
        <Button
          onClick={() => {
            setActiveTab({ item, index });
          }}
          sx={{
            color: activeTab?.index === index ? colors.cyan["600"] : colors.grey["900"],
            marginLeft: index === 0 ? 1 : 0,
            fontWeight: 600
          }}
        >
          {item.name}({item.options.filter((option) => option.isChecked).length})
        </Button>
      ))}
    </Box>
  );
};

export default GridSelectionActions;
