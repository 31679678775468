import DeleteButton from "components/ra/Button/DeleteButton";
import { BomDeleteButtonTypes } from "components/types/otherComponentTypes";
import { OPEN } from "constants/status";
import React from "react";
import { useRecordContext } from "react-admin";

const BomDeleteButton: React.FC<BomDeleteButtonTypes> = (props) => {
  const { forResource, dataTestId } = props;

  const record = useRecordContext(props);

  const statusChoice = (currStatus: string | number) => {
    switch (currStatus) {
      case "NEW":
      case "PENDING":
      case "NOSTATUS":
      case 2:
      case "READY":
      case "VALIDATIONERROR":
        return false;
      default:
        return true;
    }
  };

  const resourceChoice = (rsc: string | number | void) => {
    switch (rsc) {
      case "payout-request":
      case "bulk_payment":
      case "bulk_collection":
        return (
          <DeleteButton data-testid={dataTestId} disabled={statusChoice(record?.status)} record={record} />
        );
      default:
        return record?.paymentStatus === OPEN ? (
          <DeleteButton data-testid={dataTestId} record={record} />
        ) : null;
    }
  };

  return resourceChoice(forResource);
};

export default BomDeleteButton;
