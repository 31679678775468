import Tabs from "components/material/Tabs";
import React from "react";
import { usePermissions, useTranslate } from "react-admin";
import BulkCollectionGeneralTab from "./tabs/BulkCollectionGeneralTab";
import { Grid, Typography } from "@mui/material";
import BulkCollectionHistoryTab from "./tabs/BulkCollectionHistoryTab";
import BulkCollectionImportedTab from "./tabs/BulkCollectionImportedTab";
import BulkCollectListApproveReject from "components/ra/List/BulkCollectionList/components/BulkCollectListApproveReject";
import BulkCollectionAuditTab from "./tabs/BulkCollectionAuditTab";

const BulkCollectionDataShowLayout = (props) => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  return (
    <>
      <Grid
        container
        direction={"row"}
        style={{ width: "100%", paddingBottom: 55, paddingTop: 35, paddingLeft: 24 }}
        alignItems={"center"}
      >
        {permissions === "bulkpay_approver" && (
          <Typography style={{ paddingRight: 45, fontWeight: 700, fontSize: 18 }}>
            {t("payout.process_request")}
          </Typography>
        )}
        <Grid>{permissions === "bulkpay_approver" && <BulkCollectListApproveReject show {...props} />}</Grid>
      </Grid>
      <Tabs
        tabLabels={[
          `${t("bulk_collection.general_information")}`,
          `${t("bulk_collection.imported_record")}`,
          `${t("bulk_collection.payment_history")}`,
          `${t("bulk_collection.audit")}`
        ]}
        onChange={handleChange}
        activeTabIndex={activeTabIndex}
      >
        <BulkCollectionGeneralTab {...props} setActiveTabIndex={setActiveTabIndex} />
        <BulkCollectionImportedTab {...props} />
        <BulkCollectionHistoryTab {...props} />
        <BulkCollectionAuditTab {...props} />
      </Tabs>
    </>
  );
};

export default BulkCollectionDataShowLayout;
