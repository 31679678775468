export const headCells = [
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "resources.bulk_payment.fields.user",
    sortable: false
  },
  {
    id: "reasons",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "resources.bulk_payment.fields.reason",
    sortable: false
  },
  {
    id: "time",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "resources.bulk_payment.fields.time",
    sortable: false
  }
];
