import { Image, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import { SxProps } from "@mui/material";

const renderMimeTypeIcon = (type?: string, sx?: SxProps) => {
  switch (type) {
    case "image/png":
    case "image/jpeg":
      return <Image sx={sx} />;

    case "application/pdf":
      return <PictureAsPdf sx={sx} />;

    default:
      return <InsertDriveFile sx={sx} />;
  }
};

export default renderMimeTypeIcon;
