import { Toolbar } from "@mui/material";
import CompanyPrlLayout from "./CompanyPrlLayout";

const CompanyPrl = () => {
  return (
    <Toolbar disableGutters={true}>
      <CompanyPrlLayout />
    </Toolbar>
  );
};

export default CompanyPrl;
