import { flatten, fromPairs, isArray, toPairs } from "lodash";
import { GetListParams } from "react-admin";
import dayjs from "dayjs";

type FilterType = Pick<GetListParams, "filter">;
const splitFilterDateRangeKeys = (filter: FilterType) => {
  let refinedFilter: any = filter;
  // Convert object to array
  refinedFilter = toPairs(refinedFilter);
  // Split key into key_start and key_end and assign proper values
  refinedFilter = refinedFilter.map(([key, val]: [key: string, val: string]) => {
    // Check if it is array of date strings (represents datepicker format)
    const isDateStr = dayjs(val[0]).isValid();
    if (isArray(val) && isDateStr) {
      return [
        [`${key}_start`, val[0]],
        [`${key}_end`, val[1]]
      ];
    }

    return [[key, val]];
  });

  // Array flattening
  refinedFilter = flatten(refinedFilter);
  // Convert back array to object
  refinedFilter = fromPairs(refinedFilter);

  return refinedFilter;
};

export default splitFilterDateRangeKeys;
