import polyglotI18nProvider from "ra-i18n-polyglot";
import { en } from "i18n/en.ts";
import { fr } from "i18n/fr.ts";

const translations = { en, fr };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "en", // default locale
  [
    { locale: "en", name: "English" },
    { locale: "fr", name: "Français" }
  ]
);
