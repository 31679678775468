import { useMutation, useQueryClient } from "react-query";
import { DELETE_ATTACHMENT, UPDATE_ATTACHMENT, UPLOAD_ATTACHMENT } from "constants/dataProviderTypes";
import queryErrorHandler from "api/queryErrorHandler";
import {
  Identifier,
  NotificationType,
  useDataProvider,
  useNotify,
  useResourceContext,
  useTranslate
} from "react-admin";
import { DropzoneFileType } from "components/types/otherComponentTypes";
import { FORM_TYPE_EDIT } from "constants/formTypes";

const useMutatePrlAttachment = ({
  formType,
  setModalOpen
}: {
  formType: "create" | "edit";
  setModalOpen: (e: boolean) => void;
}) => {
  const notify = useNotify();
  const resource = useResourceContext();
  const t = useTranslate();
  const showNotify = (action: string, key: string, smart_count = 1, type: NotificationType = "success") =>
    notify(t(`cc.notification.${action}_${key}`, { smart_count }), { type });

  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const { mutate: mutateUploadAttachment, isLoading: isLoadingUploadAttachment } = useMutation(
    [resource, UPLOAD_ATTACHMENT],
    ({ id, files }: { id: Identifier; files: DropzoneFileType[] }) => {
      return dataProvider.uploadAttachment(resource, { id, data: { files } });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([resource]);
        if (formType === FORM_TYPE_EDIT) {
          showNotify("uploaded", "attachment");
        } else {
          showNotify("created", resource);
          setModalOpen(true);
        }
      },
      onError: (error) => queryErrorHandler({ error, notify })
    }
  );

  const { mutate: mutateUpdateAttachment, isLoading: isLoadingUpdateAttachment } = useMutation(
    [resource, UPDATE_ATTACHMENT],
    ({ id, file, label }: { id: Identifier; file: DropzoneFileType; label: string }) => {
      return dataProvider.updateAttachment(resource, { id, data: { file, label } });
    },
    {
      onSuccess: async () => {
        showNotify("updated", "attachment");
        await queryClient.invalidateQueries([resource]);
      },
      onError: (error) => queryErrorHandler({ error, notify })
    }
  );

  const { mutate: mutateDeleteAttachment, isLoading: isLoadingDeleteAttachment } = useMutation(
    [resource, DELETE_ATTACHMENT],
    ({ id, file }: { id: Identifier; file: DropzoneFileType }) => {
      return dataProvider.deleteAttachment(resource, { id, data: { file } });
    },
    {
      onSuccess: async () => {
        showNotify("deleted", "attachment");
        await queryClient.invalidateQueries([resource]);
      },
      onError: (error) => queryErrorHandler({ error, notify })
    }
  );

  const isLoading = isLoadingUpdateAttachment || isLoadingDeleteAttachment || isLoadingUploadAttachment;
  return { isLoading, mutateUploadAttachment, mutateUpdateAttachment, mutateDeleteAttachment };
};

export default useMutatePrlAttachment;
