import { makeStyles } from "@mui/styles";
import {
  Datagrid,
  FilterButton,
  List,
  RaRecord,
  ResourceContextProvider,
  SelectArrayInput,
  SelectArrayInputProps,
  TextField,
  TextInput,
  TopToolbar,
  UseRecordContextParams,
  useTranslate
} from "react-admin";
import { getStorageMerchantCCId } from "utils/storageUtils";
import ListPayoutAccountProvider from "./components/ListPayoutAccountProvider";
import ListPayoutAccountSetAsDefault from "./components/ListPayoutAccountSetAsDefault";
import CreateButton from "components/ra/Button/CreateButton";
import EditButton from "components/ra/Button/EditButton";
import DeleteButton from "components/ra/Button/DeleteButton";
import ShowButton from "components/ra/Button/ShowButton";

const financialServiceProviderChoices = [
  { id: "YoomeeMoney", name: "YoomeeMoney" },
  { id: "BICEC Bank", name: "BICEC Bank" },
  { id: "OrangeMoney", name: "OrangeMoney" },
  { id: "MTN Mobile Money", name: "MTN Mobile Money" }
];

const useStyles = makeStyles({
  root: { minWidth: 220 }
});

const StyledSelectArrayInput = (props: SelectArrayInputProps) => {
  const classes = useStyles();
  return <SelectArrayInput className={classes.root} {...props} />;
};

const PayoutAccountList = ({ permissions }: UseRecordContextParams<RaRecord>) => {
  const translate = useTranslate();
  const merchantCoreCompanyId = getStorageMerchantCCId();

  const ListActions = () => {
    return (
      <TopToolbar>
        <FilterButton />
        <CreateButton />
      </TopToolbar>
    );
  };

  const filterProductList = [
    <StyledSelectArrayInput
      label={translate("payout.financial_provider")}
      source="accountName"
      choices={financialServiceProviderChoices}
    />,
    <TextInput label={translate("payout.account_number")} source="accountNumber" />,
    <TextInput label={translate("payout.account_holder_name")} source="accountHolderName" />
  ];
  return (
    <ResourceContextProvider value="request-account">
      <>
        <List
          actions={<ListActions />}
          filters={filterProductList}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <Datagrid bulkActionButtons={false}>
            <ListPayoutAccountProvider label={translate("payout.financial_provider")} />
            <TextField label={translate("payout.account_number")} source="accountNumber" />
            <TextField label={translate("payout.account_holder_name")} source="accountHolderName" />
            <EditButton />
            <ShowButton />
            <DeleteButton />
            {merchantCoreCompanyId && <ListPayoutAccountSetAsDefault redirect="/request-account" />}
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default PayoutAccountList;
