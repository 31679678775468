const paymentsMockData = {
  payments: [
    {
      id: 1,
      coreCompanyId: "Njeiforbi PLC",
      amount: "10000",
      accountType: "Mobile Money",
      paymentMethodId: "MTNMOMO",
      accountNumber: "1002 5000 5038 3010 4418 618",
      status: "APPROVED",
      accountSwiftCode: "BXDC903",
      attachments: [{ id: 1 }],
      accountHolderName: "BISEC Bank",
      address: "Bonanjo",
      state: "Litoral",
      city: "Douala",
      information: "Additional information about account",
      createdAt: "2022-02-04",
      updatedAt: "2022-02-04"
    },
    {
      id: 2,
      coreCompanyId: "Careffou market",
      amount: "30000",
      accountType: "Bank",
      paymentMethodId: "BISEC",
      accountNumber: "1002 5000 5038 3010 4418 618",
      status: "APPROVED",
      accountSwiftCode: "BXDC903",
      attachments: [{ id: 1 }],
      accountHolderName: "BISEC Bank",
      address: "Bonanjo",
      state: "Litoral",
      city: "Douala",
      information: "Additional information about account",
      createdAt: "2022-02-04",
      updatedAt: "2022-02-04"
    },
    {
      id: 3,
      coreCompanyId: "Guiness SA",
      amount: "500000",
      accountType: "Mobile Money",
      paymentMethodId: "ORANGE Money",
      accountNumber: "1002 5000 5038 3010 4418 618",
      status: "PROCESSING",
      accountSwiftCode: "BXDC903",
      attachments: [{ id: 1 }],
      accountHolderName: "Guiness Cameroon",
      address: "Bonanjo",
      state: "Litoral",
      city: "Douala",
      information: "Additional information about account",
      createdAt: "2022-02-04",
      updatedAt: "2022-02-04"
    }
  ]
};

export default paymentsMockData;
