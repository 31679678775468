import { Box, colors, Divider, Grid, Typography, Button as CustomButton } from "@mui/material";
import { Close, Check } from "@mui/icons-material";
import React, { useState } from "react";
import { Button, useLocale, useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";
import CardGrid from "components/material/Card/CardGrid";
import BomAttachment from "components/material/List/ListAttachment";
import { isEmpty } from "lodash";
import formatDate from "utils/formatDate";
import ChipStatus from "components/other/context/StatusField";
import AlertStatus from "components/other/context/AlertStatus";
import { renderLogo } from "utils/logoUtils";
import ProviderLogo from "components/other/context/ProviderLogo";
import findPermission from "utils/findPermission";
import { APPROVE_PAYOUT_REQUEST, REJECT_PAYOUT_REQUEST } from "constants/dataProviderTypes";
import Dialog from "components/material/Dialog";
import { APPROVED, PAID, REJECTED } from "constants/status";
import { withStyles } from "@mui/styles";
import { useMutation } from "react-query";

const styles = {
  balanceText: {
    fontWeight: "bold"
  },
  processRequest: {
    fontWeight: "bold"
  },
  topContainer: {
    padding: `10px 25px`
  },
  bolderText: {
    fontSize: 18,
    fontWeight: "bolder",
    color: colors.grey[600]
  },
  approveButton: {
    backgroundColor: colors.green[600],
    color: "white",
    fontWeight: "bold",
    width: 130,
    "&:hover": {
      backgroundColor: colors.green[800]
    }
  },

  rejectButton: {
    backgroundColor: colors.red[500],
    color: "white",
    fontWeight: "bold",
    width: 130,
    "&:hover": {
      backgroundColor: colors.red[900]
    }
  }
};

const PayoutRequestShow = ({ permissions, classes, ...rest }) => {
  const t = useTranslate();
  const locale = useLocale();
  const record = useRecordContext();
  const pssRequestApproveAny = findPermission(permissions, "pss_request_approve_any");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentApprovalState, setCurrentApprovalState] = useState("");
  const [mutateApprove] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  const statusMessageChoice = (currStatus) => {
    switch (currStatus?.toUpperCase()) {
      case "PAID":
        return "";
      case "APPROVED":
        return t("payout.transaction_approved_msg");
      case "NEW":
      case "CREATED":
      case "PENDING":
        return t("payout.transaction_check_msg");
      case "REJECTED":
        return t("payout.transaction_failed_msg");
      default:
        return "";
    }
  };

  const topInformation = [
    {
      [`${t("payout.amount")}`]: <Typography className={classes.bolderText}>{record?.amount} XAF</Typography>
    },
    { [`${t("kyc.status")}`]: <ChipStatus record={record} source="status" /> },
    record?.payoutRequestStatus !== PAID &&
      record?.payoutRequestStatus === REJECTED && {
        [`${t("kyc.reason")}`]: record?.statusReason?.find((ln) => ln.lang === locale)?.text
      },
    {
      [`${t("payout.decision_date")}`]: `${formatDate(record?.createdAt)} , ${new Date(
        record?.createdAt
      ).toLocaleTimeString()}`
    }
  ];

  const payoutRequestInformationContent = [
    { [`${t("payout.request_date")}`]: formatDate(record?.createdAt) },
    {
      [`${t("payout.service_provider")}`]: renderLogo(record?.requestInfo?.fspId) ? (
        <>
          <ProviderLogo provider={record?.requestInfo?.fspId} />
          <Typography>{record?.requestInfo?.fspId}</Typography>
        </>
      ) : (
        <Typography>{record?.requestInfo?.fspId}</Typography>
      )
    },
    { [`${t("payout.iban_number")}`]: record?.requestInfo?.accountNumber },
    { [`${t("payout.account_holder_name")}`]: record?.requestInfo?.accountName },
    {
      [`${t("prl.attachment")}`]: (
        <BomAttachment
          fileResource="payment-request"
          isEmpty={isEmpty(record?.attachments)}
          isLoading={false}
          content={record?.attachments}
        />
      )
    },
    {
      [`${t("payout.payment_date")}`]: `${formatDate(record?.createdAt)} , ${new Date(
        record?.createdAt
      ).toLocaleTimeString()}`
    }
  ];

  const handleClickApprove = () => {
    setOpenDialog(true);
    setCurrentApprovalState("approve");
  };

  const handleClickReject = () => {
    setOpenDialog(true);
    setCurrentApprovalState("reject");
  };

  const processApproveOrReject = (reason) => {
    if (!isEmpty(reason)) {
      if (pssRequestApproveAny || currentApprovalState !== "") {
        const type = currentApprovalState === "approve" ? APPROVE_PAYOUT_REQUEST : REJECT_PAYOUT_REQUEST;
        mutateApprove(
          {
            type: type,
            resource: "payout-request",
            payload: {
              id: record?.id,
              reason
            }
          },
          {
            onSuccess: () => {
              notify(
                currentApprovalState === "approve" ? t("payout.approved") : t("payout.rejected"),
                "success"
              );
              setOpenDialog(false);
              refresh();
            },

            onFailure: (err) => {
              notify(err?.body?.raw, { type: "error" });
              setOpenDialog(false);
            }
          }
        );
      }
    } else {
      notify(t("payout.please_enter_reason"), { type: "error" });
    }
  };

  return (
    <>
      <Dialog
        title={t("payout.reason")}
        type="input"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={(e) => processApproveOrReject(e)}
        inputProps={{
          multiline: true,
          minRows: 4
        }}
      />
      <Box p={2}>
        <Box {...rest} mt={3} mb={2}>
          <Grid className={classes.topContainer} container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Typography color="secondary" className={classes.balanceText}>
                {t("payout.current_balance")}:
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.balanceText}>1 000 000 XAF</Typography>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        {record?.status !== "PAID" && !pssRequestApproveAny && (
          <AlertStatus
            record={record}
            source="status"
            label={statusMessageChoice(record?.status.toUpperCase())}
          />
        )}
        {pssRequestApproveAny && (
          <Box mt={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="span" className={classes.processRequest}>
                  {t("payout.process_request")}
                </Typography>
              </Grid>
              {record?.status?.toLowerCase() !== APPROVED.toLowerCase() && (
                <Grid item>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    className={classes.approveButton}
                    startIcon={<Check />}
                    onClick={handleClickApprove}
                  >
                    {t("payout.approve")}
                  </CustomButton>
                </Grid>
              )}
              {record?.status?.toLowerCase() !== REJECTED.toLowerCase() && (
                <Grid item>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    className={classes.rejectButton}
                    startIcon={<Close />}
                    onClick={handleClickReject}
                  >
                    {t("payout.reject")}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        <Box mt={3} />
        <CardGrid variant="outlined" content={topInformation}></CardGrid>
        <Box mt={3} />
        <CardGrid
          variant="outlined"
          cardTitle={t("payout.payout_request_details")}
          content={payoutRequestInformationContent}
        ></CardGrid>
        <Box mt={3}>
          <Grid container xs={12} justifyContent="flex-end">
            <Button
              color="secondary"
              label={t("ra.action.back")}
              variant="contained"
              size="large"
              href="/#/payout-request"
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(PayoutRequestShow);
