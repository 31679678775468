import { RequestPrlFulfilledObject, RequestPrlObject } from "api/types/prlTypes";
import config from "config";
import { stringify } from "query-string";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils, GetListParams } from "react-admin";
import { getStorageToken } from "utils/storageUtils";
import createAttachmentDataProvider from "./utils/createAttachmentDataProvider";
import splitFilterDateRangeKeys from "api/dataProvider/utils/splitFilterDateRangeKeys";

const prlHttpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const proxyUrl: string = config.REACT_APP_PROXY_BASE_URL;

const dataProvider = simpleRestProvider(proxyUrl, prlHttpClient);
const attachmentDataProvider = createAttachmentDataProvider(proxyUrl, prlHttpClient);

const prlDataProvider = {
  ...dataProvider,
  ...attachmentDataProvider,

  updateFulfilled: (resource: string, params: { id: string; data: RequestPrlFulfilledObject }) => {
    const { id, data } = params;
    const url = `${proxyUrl}/${resource}/${id}/fulfilled`;

    return prlHttpClient(url, {
      method: "PATCH",
      body: JSON.stringify(data)
    }).then(({ json }) => ({ data: json }));
  },
  updateUnfulfilled: (resource: string, params: { id: string; data: RequestPrlFulfilledObject }) => {
    const { id } = params;
    const url = `${proxyUrl}/${resource}/${id}/unfulfilled`;

    return prlHttpClient(url, {
      method: "PATCH",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },
  create: (
    resource: string,
    params: {
      data: RequestPrlObject;
    }
  ) =>
    prlHttpClient(`${proxyUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => {
      return {
        data: json
      };
    }),

  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { filter } = params;
    const { field, order } = params.sort;

    // Split date range keys
    const refinedFilter = splitFilterDateRangeKeys(filter);

    const query = {
      sort: JSON.stringify([field, order]),
      page: page,
      limit: perPage,
      filter: JSON.stringify(refinedFilter)
    };

    const url = `${proxyUrl}/${resource}?${stringify(query)}`;
    return prlHttpClient(url).then(({ headers, json }) => ({
      data: json,
      // eslint-disable-next-line
      // @ts-ignore: Object is possibly 'null'

      total: parseInt(headers.get("content-range").split("/").pop(), 10)
    }));
  }
};

export default prlDataProvider;
