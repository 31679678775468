import { useRecordContext } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const ProductPrice = (props: { price?: string; label?: string; source: string }) => {
  const record = useRecordContext(props);

  return <span>{formatCurrency(record?.price)}</span>;
};

export default ProductPrice;
