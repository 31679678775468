import React from "react";
import Table from "components/material/Table/Table";
import { useRecordContext, useTranslate } from "react-admin";
import { mapValues } from "lodash";
import dayjs from "dayjs";
import formatDate from "utils/formatDate";

const PrlHistoryTab = () => {
  const record = useRecordContext();
  const t = useTranslate();
  const headers = [
    t("resources.prl.fields.user"),
    t("resources.prl.fields.message"),
    t("resources.prl.fields.created_at")
  ];
  const rows = record?.changelog?.map((comment) => {
    // Format dates properly

    return mapValues(comment, (value) => {
      if (dayjs(value).isValid()) {
        return formatDate(value, "downstream");
      }
      return value;
    });
  });
  return <Table headers={headers} rows={rows || []} />;
};

export default PrlHistoryTab;
