import {
  colors,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import CardBase from "components/material/Card/CardBase";
import React from "react";
import { BulkPaymentTableHead } from "../components/BulkPaymentTableHead";
import { headCells } from "../components/utils/OderColumnsBulkPaymentAuditTable";
import { DateField, useRecordContext, useTranslate } from "react-admin";
import ButtonBack from "../../../../other/ButtonBack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  name: {
    fontSize: 22,
    fontWeight: 700
  }
});
const BulkPaymentAuditTab = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const { mpay_created_by_name, mpay_reviewed_by_name, rejection_reason, reviewed_at, ref, status } = record;

  const translate = useTranslate();

  return (
    <>
      <CardBase noPadding variant="classic-white">
        <TableContainer>
          <Table size="small">
            <BulkPaymentTableHead headCells={headCells} />
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.name}>{mpay_created_by_name}</Typography>
                  <Typography component={"span"} style={{ fontWeight: 700 }}>
                    {translate("cc.status.created")}
                  </Typography>
                  <Typography component={"span"}>
                    {" " + translate("resources.bulk_payment.fields.user")}
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 700 }}>
                    {" "}
                    {ref}
                  </Typography>
                </TableCell>
                <TableCell align="left">{""}</TableCell>
                <TableCell align="left">
                  <DateField showTime source="started_at" locales="fr-FR" />
                </TableCell>
              </TableRow>
              {reviewed_at ? (
                <TableRow>
                  <TableCell align="left">
                    <Typography className={classes.name}>{mpay_reviewed_by_name}</Typography>
                    {status === 2048 ? (
                      <Typography component={"span"} style={{ color: colors.red[500] }}>
                        {translate("cc.status.rejected")}
                      </Typography>
                    ) : (
                      <Typography component={"span"} style={{ color: colors.green[500] }}>
                        {translate("cc.status.approved")}
                      </Typography>
                    )}
                    <Typography component={"span"}>
                      {" " + translate("resources.bulk_payment.fields.title")}
                    </Typography>
                    <Typography component={"span"} style={{ fontWeight: 700 }}>
                      {" "}
                      {ref}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{rejection_reason}</TableCell>
                  <TableCell align="left">
                    <DateField showTime source="reviewed_at" locales="fr-FR" />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </CardBase>
      <Grid container justifyContent={"flex-end"} style={{ width: "100%", paddingTop: 60 }}>
        <ButtonBack color="primary" variant="contained">
          {translate("ra.action.back")}
        </ButtonBack>
      </Grid>
    </>
  );
};

export default BulkPaymentAuditTab;
