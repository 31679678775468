import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import { PropsOfStyles, withStyles } from "@mui/styles";
import queryErrorHandler from "api/queryErrorHandler";
import authBg from "assets/authBg.webp";
import Logo from "assets/smobilpayLogo.webp";
import {
  Form,
  Notification,
  RaRecord,
  SaveButton,
  TextInput,
  useLogin,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";
import * as yup from "yup";

const styles: PropsOfStyles<string> = {
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(${authBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center bottom"
  },
  logo: {
    height: 80,
    objectFit: "contain",
    margin: "10rem 0 20px 0"
  },
  paper: {
    padding: "15px 24px",
    width: 350,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  mb: {
    marginBottom: 20
  },
  footer: {
    position: "absolute",
    bottom: 20,
    "& *": {
      textAlign: "center",
      fontSize: 14
    }
  },
  bold: {
    fontWeight: "bold"
  }
};

const schema = yup
  .object()
  .shape({
    username: yup.string().required(),
    password: yup.string().required()
  })
  .required();

const LoginPage = ({ classes }: RaRecord) => {
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (data: Partial<RaRecord>) => {
    await login(data)
      .then(() => redirect("/"))
      .catch((error) => queryErrorHandler({ error, notify }));
  };

  return (
    <Box className={classes.main}>
      <img className={classes.logo} src={Logo} alt="Logo" />
      <Paper className={classes.paper}>
        <Avatar className={classes.mb}>
          <LockIcon />
        </Avatar>
        <Form onSubmit={handleSubmit} resolver={yupResolver(schema)}>
          <Grid container>
            <Grid item xs={12}>
              <TextInput
                source="username"
                variant="standard"
                data-testid="username"
                fullWidth
                label={translate("resources.prl.fields.username")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                source="password"
                type="password"
                variant="standard"
                data-testid="password"
                fullWidth
                label={translate("resources.prl.fields.password")}
              />
            </Grid>

            <Grid item xs={12}>
              <SaveButton data-testid="sign-in" fullWidth label={translate("cc.button.sign_in")} />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification />
      <Box className={classes.footer}>
        <Typography>{translate("cc.typography.auth.powered")} smobilpay</Typography>
        <Typography className={classes.bold}>
          &copy; 2012 - {new Date().getFullYear()} {translate("cc.typography.auth.rights")}
        </Typography>
        <Typography className={classes.bold}>{translate("cc.typography.auth.statement")}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(LoginPage);
