import {
  BULKPAY_ACCESS,
  ENTITY_READ_ANY,
  ENTITY_READ_OWN,
  KYC_BOM_ACCESS,
  MERCHANT_READ_OWN,
  PRL_BOM_ACCESS,
  PRL_PRODUCT_READ_ANY,
  PRL_PRODUCT_READ_OWN,
  PRL_READ_ANY,
  PRL_READ_OWN
} from "constants/permissionsNames";
import {
  AGENT_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_PRL_SERVICE,
  COMPANY_SERVICE,
  PAYMENT_REQUEST_HISTORY_SERVICE,
  PAYMENT_TRANSACTION_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE
} from "constants/serviceNames";
import { getStorageMerchantCCId } from "utils/storageUtils";

/**
 *
 * @param {Array} permissions
 * @returns {Array} - List of service that use has access to
 */
const getPermittedServices = (permissions = []) => {
  const services = [];
  const merchantCCId = getStorageMerchantCCId();

  const includesPermission = (perm) => permissions?.includes(perm);

  //   PRL
  if (includesPermission(PRL_BOM_ACCESS)) {
    if (includesPermission(PRL_READ_ANY) || includesPermission(PRL_READ_OWN)) {
      services.push(PRL_SERVICE);
    }

    // Product
    if (includesPermission(PRL_PRODUCT_READ_ANY) || includesPermission(PRL_PRODUCT_READ_OWN)) {
      services.push(PRODUCT_SERVICE);
    }
    if (includesPermission(MERCHANT_READ_OWN) || (includesPermission(PRL_READ_ANY) && merchantCCId)) {
      // Payment transaction
      services.push(PAYMENT_TRANSACTION_SERVICE);
      services.push(COMPANY_PRL_SERVICE);
      services.push(PAYMENT_REQUEST_HISTORY_SERVICE);
    }
  }

  //   KYC
  if (includesPermission(KYC_BOM_ACCESS)) {
    if (includesPermission(ENTITY_READ_ANY) || includesPermission(ENTITY_READ_OWN)) {
      // Agent
      services.push(AGENT_SERVICE);
      // Company
      services.push(COMPANY_SERVICE);
    }
  }

  //   BULK PAYMENT
  if (includesPermission(BULKPAY_ACCESS) && merchantCCId) {
    services.push(BULK_PAYMENT_SERVICE);
  }

  return services;
};

export default getPermittedServices;
