import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { RaRecord, useRecordContext, UseRecordContextParams } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const styles = {
  amountText: {
    fontSize: 14
  }
};

const PayoutRequestAmount = (props: UseRecordContextParams<RaRecord>) => {
  const { classes, ...rest } = props;
  const record = useRecordContext(props);
  return (
    <>
      <Typography {...rest} className={classes.amountText}>
        {formatCurrency(record?.amount)}
      </Typography>
    </>
  );
};

export default withStyles(styles)(PayoutRequestAmount);
