import AcUnit from "@mui/icons-material/AcUnit";
import Person from "@mui/icons-material/Person";
import Receipt from "@mui/icons-material/Receipt";
import authProvider from "api/authProvider";
import { QueryClient } from "react-query";
import {
  BulkCollectionCreate,
  BulkPaymentCreate,
  BusinessListCreate,
  PayoutAccountCreate,
  PayoutRequestCreate,
  PrlCreate,
  ProductCreate
} from "components/ra/Create";
import { BusinessListEdit, PayoutAccountEdit, PrlEdit, ProductEdit } from "components/ra/Edit";
import { LoginForm } from "components/ra/Form";

import {
  BulkCollectionList,
  BulkPaymentList,
  KycDataListAgent,
  KycDataListCompany,
  PayoutAccountList,
  PrlList,
  ProductList
} from "components/ra/List";

import {
  BulkCollectionShow,
  BulkPaymentShow,
  KycShow,
  PaymentTransactionShow,
  PayoutAccountShow,
  PayoutApprovalShow,
  PayoutRequestShow,
  PrlShow,
  ProductShow
} from "components/ra/Show";

import ProfileEdit from "components/ra/Profile/ProfileEdit";
import { Admin, CustomRoutes, Resource, ThemeProvider } from "react-admin";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import dataProviderManager from "./api/dataProviderManager";

import { StyledEngineProvider } from "@mui/material";
import {
  BULKCOL_ACCESS,
  BULKPAY_ACCESS,
  KYC_BOM_ACCESS,
  MERCHANT_READ_OWN,
  PRL_READ_ANY,
  REMIT_TRANSACT
} from "constants/permissionsNames";
import findPermission from "utils/findPermission";
import theme from "./config/theme";
import { i18nProvider } from "./i18n/i18nProvider";
import PaymentsList from "components/ra/List/PaymentsList/PaymentsList";
import { BomDashboard, BomLayout } from "components/ra/other";
import PaymentTransactionList from "components/ra/List/PaymentTransactionHistoryList/PaymentTransactionList";
import PaymentRequestHistoryList from "components/ra/List/PaymentRequestHistoryList/PaymentRequestHistoryList";
import CompanyPrl from "components/ra/custom/companyPrl/CompanyPrl";
import PaymentRequestHistoryShow from "components/ra/Show/paymentRequestHistory/PaymentRequestHistoryShow";
import RemittanceTransactionShow from "components/ra/Show/RemittanceTransactionHistory/RemittanceTransactionShow";
import React, { useEffect } from "react";
import config from "config";
import RemittanceHistoryList from "components/ra/List/RemittanceHistoryList/RemittanceHistoryList";
import { UtilsProvider } from "context/UtilsContext";
import { getStorageEntityOrganisationExternalId } from "utils/storageUtils";
import RemittanceSend from "components/ra/custom/RemittanceSend";
import AgentPaymentsHome from "components/ra/custom/AgentPayments/AgentPaymentsHome";
import AgentPaymentsProcess from "components/ra/custom/AgentPayments/AgentPaymentsProcess";
import RemittanceReports from "components/ra/custom/Remittance/RemittanceReports/RemittanceReports";

const App = () => {
  const apiUrl = config.REACT_APP_PROXY_BASE_URL;
  const entityID = getStorageEntityOrganisationExternalId();
  const merchantPaymentRead = (permissions) =>
    findPermission(permissions, MERCHANT_READ_OWN) || findPermission(permissions, PRL_READ_ANY);
  // Fetch application meta data on component mount
  useEffect(() => {
    const fetchMeta = async () => {
      const response = await fetch(`${apiUrl}/meta`);
      const data = await response.json();
      // eslint-disable-next-line no-console
      console.log(`Version: ${data.version}`);
    };
    // eslint-disable-next-line
    fetchMeta();
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <StyledEngineProvider injectFirst>
      <UtilsProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <BrowserRouter>
              <Admin
                queryClient={queryClient}
                theme={theme}
                authProvider={authProvider}
                dashboard={BomDashboard}
                dataProvider={dataProviderManager}
                layout={BomLayout}
                title="smobilpay 2.0"
                i18nProvider={i18nProvider}
                loginPage={LoginForm}
              >
                {(permissions) => (
                  <>
                    {console.log(permissions)}
                    <CustomRoutes>
                      <Route path="/entity_agent" element={<KycDataListAgent />} />
                      <Route
                        path="/entity_company"
                        element={
                          entityID ? <Navigate to={`/entity/${entityID}/show`} /> : <KycDataListCompany />
                        }
                      />
                      {findPermission(permissions, REMIT_TRANSACT) ? (
                        <Route path="/remittance_send" element={<RemittanceSend />} />
                      ) : null}

                      <Route path="/remittance_reports" element={<RemittanceReports />} />
                      {merchantPaymentRead(permissions) ? (
                        <Route path="/company-prl/:id" element={<CompanyPrl />} />
                      ) : null}
                      <Route path="/agent_payments/home" element={<AgentPaymentsHome />}></Route>
                      <Route path="/agent_payments/process/:id" element={<AgentPaymentsProcess />}></Route>
                      <Route path="/company-prl/:id" element={<CompanyPrl />} />
                      <Route path="/remittance_send" element={<RemittanceSend />} />
                    </CustomRoutes>
                    {findPermission(permissions, KYC_BOM_ACCESS) ? (
                      <Resource name="entity" show={KycShow} icon={Person} />
                    ) : null}
                    {findPermission(permissions, BULKPAY_ACCESS) ? (
                      <Resource
                        name="bulk_payment"
                        list={BulkPaymentList}
                        show={BulkPaymentShow}
                        create={BulkPaymentCreate}
                        icon={Receipt}
                      />
                    ) : null}
                    {findPermission(permissions, BULKCOL_ACCESS) ? (
                      <Resource
                        name="bulk_collection"
                        list={BulkCollectionList}
                        create={BulkCollectionCreate}
                        show={BulkCollectionShow}
                        icon={Receipt}
                      />
                    ) : null}
                  </>
                )}
                <Resource
                  name={"remittance"}
                  list={RemittanceHistoryList}
                  show={RemittanceTransactionShow}
                  icon={Receipt}
                />
                <Resource name={"remittance"} list={RemittanceHistoryList} icon={Receipt} />
                {/*TODO: Restrict access of all routes based on permission*/}
                <Resource
                  name="product"
                  list={ProductList}
                  create={ProductCreate}
                  edit={ProductEdit}
                  show={ProductShow}
                  icon={AcUnit}
                />
                <Resource
                  name="prl"
                  list={PrlList}
                  show={PrlShow}
                  edit={PrlEdit}
                  create={PrlCreate}
                  icon={AcUnit}
                />
                <Resource
                  name="payment-transaction"
                  list={PaymentTransactionList}
                  show={PaymentTransactionShow}
                  icon={AcUnit}
                />
                <Resource
                  name="payment-report"
                  list={PaymentRequestHistoryList}
                  show={PaymentRequestHistoryShow}
                  icon={AcUnit}
                />
                <Resource
                  name="payout-request"
                  show={PayoutRequestShow}
                  create={PayoutRequestCreate}
                  icon={AcUnit}
                />
                <Resource name="payout-approval" show={PayoutApprovalShow} icon={AcUnit} />,
                <Resource
                  name="business_management"
                  create={BusinessListCreate}
                  edit={BusinessListEdit}
                  icon={AcUnit}
                />
                <Resource name="merchant" edit={ProfileEdit} />,
                <Resource
                  name="request-account"
                  list={PayoutAccountList}
                  create={PayoutAccountCreate}
                  edit={PayoutAccountEdit}
                  show={PayoutAccountShow}
                />
                <Resource name="payments" list={PaymentsList} />,
                <Resource name="payment-method" />,
                <Resource name="fsp" />
              </Admin>
            </BrowserRouter>
          </ToastProvider>
        </ThemeProvider>
      </UtilsProvider>
    </StyledEngineProvider>
  );
};

export default App;
