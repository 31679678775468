import React from "react";
import Table from "components/material/Table";
import { Card, CardContent, colors, TableCell, TableRow, Typography } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

const BulkPaymentListExpand = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return record?.status === 2048 ? (
    <Card sx={{ minWidth: 275, background: colors.grey[50] }}>
      <CardContent>
        <Typography variant={"h5"} gutterBottom>
          {t("resources.bulk_payment.fields.reason")}
        </Typography>
        <Typography variant="body2" component="div">
          {record?.rejection_reason}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Table
      align={"center"}
      renderSecondaryHeader={() => (
        <TableRow>
          <TableCell
            align="center"
            colSpan={3}
            sx={{
              background: `${colors.grey[50]} !important`,
              fontWeight: "bold",
              color: `${colors.grey[800]} !important`
            }}
          >
            {t("cc.table.pre_validated")}
          </TableCell>
          <TableCell
            align="center"
            colSpan={4}
            sx={{
              background: `${colors.grey[200]} !important`,
              fontWeight: "bold",
              color: `${colors.grey[800]} !important`
            }}
          >
            {t("cc.table.processing")}
          </TableCell>
        </TableRow>
      )}
      size={"small"}
      headers={[
        t("cc.table.imported"),
        t("cc.table.valid"),
        t("cc.table.invalid"),
        t("cc.table.pending"),
        t("cc.table.success"),
        t("cc.table.errors"),
        t("cc.table.total_processed")
      ]}
      rows={[
        {
          imported: record?.cnt_rows,
          valid: record?.cnt_valid,
          invalid: record?.cnt_invalid,
          pending: record?.cnt_pending,
          success: record?.cnt_success,
          errors: record?.cnt_error,
          total: record?.cnt_total
        }
      ]}
    />
  );
};

export default BulkPaymentListExpand;
