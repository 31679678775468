import { Box, colors, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import CardGrid from "components/material/Card/CardGrid";
import BomAttachment from "components/material/List/ListAttachment";
import { isEmpty } from "lodash";
import formatDate from "utils/formatDate";
import ChipStatus from "components/other/context/StatusField";
import ButtonBack from "components/other/ButtonBack";
import PayoutApprovalActions from "./PayoutApprovalActions";
import PayoutApprovalHistory from "./PayoutApprovalHistory";
import { withStyles } from "@mui/styles";

const styles = {
  balanceText: {
    fontWeight: "bold"
  },
  topContainer: {
    padding: `10px`,
    display: "flex",
    justifyContent: "flex-end"
  },
  bolderText: {
    fontSize: 18,
    fontWeight: "bolder",
    color: colors.grey[600]
  },

  additionalInstructions: {
    gridRow: 5,
    gridColumn: "1 /4"
  },

  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
};

const PayoutApprovalShowLayout = ({ classes, ...rest }) => {
  const t = useTranslate();
  const record = useRecordContext();
  const payoutRequestInformationContent = [
    { [`${t("kyc.status")}`]: <ChipStatus record={record} source="status" /> },
    {
      [`${t("payout.amount")}`]: <Typography className={classes.bolderText}>{record?.amount} XAF</Typography>
    },
    { [`${t("payout.request_date")}`]: formatDate(record?.createdAt) },
    { [`${t("payout.country")}`]: record?.country?.name },
    { [`${t("payout.financial_provider")}`]: record?.paymentMethodId },
    { [`${t("payout.service_provider")}`]: record?.serviceProvider },
    { [`${t("payout.iban_number")}`]: record?.accountNumber },
    { [`${t("payout.account_holder_name")}`]: record?.accountHolderName },
    { [`${t("payout.swift_code")}`]: record?.swiftCode },
    { [`${t("payout.address")}`]: record?.address },
    { [`${t("payout.state_province")}`]: record?.state },
    { [`${t("payout.city")}`]: record?.city },
    { [`${t("payout.company_name")}`]: record?.company?.name },
    {
      [`${t("prl.attachment")}`]: (
        <BomAttachment
          fileResource="payment-request"
          isEmpty={isEmpty(record?.attachments)}
          isLoading={false}
          content={record?.attachments}
        />
      )
    },
    { [`${t("payout.company_id")}`]: record?.company?.id }
  ];
  return (
    <Box p={2}>
      <Box {...rest} mt={3} mb={2} className={classes.topContainer}>
        <Typography color="secondary" className={classes.balanceText} style={{ paddingRight: 10 }}>
          {t("payout.current_balance")}:
        </Typography>
        <Typography className={classes.balanceText}>1 000 000 XAF</Typography>
      </Box>
      <Divider />
      <Box mt={3} mb={3}>
        <PayoutApprovalActions data={record} />
      </Box>
      <CardGrid
        variant="outlined"
        cardTitle={t("payout.payout_request_details")}
        content={payoutRequestInformationContent}
      >
        <Box className={classes.additionalInstructions}>
          <Typography className={classes.valueKey}>{t("prl.additional_information")}:</Typography>
          <Typography className={classes.valueText}>{record?.additionalInstructions}</Typography>
        </Box>
      </CardGrid>
      <Box mt={3} mb={3} />
      <PayoutApprovalHistory record={record} />
      <Box mt={3} className={classes.bottomContainer}>
        <ButtonBack color="secondary" variant="contained" size="large">
          {t("ra.action.back")}
        </ButtonBack>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PayoutApprovalShowLayout);
