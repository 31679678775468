import { withStyles } from "@mui/styles";
import { RaRecord } from "react-admin";
import { renderLogo } from "../../../../../utils/logoUtils";

const styles = {
  image: {
    height: 29,
    width: 40,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(25, 105, 135, .3)",
    marginRight: 7
  },
  text: {
    fontSize: 12,
    color: "#777777"
  },
  wrapper: {
    display: "flex",
    alignItems: "center"
  }
};

interface PaymentMethodProps extends RaRecord {
  id: string;
}

const PaymentMethod = ({ classes, id }: PaymentMethodProps) => {
  return (
    <div className={classes.wrapper}>
      <img className={classes.image} src={renderLogo(id)} alt={id.toString() || ""} />
      <span className={classes.text}>{id}</span>
    </div>
  );
};

export default withStyles(styles)(PaymentMethod);
