import * as React from "react";
import Box from "@mui/material/Box";
import MuiStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useGetTranslation from "hooks/utils/useGetTranslation";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Alert, AlertTitle, tooltipClasses } from "@mui/material";
//import { pick } from "lodash"
import { useWatch, useFormState, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import formatCurrency from "utils/formatCurrency";
import { GET_SERVICE_CHARGE } from "constants/dataProviderTypes";
import Button from "components/material/Button/Button";

const RemittanceStepper = ({
  activeStep = 0,
  handleBack,
  steps,
  handleReset,
  handleNext,
  handleComplete,
  handlePrint,
  handleDownload,
  handleHistory,
  serviceId
}: {
  steps: any[];
  handleNext: any;
  handleReset?: () => void;
  handleBack: () => void;
  activeStep: number;
  handleComplete: any;
  handlePrint?: () => void;
  handleDownload?: any;
  handleHistory?: any;
  serviceId: string;
}) => {
  // const isStepOptional = (step: number) => {
  //   return step === 1;
  // };
  const getTranslation = useGetTranslation();
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryState(["service", GET_SERVICE_CHARGE, String(serviceId)]);

  // const formValue: any = pick(values, ["service_charge_error"]);

  // const formValue: any = pick(values, ["service_charge_error"]);

  const getCompleteButtonText = () => {
    switch (activeStep) {
      case steps.length - 2:
        return getTranslation("confirm");
      case steps.length - 1:
        return getTranslation("close");
      default:
        return getTranslation("next");
    }
  };

  const totalAmount = useWatch({ name: "total_amount" });
  const transactionStatus = useWatch({ name: "transaction_status" });

  const getConfirmButtonTooltip = () => {
    switch (activeStep) {
      case steps.length - 2:
        return (
          <Alert severity="info" sx={{ fontSize: "12px" }}>
            <AlertTitle sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {getTranslation("total_amount_to_pay")} : {formatCurrency(totalAmount)}
            </AlertTitle>
            {getTranslation(
              "please_ensure_all_information_above_is_correct_then_confirm_and_collect_the_total_amount_from_the_customer",
              "remittance"
            )}
          </Alert>
        );
      default:
        return "";
    }
  };

  const onCompleteClicked = () => {
    switch (activeStep) {
      case steps.length - 2:
        return handleComplete;
      case steps.length - 1:
        return handleReset;
      default:
        return handleNext;
    }
  };
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent"
    }
  }));

  const { isValid, isDirty } = useFormState();
  const { trigger } = useForm();

  return (
    <Box sx={{ width: "100%" }}>
      <MuiStepper activeStep={activeStep}>
        {steps.map(({ label }: any, index: number) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step
              key={label}
              {...stepProps}
              completed={
                activeStep > index || (activeStep === steps.length - 1 && transactionStatus === "SUCCESS")
              }
            >
              <StepLabel
                error={
                  activeStep === steps.length - 1 && index === activeStep && transactionStatus === "FAILED"
                }
                {...labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </MuiStepper>
      <Box sx={{ marginTop: 3 }}>{steps[activeStep].component}</Box>
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {activeStep !== steps.length - 1 && activeStep !== 0 && (
            <Button variant={"outlined"} disabled={activeStep === 0} onClick={handleReset} color="error">
              {getTranslation("cancel")}
            </Button>
          )}

          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep !== steps.length - 1 && activeStep !== 0 && (
            <Button
              variant={"outlined"}
              disabled={activeStep === 0}
              onClick={async () => {
                handleBack();
                await trigger("account_holder_number", { shouldFocus: true });
              }}
              sx={{ mr: 2 }}
            >
              {getTranslation("back")}
            </Button>
          )}
          {activeStep === steps.length - 1 && transactionStatus === "SUCCESS" && (
            <>
              <Button startIcon={<PrintIcon />} variant={"outlined"} onClick={handlePrint}>
                {getTranslation("print")}
              </Button>
              <Button
                startIcon={<DownloadIcon />}
                variant={"outlined"}
                onClick={handleDownload}
                sx={{ mr: 2 }}
              >
                {getTranslation("download")}
              </Button>
            </>
          )}
          <HtmlTooltip title={getConfirmButtonTooltip()}>
            <Button
              variant={"contained"}
              onClick={onCompleteClicked()}
              disabled={!isValid || !isDirty || queryData?.isFetching || queryData?.status === "error"}
              sx={{ px: 5 }}
            >
              {getCompleteButtonText()}
            </Button>
          </HtmlTooltip>
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default RemittanceStepper;
