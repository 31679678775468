import { ProductRequestObject } from "api/types/prlTypes";
import config from "config";
import { stringify } from "query-string";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils, GetListParams } from "react-admin";
import { getStorageToken } from "utils/storageUtils";
import createAttachmentDataProvider from "./utils/createAttachmentDataProvider";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const proxyUrl: string = config.REACT_APP_PROXY_BASE_URL;

const dataProvider = simpleRestProvider(proxyUrl, httpClient);
const attachmentDataProvider = createAttachmentDataProvider(proxyUrl, httpClient);

const productDataProvider = {
  ...dataProvider,
  ...attachmentDataProvider,
  create: (resource: string, params: { data: ProductRequestObject }) =>
    httpClient(`${proxyUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: json
    })),
  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { filter } = params;
    const { field, order } = params.sort;

    const query = {
      page: page,
      limit: perPage,
      filter: JSON.stringify(filter),
      sort: JSON.stringify([field, order])
    };

    const url = `${proxyUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      // eslint-disable-next-line
      // @ts-ignore: Object is possibly 'null'
      total: parseInt(headers.get("content-range").split("/").pop(), 10)
    }));
  }
};

export default productDataProvider;
