import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BomDeleteButton from "components/other/context/DeleteButton";
import Datepicker from "components/other/Datepicker";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  ResourceContextProvider,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate
} from "react-admin";
import BulkPCollectionChipStatus from "./components/BulkCollectChipStatus";
import BulkPayListDownload from "./components/BulkCollectListDownload";
import BulkCollectionListExpand from "./components/BulkCollectionListExpand";
import BulkPayTopContent from "./components/BulkCollectTopContent";
import findPermission from "utils/findPermission";
import BulkCollectListApproveReject from "./components/BulkCollectListApproveReject";
import CreateButton from "components/ra/Button/CreateButton";

const BulkCollectionList = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const bulkApprover = findPermission(permissions, "bulkpay_approver");

  const ListActions = () => {
    return (
      <TopToolbar>
        <FilterButton />
        {!bulkApprover && <CreateButton label={t("bulk_collection.new_bulk_collection")} />}
      </TopToolbar>
    );
  };

  const filterList = [
    <TextInput label={t("bulk_collection.bulk_collection_ref")} source="ref" />,
    <SelectInput
      choices={[
        { id: "Airtime", name: "AIRTIME" },
        { id: "Mobile Money", name: "MOBILE MONEY" }
      ]}
      label={t("bulk_pay.service_type")}
      source="mpay_serviceId"
    />,
    <Datepicker source="started_at_from" label={t("bulk_pay.started_at")} />,
    <Datepicker source="started_at_to" label={t("bulk_pay.started_to")} />,
    <Datepicker source="ended_at_from" label={t("bulk_pay.ended_at")} />,
    <Datepicker source="ended_at_to" label={t("bulk_pay.ended_to")} />,
    <SelectInput
      source="status"
      label="kyc.status"
      choices={[
        { id: "CREATED", name: t("status.created") },
        { id: "READY", name: t("status.ready") },
        { id: "APPROVED", name: t("status.approved") },
        { id: "PROCESSING", name: t("status.processing") },
        { id: "REJECTED", name: "status.rejected" },
        { id: "VALIDATIONERROR", name: "status.validation_error" },
        { id: "FAILED", name: "status.failed" },
        { id: "PARTIALSUCCESS", name: "status.partial_success" }
      ]}
    />
  ];

  return (
    <ResourceContextProvider value="bulk_collection">
      <>
        <Breadcrumbs service={"bulk_collection"} page={"list"} />
        <BulkPayTopContent />
        <List
          // syncWithLocation
          actions={<ListActions />}
          filters={filterList}
        >
          <Datagrid expand={<BulkCollectionListExpand />} bulkActionButtons={false}>
            <TextField label={t("bulk_collection.bulk_collection_ref")} source="ref" />
            <TextField label={t("bulk_pay.service_type")} source="mpay_serviceId" />
            <BulkPCollectionChipStatus label={t("kyc.status")} source="status" />
            <DateField label={t("bulk_collection.started_at")} source="started_at" locales="fr-FR" />
            <DateField label={t("bulk_collection.ended_at")} source="ended_at" locales="fr-FR" />
            <ShowButton />
            {bulkApprover && <BulkCollectListApproveReject />}
            <BulkPayListDownload />
            {!bulkApprover && <BomDeleteButton forResource="bulk_collection" />}
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default BulkCollectionList;
