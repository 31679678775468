import { useState } from "react";
import { useRefresh } from "react-admin";

interface UseHandleModalSuccessProps {
  setAttachments: (arg: []) => void;
}

const useHandleModalSuccess = ({ setAttachments }: UseHandleModalSuccessProps) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const handleClose = () => {
    // Clear attachments
    setAttachments([]);
    // Close dialog
    setOpen(false);
  };

  const handleCreate = () => {
    setOpen(false);
    refresh();
  };

  return { handleClose, handleCreate, open, setOpen };
};

export default useHandleModalSuccess;
