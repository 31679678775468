const prlMockData = {
  prl: [
    {
      prlId: 181,
      id: "7c446976-7781-4d1c-8788-2c7545432254",
      url: "https://prlpaymentwebui.smobilpay.acceptance.maviance.info/prl/7c446976-7781-4d1c-8788-2c7545432254",
      flowType: "customer",
      paymentStatus: "OPEN",
      isPaid: false,
      product: [
        {
          productLogId: 247,
          name: "New Product",
          description: "Good product",
          reference: null,
          quantity: 11,
          price: 500,
          total: 3500,
          attachments: []
        }
      ],
      customer: {
        customerLogId: 181,
        nameFirst: "Johnny",
        nameLast: "Bravo",
        phonenumber: "123456789",
        email: "test@gmail.com",
        customerCompanyName: "Maviance",
        customerCompanyAddress: "Rue du Eto"
      },
      isFulfilled: false,
      createdAt: "2022-08-13T14:59:35+0000",
      fulfilmentUpdatedAt: null,
      fulfilmentUpdatedBy: null,
      attachments: [
        {
          checksum: "e23c55d15d0fdf4b012bf384f7ced775",
          createdAt: "2022-08-12 12:50:53",
          documentLabel: "merchant-logo.png",
          documentUrl: "/payment-api/v1/document/5030eb53-f07e-4154-9969-5ccab68ae487/download",
          documentUuid: "5030eb53-f07e-4154-9969-5ccab68ae487",
          id: 54,
          mimeType: "image/png",
          originalName: "merchant-logo.png",
          updatedAt: "2022-08-12 12:50:53"
        }
      ],
      payments: [],
      reference: "",
      notes: "",
      comments: "",
      changeLog: []
    },
    {
      prlId: 180,
      id: "6b6690e7-2fa2-4256-952c-adb28049d70d",
      url: "https://prlpaymentwebui.smobilpay.acceptance.maviance.info/prl/6b6690e7-2fa2-4256-952c-adb28049d70d",
      flowType: "customer",
      paymentStatus: "SUCCESS",
      isPaid: true,
      product: [
        {
          productLogId: 246,
          name: "New Product",
          description: "Good product",
          reference: null,
          quantity: 11,
          price: 500,
          total: 5500,
          attachments: []
        }
      ],
      customer: {
        customerLogId: 180,
        nameFirst: "Dustin",
        nameLast: "Hoffman",
        phonenumber: "12345678911",
        email: "dustin@gmail.com",
        customerCompanyName: "Maviance",
        customerCompanyAddress: "Rue du Eto"
      },
      isFulfilled: true,
      createdAt: "2022-08-16T14:55:40+0000",
      fulfilmentUpdatedAt: null,
      fulfilmentUpdatedBy: null,
      attachments: [
        {
          checksum: "e23c55d15d0fdf4b012bf384f7ced775",
          createdAt: "2022-08-12 12:50:53",
          documentLabel: "merchant-logo.png",
          documentUrl: "/payment-api/v1/document/5030eb53-f07e-4154-9969-5ccab68ae487/download",
          documentUuid: "5030eb53-f07e-4154-9969-5ccab68ae487",
          id: 54,
          mimeType: "image/png",
          originalName: "merchant-logo.png",
          updatedAt: "2022-08-12 12:50:53"
        }
      ],
      payments: [
        {
          paymentMethod: {
            nameInternal: [
              {
                lang: "en",
                text: "MTN Payment"
              },
              {
                lang: "fr",
                text: "MTN Payment FR"
              }
            ],
            paymentProviderId: "MOMO"
          },
          amount: 500,
          quote: 10,
          total: 700,
          status: "SUCCESS",
          createdAt: "2022-08-31T21:08:15+0000"
        }
      ],
      reference: "",
      notes: "",
      comments: "",
      changeLog: []
    }
  ],

  attachment: [
    {
      id: 120,
      documentUuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
      documentLabel: "Purchase receipt",
      originalName: "receipt.pdf",
      mimeType: "application/pdf",
      checksum: "c6779ec2960296ed9a04f08d67f64422",
      createdAt: "2022-03-30T12:28:27.879Z",
      updatedAt: "2022-03-30T12:28:27.879Z"
    },
    {
      id: 5,
      documentUuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
      documentLabel: "Purchase receipt",
      originalName: "receipt.pdf",
      mimeType: "application/pdf",
      checksum: "c6779ec2960296ed9a04f08d67f64422",
      createdAt: "2022-03-30T12:28:27.879Z",
      updatedAt: "2022-03-30T12:28:27.879Z"
    }
  ]
};

export default prlMockData;
