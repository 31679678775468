import React from "react";
import { renderLogo } from "utils/logoUtils";
import { withStyles } from "@mui/styles";
import { ProviderLogoTypes } from "components/types/otherComponentTypes";
const styles = {
  text: {
    fontSize: 10,
    fontWeight: "bold"
  },
  image: {
    height: 29,
    width: 40,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(25, 105, 135, .3)"
  }
};

const ProviderLogo: React.FC<ProviderLogoTypes> = (props) => {
  const { classes, provider, altText } = props;
  return <img className={classes.image} src={renderLogo(provider)} alt={altText || ""} />;
};

export default withStyles(styles)(ProviderLogo);
