import * as React from "react";
import { useRecordContext } from "react-admin";
import { PENDING, OPEN } from "constants/status";
import { EditButtonTypes } from "components/types/otherComponentTypes";
import EditButton from "components/ra/Button/EditButton";

const BomEditButton: React.FC<EditButtonTypes> = (props) => {
  const { dataTestId } = props;
  const record = useRecordContext(props);

  // Display button only for PENDING and OPEN status
  return record?.paymentStatus === PENDING || record?.paymentStatus === OPEN ? (
    <EditButton record={record} data-testid={dataTestId} />
  ) : null;
};

export default BomEditButton;
