import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BackButton from "components/other/ButtonBack";
import { DeleteButton, EditButton, Show, usePermissions, useTranslate } from "react-admin";
import findPermission from "utils/findPermission";
import ProductShowLayout from "./ProductShowLayout";

const ProductShowActions = ({ basePath, data }) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const prlProductDelete = findPermission(permissions, "prl_product_delete_own");
  const prlProductEdit = findPermission(permissions, "prl_product_update_own");
  return (
    <Box sx={{ marginTop: 5, justifyContent: "flex-end", display: "flex" }}>
      {/*  FIXME: Move product show back button to the left*/}
      <BackButton startIcon={<ChevronLeftIcon />}>{translate("ra.action.back")}</BackButton>
      {prlProductEdit && <EditButton basePath={basePath} record={data} data-testid="edit-button" />}
      {prlProductDelete && <DeleteButton basePath={basePath} record={data} data-testid="delete-button" />}
    </Box>
  );
};

const ProductShow = ({ permissions }) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"product"} page={"show"} />
      </Toolbar>
      <Show component={"div"} resource="product" actions={<ProductShowActions permissions={permissions} />}>
        <ProductShowLayout permissions={permissions} />
      </Show>
    </>
  );
};

export default ProductShow;
