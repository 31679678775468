import { Box, Grid, Typography } from "@mui/material";
import { AppBar, LocalesMenuButton, usePermissions } from "react-admin";
import findPermission from "utils/findPermission";
import AutocompleteAppBar from "./components/AutocompleteAppBar";
import BomUserMenu from "./components/BomUserMenu";

const BomAppBar = (props) => {
  const { permissions } = usePermissions();
  const prlReadAny = findPermission(permissions, "prl_read_any");
  return (
    <AppBar {...props} userMenu={<BomUserMenu />} color="primary" sx={{ color: "white" }}>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
      </Box>
      <Box display={"flex"}>
        {prlReadAny && (
          <Grid>
            <AutocompleteAppBar clearIcon={<></>} />
          </Grid>
        )}
        <Grid container alignItems="center">
          <LocalesMenuButton
            languages={[
              { locale: "en", name: "English" },
              { locale: "fr", name: "Français" }
            ]}
          />
        </Grid>
      </Box>
    </AppBar>
  );
};

export default BomAppBar;
