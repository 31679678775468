import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import ChipStatus from "components/other/context/StatusField";
import Datepicker from "components/other/Datepicker";
import { INDIVIDUAL } from "constants/entityType";
import { APPROVED, COMPLETED, CREATED, REJECTED } from "constants/status";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  ResourceContextProvider,
  SelectArrayInput,
  SelectArrayInputProps,
  TextField,
  TextInput,
  TopToolbar
} from "react-admin";
import { useUtils } from "context/UtilsContext";
import ExportButton from "components/ra/Button/ExportButton";
import ShowButton from "components/ra/Button/ShowButton";

const statusChoices = [
  { id: APPROVED, name: APPROVED },
  { id: REJECTED, name: REJECTED },
  { id: CREATED, name: CREATED },
  { id: COMPLETED, name: COMPLETED }
];

const useStyles = makeStyles({
  root: { minWidth: 220 }
});

const StyledSelectArrayInput = (props: SelectArrayInputProps) => {
  const classes = useStyles();
  return <SelectArrayInput className={classes.root} {...props} />;
};

const ListActions = () => (
  <TopToolbar>
    <FilterButton data-testid="filter-button" />
    <ExportButton data-testid="export-button" maxResults={500} />
  </TopToolbar>
);

const KycDataListAgent = () => {
  const { merchantCCId } = useUtils();

  const postFiltersAgent = [
    <StyledSelectArrayInput data-testid="filter-status" source="status" choices={statusChoices} />,
    <Datepicker dataTestId="filter-submittedAt" source="submittedAt" />,
    <Datepicker dataTestId="filter-createdAt" source="createdAt" />,
    <TextInput data-testid="filter-givenName" source="givenName" />,
    <TextInput data-testid="filter-displayName" source="displayName" />,
    <TextInput data-testid="filter-familyName" source="familyName" />,
    <TextInput data-testid="filter-entityExternalId" source="entityExternalId" />,
    <TextInput data-testid="filter-entityOrganisationExternalName" source="entityOrganisationExternalName" />
  ];

  let filter: { entityType: string; entityOrganisationExternalId?: string } = {
    entityType: INDIVIDUAL
  };
  if (merchantCCId) {
    filter = {
      entityType: INDIVIDUAL,
      entityOrganisationExternalId: merchantCCId
    };
  } else {
    filter = {
      entityType: INDIVIDUAL
    };
  }

  return (
    <ResourceContextProvider value="entity">
      <>
        <Toolbar disableGutters={true}>
          <Breadcrumbs service={"agent"} page={"list"} />
        </Toolbar>
        {/*FIXME: List page should be direct child of resource context provider (follow other list pages)*/}
        <List actions={<ListActions />} filter={filter} filters={postFiltersAgent}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="name.givenName" />
            <TextField source="name.familyName" />
            <TextField source="name.displayName" />
            <TextField source="entityExternalId" />
            <TextField source="entityOrganisationExternalName" />
            <ChipStatus source="status" />
            <DateField source="submittedAt" locales="fr-FR" />
            <DateField source="createdAt" locales="fr-FR" />
            <ShowButton data-testid={"show-button"} />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default KycDataListAgent;
