import { CloudDownloadOutlined } from "@mui/icons-material";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import dataProvider from "api/dataProvider/remittanceDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { REMITTANCE_RSP_REPORTS_SERVICE } from "constants/dataProviderTypes";
import { useEffect, useState } from "react";
import { Form, FormDataConsumer, useNotify, useTranslate } from "react-admin";
import { useQuery } from "react-query";
import DatesPickerGroup from "./components/DatesPickerGroup";
import { DownloadButton, IDownloadButton } from "./components/DownloadButton";
import GridSelectionActions, { IGridSelectionPageData } from "./components/GridSelectionActions";
import GridSelector from "./components/GridSelector";
import adaptDataToOptions from "./utils/adaptDataToOptions";
import { mergeOptions } from "./utils/mergeOptions";
import { clearSelection } from "./utils/clearSelection";
import { generateYearsOptions } from "./utils/generateYearsOptions";

const RemittanceRspReports = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const notify = useNotify();
  const t = useTranslate();
  const [months] = useState([
    {
      id: 1,
      label: t(`resources.${["remittance"]}.fields.january`),
      isChecked: false,
      value: 1
    },
    {
      id: 2,
      label: t(`resources.${["remittance"]}.fields.february`),
      isChecked: false,
      value: 2
    },
    {
      id: 3,
      label: t(`resources.${["remittance"]}.fields.march`),
      isChecked: false,
      value: 3
    },
    {
      id: 4,
      label: t(`resources.${["remittance"]}.fields.april`),
      isChecked: false,
      value: 4
    },
    {
      id: 5,
      label: t(`resources.${["remittance"]}.fields.may`),
      isChecked: false,
      value: 5
    },
    {
      id: 6,
      label: t(`resources.${["remittance"]}.fields.june`),
      isChecked: false,
      value: 6
    },
    {
      id: 7,
      label: t(`resources.${["remittance"]}.fields.july`),
      isChecked: false,
      value: 7
    },
    {
      id: 8,
      label: t(`resources.${["remittance"]}.fields.august`),
      isChecked: false,
      value: 8
    },
    {
      id: 9,
      label: t(`resources.${["remittance"]}.fields.september`),
      isChecked: false,
      value: 9
    },
    {
      id: 10,
      label: t(`resources.${["remittance"]}.fields.october`),
      isChecked: false,
      value: 10
    },
    {
      id: 11,
      label: t(`resources.${["remittance"]}.fields.november`),
      isChecked: false,
      value: 11
    },
    {
      id: 12,
      label: t(`resources.${["remittance"]}.fields.december`),
      isChecked: false,
      value: 12
    }
  ]);

  const [data, setData] = useState<IGridSelectionPageData[]>([
    {
      options: [],
      name: "RSP",
      isSelectAll: false
    },
    {
      options: months,
      name: t(`resources.${["remittance"]}.fields.month`),
      isSelectAll: false
    },
    {
      options: generateYearsOptions(new Date("2022-01-01")),
      name: t(`resources.${["remittance"]}.fields.year`),
      isSelectAll: false
    }
  ]);

  const { data: rspData, isLoading: isRspLoading } = useQuery(
    ["remittance", REMITTANCE_RSP_REPORTS_SERVICE],
    () => dataProvider.getRspReports(),
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (rspData) {
      const tempData = data;
      tempData[0] = {
        ...tempData[0],
        options: adaptDataToOptions(rspData, "fullName", "rspId")
      };
      setData([...tempData]);
      console.log("daata: ", adaptDataToOptions(rspData, "fullName", "rspId"));
    }
  }, [rspData]);

  const getSelectedOptions = (index: number) => {
    return data[index].options.filter((option) => option.isChecked).map((option) => option.value);
  };

  const actionsButtons: IDownloadButton[] = [
    {
      name: t(`resources.${["remittance"]}.fields.download_csv`),
      icon: <CloudDownloadOutlined />,
      format: "CSV",
      data: {
        rspIds: getSelectedOptions(0),
        months: getSelectedOptions(1),
        years: getSelectedOptions(2)
      },
      type: "RSP"
    },
    {
      name: t(`resources.${["remittance"]}.fields.download_excel`),
      icon: <CloudDownloadOutlined />,
      format: "EXCEL",
      data: {
        rspIds: getSelectedOptions(0),
        months: getSelectedOptions(1),
        years: getSelectedOptions(2)
      },
      type: "RSP"
    }
  ];

  return (
    <Box height={"100%"}>
      <GridSelector
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        data={data}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setData={setData}
      />
      <Box sx={{ height: "100%" }}>
        <Box mb={2}>
          <Typography fontWeight={700} fontSize={20}>
            {t(`resources.${["remittance"]}.fields.rsp_report`)}
          </Typography>
        </Box>
        <GridSelectionActions
          data={data}
          onClearSelection={() => clearSelection(data, setData)}
          mb={2}
          activeTab={activeTab}
          setActiveTab={(data: any) => {
            setIsOpenDialog(true);
            return setActiveTab(data);
          }}
        />
        {mergeOptions(data).filter((option) => option.isChecked).length > 0 && (
          <Form mode="onChange" defaultValues={{ createdAt_start: "", createdAt_end: "" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <>
                    <DatesPickerGroup {...rest} formData={formData} />
                    <Box sx={{ position: "absolute", bottom: 15, right: 15 }}>
                      {actionsButtons.map((item, idx) => (
                        <DownloadButton key={idx} {...item} />
                      ))}
                    </Box>
                  </>
                );
              }}
            </FormDataConsumer>
          </Form>
        )}
      </Box>
      <Backdrop open={isRspLoading}>
        <CircularProgress size={50} />
      </Backdrop>
    </Box>
  );
};

export default RemittanceRspReports;
