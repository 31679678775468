import ProviderLogo from "components/other/context/ProviderLogo";
import { RaRecord, useRecordContext } from "react-admin";

const ProviderField = (props: RaRecord | { label?: string; source?: string; sortable?: boolean }) => {
  const record = useRecordContext(props);

  const successPayment = record?.payments?.find((item: { status: string }) => item?.status === "SUCCESS");
  const paymentProviderName = successPayment?.paymentMethod?.nameInternal[0]?.text;

  return successPayment ? (
    <ProviderLogo provider={paymentProviderName} altText={paymentProviderName} />
  ) : (
    <div></div>
  );
};

export default ProviderField;
