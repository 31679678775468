import React from "react";
import { Create } from "react-admin";
import PayoutRequestCreateEditForm from "../Form/PayoutRequestCreateEditForm";
import { withStyles } from "@mui/styles";

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};

const PayoutRequestCreate = ({ classes, ...props }) => {
  return (
    <Create {...props} classes={{ card: classes.card }}>
      <PayoutRequestCreateEditForm />
    </Create>
  );
};

export default withStyles(styles)(PayoutRequestCreate);
