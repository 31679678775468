import CardGrid from "components/material/Card/CardGrid";
import Chip from "components/material/Chip/Chip";
import React from "react";
import { useRecordContext } from "react-admin";

const RemittanceTransactionErrorLogTab = () => {
  const record = useRecordContext();
  const logContent = [
    {
      status: <Chip label={record?.status} status={record?.status} />
    },
    {
      authentification_proof: "None"
    },
    {
      signature_type: "None"
    },
    {
      error_type: "None"
    },
    {
      error_message: "None"
    },
    {
      error_code: "10"
    }
  ];
  return (
    <>
      <CardGrid variant="outlined" resource="remittance" content={logContent} />
    </>
  );
};

export default RemittanceTransactionErrorLogTab;
