import { Backdrop, CircularProgress, colors } from "@mui/material";
import StackList from "components/material/custom/StackList/StackList";
import useGetTranslation from "hooks/utils/useGetTranslation";
import RemittanceSendDetails from "../RemittanceSendDetails";
import { useQuery } from "react-query";
import { GET_REMITTANCE_TRANSACTION } from "constants/dataProviderTypes";
import dataProvider from "api/dataProvider/remittanceDataProvider";
import { useWatch, useFormContext } from "react-hook-form";
import { pick } from "lodash";
import { CASH_PICKUP } from "constants/remittanceDeliveryMethods";

const RemittanceSendStepFive = () => {
  const getTranslation = useGetTranslation();
  const values = useWatch();
  const { setValue } = useFormContext();

  const formValues: any = pick(values, ["current_transaction_id", "transaction_status"]);

  const { data: remitData, isLoading: loading }: { data: any; isLoading: boolean } = useQuery(
    ["remittance", GET_REMITTANCE_TRANSACTION],
    () => dataProvider.getRemittanceTransaction({ id: Number(formValues?.current_transaction_id) }),
    {
      onSuccess: ({ data }) => {
        setValue("transaction_status", data?.status);
      },
      refetchInterval:
        formValues?.transaction_status === "FAILED" || formValues?.transaction_status === "SUCCESS"
          ? false
          : 10000
    }
  );

  const getTransactionInformationContent = () => {
    const isSuccess = formValues?.transaction_status === "SUCCESS";
    const isCashPickup = formValues?.delivery_method === CASH_PICKUP;

    const transactionInformationContent = [];

    if (isSuccess) {
      transactionInformationContent.push({
        title: getTranslation("ptn"),
        value: remitData?.data?.ptn
      });

      if (isCashPickup) {
        transactionInformationContent.push({
          title: getTranslation("mtnc"),
          value: remitData?.data?.mtnc
        });
      }
    }

    return transactionInformationContent;
  };

  const transactionInformationContent = getTransactionInformationContent();

  const getAlertStatus = (): any => {
    switch (formValues?.transaction_status) {
      case "FAILED":
        return {
          alertType: "error",
          message: "Transaction failed",
          statusText: "not_paid"
        };
      case "PENDING":
        return {
          alertType: "warning",
          message: "Transaction processing.",
          statusText: "not_paid"
        };
      case "SUCCESS":
        return {
          alertType: "success",
          message: "Transaction processed successfully",
          statusText: "paid"
        };
      default:
        return {
          alertType: "info",
          message: "N/A",
          statusText: "not_paid"
        };
    }
  };

  return (
    <>
      <RemittanceSendDetails
        alertType={getAlertStatus()?.alertType}
        alertTitle={formValues?.transaction_status}
        alertMessage={
          getAlertStatus()?.message ?? "Loading..."
          //
        }
        statusText={getAlertStatus()?.statusText}
        paymentStatus={formValues?.transaction_status}
        AdditionalDeliveryInfo={
          <StackList
            list={transactionInformationContent}
            valueStyles={{ fontWeight: "bold", color: colors.cyan[600] }}
            spacing={1}
            sx={{
              padding: "0 24px 0 24px"
            }}
          />
        }
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress color={"primary"} />
      </Backdrop>
    </>
  );
};

export default RemittanceSendStepFive;
