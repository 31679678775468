import React from "react";
import { useTranslate } from "react-admin";
import Tabs from "components/material/Tabs";
import PrlDetailsTab from "./tabs/PrlDetailsTab";
import PrlHistoryTab from "./tabs/PrlHistoryTab";

const PrlDataShowLayout = (props) => {
  const [value, setValue] = React.useState(0);
  const t = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      tabLabels={[`${t("cc.tab.details")}`, `${t("cc.tab.history")}`]}
      onChange={handleChange}
      activeTabIndex={value}
    >
      <PrlDetailsTab {...props} />
      <PrlHistoryTab />
    </Tabs>
  );
};

export default PrlDataShowLayout;
