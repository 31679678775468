import { Box, Paper, Toolbar, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import BackButton from "components/other/ButtonBack";
import { Form, SaveButton, TextInput } from "react-admin";

const styles = {
  paper: {
    padding: 20,
    height: "100%",
    boxSizing: "border-box"
  }
};

const PrlCreateForm = (props) => {
  const { classes } = props;

  return (
    <Form>
      <Box p="1em">
        <Box display="flex" sx={{ height: "100%" }}>
          <Box flex={3} mr="1em">
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Business Information
              </Typography>
              <TextInput source="name" label="Business Name" type="text" fullWidth />
              <TextInput source="description" multiline label="Description" type="text" fullWidth />
              <Toolbar>
                <Box display="flex" justifyContent="end" width="100%">
                  <BackButton variant="outlined" color="secondary">
                    Cancel
                  </BackButton>
                  <SaveButton
                    style={{ margin: "0px 15px" }}
                    color="secondary"
                    redirect="/business_management_list"
                    label="Save"
                    // saving={formProps.saving}
                    // handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  {/* <DeleteButton record={formProps.record} redirect="/business_management_list" /> */}
                </Box>
              </Toolbar>
              <Box mt="1em" />
            </Paper>
          </Box>
          <Box flex={3} />
        </Box>
      </Box>
    </Form>
  );
};

export default withStyles(styles)(PrlCreateForm);
