import { SxProps } from "@mui/material";
import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ButtonItem from "./ButtonItem";

const ExpandableButtonItem = ({
  children,
  dataTestId,
  icon,
  title,
  sx
}: {
  children: JSX.Element | null;
  dataTestId: string;
  icon: JSX.Element;
  title: string;
  sx?: SxProps;
}) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ButtonItem sx={sx} icon={icon} onClick={handleClick} title={title} dataTestId={dataTestId}>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ButtonItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default ExpandableButtonItem;
