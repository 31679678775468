export const headCells = [
  {
    id: "user",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_pay.user",
    sortable: false
  },
  {
    id: "reasons",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_pay.reasons",
    sortable: false
  },
  {
    id: "time",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_pay.time",
    sortable: false
  }
];
