import { Alert, AlertTitle, Box, Tooltip, Typography, colors } from "@mui/material";
import getCountryNameFromCode from "components/ra/Input/AutocompleteInput/utils/getCountryNameFromCode";
import RemittanceDetailsLayout from "components/ra/layout/RemittanceDetailsLayout";
import { BANK, MOBILE_MONEY } from "constants/remittanceDeliveryMethods";
import dayjs from "dayjs";
import useGetTranslation from "hooks/utils/useGetTranslation";
import { pick } from "lodash";
import { useLocaleState } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import formatCurrency from "utils/formatCurrency";

const RemittanceSendDetails = ({
  AdditionalDeliveryInfo,
  paymentStatus = "ERRORED",
  statusText = "not_paid",
  alertType,
  alertTitle,
  alertMessage
}: {
  AdditionalDeliveryInfo?: JSX.Element;
  paymentStatus?: string;
  statusText?: string;
  alertType?: "warning" | "success" | "info" | "error";
  alertTitle?: string;
  alertMessage?: string | JSX.Element;
}) => {
  const values: any = useWatch();
  const getTranslation = useGetTranslation();
  const [locale] = useLocaleState();
  const { getValues } = useFormContext();
  const formValues = getValues();
  const recipient_country_currency = formValues?.recipient_country?.label;
  const sender_country_currency = formValues?.sender_country?.label;

  const recipientDetailsContent = pick(values, [
    "recipient_first_name",
    "recipient_last_name",
    "recipient_middle_name",
    "recipient_phone_number",
    "recipient_city",
    "recipient_state"
  ]);

  const totalAmountContent = pick(values, ["total_amount"]);

  const moneyInformationContent = pick(values, ["sender_amount", "recipient_amount", "service_charge"]);

  const moneyInformationTransformed = [
    {
      title: getTranslation("sender_sends"),
      value: String(formatCurrency(moneyInformationContent?.sender_amount, locale, sender_country_currency))
    },
    {
      title: getTranslation("service_charge"),
      value: String(formatCurrency(moneyInformationContent?.service_charge, locale, sender_country_currency))
    },
    {
      title: getTranslation("recipient_gets"),
      value: String(
        formatCurrency(moneyInformationContent?.recipient_amount, locale, recipient_country_currency)
      )
    }
  ];

  const deliveryChannelContent = pick(values, [
    "delivery_method",
    "delivery_channel_name",
    "account_holder_number"
  ]);

  const getAccountNumberInformation = () => {
    const accountHolderNumber = String(deliveryChannelContent?.account_holder_number);
    const accountNumberTitle = String(getTranslation("account_number"));

    const accountNumberItem = {
      title: accountNumberTitle,
      value: accountHolderNumber
    };

    if (formValues?.delivery_method === BANK || formValues?.delivery_method === MOBILE_MONEY) {
      return [accountNumberItem];
    }

    return [];
  };

  const deliveryChannelTransformed = [
    {
      title: getTranslation("delivery_method"),
      value: String(deliveryChannelContent?.delivery_method)
    },
    {
      title: getTranslation("delivery_channel"),
      value: String(deliveryChannelContent?.delivery_channel_name)
    },
    ...getAccountNumberInformation()
  ];

  const recipient_country = getCountryNameFromCode(
    pick(values, ["recipient_country"])?.recipient_country?.code,
    locale
  );

  const senderDetailsContent = pick(values, [
    "sender_first_name",
    "sender_last_name",
    "sender_middle_name",
    "sender_phone_number",
    "sender_date_of_birth",
    "sender_gender",
    "sender_id_type",
    "sender_id_number",
    "sender_place_of_issue",
    "sender_date_of_issue",
    "sender_expiry_date",
    "occupation",
    "sender_city",
    "sender_state"
  ]);

  const senderInfoTransformed = {
    sender_gender: getTranslation(senderDetailsContent?.sender_gender?.toLowerCase()),
    sender_id_type: getTranslation(senderDetailsContent?.sender_id_type?.toLowerCase()),
    sender_date_of_birth: dayjs(senderDetailsContent?.sender_date_of_birth).format("DD/MM/YYYY"),
    sender_date_of_issue: dayjs(senderDetailsContent?.sender_date_of_issue).format("DD/MM/YYYY"),
    sender_expiry_date: dayjs(senderDetailsContent?.sender_expiry_date).format("DD/MM/YYYY")
  };

  const combinedSenderDetails = {
    ...senderDetailsContent,
    ...senderInfoTransformed
  };

  const sender_country = getCountryNameFromCode(
    pick(values, ["sender_country"])?.sender_country?.code,
    locale
  );
  const additionalInformationContent = pick(values, [
    "sender_source_of_funds",
    "sender_reason",
    "sender_relationship_to_recipient"
  ]);

  const attachmentInformation = pick(values, ["document_1", "document_2"]);
  const attachmentInfoTransformed = {
    document_1: attachmentInformation?.document_1?.title,
    document_2: attachmentInformation?.document_2?.title
  };

  return (
    <>
      <Box ml={1}>
        <Alert severity={alertType || "warning"} variant="standard">
          <AlertTitle sx={{ fontWeight: "bold" }}>{alertTitle}</AlertTitle>
          {alertMessage}
        </Alert>
      </Box>
      <RemittanceDetailsLayout
        moneyInformationCardFields={{
          total_amount: totalAmountContent?.total_amount,
          list: moneyInformationTransformed,
          paymentStatus: paymentStatus,
          statusTextLabel: getTranslation(statusText)
        }}
        deliveryChannelFields={{
          list: deliveryChannelTransformed,
          addtionalInfo: AdditionalDeliveryInfo
        }}
        recipientDetailFields={{
          content: recipientDetailsContent,
          children: (
            <Tooltip title={recipient_country ?? ""} placement="left">
              <Box component="div">
                <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
                  {getTranslation("recipient_country")} :
                </Typography>
                <Typography>{recipient_country}</Typography>
              </Box>
            </Tooltip>
          )
        }}
        senderDetailFields={{
          content: combinedSenderDetails,
          children: (
            <Tooltip title={sender_country ?? ""} placement="left">
              <Box component="div">
                <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
                  {getTranslation("sender_country")} :
                </Typography>
                <Typography>{sender_country}</Typography>
              </Box>
            </Tooltip>
          )
        }}
        additionalInformationfields={{
          content: additionalInformationContent
        }}
        attachmentFields={{
          content: attachmentInfoTransformed
        }}
      />
    </>
  );
};

export default RemittanceSendDetails;
