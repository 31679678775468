import { isEmpty } from "lodash";

/**
 * Utils function which performs total calculation for array input
 *
 * @param {Array.<Object>} collection
 * @returns {Number}
 */
const arrayInputTotalPriceCalculator = (collection) => {
  let total;

  total = collection ? collection.filter((item) => item) : [];

  if (!isEmpty(total)) {
    // eslint-disable-next-line
    total = total?.reduce((acc, curr) => acc + Number(curr?.price) * Number(curr?.quantity), 0);
    total = String(total);
  } else {
    total = 0;
  }

  return total;
};

export default arrayInputTotalPriceCalculator;
