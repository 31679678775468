import { Backdrop, Box, CircularProgress, Paper, Typography } from "@mui/material";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { Bill } from "api/types/agentPaymentsTypes";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { AGENT_PAYMENTS_GET_SERVICE } from "constants/dataProviderTypes";
import { useState } from "react";
import { Toolbar, useLocaleState, useNotify, useTranslate } from "react-admin";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SEARCHABLE_BILL } from "constants/agentPaymentsServiceTypes";
import AlertGuide from "components/material/Alert/AlertGuide";
import AgentPaymentsBillSearch from "./processSteps/AgentPaymentsBillSearch";
import SubmitStepsWrapper from "./processSteps/SubmitStepsWrapper/SubmitStepsWrapper";
import BackButton from "components/other/ButtonBack";
import { ChevronLeft } from "@mui/icons-material";

type IStep = {
  id: number;
  component: React.ReactElement;
  name: string;
};

const AgentPaymentsProcess = () => {
  const t = useTranslate();
  const [locale] = useLocaleState();
  const notify = useNotify();
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [billsSearchList, setBillsSearchList] = useState<Bill[]>([]);
  const [billItem, setBillItem] = useState<Bill>();

  const { data: serviceData, isLoading: isLoadingService } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_GET_SERVICE],
    () => dataProvider.getBillService({ serviceId: id || "" }),
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  const handleMove = (stp: number) => {
    if (stp > 4) return;
    if (stp < 1) return;
    setStep(stp);
  };

  const STEPS: IStep[] = [
    {
      id: 0,
      name: "BillSearch",
      component: <AgentPaymentsBillSearch handleMove={handleMove} setBillsSearchList={setBillsSearchList} />
    },
    {
      id: 1,
      name: "SubmitWrapper",
      component: (
        <SubmitStepsWrapper
          billItem={billItem}
          billsSearchList={billsSearchList}
          setBillItem={setBillItem}
          handleMove={handleMove}
        />
      )
    }
  ];

  return (
    <>
      <Toolbar
        variant={"regular"}
        disableGutters={true}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "transparent"
        }}
      >
        <Breadcrumbs service={"agent_payments_home"} page={"alleh_afrique"} />
      </Toolbar>
      <Box mb={1}>
        <AlertGuide
          title={`${t(`resources.${["agent_payments"]}.fields.how_to_pay`)} - ${t(
            `resources.${["agent_payments"]}.fields.steps_to_follow`
          )}`}
          service="agent_payments"
          type="instruction"
        >
          <ul>
            {Array.isArray(serviceData?.stepHints?.find((item: any) => item?.lang === locale)?.steps) &&
              serviceData?.stepHints
                ?.find((item: any) => item?.lang === locale)
                ?.steps.map((step: any, idx: number) => <li key={idx}>{step?.value}</li>)}
          </ul>
        </AlertGuide>
      </Box>
      <Paper sx={{ height: "100%", paddingTop: 1, marginBottom: 2, position: "relative" }}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoadingService}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {serviceData?.type === SEARCHABLE_BILL ? (
          STEPS[step - 1]?.component
        ) : (
          <>
            <BackButton startIcon={<ChevronLeft />}>{t("ra.action.back")}</BackButton>
            <Typography textAlign="center" fontWeight="bold" fontSize={22}>
              Service not yet handled...
            </Typography>
          </>
        )}
      </Paper>
    </>
  );
};

export default AgentPaymentsProcess;
