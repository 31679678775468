import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { PropsOfStyles, withStyles } from "@mui/styles";
import Card from "components/material/Card/CardBase";
import BackButton from "components/other/ButtonBack";
import { GET_FILE, UPLOAD_MERCHANT_LOGO } from "constants/dataProviderTypes";
import { FieldValues } from "react-hook-form";
import React, { useRef } from "react";
import {
  email,
  Form,
  RaRecord,
  required,
  SaveButton,
  TextInput,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate
} from "react-admin";
import { useMutation, useQuery } from "react-query";
import { getStorageMerchantCCId } from "utils/storageUtils";
import { DropzoneFileType } from "components/types/otherComponentTypes";
import queryErrorHandler from "api/queryErrorHandler";

const styles: PropsOfStyles<string | object> = {
  loadingWrapper: {
    height: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  image: {
    marginTop: 25,
    height: 150,
    width: 250
  },
  inputFile: {
    display: "none"
  },
  buttonSelect: {
    marginTop: 15
  }
};

const validateName = [required()];
const validateAddress = [required()];
const validatePhoneNumber = [required()];
const validateEmail = [required(), email()];

const ProfileCreateEditForm = ({ classes }: RaRecord) => {
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const record = useRecordContext();
  const merchantCcId = getStorageMerchantCCId();
  const notify = useNotify();
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const [updateProfile, { isLoading: loadingUpdateProfile }] = useUpdate();

  const { data: merchantData } = useGetOne(
    "merchant",
    {
      id: merchantCcId
    },
    { enabled: !!merchantCcId, refetchOnWindowFocus: false }
  );

  const { mutate: mutateUploadLogo, isLoading: isLoadingUploadLogo } = useMutation(
    ["merchant", UPLOAD_MERCHANT_LOGO],
    ({ id, file }: { id: string | null; file: File | DropzoneFileType }) => {
      return dataProvider.uploadLogo("merchant", { id, file });
    }
  );

  const { data: dataLogo } = useQuery(
    ["merchant", GET_FILE],
    () => dataProvider.getLogo("merchant", { id: merchantData?.merchantId }),
    { enabled: !!merchantData?.merchantId, refetchOnWindowFocus: false }
  );

  const handleClickUploadLogo = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];

      // Check for the image dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        if (width !== 125 || height !== 100) {
          notify(t("cc.notification.invalid_image_dimensions"), { type: "error" });
          return;
        } else {
          mutateUploadLogo(
            { id: getStorageMerchantCCId(), file },
            {
              onSuccess: () => {
                notify(t("cc.notification.uploaded"), { type: "success" });
              },
              onError: (error) => {
                queryErrorHandler({ error, notify });
              }
            }
          );
        }
      };
    }
  };

  const processEditProfile = async (values: FieldValues) => {
    const { name, address, phonenumber, email } = values;
    const profilePayload = {
      name,
      address,
      phonenumber,
      email
    };
    await updateProfile(
      "merchant",
      { id: record?.id, data: profilePayload },
      {
        onSuccess: () => {
          notify(`cc.notification.updated_profile`, { type: "success" });
        },
        onError: (error) => {
          notify(`${error}`, { type: "error" });
        }
      }
    );
  };

  const submit = async (values: FieldValues): Promise<void> => {
    await processEditProfile(values);
  };

  return (
    <Form onSubmit={submit}>
      <Box p="1em">
        <Box display="flex" sx={{ height: "100%" }}>
          <Box flex={5} mr="2em">
            <Card cardTitle={t("cc.card.personal_information")} variant="outlined">
              <Grid container alignItems="center" spacing={3} style={{ width: "100%" }}>
                <Grid item xs={6}>
                  <TextInput source="name" type="text" validate={validateName} fullWidth />
                </Grid>

                <Grid item xs={6}>
                  <TextInput source="address" type="text" validate={validateAddress} fullWidth />
                </Grid>

                <Grid item xs={6}>
                  <TextInput source="phonenumber" type="text" validate={validatePhoneNumber} fullWidth />
                </Grid>

                <Grid item xs={6}>
                  <TextInput source="email" type="email" validate={validateEmail} fullWidth />
                </Grid>
              </Grid>

              <Box mt="1em" />
            </Card>
            <Box display="flex" justifyContent="end" width="100%" mt={2}>
              <BackButton variant="outlined" color="secondary">
                {t("cc.button.cancel")}
              </BackButton>
              <SaveButton
                style={{ margin: "0px 15px" }}
                color="secondary"
                label={t("cc.button.save")}
                // redirect="/prl"
                saving={loadingUpdateProfile}
                // handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
          </Box>
          <Box flex={3} ml="2em" sx={{ height: "auto" }}>
            <Card cardTitle={t("cc.card.merchant_logo")} variant="outlined">
              {!isLoadingUploadLogo ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Button variant="outlined" className={classes.buttonSelect} onClick={handleClick}>
                    {t("cc.button.select")}
                  </Button>
                  <Typography variant={"body2"} color={"secondary"} sx={{ marginTop: 1 }}>
                    {t("cc.typography.merchant.dimension_requirement")}
                  </Typography>
                  <input
                    className={classes.inputFile}
                    type="file"
                    onChange={handleClickUploadLogo}
                    ref={hiddenFileInput}
                    accept="image/*"
                  />
                  <img src={dataLogo?.data} alt="" className={classes.image} placeholder="toto" />
                </Box>
              ) : (
                <Box className={classes.loadingWrapper}>
                  <CircularProgress color="secondary" />
                  <Typography variant="body1" style={{ marginTop: 15 }}>
                    {`${t("cc.status.uploading")}...`}
                  </Typography>
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </Box>
    </Form>
  );
};

export default withStyles(styles)(ProfileCreateEditForm);
