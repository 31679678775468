import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import formatCurrency from "utils/formatCurrency";
import formatDate from "utils/formatDate";

import PaymentMethod from "../../../List/PrlList/components/PaymentMethod";
import TransactionStatus from "./TransactionStatus";

const styles = {
  headTableCell: {
    fontWeight: "bold"
  },
  tableContainer: {
    borderRadius: 8,
    minHeight: 90
  }
};

export const TableTransactionHistory = ({ classes }) => {
  const t = useTranslate();
  const record = useRecordContext();

  const headers = ["payment_method", "amount", "fees", "total", "date", "status"];

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={1}
        className={classes.tableContainer}
        data-testid="transaction-history-table"
      >
        <Table aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers.map((name) => (
                <TableCell className={classes.headTableCell}>{t(`resources.prl.fields.${name}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {record?.payments?.map((row, index) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <PaymentMethod id={row?.paymentMethod?.nameInternal[0]?.text} />
                </TableCell>
                <TableCell>{formatCurrency(row?.amount)}</TableCell>
                <TableCell>{formatCurrency(row?.quote)}</TableCell>
                <TableCell>{formatCurrency(row?.total)}</TableCell>
                <TableCell>{formatDate(row?.createdAt)}</TableCell>
                <TableCell>
                  <TransactionStatus status={row?.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(styles)(TableTransactionHistory);
