import { Form, ResourceContextProvider } from "react-admin";
import { Paper, Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import * as React from "react";
import RemittanceStepperWrapper from "./RemittanceStepperWrapper";

const RemittanceSend = () => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const defaultValues = {
    // sender_amount: 0,
    sender_country: { code: "CM", label: "XAF" }
  };

  return (
    <>
      <Toolbar disableGutters>
        <Breadcrumbs service={"remittance"} page={"send"} />
      </Toolbar>
      <ResourceContextProvider value={"remittance"}>
        <Paper sx={{ padding: 3 }}>
          <Form mode={"onBlur"} defaultValues={defaultValues}>
            <RemittanceStepperWrapper activeStep={activeStep} setActiveStep={setActiveStep} />
          </Form>
        </Paper>
      </ResourceContextProvider>
    </>
  );
};

export default RemittanceSend;
