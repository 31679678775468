import { Edit } from "react-admin";
import ProfileCreateEditForm from "../Form/ProfileCreateEditForm";

const ProfileEdit = ({ permissions, ...rest }) => {
  return (
    <Edit component="div" {...rest}>
      <ProfileCreateEditForm permissions={permissions} />
    </Edit>
  );
};

export default ProfileEdit;
