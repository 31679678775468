import { AddCircleOutline, Circle, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  colors,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CardBase from "components/material/Card/CardBase";
import ModalSuccess from "components/material/Modal/ModalSuccess";
import FieldArray from "components/other/FieldArray";
import { debounce, isEmpty, rest, toPairs } from "lodash";
import { useState } from "react";
// eslint-disable-next-line
// eslint-disable-next-line
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import queryErrorHandler from "api/queryErrorHandler";
import AlertGuide from "components/material/Alert/AlertGuide";
import useHandleModalSuccess from "hooks/useHandleModalSuccess";
import {
  DeleteButton,
  Form,
  ListButton,
  NotificationType,
  NumberInput,
  SaveButton,
  TextInput,
  useCreate,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useTranslate,
  useUpdate
} from "react-admin";
import { useFormContext } from "react-hook-form";
import findPermission from "utils/findPermission";
import Dropzone from "../../other/Dropzone/Dropzone";
import AutocompleteProduct from "./components/AutocompleteProduct";
import arrayInputTotalPriceCalculator from "./utils/arrayInputTotalPriceCalculator";
import { ProductLogInsertObject } from "api/types/prlTypes";
import { DropzoneFileType } from "components/types/otherComponentTypes";
import useQueryPrlAttachment from "hooks/api/useQueryPrlAttachment";
import useHandlePrlAttachment from "hooks/utils/useHandlePrlAttachment";
import useMutatePrlAttachment from "hooks/api/useMutatePrlAttachment";
import { FORM_TYPE_CREATE, FORM_TYPE_EDIT } from "constants/formTypes";

const PrlCreateEditForm = ({ formType }: { formType: "create" | "edit" }) => {
  const { permissions } = usePermissions();
  const prlDeleteAny = findPermission(permissions, "prl_delete_any");

  const prlDeleteOwn = findPermission(permissions, "prl_delete_own");
  const t = useTranslate();
  const [createPrl, { isLoading: loadingCreatePrl, data: dataPrl }] = useCreate();
  const [updatePrl, { isLoading: loadingUpdatePrl }] = useUpdate();
  // Local state for attachments
  const [attachments, setAttachments] = useState<DropzoneFileType[]>([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const [link, setLink] = useState("");
  const [autocompleteFilterValue, setAutocompleteFilterValue] = useState("");
  const resource = useResourceContext();

  const record = useRecordContext();

  // List used for autocomplete component
  const { data: productData, isLoading: productIsLoading } = useGetList("product", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "createdAt", order: "DESC" },
    filter: { name: autocompleteFilterValue }
  });
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    handleCreate: handleModalCreate,
    handleClose: handleModalClose
  } = useHandleModalSuccess({ setAttachments });
  const {
    add: handleDropAttachment,
    remove: handleDeleteAttachment,
    update: handleUpdateAttachment
  } = useHandlePrlAttachment({
    formType,
    setModalOpen,
    attachments,
    setAttachments
  });

  const { data: dataAttachment, isLoading: isLoadingQueryPrlAttachment } = useQueryPrlAttachment();
  const { mutateUploadAttachment, isLoading: isLoadingMutatePrlAttachment } = useMutatePrlAttachment({
    formType,
    setModalOpen
  });
  const goToListPage = () => {
    redirect(`/prl`, resource);
  };

  const showNotify = (action: string, key: string, smart_count = 1, type: NotificationType = "success") =>
    notify(t(`cc.notification.${action}_${key}`, { smart_count }), { type });

  const TotalPrice = () => {
    const { getValues } = useFormContext();

    const values = getValues();

    return (
      <Typography style={{ fontWeight: 600 }} data-testid="total-price">
        {arrayInputTotalPriceCalculator(values?.product)}
      </Typography>
    );
  };

  const submit = async (values: any) => {
    if (formType === FORM_TYPE_CREATE) {
      //Create
      await handleCreatePrl(values);
    } else {
      //Edit
      await handleEditPrl(values);
    }
  };

  const handleCreatePrl = async (values: any) => {
    const { customer, product, notes, reference } = values;

    const mappedProducts = product?.map(
      ({ id, quantity, name, description, price }: ProductLogInsertObject) => ({
        id,
        quantity,
        name,
        description,
        price: Number(price)
      })
    );

    const prlPayload = {
      customer,
      notes: notes || "",
      product: mappedProducts || [],
      flowType: "customer",
      reference
    };

    await createPrl(
      resource,
      { data: prlPayload },
      {
        onSuccess: (data) => {
          mutateUploadAttachment({ files: attachments, id: data?.id });
          setLink(data?.shortUrl || data?.url);
        },
        onError: (error) => queryErrorHandler({ error, notify })
      }
    );
  };

  const handleEditPrl = async (values: any) => {
    const { customer, product, notes, reference } = values;

    const mappedProducts = product?.map(
      ({ id, quantity, name, description, price }: ProductLogInsertObject) => ({
        id,
        quantity,
        name,
        description,
        price: Number(price)
      })
    );

    delete customer.customerLogId;

    const prlPayload = {
      customer,
      notes: notes || "",
      product: mappedProducts || [],
      flowType: "customer",
      reference
    };
    await updatePrl(
      resource,
      { id: record.id, data: prlPayload },
      {
        onSuccess: () => {
          showNotify("updated", resource);
          redirect("/prl", resource);
        },
        onError: (error) => queryErrorHandler({ error, notify })
      }
    );
  };

  const schema = yup
    .object()
    .shape({
      product: yup.array().of(
        yup.object().shape({
          name: yup.string().required(t("validation.required")),
          price: yup
            .number()
            .min(
              1,
              t("validation.minValue", {
                min: 0
              })
            )
            .required(t("validation.required")),
          description: yup.string().required(t("validation.required")),
          quantity: yup
            .number()
            .min(
              1,
              t("validation.minValue", {
                min: 0
              })
            )
            .required(t("validation.required"))
        })
      ),
      customer: yup.object().shape({
        nameFirst: yup.string().required(t("validation.required")),
        nameLast: yup.string().required(t("validation.required")),
        email: yup.string().email(t("validation.email")).required(t("validation.required")),
        customerCompanyName: yup.string(),
        customerCompanyAddress: yup.string(),
        phonenumber: yup
          .string()
          .required(
            t("validation.regex", {
              pattern: "[0-9]"
            })
          )
          .matches(/[0-9]/)
      })
    })
    .required();

  return (
    <>
      <Stack spacing={1} sx={{ marginBottom: 4 }}>
        <AlertGuide
          service={resource}
          type={"instruction"}
          title={t("cc.alert.instruction.title", { smart_count: 0 })}
        >
          <List dense>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <Circle sx={{ fontSize: 7 }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {t(`cc.alert.instruction.prl.step_${num}`)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AlertGuide>
        {/*FIXME: Fix prl alert tip color*/}
        <AlertGuide service={resource} type="tip" title={t("cc.alert.tip.title", { smart_count: 0 })}>
          <List dense>
            {[1, 2, 3, 4].map((num) => (
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <Circle sx={{ fontSize: 7 }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {t(`cc.alert.tip.prl.tip_${num}`)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AlertGuide>
      </Stack>

      <Form {...rest} defaultValues={record} onSubmit={submit} resolver={yupResolver(schema)}>
        <Box>
          <CardBase
            cardTitle={t("cc.card.product_information")}
            footerContent={
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Typography style={{ fontWeight: 700, color: colors.cyan[700], marginRight: 5 }}>
                  {t("resources.prl.fields.total")}:
                </Typography>

                <TotalPrice />
              </Box>
            }
          >
            <FieldArray
              render={({ fields, append, remove, update, watch }: any) => {
                const handleProductSelect = (index: number, value: any) => {
                  if (typeof value === "object") {
                    const { description, price = 0, name, productUuid } = value;

                    update(index, { description, price, quantity: 1, name, productUuid });
                  }
                };

                // Clearing the product field values
                const handleProductRemove = (index: number, value: any) => {
                  update(index, { ...value, description: "", price: 0, quantity: 0, name: "" });
                };

                return (
                  <div>
                    {fields?.map((field: any, index: number) => {
                      const priceWatcher = watch(`product.${index}.price`);
                      const quantityWatcher = watch(`product.${index}.quantity`);

                      return (
                        <Grid container spacing={1} style={{ width: "100%" }} key={field.id}>
                          <Grid item xs={3}>
                            <AutocompleteProduct
                              freeSolo
                              fetchMoreOptions={debounce(setAutocompleteFilterValue, 200)}
                              selectedValues={fields}
                              loading={productIsLoading}
                              label={t("resources.prl.fields.product_name")}
                              onCloseClick={(value: any) => handleProductRemove(index, value)}
                              onSelect={(value: any) => handleProductSelect(index, value)}
                              name={`product.${index}.name`}
                              options={
                                // eslint-disable-next-line
                                !isEmpty(productData) ? toPairs(productData).map(([i, value]) => value) : []
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextInput
                              data-testid="description"
                              source={`product.${index}.description`}
                              multiline
                              minRows={1}
                              type="text"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberInput
                              source={`product.${index}.price`}
                              min={0}
                              data-testid="price"
                              format={(e) => Number(Math.round(e)).toString()}
                              fullWidth
                              label={t("resources.prl.fields.price")}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <NumberInput
                              data-testid="quantity"
                              source={`product.${index}.quantity`}
                              fullWidth
                              label={t("resources.prl.fields.quantity")}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              size={"medium"}
                              label={t("resources.prl.fields.total")}
                              inputProps={{
                                readOnly: true,
                                "data-testid": "price-total",
                                sx: { height: "100%" }
                              }}
                              sx={{ height: "100%" }}
                              variant={"outlined"}
                              value={priceWatcher * quantityWatcher}
                            ></TextField>
                          </Grid>

                          <Grid item xs={1}>
                            <Box
                              sx={{
                                marginTop: 1,
                                display: "flex",
                                alignItems: "start"
                              }}
                            >
                              <Tooltip title={t("cc.tooltip.remove")} placement="top">
                                <IconButton onClick={() => remove(index)} color="primary" size="large">
                                  <RemoveCircleOutline />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}

                    {fields?.length > 0 && <Divider />}

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip title={t("cc.tooltip.add")} placement="top">
                        <IconButton
                          style={{ marginTop: 20 }}
                          size="large"
                          data-testid="plus-button"
                          onClick={() =>
                            append({
                              name: "",
                              description: "",
                              price: 0,
                              quantity: 0
                            })
                          }
                          color="primary"
                        >
                          <AddCircleOutline sx={{ fontSize: 50 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </div>
                );
              }}
            ></FieldArray>
          </CardBase>
          <Box mb="1em" />
          <CardBase cardTitle={t("cc.card.customer_information")}>
            <Grid container spacing={1}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextInput data-testid="nameFirst" source="customer.nameFirst" type="text" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextInput data-testid="nameLast" source="customer.nameLast" type="text" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextInput data-testid="phonenumber" source="customer.phonenumber" type="text" fullWidth />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextInput data-testid="email" source="customer.email" type="email" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    data-testid="companyName"
                    source="customer.customerCompanyName"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    data-testid="companyAddress"
                    source="customer.customerCompanyAddress"
                    resource={resource}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardBase>
          <Box mt="1em" />

          <CardBase cardTitle={t("cc.card.additional_information")}>
            <Grid container spacing={1}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextInput
                    data-testid="reference"
                    source="reference"
                    resource={resource}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Dropzone
                  files={formType === FORM_TYPE_EDIT ? dataAttachment?.data : attachments}
                  onDrop={handleDropAttachment}
                  onDelete={handleDeleteAttachment}
                  onUpdate={handleUpdateAttachment}
                  loading={isLoadingQueryPrlAttachment}
                />
              </Grid>
            </Grid>
          </CardBase>
        </Box>
        <Toolbar sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Box>
            <ListButton
              variant="outlined"
              size="medium"
              label={t("ra.action.cancel")}
              icon={undefined}
              sx={{ marginRight: 2 }}
            ></ListButton>
            <SaveButton
              data-testid="submit-button"
              color="primary"
              label={t("ra.action.save")}
              saving={loadingCreatePrl || loadingUpdatePrl || isLoadingMutatePrlAttachment}
            />
          </Box>

          {record?.paymentStatus !== "SUCCESS" && (prlDeleteAny || prlDeleteOwn) && (
            <DeleteButton record={record} />
          )}
        </Toolbar>
        <ModalSuccess
          open={modalOpen}
          onCreate={handleModalCreate}
          onBack={goToListPage}
          onClose={handleModalClose}
          link={link}
          data={dataPrl}
        />
      </Form>
    </>
  );
};

export default PrlCreateEditForm;
