import { Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import Divider from "components/material/Divider";
import PhoneInput from "components/ra/Input/PhoneInput";
import { BANK, CASH_PICKUP, MOBILE_MONEY } from "constants/remittanceDeliveryMethods";
import useGetTranslation from "hooks/utils/useGetTranslation";
import {
  // BooleanInput,
  TextInput,
  regex,
  required,
  useLocaleState
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import getCountryNameFromCode from "components/ra/Input/AutocompleteInput/utils/getCountryNameFromCode";

function RemittanceSendStepTwo() {
  const getTranslation = useGetTranslation();
  const { getValues, register, trigger } = useFormContext();
  const formValues = getValues();
  const recipientCountry = useWatch({ name: "recipient_country" });
  const [locale] = useLocaleState();

  // Get dynamic fields based on delivery method
  const getRecipientAccountDetails = () => {
    switch (formValues?.delivery_method) {
      case BANK:
        return {
          title: getTranslation("recipient_bank_details"),
          accTypeLabel: getTranslation("bank"),
          accNumberLabel: getTranslation("account_holder_number")
        };
      case MOBILE_MONEY:
        return {
          title: getTranslation("recipients_mobile_money_details"),
          accTypeLabel: getTranslation("mobile_money"),
          accNumberLabel: getTranslation("phone_number")
        };
      default:
        return "";
    }
  };

  const getRecinpientInfoHeaderTitle = () => {
    switch (formValues?.delivery_method) {
      case CASH_PICKUP:
        return getTranslation("recipient_name_must_match_valid_id");
      default:
        return getTranslation("recipient_name_must_match_account_holders_name");
    }
  };

  const accDetail: any = getRecipientAccountDetails();

  const validateNameFields = regex(/^[a-zA-Z0-9À-ÿ\s]+$/, getTranslation("invalid_format"));
  const validateRequiredNameFields = [required(), validateNameFields];
  const validateSpacedNameFields = regex(/^[a-zA-Z0-9À-ÿ\s]+$/, getTranslation("invalid_format"));
  const validateAddressFields = [required(), validateSpacedNameFields];
  const nameFieldRules = {
    maxLength: {
      value: 35,
      message: getTranslation("invalid_format_max_lenght")
    }
  };

  const addressFieldRules = {
    maxLength: {
      value: 35,
      message: getTranslation("invalid_format_max_lenght")
    }
  };
  const country = getCountryNameFromCode(formValues?.recipient_country?.code, locale);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await trigger("sender_amount", { shouldFocus: true });
    })();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await trigger("sender_amount", { shouldFocus: true });
    })();
  }, []);

  return (
    <div>
      <Typography variant={"h6"} sx={{ marginBottom: 2, fontWeight: "bold" }}>
        {accDetail?.title}
      </Typography>
      {formValues?.delivery_method !== CASH_PICKUP && (
        <Grid container columnSpacing={5}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label={accDetail?.accTypeLabel}
              name="delivery_channel_name"
              defaultValue={formValues?.delivery_channel_name}
              disabled
              required
              data-testid="delivery_channel_name"
              size="medium"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label={accDetail?.accNumberLabel}
              variant={"outlined"}
              fullWidth
              source="account_holder_number"
              disabled
              size="medium"
            />
          </Grid>
        </Grid>
      )}

      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />

      <Typography
        variant={"h6"}
        sx={{
          fontWeight: "bold"
        }}
      >
        {getTranslation("recipient_information")}
      </Typography>
      <Typography sx={{ marginBottom: 2, fontSize: 14 }}>{getRecinpientInfoHeaderTitle()}</Typography>
      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <TextInput
            data-testid="recipient_first_name"
            validate={validateRequiredNameFields}
            variant={"outlined"}
            fullWidth
            source="recipient_first_name"
            size="medium"
            inputProps={{
              ref: {
                ...register("recipient_first_name", nameFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            data-testid="recipient_last_name"
            validate={validateRequiredNameFields}
            variant={"outlined"}
            fullWidth
            source="recipient_last_name"
            size="medium"
            inputProps={{
              ref: {
                ...register("recipient_last_name", nameFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            data-testid="recipient_middle_name"
            variant={"outlined"}
            fullWidth
            source="recipient_middle_name"
            validate={validateNameFields}
            inputProps={{
              ref: {
                ...register("recipient_middle_name", nameFieldRules)
              }
            }}
            size="medium"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={5} mb={5}>
        <Grid item xs={4}>
          <PhoneInput
            source={"recipient_phone_number"}
            defaultCountry={recipientCountry?.code}
            label={getTranslation("phone_number")}
            disableDropdown={true}
            data-testid="recipient_phone_number"
            variant="outlined"
            validate={[required()]}
            size="medium"
          />
          {/* <BooleanInput
            label={<Typography variant={"body2"}>{getTranslation("send_sms_notification")}</Typography>}
            source="sms_notification"
            data-testid="sms_notification"
          /> */}
        </Grid>
      </Grid>

      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Typography variant={"h6"} sx={{ marginBottom: 2, fontWeight: "bold" }}>
        {getTranslation("recipient_address")}
      </Typography>

      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <TextInput
            data-testid="recipient_city"
            fullWidth
            validate={validateAddressFields}
            variant={"outlined"}
            source="recipient_city"
            size="medium"
            inputProps={{
              ref: {
                ...register("recipient_city", addressFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            data-testid="recipient_state"
            fullWidth
            variant={"outlined"}
            source="recipient_state"
            validate={validateAddressFields}
            size="medium"
            inputProps={{
              ref: {
                ...register("recipient_state", addressFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            fullWidth
            label={getTranslation("country")}
            name="recipient_country"
            defaultValue={country}
            disabled
            required
            data-testid="recipient_country"
            size="medium"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default RemittanceSendStepTwo;
