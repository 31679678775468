import { AppBar, Box, colors, Tab, Tabs } from "@mui/material";
import React from "react";
import { withStyles } from "@mui/styles";
import { snakeCase } from "lodash";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const styles = {
  appBar: {},
  tab: {
    fontWeight: 800,
    color: colors.cyan[800],
    fontSize: 16,
    textTransform: "capitalize"
  }
};

const CustomTabs = ({ tabLabels = [], children = [], activeTabIndex = 0, onChange, classes }) => {
  return (
    <div>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs aria-label="simple tabs example" value={activeTabIndex} onChange={onChange}>
          {tabLabels?.map((label, index) => (
            <Tab key={index} className={classes.tab} label={label} data-testid={`${snakeCase(label)}-tab`} />
          ))}
        </Tabs>
      </AppBar>
      {children?.map((item, index) => (
        <TabPanel key={index} value={activeTabIndex} index={index}>
          {item}
        </TabPanel>
      ))}
    </div>
  );
};

export default withStyles(styles)(CustomTabs);
