import { Grid } from "@mui/material";
import { isArray, mapKeys } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocale, useRecordContext, useTranslate } from "react-admin";
import extractReason from "utils/extractReason";
import formatDate from "utils/formatDate";
import CardListTimeline from "components/material/Card/CardListTimeline";
import ChipStatus from "components/other/context/StatusField";
import { fetchDocumentImage } from "api/dataProvider/utils/customQuery";
import { BUSINESS, PLACE_OF_BUSINESS, RESIDENTIAL } from "constants/addressType";
import { ALT1, MAIN } from "constants/phoneNumberType";
import { REJECTED } from "constants/status";
import formatRelativeTime from "utils/formatRelativeTime";
import CardGrid from "components/material/Card/CardGrid";
import CardDocuments from "components/material/Card/CardDocuments";

const KycDetailsTab = ({ setActiveTabIndex }) => {
  const record = useRecordContext();
  const t = useTranslate();
  const locale = useLocale();
  const [documentsContent, setDocumentsContent] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const generalInformationContent = [
    {
      status: (
        <ChipStatus source="status" label="Status">
          {record?.status}
        </ChipStatus>
      ),
      reason: record?.status === REJECTED ? extractReason(record?.comments) : "",
      company_name: record?.entityOrganisationExternalName,
      company_id: record?.entityOrganisationExternalId,
      entity_id: record?.entityKycRef,
      agent_id: record?.entityExternalId,
      submitted_at: formatDate(record?.submittedAt),
      last_modified: formatDate(record?.lastModifiedAt)
    }
  ];

  const personalInformationContent = [
    { first_name: record?.name?.givenName },
    { last_name: record?.name?.familyName },
    { display_name: record?.name?.displayName },
    { middle_name: record?.name?.middleName },
    { gender: record?.gender },
    { date_of_birth: formatDate(record?.dateOfBirth) },
    { nationality: record?.nationality },
    {
      business_sector: isArray(record?.businessSector)
        ? record?.businessSector?.map((sector) => sector?.name)
        : record?.businessSector?.name
    },
    {
      document_type: record?.identityDocs?.map((doc) => doc?.idType)
    },
    {
      id_number: record?.identityDocs?.map((doc) => doc?.idNumber)
    },
    {
      date_of_issue: record?.identityDocs?.map((doc) => formatDate(doc?.idIssued))
    },
    {
      place_of_issue: record?.identityDocs?.map((doc) => doc?.country)
    }
  ];

  const residentialAddress = record?.addresses?.find((address) => address?.addressType === RESIDENTIAL);
  const phonenumbers =
    record?.phonenumbers?.length > 0
      ? record?.phonenumbers?.map((phone) => ({
          [phone.phonenumberType]: phone.msisdn
        }))
      : [{ key: "" }];
  const phoneNumbersContent = phonenumbers?.map((item) =>
    mapKeys(item, (value, key) => {
      switch (key) {
        case MAIN:
          return "phone_main";
        case ALT1:
          return "phone_alt";

        default:
          return "phone";
      }
    })
  );

  const contactInformationContent = [
    { country: residentialAddress?.country },
    { region: residentialAddress?.region },
    { city: residentialAddress?.town },
    { street: residentialAddress?.street },
    { address_type: residentialAddress?.addressType },
    { state: residentialAddress?.state },
    { building_name: residentialAddress?.buildingName },
    { postal_code: residentialAddress?.postalCode },
    ...phoneNumbersContent
  ];

  const nonResidentialAddresses = record?.addresses?.filter(
    (address) => address?.addressType !== RESIDENTIAL
  );

  const otherAddresses = nonResidentialAddresses?.map((address) => [
    { country: address?.country },
    { region: address?.region },
    { city: address?.town },
    { street: address?.street },
    { address_type: address?.addressType },
    { state: address?.state },
    { building_name: address?.buildingName },
    { postal_code: address?.postalCode }
  ]);

  const comments = record?.comments?.map((comment) => ({
    name: comment?.userId,
    time: formatRelativeTime(comment?.createdAt, locale),
    text: comment?.text,
    reason: extractReason(comment?.text)
  }));
  useEffect(() => {
    const collectImages = async () => {
      if (record && record?.data) {
        setLoadingDocuments(true);
        const imgArray = [];

        for (let i = 0; i < record?.identityDocs.length; i++) {
          const doc = record?.identityDocs[i];
          const docArray = [];

          for (let j = 0; j < doc?.data?.length; j++) {
            const docImage = doc?.data[j];
            const imgBlob = await fetchDocumentImage(record?.entityId, docImage?.dataObjectId);
            docArray.push({
              url: URL.createObjectURL(imgBlob),
              dataObjectType: docImage?.dataObjectType
            });
          }

          imgArray.push({
            imgData: docArray,
            metaData: {
              idType: doc?.idType,
              documentId: doc?.documentId,
              documentStatus: doc?.documentStatus,
              idIssued: doc?.idIssued,
              idNumber: doc?.idNumber,
              country: doc?.country
            }
          });
        }
        setDocumentsContent([...documentsContent, imgArray]);
        setLoadingDocuments(false);
      }
    };

    // eslint-disable-next-line
    collectImages();
    // eslint-disable-next-line
  }, [record]);

  const getAddressCardTitle = (addressType) => {
    switch (addressType) {
      case BUSINESS:
        return `${t("cc.card.business_address")}`;

      case PLACE_OF_BUSINESS:
        return `${t("cc.card.place_of_business")}`;
      default:
        return addressType;
    }
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardGrid
              resource={"entity"}
              dataTestId={"general-information-card"}
              cardTitle={t("cc.card.general_information")}
              content={generalInformationContent}
            />
          </Grid>
          <Grid item xs={12}>
            <CardGrid
              resource={"entity"}
              dataTestId={"personal-information-card"}
              cardTitle={t("cc.card.personal_information")}
              content={personalInformationContent}
            />
          </Grid>

          <Grid item xs={12}>
            <CardGrid
              resource={"entity"}
              dataTestId={"contact-information-card"}
              cardTitle={t("cc.card.contact_information")}
              content={contactInformationContent}
            />
          </Grid>

          {otherAddresses?.length > 0 ? (
            otherAddresses?.map((addressContent, index) => (
              <Grid item xs={12} key={index}>
                <CardGrid
                  resource={"entity"}
                  dataTestId={`other-addresses-card-${index}`}
                  cardTitle={getAddressCardTitle(nonResidentialAddresses[index]?.addressType)}
                  content={addressContent}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <CardGrid resource={"entity"} cardTitle={t("cc.card.other_address")} content={[{ key: "" }]} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardDocuments
              dataTestId={"identification-documents-card"}
              variant="classic-white"
              loading={loadingDocuments}
              noBg
              cardTitle={t("cc.card.identification_documents")}
              content={documentsContent[0]}
            />
          </Grid>

          <Grid item xs={12}>
            <CardListTimeline
              dataTestId={"comments-card"}
              variant="classic-white"
              isEmpty={comments?.length === 0}
              content={comments}
              cardTitle={t("cc.card.comment", { smart_count: comments?.length })}
              onClick={setActiveTabIndex}
            />{" "}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KycDetailsTab;
