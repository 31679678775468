import { Favorite } from "@mui/icons-material";
import { Box, colors, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import {
  BooleanInput,
  Form,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useCreate,
  useGetList,
  useNotify,
  useRedirect,
  useTranslate,
  useUpdate
} from "react-admin";
import findPermission from "utils/findPermission";
import BomCard from "../../material/Card/CardBase";
import ButtonBack from "../../other/ButtonBack";

const styles = {
  label: {
    color: colors.cyan["600"],
    fontWeight: "bold",
    paddingBottom: 10
  },
  switch: {
    color: colors.cyan["600"]
  },
  inputLabels: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold"
  }
};

const countryCode = [{ id: "237", name: "Cameroon" }];

const validateServiceProvider = [required()];
const validateAccountHolder = [required()];
const validateAccountNumber = [required()];
const validateOtherInformation = [];
const validateAccountType = [required()];
const validateCountry = [required()];
const validateAddress = [required()];
const validateState = [required()];
const validateCity = [required()];
const validateSwiftCode = [required()];
const validateAccountName = [required()];

const PayoutAccountCreateEditForm = ({ record, classes, permissions, ...rest }) => {
  /*eslint-disable */
  const translate = useTranslate();
  const [selectedAccountType, setSelectedAccountType] = useState(record?.payoutAccountType);
  const [fsp, setFsp] = useState();
  const [createRequestAccount, { loading: loadingCreateRequestAccount }] = useCreate();
  const [updateRequestAccount, { loading: loadingUpdateRequestAccount }] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const pssAny = findPermission(permissions, "pss_full_access_any");

  const collectCompanyId = JSON.parse(localStorage.getItem(pssAny ? "userUuid" : "MerchantCoreCompanyId"));
  const backToListPage = () => redirect("/request-account", rest.basePath);

  const accountType = [
    { id: "mmo", name: "Mobile" },
    { id: "bank", name: "Bank" }
  ];

  // const submit = (values) => {
  //   if (Object.keys(record).length === 0) {
  //     processCreateRequestAccount(values);
  //     //Create
  //   } else {
  //     //Edit
  //     processEditRequestAccount(values);
  //   }
  // };

  // const processEditRequestAccount = async (values) => {
  //   const { isPreferred } = values;
  //   try {
  //     const {
  //       fspId,
  //       countryCode,
  //       accountName,
  //       accountNumber,
  //       accountHolderName,
  //       accountSwiftCode,
  //       address,
  //       state,
  //       city,
  //       information
  //     } = values;
  //     await updateRequestAccount("request-account", {
  //       id: record?.id,
  //       data: {
  //         fspId,
  //         coreCompanyId: collectCompanyId,
  //         countryCode,
  //         accountName,
  //         accountNumber,
  //         accountHolderName,
  //         accountSwiftCode,
  //         address,
  //         state,
  //         city,
  //         information,
  //         isPreferred: isPreferred ? true : false
  //       }
  //     });
  //     notify("Updated successfully", { type: "success" });
  //     backToListPage();
  //   } catch (error) {
  //     notify(`${error}`, { type: "warning" });
  //   }
  // };

  // const processCreateRequestAccount = async (values) => {
  //   const {
  //     fspId,
  //     countryCode,
  //     accountName,
  //     accountNumber,
  //     accountHolderName,
  //     accountSwiftCode,
  //     address,
  //     state,
  //     city,
  //     information,
  //     isPreferred
  //   } = values;

  //   const formData = {
  //     fspId,
  //     coreCompanyId: collectCompanyId,
  //     countryCode,
  //     accountName,
  //     accountNumber,
  //     accountHolderName,
  //     accountSwiftCode,
  //     address,
  //     state,
  //     city,
  //     information,
  //     isPreferred
  //   };

  //   createRequestAccount(
  //     "request-account",
  //     { data: formData },
  //     {
  //       onSuccess: async () => {
  //         notify("Created successfully", { type: "success" });
  //         backToListPage();
  //       },
  //       onFailure: (err) => {
  //         notify(err?.body?.message, { type: "error" });
  //       }
  //     }
  //   );
  // };

  const { data: fspResourceData } = useGetList("fsp", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "DESC" }
  });

  const handleAccountChange = (event) => {
    event.preventDefault();
    setSelectedAccountType(event.target.value);
    const fspData = Object.values(fspResourceData)?.filter((item) => item?.type === event.target.value);
    setFsp(fspData);
  };

  return (
    <>
      <Form>
        <BomCard cardTitle={translate("payout.account_information")} variant="outlined">
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.account_type")}*</Typography>
              <TextInput
                source="type"
                label={translate("payout.account_type")}
                type="text"
                validate={validateAccountType}
                fullWidth
                defaultValue={pssAny ? "internal" : "external"}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.country")}*</Typography>
              <SelectInput
                label={translate("payout.country")}
                source="countryCode"
                resource="payout-account"
                choices={countryCode}
                validate={validateCountry}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.select_account")}*</Typography>
              <SelectInput
                label={translate("payout.select_account")}
                source="accountName"
                resource="payout-account"
                choices={accountType}
                validate={validateAccountName}
                onChange={handleAccountChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>
                {translate("payout.financial_provider")}*
              </Typography>
              <SelectInput
                label={translate("payout.account_type")}
                source="fspId"
                resource="payout-account"
                choices={fsp}
                validate={validateServiceProvider}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              {selectedAccountType === "mmo" ? (
                <>
                  <Typography className={classes.inputLabels}>{translate("prl.phone_number")}*</Typography>
                  <TextInput
                    source="accountNumber"
                    label={translate("prl.phone_number")}
                    type="text"
                    validate={validateAccountNumber}
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <Typography className={classes.inputLabels}>
                    {translate("payout.account_number")}*
                  </Typography>
                  <TextInput
                    source="accountNumber"
                    label={translate("payout.account_number")}
                    type="text"
                    validate={validateAccountNumber}
                    fullWidth
                  />
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>
                {translate("payout.account_holder_name")}*
              </Typography>
              <TextInput
                source="accountHolderName"
                label={translate("payout.account_holder_name")}
                type="text"
                validate={validateAccountHolder}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.address")}*</Typography>
              <TextInput
                source="address"
                label={translate("payout.address")}
                type="text"
                validate={validateAddress}
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.state")}*</Typography>
              <TextInput
                source="state"
                label={translate("payout.state")}
                type="text"
                validate={validateState}
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.city")}*</Typography>
              <TextInput
                source="city"
                label={translate("payout.city")}
                type="text"
                validate={validateCity}
                fullWidth
              />
            </Grid>
          </Grid>
          {selectedAccountType !== "mmo" && (
            <Grid container spacing={3} style={{ width: "100%" }}>
              <Grid item xs={4}>
                <Typography className={classes.inputLabels}>{translate("payout.swift_code")}*</Typography>
                <TextInput
                  source="accountSwiftCode"
                  label={translate("payout.swift_code")}
                  type="text"
                  validate={validateSwiftCode}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.inputLabels}>{translate("payout.other_information")}</Typography>
              <TextInput
                label={translate("payout.other_information")}
                source="information"
                multiline
                minRows={7}
                resource="payout-account"
                type="text"
                validate={validateOtherInformation}
                fullWidth
              />
            </Grid>
          </Grid>
          {!pssAny && (
            <Grid container spacing={3} style={{ width: "100%" }}>
              <Grid item xs={4}>
                <BooleanInput
                  className={classes.switch}
                  options={{
                    checkedIcon: <Favorite color="secondary" />
                  }}
                  source="isPreferred"
                  label={translate("payout.set_as_preferred_account")}
                />
              </Grid>
            </Grid>
          )}
        </BomCard>

        <Toolbar>
          <Box mt={3} display="flex" justifyContent="end" width="100%">
            <ButtonBack variant="outlined" color="secondary">
              {translate("ra.action.cancel")}
            </ButtonBack>
            <SaveButton
              style={{ margin: "0px 15px" }}
              color="secondary"
              label={translate("ra.action.save")}
              saving={loadingCreateRequestAccount || loadingUpdateRequestAccount}
              submitOnEnter={true}
              redirect="/payout-account"
              // handleSubmitWithRedirect={formProps.handleSubmit}
            />
            {/* <DeleteButton record={formProps.record} /> */}
          </Box>
        </Toolbar>
      </Form>
    </>
  );
};

export default withStyles(styles)(PayoutAccountCreateEditForm);
