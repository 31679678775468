// import CardBase from "components/material/Card/CardBase";
import useGetTranslation from "hooks/utils/useGetTranslation";
import RemittanceSendDetails from "../RemittanceSendDetails";

const RemittanceSendStepFour = () => {
  const getTranslation = useGetTranslation();

  return (
    <RemittanceSendDetails
      alertType="warning"
      alertTitle={getTranslation("your_transaction_is_not_yet_processed")}
      alertMessage={getTranslation("please_take_a_moment")}
    />
  );
};

export default RemittanceSendStepFour;
