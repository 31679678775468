import { Autocomplete as AutocompleteMUI, AutocompleteRenderInputParams } from "@mui/material";
import { ReactNode, useState } from "react";

interface Props {
  loading: boolean;
  options: never[];
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  onChange: (e: React.SyntheticEvent, option: any) => void;
  isOptionEqualToValue: (option: never, value: never) => boolean;
  getOptionLabel: (option: never) => string;
  inputValue?: string;
  onInputChange?: (e: React.SyntheticEvent, text: string) => void;
  defaultValue?: never;
  filterSelectedOptions?: boolean;
}

const AutocompleteAsync = ({ loading, options, renderInput, ...rest }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <AutocompleteMUI
      {...rest}
      data-testid="autocomplete-async"
      id="autocomplete-async"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      renderInput={renderInput}
    />
  );
};

export default AutocompleteAsync;
