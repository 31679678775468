import { Close, CloudDownload, Facebook, Mail, Twitter, WhatsApp } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, colors, IconButton, Modal, Paper, Typography } from "@mui/material";
import { makeStyles, ThemeOfStyles } from "@mui/styles";
import CopyComponent from "components/other/CopyLinkToClipboard";
import { useRef } from "react";
import { useTranslate } from "react-admin";
import QRCode from "react-qr-code";
import exportQRCodeAsImage from "utils/exportQRCodeAsImage";
import useGetShareLinks from "hooks/useGetShareLinks";
import { PrlObject, ProductObject } from "api/types/prlTypes";
import { useFormContext } from "react-hook-form";

// TODO: Write test coverage for ModalSuccess
const useStyles = makeStyles<ThemeOfStyles<string>>({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    p: 7
  },
  social: {
    fontSize: 30,
    color: colors.cyan[600]
  },
  qrCodeWrapper: {
    borderRadius: 10,
    borderColor: colors.cyan[600],
    border: "2px solid",
    padding: 10
  }
});

interface ModalSuccessProps {
  open: boolean;
  onClose: () => void;
  link: string;
  onCreate: () => void;
  onBack: () => void;
  data: ProductObject | PrlObject;
}

const ModalSuccess = ({ open, onClose, onCreate, onBack, link, data }: ModalSuccessProps) => {
  const { getLink } = useGetShareLinks(data);
  const translate = useTranslate();
  const { reset } = useFormContext();
  const exportRef = useRef();
  const handleDownloadClick = async () => {
    if (exportRef.current) await exportQRCodeAsImage(exportRef.current, "QRCode Falcon");
  };
  const classes = useStyles();

  const emailLink = getLink("email");
  const twitterLink = getLink("twitter");
  const whatsappLink = getLink("whatsapp");
  const facebookLink = getLink("facebook");

  const handleOnCreate = () => {
    onCreate();
    reset();
  };

  return (
    <Modal
      data-testid="modal"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        className={classes.modal}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <IconButton onClick={onClose} size="large" data-testid="close-btn">
            <Close />
          </IconButton>
        </Box>
        {/*Content*/}
        <Box sx={{ padding: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box ref={exportRef} className={classes.qrCodeWrapper}>
            <QRCode value={link} data-testid="qr-code" />
          </Box>
          <Box sx={{ marginTop: 3, marginBottom: 3 }}>
            <CopyComponent url={link} data-testid="link" />
          </Box>
          <Alert severity={"info"} data-testid="alert-component">
            <AlertTitle sx={{ fontWeight: "bolder" }} data-testid="alert-title">
              {translate("cc.modal_success.instruction.title")}
            </AlertTitle>
            <Typography data-testid="alert-description">
              {translate("cc.modal_success.instruction.description")}
            </Typography>
          </Alert>
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <IconButton href={facebookLink} rel="noopener noreferrer" target="_blank" size="large">
              <Facebook className={classes.social} />
            </IconButton>

            <IconButton
              href={whatsappLink}
              data-action="share/whatsapp/share"
              rel="noopener noreferrer"
              target="_blank"
              size="large"
            >
              <WhatsApp className={classes.social} />
            </IconButton>

            <IconButton href={twitterLink} rel="noopener noreferrer" target="_blank" size="large">
              <Twitter className={classes.social} />
            </IconButton>

            <IconButton href={emailLink} size="large">
              <Mail className={classes.social} />
            </IconButton>

            <IconButton onClick={handleDownloadClick} size="large">
              <CloudDownload className={classes.social} />
            </IconButton>
          </Box>
          {/*Actions*/}
          <Box
            sx={{
              display: "flex",
              marginTop: 3
            }}
          >
            <Button variant={"outlined"} onClick={onBack} data-testid="success-modal-back-button">
              {translate("cc.button.back")}
            </Button>
            <Button
              data-testid="modal-create-button"
              color={"primary"}
              disableElevation={true}
              onClick={handleOnCreate}
              variant="contained"
              sx={{ marginLeft: 2 }}
            >
              {translate("cc.button.create_another")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ModalSuccess;
