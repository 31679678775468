import { Box, Card, CardActionArea, colors, Typography } from "@mui/material";
import {
  AGENT_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_SERVICE,
  PAYMENT_REQUEST_HISTORY_SERVICE,
  PAYMENT_TRANSACTION_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE,
  COMPANY_PRL_SERVICE
} from "constants/serviceNames";
import { useTranslate } from "react-admin";
import renderServiceIcon from "utils/renderServiceIcon";
import renderServiceTitle from "utils/renderServiceTitle";
import { getStorageMerchantCCId } from "utils/storageUtils";

const ServicePickerCard = ({ service, redirect }) => {
  const merchantID = getStorageMerchantCCId();
  const t = useTranslate();
  const renderIcon = () => {
    const sx = {
      color: colors.cyan[600],
      fontSize: 30,
      marginRight: 1
    };

    return renderServiceIcon(service, sx);
  };

  const handleCardClick = () => {
    switch (service) {
      case AGENT_SERVICE:
        redirect("/entity_agent");
        break;
      case COMPANY_SERVICE:
        redirect("/entity_company");
        break;
      case PRL_SERVICE:
        redirect("list", "prl");
        break;
      case PRODUCT_SERVICE:
        redirect("list", "product");
        break;
      case PAYMENT_TRANSACTION_SERVICE:
        redirect("list", "payment-transaction");
        break;
      case BULK_PAYMENT_SERVICE:
        redirect("list", "bulk_payment");
        break;
      case PAYMENT_REQUEST_HISTORY_SERVICE:
        redirect("list", "payment-report");
        break;
      case COMPANY_PRL_SERVICE:
        redirect(`/company-prl/${merchantID}`, "merchant");
        break;
      default:
        break;
    }
  };

  return (
    <CardActionArea onClick={handleCardClick}>
      <Card sx={{ padding: 2 }} variant="outlined" data-testid="service-picker-card">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {renderIcon()}
          <Typography color={colors.cyan[700]} component="div" variant="h5">
            {renderServiceTitle(service, t)}
          </Typography>
        </Box>
      </Card>
    </CardActionArea>
  );
};

export default ServicePickerCard;
