export const headCells = [
  {
    id: "ptn",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.ptn",
    sortable: true
  },
  {
    id: "mpay_service_label",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.service",
    sortable: true
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.status",
    sortable: true
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.amount",
    sortable: true
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.destination",
    sortable: true
  },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.customer_name",
    sortable: true
  },
  {
    id: "customer_reference",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.customer_ref",
    sortable: true
  },
  {
    id: "trid",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_payment.table.tx_ref",
    sortable: true
  }
];
