import { IGridSelectionPageData } from "../components/GridSelectionActions";
type IOption = { label: string; isChecked: boolean; value: string | number };

export function mergeOptions(data: IGridSelectionPageData[]) {
  let mergedOptions: IOption[] = [];
  data.forEach((item) => {
    mergedOptions = mergedOptions.concat(item.options);
  });
  return mergedOptions;
}
