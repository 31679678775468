import config from "config";
import saveAs from "file-saver";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { getStorageToken } from "utils/storageUtils";
import FormData from "form-data";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const apiUrl: string = config.REACT_APP_PROXY_BASE_URL;

const dataProvider = simpleRestProvider(apiUrl, httpClient);

const merchantDataProvider = {
  ...dataProvider,
  uploadLogo: (
    resource: string,
    params: {
      id: string;
      file: File;
    }
  ) => {
    const { id, file } = params;
    const formData = new FormData();
    formData.append("file", file);
    return httpClient(`${apiUrl}/${resource}/${id}/logo`, {
      method: "POST",

      // eslint-disable-next-line
      // @ts-ignore
      body: formData
    }).then(({ json }) => ({ data: json }));
  },
  getBalance: (
    resource: string,
    params: {
      id: string;
    }
  ) => {
    const { id } = params;
    return httpClient(`${apiUrl}/${resource}/${id}/balance`).then(({ json }) => ({ data: json }));
  },

  enableCompanyPrl: (resource: string, params: { id: string }) => {
    const { id } = params;
    return httpClient(`${apiUrl}/${resource}/${id}/company-prl/enable`, {
      method: "PATCH"
    }).then(({ json }) => ({ data: json }));
  },

  // For fetching binary data we are required to use fetch instead of httpClient
  getLogo: async (resource: string, params: { id: string }) => {
    const { id } = params;
    const url = `${apiUrl}/merchant/${id}/logo`;
    try {
      const request = new Request(url, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json"
        })
      });
      const response = await fetch(request);
      const data = await response.blob();
      const fileURL = window.URL.createObjectURL(data);

      return { data: fileURL };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },

  disableCompanyPrl: (resource: string, params: { id: string }) => {
    const { id } = params;
    return httpClient(`${apiUrl}/${resource}/${id}/company-prl/disable`, {
      method: "PATCH"
    }).then(({ json }) => ({ data: json }));
  },

  downloadQrCode: async (
    resource: string,
    params: {
      ccid: string;
      data: "A4" | "A5";
    }
  ) => {
    const { ccid, data = "A4" } = params;
    const url = `${apiUrl}/${resource}/${ccid}/qrcode?pageSize=${data}`;
    const token = getStorageToken() || "";

    try {
      const request = new Request(url, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      });

      const response = await fetch(request);
      const blob = await response.blob();
      return { data: blob };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },
  exportFile: async (
    resource: string,
    params: {
      ccid: string;
      format: "pdf" | "excel";
    }
  ) => {
    const { ccid, format } = params;
    const url = `${apiUrl}/${resource}/${ccid}/payment-transaction/export/${format}`;
    const token = getStorageToken();

    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      })
    });

    const response = await fetch(request);
    const type = response.headers.get("content-type") || "";
    const arraybuffer = await response.arrayBuffer();
    const blob = new Blob([arraybuffer], { type });

    saveAs(blob);
    return [];
  }
};

export default merchantDataProvider;
