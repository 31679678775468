export const formatObjectToQuery = (obj: { [key: string]: string }) => {
  let query = "";
  Object.keys(obj).forEach((key: string, idx) => {
    if (Object.values(obj)[idx]?.length > 0) {
      query += `${key.toLowerCase()}=${Object.values(obj)[idx]}&`;
    }
  });

  return query;
};
