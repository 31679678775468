import React from "react";
import { Create } from "react-admin";
import BusinessListCreateForm from "components/ra/Form/BusinessListCreateEditForm";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { withStyles } from "@mui/styles";
const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};
const BusinessListCreate = (props) => {
  return (
    <>
      <Breadcrumbs />
      <Create {...props}>
        <BusinessListCreateForm />
      </Create>
    </>
  );
};

export default withStyles(styles)(BusinessListCreate);
