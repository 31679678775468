import config from "config";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { formatObjectToQuery } from "utils/formatObjectToQuery";
import { getStorageToken } from "utils/storageUtils";

const agentPaymentsHttpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const serviceListingApi = `${config.REACT_APP_PROXY_BASE_URL}/service-listing-api`;
const agentPaymentApi = `${config.REACT_APP_PROXY_BASE_URL}/agent-payment-api`;

const dataProvider = simpleRestProvider(agentPaymentApi, agentPaymentsHttpClient);

type IPayload = {
  callbackUrl?: string;
  trid?: string;
  calculated_service_charge?: number;
  payItemId?: string;
  payMethod?: number;
  submitData?: { type: string; value: string }[];
  payAuthToken?: string;
  cdata?: string;
  lat?: number;
  lng?: number;
  amount?: number;
};

const agentPaymentsDataProvider = {
  ...dataProvider,

  getService: () => {
    const url = `${serviceListingApi}/service`;
    return agentPaymentsHttpClient(url).then(({ headers, json }) => [...json]);
  },

  getBillService: ({ serviceId }: { serviceId: string | number }) => {
    const url = `${serviceListingApi}/service/${serviceId}`;
    return agentPaymentsHttpClient(url).then(({ headers, json }) => json);
  },

  searchBills: (params: { payload: { [key: string]: string }; serviceId: string }) => {
    const { payload, serviceId } = params;
    const url = `${agentPaymentApi}/bill/search?serviceId=${serviceId}&${formatObjectToQuery(payload)}`;
    return agentPaymentsHttpClient(url).then(({ headers, json }) => json);
  },

  billSingleStepConfirm: ({ params }: { params?: IPayload }) => {
    // console.log("PARAMS: ", params)
    const url = `${agentPaymentApi}/bill/singlestep/confirm`;
    return agentPaymentsHttpClient(url, {
      method: "POST",
      body: JSON.stringify(params)
    }).then(({ headers, json }) => json);
  },

  billVerify: (params: { trid?: string; ptn?: string }) => {
    const url = `${agentPaymentApi}/verify?${formatObjectToQuery(params)}`;
    return agentPaymentsHttpClient(url).then(({ headers, json }) => json);
  }
};

export default agentPaymentsDataProvider;
