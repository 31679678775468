import Tabs from "components/material/Tabs";
import React from "react";
import { useTranslate } from "react-admin";
import RemittanceTransactionDetails from "./tabs/RemittanceTransactionDetailsTab";
import RemittanceTransactionErrorLog from "./tabs/RemittanceTransactionErrorLogTab";

const RemittanceTransactionShowLayout = (props: any) => {
  const translate = useTranslate();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event: Event, newValue: number) => {
    setActiveTabIndex(newValue);
  };
  return (
    <Tabs
      tabLabels={[`${translate("cc.tab.payment_details")}`, `${translate("cc.tab.error_log")}`]}
      onChange={handleChange}
      activeTabIndex={activeTabIndex}
    >
      <RemittanceTransactionDetails {...props} setActiveTabIndex={setActiveTabIndex} />
      <RemittanceTransactionErrorLog {...props} />
    </Tabs>
  );
};

export default RemittanceTransactionShowLayout;
