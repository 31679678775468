import { colors } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { FC } from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { BulkCollectChipStatusTypes } from "../../types/bulkCollectionListTypes";

const styles = () => ({
  root: {
    display: "inline",
    padding: "5px 10px",
    borderRadius: 5
  },

  success: {
    backgroundColor: colors.green[500],
    color: "white"
  },
  partialSuccess: {
    border: `1px solid ${colors.green[500]}`,
    color: colors.green[500]
  },
  rejected: {
    border: `1px solid ${colors.red[500]}`,
    color: colors.red[500]
  },
  warning: {
    backgroundColor: colors.yellow[700]
  },

  neutral: {
    background: colors.brown[400],
    color: "white"
  },
  error: {
    backgroundColor: colors.red[500],
    color: "white"
  },

  hidden: {
    display: "none"
  }
});

const BulkCollectChipStatus: FC<BulkCollectChipStatusTypes> = (props) => {
  const { classes, source } = props;
  const record = useRecordContext(props);
  const data: string | number = record && record[source];

  const t = useTranslate();

  const renderClass = () => {
    switch (data) {
      case "SUCCESS":
        return classes.success;
      case "REJECTED":
        return classes.rejected;
      case "VALIDATIONERROR":
      case "FAILED":
        return classes.error;
      case "PROCESSING":
        return classes.warning;
      case "PARTIALSUCCESS":
        return classes.partialSuccess;
      case 1:
        return classes.neutral;
      default:
        return classes.neutral;
    }
  };

  const renderTitle = () => {
    if (data !== null) {
      switch (data) {
        case "READY":
          return `${t("status.ready")}`;
        case "APPROVED":
          return `${t("status.approved")}`;
        case "FAILED":
          return `${t("status.failed")}`;
        case 8:
          return `${t("status.importing_data")}`;
        case "PARTIALSUCCESS":
          return `${t("status.partial_success")}`;
        case "PROCESSING":
          return `${t("status.processing")}`;
        case "VALIDATIONERROR":
          return `${t("status.validation_error")}`;
        case "ENDED":
          return `${t("status.ended")}`;
        case "REJECTED":
          return `${t("status.rejected")}`;
        case "SUCCESS":
          return `${t("status.success")}`;
        default:
          return `${t("status.ready")}`;
      }
    } else {
      return `${t("status.open")}`;
    }
  };

  return <div className={classNames(classes.root, renderClass())}>{renderTitle()}</div>;
};

export default withStyles(styles)(BulkCollectChipStatus);
