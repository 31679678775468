const payoutRequestMockData = {
  "payout-request": [
    {
      id: 1,
      alias: "string",
      coreCompanyId: "CM10001-301",
      requestAccountId: "b3fc-2c969f66afa6",
      status: "new",
      amount: 0,
      currencyCode: "string",
      requestInfo: {
        accountName: "orange",
        countryCode: "CMR",
        accountNumber: "699558844",
        accountHolderName: "Julio panda",
        accountSwiftCode: "TRVVSB855444",
        address: "Douala Bonanjo",
        state: "CMR",
        city: "Douala",
        additionalInstructions: "Instructions for approval"
      },
      requestAttachments: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          url: "string",
          label: "string"
        }
      ],
      approvals: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          approverId: "string",
          approverName: "string",
          decision: "rejected",
          decidedAt: "2005-08-15T15:52:01+00:00",
          reason: "string"
        }
      ],
      paymentInitiationAccountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      paymentInitiationAttachments: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          url: "string",
          label: "string"
        }
      ],
      paymentInitiationNotes: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          note: "string",
          createdAt: "2005-08-15T15:52:01+00:00",
          creatorId: "string",
          creatorName: "string"
        }
      ],
      paymentConfirmationDate: "2005-08-15T15:52:01+00:00",
      paymentConfirmationAttachments: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          url: "string",
          label: "string"
        }
      ],
      paymentConfirmationNotes: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          note: "string",
          createdAt: "2005-08-15T15:52:01+00:00",
          creatorId: "string",
          creatorName: "string"
        }
      ],
      createdAt: "2005-08-15T15:52:01+00:00",
      creatorId: "string",
      creatorName: "string"
    }
  ]
};

export default payoutRequestMockData;
