import config from "config";
import { getStorageToken } from "../../../utils/storageUtils";

export const fetchDocumentImage = async (id, dataObjectId) => {
  const apiUrl = config.REACT_APP_PROXY_BASE_URL;
  const url = `${apiUrl}/entity/${id}/data/${dataObjectId}/download`;
  const request = new Request(url, {
    method: "GET",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorageToken()}`
    })
  });
  const response = await fetch(request);
  const data = await response.blob();
  return data;
};
