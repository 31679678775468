import config from "config";
import { GetOneParams, ResourceProps, fetchUtils } from "react-admin";
import { getStorageToken } from "utils/storageUtils";
import simpleRestProvider from "ra-data-simple-rest";
import { stringify } from "query-string";
import FormData from "form-data";
import { v4 as uuidv4 } from "uuid";
import fetchHttpClient from "utils/fetchHttpClient";

const proxyUrl: string = config.REACT_APP_PROXY_BASE_URL;
const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };

  return fetchUtils.fetchJson(url, options);
};

const serviceListingApi = `${proxyUrl}/service-listing-api`;
const remittanceApi = `${proxyUrl}/remittance-api`;
const remittanceReportApi = `${proxyUrl}/remittance-report-api`;

const dataProvider = simpleRestProvider(proxyUrl, httpClient);

const remittanceConfigDataProvider = {
  ...dataProvider,
  // Get history list
  getList: (resource: string, params: any) => {
    const { filter } = params;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      page: page,
      limit: perPage,
      filter: JSON.stringify(filter),
      sort: JSON.stringify([field, order])
    };

    const url = `${remittanceApi}/transaction?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range")?.split("/").pop() || "0", 10)
    }));
  },

  getOne: (resource: ResourceProps, params: GetOneParams) => {
    const { id } = params;
    return httpClient(`${remittanceApi}/transaction/${id}`).then(({ json }) => ({
      data: { ...json, id: json.id }
    }));
  },

  getConfig: async (params: { id: number }) => {
    const { id } = params;
    const url = `${remittanceApi}/config/${id}`;
    const token = getStorageToken() || "";
    /*return httpClient(url).then(({ json,data }) => ({
      data: json
    }));*/
    return await fetchHttpClient(
      url,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      },
      "json"
    );
  },
  getServiceCharge: (params: { id: string }) => {
    const { id } = params;
    return httpClient(`${serviceListingApi}/service/${id}/servicecharge`).then(({ json }) => {
      return {
        data: json
      };
    });
  },
  remit: (params: { data: any }) => {
    const { data } = params;
    const formValuesTransformed = {
      calculated_service_charge: data?.service_charge,
      partnerId: data?.selected_partner_id,
      send_amount: data?.sender_amount,
      sender: {
        id: {
          type: data?.sender_id_type,
          number: data?.sender_id_number,
          issuing_date: data?.sender_date_of_issue,
          issuing_country: data?.sender_country?.code === "CM" && "CMR",
          expiration_date: data?.sender_expiry_date
        },
        first_name: data?.sender_first_name,
        last_name: data?.sender_last_name,
        address: {
          addressline: "NA",
          city: data?.sender_city,
          state: data?.sender_state,
          country: data?.sender_country?.code,
          zip: data?.sender_country?.code
        },
        email: data?.sender_email,
        dob: data?.sender_date_of_birth,
        msisdn: data?.sender_phone_number?.trim().replace(/\s/g, ""),
        gender: data?.sender_gender
      },
      receiver: {
        first_name: data?.recipient_first_name,
        last_name: data?.recipient_last_name,
        address: {
          addressline: "Address",
          city: data?.recipient_city,
          state: data?.recipient_state?.trim(),
          country: data?.recipient_country?.code,
          zip: data?.recipient_country?.code
        },
        email: "user@example.com",
        dob: "2000-07-21",
        msisdn: data?.recipient_phone_number?.trim().replace(/\s/g, ""),
        gender: data?.sender_gender
      },
      ...(data?.delivery_method.toLowerCase() !== "cashpu" && {
        account: {
          account_number: data?.account_holder_number?.toString(),
          account_type: data?.delivery_method?.toLowerCase(),
          bank_code: "000000000",
          bank_name: data?.delivery_channel_name || "NA",
          bank_sortcode: "NA",
          bank_address: "NA"
        }
      }),
      relationship: data?.sender_relationship_to_recipient,
      reason: data?.sender_reason,
      origin: data?.sender_source_of_funds
    };
    return httpClient(`${remittanceApi}/remit`, {
      method: "POST",
      body: JSON.stringify(formValuesTransformed)
    }).then(({ json }) => {
      return {
        data: json
      };
    });
  },

  singleStepPayment: (params: { confirmData: any; id: number }) => {
    const { id, confirmData } = params;
    return httpClient(`${remittanceApi}/payment/${id}/singlestep/confirm`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        nonce: uuidv4(),
        "x-correlation-id": uuidv4()
      }),
      body: JSON.stringify(confirmData)
    }).then(({ json }) => {
      return {
        data: json
      };
    });
  },

  getRemittanceTransaction: (params: { id: number }) => {
    const { id } = params;
    return httpClient(`${remittanceApi}/transaction/${id}`).then(({ json }) => {
      return {
        data: json
      };
    });
  },

  uploadRemittanceDocuments: (params: { id: number; documentObj: any; file: any }) => {
    const { id, documentObj, file } = params;

    const url = `${remittanceApi}/remit/${id}/document`;
    const formData = new FormData();

    formData.append("file", file?.rawFile);
    formData.append("document[number]", documentObj?.number);
    formData.append("document[issuing_country]", documentObj?.issuing_country);
    formData.append("document[type]", documentObj?.type);
    formData.append("document[issuing_date]", documentObj?.issuing_date);
    formData.append("document[expiration_date]", documentObj?.expiration_date);

    return httpClient(url, {
      method: "POST",
      // eslint-disable-next-line
      // @ts-ignore
      body: formData
    }).then(({ json }) => ({ data: json }));
  },
  // uploadAttachment: ({
  //   data: { files },
  //   id,
  //   documentData
  // }: {
  //   data: { files: File[] };
  //   id: string;
  //   documentData: any;
  // }) => {
  //   const url = `${proxyUrl}/remittance/remit/${id}/document`;
  //   const formData = new FormData();
  //   Array.from(files).forEach((file) => {
  //     formData.append("file", file);
  //     formData.append("checksum", file.name);
  //     formData.append("document", documentData);
  //     return formData;
  //   });

  //   return httpClient(url, {
  //     headers: new Headers({
  //       Accept: "*/*"
  //     }),
  //     method: "POST",
  //     body: formData
  //   }).then(({ json }) => ({ data: json }));
  // }
  getRspReports: () => {
    return httpClient(`${remittanceApi}/rsp`).then(({ json }) => json);
  },

  downloadInternalReports: async (params: { query: string; data: any }) => {
    const { query, data } = params;
    const url = `${remittanceReportApi}/management/internal?filter=${query}`;
    const token = getStorageToken() || "";

    return await fetchHttpClient(
      url,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify({
          companies: data?.companies,
          format: data?.format || "CSV"
        })
      },
      "blob"
    );
  },

  downloadRspReports: async (params: { query: string; data: any }) => {
    const { query, data } = params;
    const url = `${remittanceReportApi}/management/rsp?filter=${query}`;
    const token = getStorageToken() || "";

    return await fetchHttpClient(
      url,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify({
          rspIds: data?.rspIds,
          years: data?.years,
          months: data?.months,
          format: data?.format || "CSV"
        })
      },
      "blob"
    );
  },

  downloadCollectorCompanyReports: async (params: { query: string; data: { format: "EXCEL" | "CSV" } }) => {
    const { query, data } = params;
    const url = `${remittanceReportApi}/management/collector-company?filter=${query}`;
    const token = getStorageToken() || "";

    return await fetchHttpClient(
      url,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify({
          format: data?.format || "CSV"
        })
      },
      "blob"
    );
  },

  downloadTransactionReports: async (params: { query: string; data: { format: "EXCEL" | "CSV" } }) => {
    const { query, data } = params;
    const url = `${remittanceReportApi}/management/history?filter=${query}`;
    const token = getStorageToken() || "";

    return await fetchHttpClient(
      url,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify({
          format: data?.format || "CSV"
        })
      },
      "blob"
    );
  },

  downloadTransactionReceipt: async (params: { id: string }) => {
    const url = `${remittanceApi}/transaction/${params.id}/receipt`;
    const token = getStorageToken() || "";

    return await fetchHttpClient(
      url,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      },
      "blob"
    );
  }
};

export default remittanceConfigDataProvider;
