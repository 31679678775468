// in src/i18n/fr.ts
import frenchMessages from "ra-language-french";

//Business Keywords - Special type of keywords usually specified by business team
const special = {
  prl: "Demande de paiement |||| Demandes de paiement",
  product: "Produit |||| Produits",
  kyc: "KYC",
  history_and_report: "Historique et rapports |||| Historique et rapports",
  payment_transaction: "Opération de paiement |||| Opérations de paiement",
  payment_request_history: "Rapport de paiement |||| Rapports de paiement",
  merchant_payment: "Paiement du marchand |||| Paiement des marchands",
  entity: "Entité |||| Entités",
  merchant: "Marchand",
  agent: "Agent KYC |||| Agents KYC",
  company: "Entreprise KYC |||| Entreprises KYC",
  instruction: "Instruction |||| Instructions",
  tip: "Conseil |||| Conseils",
  collection: "Collection |||| Collections",
  bulk_collection: "Collecte en masse |||| Collectes en masse",
  disbursement: "Décaissement |||| Décaissement",
  bulk_payment: "Paiement en masse |||| Paiement en masse",
  company_prl: "Paiement Marchand",
  send: "Envoyer",
  remittance: "Transfert d'argent",
  history: "Historique",
  agent_payments: "Paiements Des Agents",
  agent_payments_home: "Accueil",
  agent_payments_history: "Historique",
  reports: "Rapports"
};

// Common keywords used in application
// All values inside common object must match it's key
const common = {
  next: "Suivant",
  add: "Ajouter",
  additional_information: "Autres Informations",
  amount: "Montant",
  another: "Un autre",
  select: "Sélectionnez",
  my_profile: "Mon profil",
  copy: "Copier",
  save: "Enregistrer",
  cancel: "Annuler",
  pdf_external: "PDF Externe",
  pdf_internal: "PDF Interne",
  close: "Fermer",
  confirm: "Confirmer",
  export_pdf: "Exporter PDF",
  export_excel: "Exporter Excel",
  money_information: "Informations sur l'argent",
  delivery_channel: "Moyen de retrait",
  delivery_information: "Informations de Retrait",
  age_must_be_18_or_above: "L'âge doit être supérieur ou égal à 18 ans",
  country: "Pays",
  region: "Région",
  more: "Plus",
  city: "Ville",
  street: "Rue",
  address_type: "Type d'adresse",
  address: "Adresse",
  state: "Région",
  building_name: "Nom de l'immeuble",
  postal_code: "Code postal",
  valid: "Valide",
  approved: "Approuvé",
  draft: "Brouillon",
  total_processed: "Total traité",
  article_number: "Numéro d'article",
  send: "Envoyer",
  receive: "Recevoir",
  documents: "Documents",
  reason: "Raison",
  attachment: "Pièce jointe",
  attachments: "Pièces jointes",
  recipients_bank_details: "Coordonnées bancaires du bénéficiaire",
  recipients_mobile_money_details: "Détails  Mobile Money du bénéficiaire",
  errors: "Erreurs",
  processed: "Traité",
  pre_validated: "Pré-validé",
  imported: "Importé",
  invalid: "Invalide",
  await_status_update: "En attente de mises à jour du statut",
  back: "Retour",
  comment: "Commentaire",
  company_address: "Adresse de l'entreprise",
  company_name: "Nom de l'entreprise",
  completed: "Complété",
  copied: "Copié",
  create: "Créer",
  created: "Créé",
  created_at: "Créé à",
  customer_comment: "Commentaire du client |||| Commentaires des clients",
  customer_information: "Informations sur les clients",
  date: "Date",
  debited: "Débité",
  description: "Description",
  details: "Détails",
  account_holder_number: "Numéro du titulaire du compte",
  recipient_information: "Informations du bénéficiaire",
  email: "E-mail",
  empty: "Vide",
  ended: "Terminé",
  error: "Erreur",
  errored: "Erroné",
  errored_refunded: "Erroné Remboursé",
  failed: "Échoué",
  failure: "Échec",
  fee: "Frais",
  fees: "Frais",
  first_name: "Prénom",
  fulfilled: "Réalisé",
  history: "Historique",
  importing_data: "Importation des données",
  in_process: "En cours",
  reversed: "Inversé",
  internal_comment: "Commentaire interne  |||| Commentaires internes",
  last_name: "Nom de famille",
  name: "Nom",
  updated: "Mise à jour",
  new: "Nouveau",
  not_paid: "Non payé",
  open: "Ouvrir",
  total_available_balance: "Solde total disponible",
  order_reference: "Référence de la commande",
  order_status: "Statut de la commande",
  order_total_amount: "Montant total de la commande",
  transaction_details: "Détails de la transaction",
  payment_details: "Détails du paiement",
  paid: "Payé",
  paid_at: "Payé à",
  partial_success: "Succès partiel",
  payment_link: "Lien de paiement",
  payment_method: "Méthode de paiement",
  payment_provider: "Prestataire de paiement",
  provider: "Prestataire",
  payment_status: "Statut du paiement",
  pending: "En attente",
  phone: "Téléphone",
  phone_number: "Numéro de téléphone",
  mobile_money: "Mobile Money",
  deleted: "Supprimé",
  price: "Prix",
  cash_pickup: "Retrait en espèce",
  bank_transfer: "Virement bancaire",
  processing: "Traitement",
  product_information: "Informations sur le produit",
  product_name: "Nom du produit",
  profile: "Profil",
  ptn: "PTN",
  mtnc: "MTNC",
  time: "Temps",
  user: "Utilisateur",
  quantity: "Quantité",
  ready: "Prêt",
  title: "Titre",
  download: "Télécharger",
  print: "Imprimer",
  download_template: "Télécharger le template",
  reference: "Référence",
  reference_number: "Numéro de référence",
  rejected: "Rejeté",
  remove: "Retirer",
  status: "Statut",
  started_at_from: "Démarré à partir de",
  ended_at_from: "Terminé Au De",
  ended_at_to: "Terminé à, vers",
  started_at_to: "Démarré à, vers",
  success: "Succès",
  service_id: "ID du service",
  display_name: "Nom d'affichage",
  customer_name: "Nom du client",
  customer_reference: "Référence client",
  trid: "TrID",
  service_number: "Numéro de service",
  exchange_rate: "Taux de change",
  audit: "Audit",
  comments: "Commentaires",
  general_information: "Informations générales",
  entity_id: "ID de l'entité",
  middle_name: "Second prénom",
  gender: "Sexe",
  date_of_birth: "Date de naissance",
  nationality: "Nationalité",
  business_sector: "Secteur d'activité",
  document: "Document",
  document_type: "Type de document",
  contact_information: "Informations de contact",
  other_address: "Autre adresse",
  business_address: "Adresse professionnelle",
  place_of_business: "Lieu d'activité",
  id_number: "Numéro d'identification",
  date_of_issue: "Date de délivrance",
  phone_alt: "Téléphone Alt",
  phone_main: "Téléphone principal",
  phone_second: "Deuxième téléphone",
  place_of_issue: "Lieu de délivrance",
  personal_information: "Informations personnelles",
  internal_comments: "Commentaires internes",
  customer_comments: "Commentaires des clients",
  last_modified: "Dernière modification",
  payment_history: "Historique des paiements",
  service_label: "Label de service",
  submitted_at: "Soumis à",
  message: "Message",
  identification_documents: "Documents d'identification",
  national_id: "Carte d'identité nationale",
  uploading: "Téléchargement de",
  merchant_logo: "Logo du commerçant",
  company_id: "Numéro d'entreprise",
  approve: "Approuver",
  agent_id: "ID de l'agent",
  loading: "Chargement",
  file: "Fichier",
  started: "Démarré",
  passport: "Passeport",
  reject: "Rejeter",
  create_another: "Créer un autre",
  bulk_payment_request: "Demande de paiement en masse",
  supported_extensions: "Extensions prises en charge",
  sign_in: "S'inscrire",
  started_at: "Commencé à",
  ended_at: "Terminé à",
  total: "Total",
  total_amount: "Montant Total",
  transaction_amount: "Montant de la transaction",
  transaction_status: "Statut de la transaction",
  under_investigation: "Sous enquête",
  uploaded: "Téléchargé sur",
  validation_error: "Erreur de validation",
  salary: "Salaire",
  pension: "La pension",
  investment: "L'investissement",
  school_fees: "Frais de scolarité",
  house_rent: "Paiement du loyer",
  insurance: "Assurance",
  allowance: "Indemnité",
  gift: "Cadeau",
  mother: "Mère",
  father: "Père",
  uncle: "Oncle",
  sibling: "Fratrie",
  aunt: "Tante",
  occupation: "Occupation",
  source_of_funds: "Origine des fonds",
  relation_to_recipient: "Lien avec le bénéficiaire",
  senders_information: "Informations de l'expéditeur",
  your_transaction_has_been_successfully_processed: "Votre transaction a été traitée avec succès",
  id_type: "Type d'identifiant",
  expiry_date: "Date d'expiration",
  logout: "Déconnexion",
  share: "Partager",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  company_prl: "Company PRL",
  invalid_image_dimensions: "Dimensions d'image non valides",
  canceled: "Annulé",
  expired: "Expiré",
  amount_and_delivery_method: "Montant et méthode de livraison",
  bank: "Banque",
  account_number: "Numéro de compte",
  sender_details: "Détails de l'expéditeur",
  review_details: "Vérifier les détails",
  payment_link_information: "Informations Sur Le Lien De Paiement",
  active: "Active",
  inactive: "Desactive",
  sender_amount: "Montant de l'Expéditeur",
  recipient_amount: "Montant du Bénéficiaire",
  enable: "Activer",
  disable: "Desactiver",
  enabled: "Activé",
  disabled: "Desactivé",
  merchant_specific_code: "Code spécifique au marchand",
  male: "Homme",
  female: "Femme",
  other: "Autre",
  invalid_format: "Format non valide",
  date_of_birth_invalid: "La date de naissance n'est pas valide : Ne peut être dans le futur",
  document_1: "Document 1",
  document_2: "Document 2",
  transaction_confirmation: "Confirmation de la transaction",
  please_confirm_the_payment_of: "Veuillez confirmer le paiement de",

  total_amount_to_pay: "Montant total à payer",
  download_printable_qr_code: "Télécharger le QRcode %{format}",
  context_switched: "Changement du contexte de l'utilisateur",
  company_prl_enabled: "Prl de la société activé",
  company_prl_disabled: "Prl de la société désactivé",
  global_context: "Context Global",
  sender_sends: "Vous envoyez",
  or: "ou",
  recipient_gets: "Le Bénéficiaire Reçoit",
  select_delivery_method: "Sélectionnez le type de transfert",
  delivery_method: "Type de transfert",
  select_bank: "Sélectionnez la banque",
  select_provider: "Sélectionnez le fournisseur",
  recipient_address: "Adresse du bénéficiaire",
  select_delivery_channel: "Sélectionner le moyen de reception",
  service_charge: "Frais de service",
  recipient_details: "Détails du bénéficiaire",
  amount_and_delivery_methods: "Montant et méthodes de livraison",
  list: "Liste",
  edit: "Editer",
  show: "Voir",
  home: "Accueil",
  how_to_pay: "Comment Payer",
  steps_to_follow: "Étapes à suivre",
  search: "Recherche",
  results: "Résultats",
  Please_provide_those_informations: "Veuillez fournir ces informations",
  optional: "Optionnel",
  payment_confirmation: "Confirmation du paiement",
  biller: "Factureur",
  service: "Service",
  due_date: "Date d'échéance",
  generation_date: "Date de génération",
  payment_reference: "Référence du paiement",
  biller_number: "Numéro d'émetteur de la facture",
  contract_number: "Numéro du contrat",
  service_amount: "Montant du service",
  proceed: "Procéder",
  authentification_proof: "Preuve d'Authentification",
  signature_type: "Signature Type",
  error_type: "Type de Signature",
  error_message: "Message d'Erreur",
  error_code: "Code d'Erreur",
  error_log: "Journal d'Erreur",
  log: "Journal",
  select_all: "Tout sélectionner",
  unselect_all: "Tout désélectionner",
  clear_selection: "Effacer la sélection",
  company: "Société",
  download_csv: "Télécharger CSV",
  download_excel: "Télécharger Excel",
  month: "Mois",
  year: "Année",
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",
  rsp_report: "RSP Report",
  management_reports: "Rapports de gestion",
  regulatory_reports: "Rapports de réglementation",
  start_date: "date de début",
  end_date: "date de fin",
  collector_company: "Company",
  transaction_report: "Rapport de transactions",
  reports: "Rapports",
  you_are_about_to_trigger_a_transaction: "Vous êtes sur le point de déclencher une transaction",
  please_confirm_collection_of: "Veuillez confirmer le paiement de",
  filter: "Filtrer"
};

// Common fields inside app
const fields = {
  ...common,
  //Special case of fields (e.g. name and value are totally different or name casing is pascalCase)
  ref: common.reference,
  createdAt: common.created_at,
  customerCompanyAddress: common.company_address,
  mpay_service_label: common.service_label,
  customerCompanyName: common.company_name,
  customerNameFirst: common.first_name,
  customerNameLast: common.last_name,
  customerPhone: common.phone_number,
  desciption: common.description,
  isFulfilled: common.fulfilled,
  mpay_serviceId: common.service_id,
  nameFirst: common.first_name,
  nameLast: common.last_name,
  orderReference: common.order_reference,
  orderStatus: common.order_status,
  orderTotalAmount: common.order_total_amount,
  paymentProvider: common.payment_provider,
  paymentStatus: common.payment_status,
  phonenumber: common.phone_number,
  transactionAmount: common.transaction_amount,
  transactionStatus: common.transaction_status,
  prlStatus: common.status,
  prlCreatedAt: common.created_at,
  orderPaidAt: common.paid_at,
  entityExternalId: common.agent_id,
  submittedAt: common.submitted_at,
  entityOrganisationExternalId: common.company_id,
  entityOrganisationExternalName: common.company_name,
  givenName: common.first_name,
  familyName: common.last_name,
  displayName: common.display_name,
  inprocess: common.in_process,
  underinvestigation: common.under_investigation,
  reversed: common.reversed,
  erroredrefunded: common.errored_refunded,
  bulk_payment_status: common.status,
  payment_link_information: common.payment_link_information,
  global_context: common.global_context,
  recipient_first_name: common.first_name,
  recipient_last_name: common.last_name,
  recipient_phone_number: common.phone_number,
  recipient_email: common.email,
  recipient_address: common.recipient_address,
  recipient_middle_name: common.middle_name,
  recipient_city: common.city,
  recipient_country: common.country,
  recipient_state: common.state,
  sender_first_name: common.first_name,
  sender_last_name: common.last_name,
  sender_phone_number: common.phone_number,
  sender_email: common.email,
  sender_date_of_birth: common.date_of_birth,
  sender_gender: common.gender,
  sender_date_of_issue: common.date_of_issue,
  sender_source_of_funds: common.source_of_funds,
  sender_reason: common.reason,
  sender_relationship_to_recipient: common.relation_to_recipient,
  sender_id_type: common.id_type,
  sender_id_number: common.id_number,
  sender_place_of_issue: common.place_of_issue,
  sender_expiry_date: common.expiry_date,
  sender_address: common.address,
  sender_middle_name: common.middle_name,
  sender_city: common.city,
  sender_country: common.country,
  sender_state: common.state
};

export const fr = {
  ...frenchMessages,

  resources: {
    remittance: {
      name: special.remittance,
      fields: {
        ...fields,
        please_ensure_all_information_above_is_correct_then_confirm_and_collect_the_total_amount_from_the_customer:
          "Veuillez vous assurer que toutes les informations ci-dessus sont correctes, puis CONFIRMER et COLLECTER le montant total auprès du client.",
        recipient_name_must_match_account_holders_name:
          "Le nom du destinataire doit correspondre au nom du titulaire du compte",
        recipient_name_must_match_valid_id:
          "Le nom du destinataire doit correspondre à un identifiant valide",
        unsupported_file_type_please_upload_a_pdf_jpg_or_png:
          "Type de fichier non pris en charge : Veuillez télécharger un fichier pdf, jpg ou png",
        send_sms_notification: "Envoyer une notification SMS",
        enter_senders_name: "Saisir le nom de l'expéditeur tel qu'il figure sur une pièce d'identité valide",
        senders_address: "Adresse de l'expéditeur",
        senders_id_info: "Informations d'identification de l'expéditeur",
        email_notification: "Notification par e-mail",
        your_transaction_is_not_yet_processed: "Votre transaction n'est pas encore traitée",
        please_take_a_moment:
          "Prenez un moment pour vérifier les informations ci-dessous et confirmer votre transaction",
        your_id_has_expired: "Votre identifiant a expiré : La transaction ne peut pas être traitée",
        issue_and_expired_cannot_be_identical:
          "La date de délivrance et la date d'expiration ne peuvent pas être identiques",
        expiry_date_cannot_be_less_than_issue_date:
          "La date d'expiration ne peut être inférieure à la date  de délivrance : saisissez une date valide.",
        issue_date_invalid:
          "La date de délivrance n'est pas valide : la date doit être antérieure à aujourd'hui.",
        drop_a_file: "glisser-déposer un fichier ici ou cliquer pour sélectionner un fichier",
        supported_remittance_files: "Extensions prises en charge : .pdf .jpeg .png",
        invalid_format_max_lenght: "Ce champ ne peut contenir plus de 35 caractères.",
        recipient_bank_details: "Coordonnées bancaires du destinataire",
        invalid_format_non_alphabetic: "Ce champs ne peut contenir des  caractères non alphabétique.",
        invalid_format_alphabetic_accent: "Ce champ ne peut pas contenir de caractères accentués",
        invalid_phone_number: "Numéro de téléphone non valide : saisissez un numéro de téléphone valide",
        invalid_account_number: "Numéro de compte invalide : Veuillez entrer un numéro de compte valide.",
        maviance_internal_report: "Rapport interne de Maviance",
        detailed_management_report: "Rapport de gestion détaillé",
        detailed_management_report_description:
          "Pour consulter un rapport, il suffit de choisir le rapport et le filtre à partir des liens rapides qui s'affichent.",
        download_report_instruction:
          "Pour consulter un rapport, il suffit de sélectionner les dates de début et de fin et de le télécharger.",
        transaction_report_description:
          "Pour consulter un rapport, il suffit de sélectionner les dates de début et de fin et de le télécharger.",
        error_while_fetching_config_data:
          "Une erreur s'est produite lors de la récupération des données de configuration. Veuillez réessayer ultérieurement ou contacter le service client de Smobilpay.",
        error_occured:
          "Une erreur s'est produite . Veuillez réessayer ultérieurement ou contacter le service client de Smobilpay."
      }
    },
    merchant: {
      name: special.merchant,
      fields: {
        ...fields
      }
    },
    entity: {
      name: special.kyc,
      fields: {
        ...fields,
        //Duplication is presented here as filters are not stored inside name object
        name: {
          givenName: common.first_name,
          familyName: common.last_name,
          displayName: common.display_name
        }
      }
    },
    product: {
      name: special.product,
      fields: {
        ...fields,
        // Unique name based on resource
        productExtId: common.article_number
      }
    },
    prl: {
      name: special.prl,
      fields: {
        ...fields,
        customer: {
          nameFirst: fields.nameFirst,
          nameLast: fields.nameLast,
          phonenumber: fields.phonenumber,
          customerCompanyName: fields.customerCompanyName,
          customerCompanyAddress: fields.customerCompanyAddress,
          email: fields.email
        }
      }
    },
    "company-prl": {
      fields: {
        ...fields,
        download_printable_qr_code: "Télécharger le QRcode %{format}"
      }
    },
    "payment-transaction": {
      name: special.payment_transaction,
      fields: {
        ...fields
      }
    },
    "payment-report": {
      name: special.payment_request_history,
      fields: {
        ...fields
      }
    },
    bulk_payment: {
      name: special.bulk_payment,
      fields: {
        ...fields
      }
    },
    agent_payments: {
      name: special.agent_payments,
      fields: {
        ...fields,
        transaction_secondary_text:
          "Veuillez vous assurer que les détails du paiement sont corrects avant de confirmer!",
        any_errors_after_this_step_cannot_be_corrected:
          "Toute erreur commise après cette étape ne peut être corrigée !",
        the_payment_was_successfully_processed: "Le paiement a été traité avec succès",
        this_payment_could_not_be_processed: "Ce paiement n'a pas pu être traité",
        the_payment_is_being_processed: "Le paiement est en cours de traitement",
        please_wait_for_the_confirmation: "Veuillez attendre la confirmation",
        due_date_has_passed_of: "La date d'échéance est dépassée de %{smart_count}",
        your_bill_has_expired: "Votre facture est arrivée à échéance",
        due_date: "Date d'échéance - %{smart_count}",
        your_bill_is_due_soon: "Votre facture arrive bientôt à échéance",
        please_pay_now_to_avoid_being_cut_off: "Veuillez payer maintenant pour éviter d'être déconnecté",
        payment_request_created: "Demande de paiement créée",
        service_image_alt_text: "Image du service"
      }
    }
  },

  // custom components
  cc: {
    button: {
      ...common,
      create_product: `${common.create} ${special.product}`,
      create_bulk_payment: `${common.create} ${special.bulk_payment}`,
      add_comment: `${common.add} ${common.comment}`
    },
    dropzone: {
      title: "Déposez les fichiers ou cliquez pour les sélectionner",
      supported_extensions: common.supported_extensions
    },
    card: {
      ...fields,
      attachment: `${common.attachment} |||| ${common.attachments}`,
      empty: common.empty,
      comment: `${common.comment} |||| ${common.comments}`,
      document: `${common.document} |||| ${common.documents}`,
      logo_merchant: common.merchant_logo
    },

    table: { ...fields },
    breadcrumbs: {
      ...common,
      ...special
    },
    tab: {
      ...fields
    },
    typography: {
      auth: {
        rights: "tous droits réservés",
        statement: "Smobilpay e-commerce est une marque déposée de la société maviance PLC.",
        powered: "Réalisé par",
        terms: "Confidentialité et conditions"
      },
      bulk_payment: {
        step_1: "Première étape : Configurer la demande de paiement",
        step_2: "Deuxième étape : télécharger le fichier de paiement",
        must_be_unique: "Doit être unique"
      },
      merchant: {
        dimension_requirement: "Les dimensions doivent être de 125x100"
      }
    },
    tooltip: {
      ...fields,
      unique_reference: "Saisissez un nom de texte unique pour la référence du paiement en masse"
    },
    dialog: {
      ...fields,
      approve_text: "Voulez-vous commencer à traiter le paiement ?",
      reject_text: "Veuillez indiquer la raison du rejet",
      payment_confirmation: "Confirmation du paiement",
      bill_confirm_text: "Veuillez confirmer le paiement de",
      bill_cancel_text: "Voulez-vous annuler cette transaction ?"
    },
    accordion: {
      ...fields
    },
    notification: {
      ...frenchMessages.ra.notification,
      ...common,
      copied: common.copied,
      uploaded: common.uploaded,
      approved: common.approved,
      rejected: common.rejected,
      // Combining pluralization key with another one works only if you add key which contains pluralization at the end
      uploaded_attachment: `${common.uploaded} ${common.attachment}`,
      updated_prl: `${common.updated} ${special.prl}`,
      updated_product: `${common.updated} ${special.product}`,
      updated_profile: `${common.updated} ${common.profile}`,
      updated_attachment: `${common.updated} ${common.attachment}`,
      created_prl: `${common.created} ${special.prl}`,
      created_bulk_payment: `${common.created} ${special.bulk_payment}`,
      created_product: `${common.created} ${special.product}`,
      deleted_attachment: `${common.deleted} ${common.attachment}`,
      approved_bulk_payment: `${common.approved} ${special.bulk_payment}`,
      rejected_bulk_payment: `${common.rejected} ${special.bulk_payment}`,
      rejected_dropzone:
        "Le fichier que vous essayez de télécharger a un format de fichier non supporté. Seuls les fichiers de type %{supportedExtensions} peuvent être téléchargés."
    },
    status: {
      success: common.success,
      approved: common.approved,
      completed: common.completed,
      paid: common.paid,
      failure: common.failure,
      uploading: common.uploading,
      errored: common.errored,
      rejected: common.rejected,
      pending: common.pending,
      not_paid: common.not_paid,
      open: common.open,
      debited: common.debited,
      in_process: common.in_process,
      under_investigation: common.under_investigation,
      errored_refunded: common.errored_refunded,
      created: common.created,
      ready: common.ready,
      error: common.error,
      partial_success: common.partial_success,
      processing: common.processing,
      validation_error: common.validation_error,
      ended: common.ended,
      await_status_update: common.await_status_update,
      importing_data: common.importing_data,
      new: common.new,
      failed: common.failed,
      draft: common.draft
    },

    alert: {
      tip: {
        title: special.tip,
        prl: {
          tip_1:
            "Vous pouvez ajouter un ou plusieurs produits à un PRL en cliquant simplement sur le bouton [+].",
          tip_2:
            "Vous pouvez créer un PRL avec un nouveau produit (non existant dans votre liste de produits) ou avec des produits existants dans votre liste de produits. ",
          tip_3:
            "En cliquant sur le champ (Nom), vous pouvez saisir un produit entièrement nouveau ou sélectionner un produit existant dans la liste déroulante.",
          tip_4:
            "Les informations relatives au client ne sont pas obligatoires, mais si vous décidez de les fournir, les champs (nom de famille - prénom - numéro de téléphone) sont obligatoires. "
        }
      },
      instruction: {
        title: special.instruction,
        bulk_payment: {
          title: "Instructions",
          subtitle: "Veuillez inclure les en-têtes suivants dans la première ligne de votre fichier",
          step_1:
            "Numéro de téléphone - Obligatoire. Le numéro de téléphone du destinataire au format international, sans le signe plus. Par exemple : 237695187645.",
          step_2:
            "Montant - Obligatoire. Le montant à envoyer à chaque destinataire. Formatez-le sous forme de nombre, pas de texte.",
          step_3: "Description - Obligatoire. La description du paiement. ",
          step_4: "Prénom - Facultatif. Le prénom du destinataire.",
          step_5:
            "Nom de famille - Facultatif. Le nom de famille du destinataire.Nom de famille - Facultatif. Le nom de famille du destinataire.",
          step_6: "Référence - Facultatif. La référence"
        },
        prl: {
          step_1: "Cliquez sur + (Ajouter un produit) dans la section Informations sur le produit.",
          step_2: "Remplir les informations sur (Nom - Description - Prix - Quantité) du produit",
          step_3: "Remplir des informations sur le client",
          step_4: "Remplissez (référence de la commande) si nécessaire (cette étape n'est pas obligatoire)",
          step_5:
            "Saisissez un nom (d'étiquette) et joignez une ou plusieurs images du produit si nécessaire (cette étape n'est pas obligatoire)",
          step_6: "Cliquez sur Enregistrer"
        }
      }
    },

    modal_success: {
      instruction: {
        title: "Note",
        description:
          "Copiez le lien généré dans le coin droit pour le partager avec vos amis et clients sur le web et les réseaux sociaux afin de les aider à acheter l'article"
      },
      download_qrcode: "Télécharger le code QR"
    },
    merchant_balance: {
      title: common.total_available_balance
    },

    menu: {
      ...special,
      my_profile: common.my_profile,
      logout: common.logout
    },
    dashboard: {
      ...special,
      title_1: "BIENVENUE SUR VOTRE PLATEFORME DE",
      subtitle: "PAIEMENT DIGITAL",
      title_2: "",
      access_denied:
        "Accès refusé ! La page ou la fonctionnalité à laquelle vous essayez d'accéder nécessite des autorisations que vous ne possédez pas."
    },

    expand_panel: {
      bulk_payment: {
        reason: common.reason,
        pre_validated: common.pre_validated,
        processing: common.processing,
        imported: common.imported,
        valid: common.valid,
        total_processed: common.total_processed,
        invalid: common.invalid,
        pending: common.pending,
        success: common.success,
        error: `${common.error} |||| ${common.errors}`,
        processed: common.processed
      }
    },

    share: {
      prl: {
        whatsapp: {
          message:
            "Cher client, \n\n Vous souhaitez payer %{amount} FCFA pour un achat effectué auprès de %{merchantName}. Veuillez cliquer sur le lien ci-dessous et procéder au paiement en toute sécurité. \nLien de paiement rapide : %{link}. \nMerci pour votre confiance, \n \n Maviance"
        },

        email: {
          body: "Cher client, \n\n Vous souhaitez payer %{amount} FCFA pour un achat effectué auprès de %{merchantName}. Veuillez cliquer sur le lien ci-dessous et procéder au paiement en toute sécurité. \n\n\t - Lien de paiement rapide : %{link} \n\t- Nom du commerçant : %{merchantName} \n\t- Adresse du commerçant : %{merchantAddress} . \nMerci pour votre confiance, \n \n Maviance",
          subject: "Salut! %{fullName} veuillez payer avec ce lien"
        }
      },

      product: {
        whatsapp: {
          message:
            "Cher client, \n \n Vous souhaitez effectuer un achat auprès de %{merchantName}. Veuillez cliquer sur le lien ci-dessous et procéder au paiement en toute sécurité. \nLien de paiement rapide : %{link}. \nMerci de votre confiance, \n \n Maviance"
        },
        email: {
          subject: "Salut! Veuillez payer avec ce lien",
          body: "Cher client, \n \n Vous souhaitez effectuer un achat auprès de %{merchantName}. Veuillez cliquer sur le lien ci-dessous et procéder au paiement en toute sécurité. \n\t - Lien de paiement rapide : %{link} \n\t- Nom du commerçant : %{merchantName} \n\t- Adresse du commerçant : %{merchantAddress} . \n\n Merci pour votre confiance, \n\n Maviance"
        }
      }
    }
  },
  //  validation
  validation: {
    ...frenchMessages.ra.validation,
    alpha: "Doit correspondre à des caractères alphabétiques"
  }
};
