import { Box, Typography, colors } from "@mui/material";
import { Bill, ServiceChargeConfig } from "api/types/agentPaymentsTypes";
import { SUCCESS, ERRORED, PENDING } from "constants/status";
import { useTranslate } from "react-admin";
import formatCurrency from "utils/formatCurrency";
import StackList from "components/material/custom/StackList/StackList";
import { IKeyValueText } from "components/material/KeyValueText/KeyValueText";
import calculateServiceCharge from "../utils/calculateServiceCharge";
import CardDetails from "components/material/CardDetails";

type Props = {
  billItem?: Bill;
  screen?: string;
  paymentConfirmationStatus?: any;
  serviceCharge?: ServiceChargeConfig;
};

const ServiceChargeDetails = ({ billItem, screen = "", paymentConfirmationStatus, serviceCharge }: Props) => {
  const t = useTranslate();

  const cardDetails: IKeyValueText[] = [
    {
      title: t(`resources.${["agent_payments"]}.fields.bill_number`),
      value: billItem?.billNumber || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.contract_number`),
      value: billItem?.payItemId || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      },
      valueStyles: {
        textAlign: "right",
        width: "60%",
        whiteSpace: "nowrap",
        overflow: "auto",
        textOverflow: "ellipsis"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.service_number`),
      value: billItem?.serviceNumber || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    }
  ];
  const paymentConfirmDetails: IKeyValueText[] = [
    {
      title: t(`resources.${["agent_payments"]}.fields.service_amount`),
      value: formatCurrency(billItem?.amount),
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.service_charge`),
      value: formatCurrency(calculateServiceCharge(serviceCharge, billItem?.amount || 0)),
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.total_amount`),
      value: formatCurrency(
        (billItem?.amount || 0) + calculateServiceCharge(serviceCharge, billItem?.amount || 0)
      ),
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    }
  ];
  return (
    <>
      <CardDetails
        label={
          paymentConfirmationStatus?.status !== SUCCESS
            ? t(`resources.${["agent_payments"]}.fields.not_paid`)
            : t(`resources.${["agent_payments"]}.fields.paid`)
        }
        amount={billItem?.amount || 0}
        name={billItem?.customerName}
        status={paymentConfirmationStatus?.status !== SUCCESS ? ERRORED : SUCCESS}
        chipStyle={{ color: "#fff" }}
        sx={{ width: 600, m: 0, p: 2, marginBottom: 3 }}
      >
        <StackList spacing={2} list={cardDetails} marginBottom={1} />
        {paymentConfirmationStatus?.ptn && paymentConfirmationStatus?.status !== PENDING && (
          <Box display="flex" justifyContent="space-between" sx={{ marginBottom: 0 }}>
            <Typography fontWeight={"bold"} color={colors.grey[600]}>
              {t(`resources.${["agent_payments"]}.fields.ptn`)}:
            </Typography>
            <Typography fontWeight={"bold"}>{paymentConfirmationStatus?.ptn}</Typography>
          </Box>
        )}
      </CardDetails>
      {screen === "confirm" && (
        <Box sx={{ width: "95%", marginBottom: 2, marginLeft: 2 }}>
          <StackList spacing={2} list={paymentConfirmDetails} />
        </Box>
      )}
    </>
  );
};

export default ServiceChargeDetails;
