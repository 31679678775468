import InfoIcon from "@mui/icons-material/Info";
import { Alert, AlertTitle, Backdrop, Box, CircularProgress, Grid, Typography, colors } from "@mui/material";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { Bill, Service } from "api/types/agentPaymentsTypes";
import Dialog from "components/material/Dialog";
import { IKeyValueText } from "components/material/KeyValueText/KeyValueText";
import StackList from "components/material/custom/StackList/StackList";
import {
  AGENT_PAYMENTS_GET_SERVICE,
  BILL_SINGLE_STEP_CONFIRM,
  BILL_VERIFY
} from "constants/dataProviderTypes";
import { PENDING } from "constants/status";
import { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import daysjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import formatCurrency from "utils/formatCurrency";
import BillServiceWrapper from "../components/BillServiceWrapper";
import ServiceChargeDetails from "../components/ServiceChargeDetails";
import calculateServiceCharge from "../utils/calculateServiceCharge";
import { daysDifference } from "../utils/daysDifference";
import { FIXED_CASH_PAYAUTHTOKEN } from "constants/paymentsTypes";
import { AGENT_PAYMENTS_CONFIRM_CALLBACK } from "constants/urls";

type Props = {
  handleMove: (num: number) => void;
  billItem?: Bill;
  handleExit: (num: number) => void;
};

const AgentPaymentsBillConfirm = ({ billItem, handleExit }: Props) => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [check, setCheck] = useState(false);
  const [severity, setSeverity] = useState<"warning" | "success" | "error">("warning");
  const [message, setMessage] = useState({
    title: "",
    desciption: ""
  });
  const [result, setResult] = useState(false);
  const notify = useNotify();
  const t = useTranslate();
  const { id } = useParams();
  const { getValues } = useFormContext();
  const formValues = getValues();

  const { data: service }: { data?: Service } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_GET_SERVICE],
    () => dataProvider.getBillService({ serviceId: id || "" }),
    {
      onSuccess: () => {
        setResult(false);
      },
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  const {
    mutate: mutateBillSingleStepConfirm,
    isLoading: isLoadingBillConfirm,
    data: paymentConfirmationStatus,
    isSuccess: isConfirmPaymentSuccess
  } = useMutation(
    ["agent-payments", BILL_SINGLE_STEP_CONFIRM],
    () =>
      dataProvider.billSingleStepConfirm({
        params: {
          callbackUrl: AGENT_PAYMENTS_CONFIRM_CALLBACK,
          payAuthToken: FIXED_CASH_PAYAUTHTOKEN,
          calculated_service_charge: calculateServiceCharge(service?.serviceCharge, billItem?.amount || 0),
          payItemId: billItem?.payItemId,
          payMethod: 502,
          amount: billItem?.amount,
          trid: billItem?.payItemId,
          lat: 85,
          lng: 180,
          submitData:
            formValues === undefined
              ? []
              : Object.values(formValues)
                  ?.filter((val) => val !== "")
                  .map((val, idx) => ({
                    type: Object.keys(formValues)[idx] || "",
                    value: val
                  }))
        }
      }),
    {
      onSuccess: (data) => {
        if (data?.error) {
          return queryErrorHandler({ error: data?.error, notify });
        }
        notify(t(`resources.${["agent_payments"]}.fields.payment_request_created`), { type: "success" });
      },
      onError: (error) => {
        queryErrorHandler({ error, notify });
      }
    }
  );

  const { isLoading: isLoadingBillVerify, isRefetching } = useQuery(
    ["agent-payments", BILL_VERIFY],
    () =>
      dataProvider.billVerify({ ptn: paymentConfirmationStatus?.ptn, trid: paymentConfirmationStatus?.trid }),
    {
      enabled: isConfirmPaymentSuccess,
      onSuccess: (data) => {
        setResult(true);
        if (data?.error) {
          setSeverity("error");
          setMessage({
            title: data?.error?.devMsg,
            desciption: data?.error?.devMsg
          });
          return queryErrorHandler({ error: data?.error, notify });
        }

        setSeverity("success");
        setMessage({
          title: t(`resources.${["agent_payments"]}.fields.success`),
          desciption: t(`resources.${["agent_payments"]}.fields.the_payment_was_successfully_processed`)
        });
      },
      onError: (error: Error) => {
        setResult(true);
        setSeverity("error");
        setMessage({
          title: error?.message,
          desciption: error?.message
        });
        return queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false,
      refetchInterval: (data, query) => {
        return data?.status === PENDING ? 10000 : false;
      }
    }
  );

  const onCancel = () => setOpenCancelDialog(true);

  const onProceed = () => {
    // setOpenConfirmDialog(!openConfirmDialog);
    setOpenConfirmDialog(true);
  };

  const onDialogConfirm = () => {
    mutateBillSingleStepConfirm();
    setCheck(true);
    setMessage({
      title: t(`resources.${["agent_payments"]}.fields.pending`),
      desciption:
        t(`resources.${["agent_payments"]}.fields.the_payment_is_being_processed`) +
        ", " +
        t(`resources.${["agent_payments"]}.fields.please_wait_for_the_confirmation`)
    });
    setOpenConfirmDialog(false);
  };

  const onDialogCancel = () => {
    setOpenCancelDialog(false);
    handleExit(1);
  };

  const days = daysDifference(new Date(billItem?.billDueDate || ""), new Date());
  daysjs.extend(relativeTime);

  const rightDetails: IKeyValueText[] = [
    {
      title: t(`resources.${["agent_payments"]}.fields.biller`),
      value: service?.title || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.service`),
      value: service?.title || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.description`),
      value: billItem?.payItemDescr || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      },
      valueStyles: {
        textAlign: "end",
        maxWidth: "75%"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.due_date`, {
        smart_count: days < 0 ? daysjs(new Date(billItem?.billDueDate || "")).fromNow(true) : ""
      }),
      value: daysjs(billItem?.billDueDate).format("DD-MM-YYYY") || "",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.generation_date`),
      value: daysjs(billItem?.billDate).format("DD-MM-YYYY"),
      marginBottom: 10,
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      }
    }
  ];

  const referenceDetails: IKeyValueText[] = [
    {
      title: t(`resources.${["agent_payments"]}.fields.payment_reference`),
      value: billItem?.payItemId || "n/a",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      },
      valueStyles: {
        textAlign: "right",
        width: "60%",
        whiteSpace: "nowrap",
        overflow: "auto",
        textOverflow: "ellipsis"
      },
      marginBottom: 1
    },
    {
      title: t(`resources.${["agent_payments"]}.fields.phone_number`),
      value: billItem?.customerNumber || "n/a",
      titleStyles: {
        fontSize: 16,
        fontWeight: "bold"
      },
      marginBottom: 1
    }
  ];

  return (
    <>
      {openConfirmDialog && (
        <Dialog
          open={true}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={onDialogConfirm}
          data-testid="blablabla"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: 600,
              textAlign: "center"
            }}
          >
            <Typography fontWeight="500" sx={{ fontSize: 30, marginBottom: 1 }}>
              {t(`cc.dialog.payment_confirmation`)}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 1 }}>
              <InfoIcon color="primary" />
              <Typography ml={1} sx={{ fontSize: 26, color: colors.grey[600] }}>
                {t(`cc.dialog.bill_confirm_text`)}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>
              {formatCurrency(
                (billItem?.amount || 0) +
                  calculateServiceCharge(service?.serviceCharge, billItem?.amount || 0)
              )}
            </Typography>
          </Box>
        </Dialog>
      )}
      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)} onConfirm={onDialogCancel}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 600,
            textAlign: "center"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 1 }}>
            <InfoIcon color="error" />
            <Typography ml={1} sx={{ fontSize: 26 }}>
              {t(`cc.dialog.bill_cancel_text`)}
            </Typography>
          </Box>
        </Box>
      </Dialog>
      <BillServiceWrapper
        service={service}
        onCancel={onCancel}
        onProceed={onProceed}
        onProceedText="Confirm"
        screen="confirm"
        confirmResult={result}
        paymentConfirmationStatus={paymentConfirmationStatus}
      >
        {check && isConfirmPaymentSuccess ? (
          <Box mb={5}>
            <Alert sx={{ color: "#fff" }} severity={severity} variant="filled">
              <AlertTitle>{message.title}</AlertTitle>
              {message.desciption}
            </Alert>
          </Box>
        ) : (
          <Box mb={5}>
            <Typography fontWeight={"bold"} fontSize={16}>
              {t(`resources.${["agent_payments"]}.fields.transaction_details`)}
            </Typography>
            <Typography fontSize={14} color={colors.grey[600]}>
              {t(`resources.${["agent_payments"]}.fields.transaction_secondary_text`)}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }} mb={10}>
          <Grid item xs={6}>
            <ServiceChargeDetails
              billItem={billItem}
              screen="confirm"
              serviceCharge={service?.serviceCharge}
              paymentConfirmationStatus={paymentConfirmationStatus}
            />
          </Grid>
          <Grid item xs={6}>
            <Box marginBottom={8} width={600}>
              <StackList spacing={2} list={rightDetails} marginBottom={6} />
              <StackList spacing={2} list={referenceDetails} />
            </Box>
          </Grid>
        </Box>
      </BillServiceWrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingBillVerify || isLoadingBillConfirm || isRefetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AgentPaymentsBillConfirm;
