import EditButton from "components/ra/Button/EditButton";
import { usePermissions, useRecordContext } from "react-admin";
import findPermission from "utils/findPermission";

const ProductEditButton = (props: { dataTestId: string }) => {
  const { dataTestId } = props;
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const prlProductEdit = findPermission(permissions, "prl_product_update_own");
  return <>{prlProductEdit && <EditButton record={record} data-testid={dataTestId} {...props} />}</>;
};

export default ProductEditButton;
