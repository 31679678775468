const payoutApprovalMockData = {
  "payout-approval": [
    {
      id: 1,
      accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      requestId: "3fa85f64-5717-4562-b3fc-2c963f66a33e",
      merchantId: 23,
      amount: 6000000,
      country: {
        id: 1,
        name: "Cameroon"
      },
      accountNumber: "34554663636",
      accountHolderName: "John Doe",
      companyName: "Test Company",
      company: {
        id: "SM",
        name: "Test Company"
      },
      accountType: "Bank",
      accountHolder: "John Doe",
      state: "SW",
      city: "Douala",
      address: "No 4, mayor's street",
      swiftCode: "BCECMCFX",
      additionalInstructions: "Some additional instructions",
      status: "PAID",

      serviceProvider: "MTN Momo",
      payoutAccountType: "Mobile Money",
      paymentMethodId: "Express  Union",
      statusReason: [
        {
          lang: "en",
          text: "You transaction was successful"
        },
        {
          lang: "fr",
          text: "Votre transaction a réussi"
        }
      ],
      attachments: [
        {
          documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
          documentUuid: "35564ffdre6466dre",
          documentLabel: "Account receipt",
          originalName: "receipt.pdf",
          checksum: "c6779ec2960296ed9a04f08d67f64422",
          mimeType: "application/pdf",
          createdAt: "2022-03-24T15:10:40.629Z",
          updatedAt: "2022-03-24T15:10:40.629Z"
        },
        {
          documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
          documentUuid: "35564ffdre6466dre",
          documentLabel: "Account receipt",
          originalName: "receipt.jpg",
          checksum: "c6779ec2960296ed9a04f08d67f64422",
          mimeType: "image/jpeg",
          createdAt: "2022-03-24T15:10:40.629Z",
          updatedAt: "2022-03-24T15:10:40.629Z"
        }
      ],
      history: [
        {
          id: "22122535533",
          status: "REJECTED",
          approver: "Jones Peter",
          reason: "Some reason",
          createdAt: "2022-02-04"
        },
        {
          id: "22122535533",
          status: "APPROVED",
          approver: "Esther Bright",
          reason: "Some reason",
          createdAt: "2022-02-04"
        }
      ],
      createdAt: "2022-02-04",
      updatedAt: "2022-02-04"
    },
    {
      id: 2,
      accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      requestId: "3fa85f64-5717-4562-b3fc-2c963f66af00",
      merchantId: 23,
      amount: 450000,
      country: {
        id: 1,
        name: "Cameroon"
      },
      accountNumber: "34554663636",
      companyName: "Cool Company",
      company: {
        id: "GM2",
        name: "Great Company"
      },
      accountType: "Mobile Money",
      accountHolderName: "John Doe",
      accountHolder: "John Doe",
      state: "SW",
      city: "Douala",
      address: "No 4, mayor's street",
      swiftCode: "BCECMCFX",
      additionalInstructions: "Some additional instructions",
      payoutRequestStatus: "REJECTED",
      serviceProvider: "Orange Money",
      payoutAccountType: "Mobile Money",
      status: "REJECTED",
      paymentMethodId: "MTN Money",
      statusReason: [
        {
          lang: "en",
          text: "Your transaction request was rejected because you don't have sufficient balance."
        },
        {
          lang: "fr",
          text: "Votre demande de transaction a été rejetée car vous n'avez pas un solde suffisant."
        }
      ],
      attachments: [
        {
          documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
          documentUuid: "35564ffdre6466dre",
          documentLabel: "Account receipt",
          originalName: "receipt.pdf",
          checksum: "c6779ec2960296ed9a04f08d67f64422",
          mimeType: "application/pdf",
          createdAt: "2022-03-24T15:10:40.629Z",
          updatedAt: "2022-03-24T15:10:40.629Z"
        }
      ],
      history: [
        {
          id: "22122535533",
          status: "APPROVED",
          approver: "John Doe",
          reason: "Some reason",
          createdAt: "2022-02-04"
        },
        {
          id: "22122535533",
          status: "REJECTED",
          approver: "Brad Beacon",
          reason: "Some reason",
          createdAt: "2022-02-04"
        }
      ],
      createdAt: "2022-05-04",
      updatedAt: "2022-02-04"
    },
    {
      id: 3,
      accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      requestId: "3fa85f64-5717-4562-b3fc-2c963f66af00",
      merchantId: 23,
      amount: 650000,
      accountType: "Mobile Money",
      serviceProvider: "MTN Momo",
      companyName: "New Company",
      company: {
        id: "AB2",
        name: "Able Company"
      },
      country: {
        id: 1,
        name: "Cameroon"
      },
      accountNumber: "34554663636",
      accountHolder: "John Doe",
      state: "SW",
      payoutAccountType: "Bank",
      city: "Douala",
      address: "No 4, mayor's street",
      swiftCode: "BCECMCFX",
      additionalInstructions: "Some additional instructions",
      status: "APPROVED",

      paymentMethodId: "BICEC BANK",
      statusReason: [
        {
          lang: "en",
          text: "Your request meets the requirements"
        },
        {
          lang: "fr",
          text: "Votre demande répond aux exigences"
        }
      ],
      attachments: [
        {
          documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
          documentUuid: "35564ffdre6466dre",
          documentLabel: "Account receipt",
          originalName: "receipt.pdf",
          checksum: "c6779ec2960296ed9a04f08d67f64422",
          mimeType: "application/pdf",
          createdAt: "2022-03-24T15:10:40.629Z",
          updatedAt: "2022-03-24T15:10:40.629Z"
        }
      ],
      history: [
        {
          id: "22122535533",
          status: "REJECTED",
          approver: "Thomas Hook",
          reason: "Some reason",
          createdAt: "2022-02-04"
        },
        {
          id: "22122535533",
          status: "APPROVED",
          approver: "D. Felix",
          reason: "Some reason",
          createdAt: "2022-02-04"
        }
      ],
      createdAt: "2022-03-28",
      updatedAt: "2022-04-28"
    },
    {
      id: 4,
      accountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      requestId: "3fa85f64-5717-4562-b3fc-2c963f66af00",
      merchantId: 23,
      amount: 680000,
      accountType: "Mobile Money",
      serviceProvider: "Orange Momo",
      companyName: "Orange Company",
      company: {
        id: "AB2",
        name: "Able Company"
      },
      country: {
        id: 1,
        name: "Cameroon"
      },
      accountNumber: "34554663636",
      accountHolder: "John Doe",
      state: "SW",
      payoutAccountType: "Bank",
      city: "Douala",
      address: "No 4, mayor's street",
      swiftCode: "BCECMCFX",
      additionalInstructions: "Some additional instructions",
      status: "NEW",

      paymentMethodId: "BICEC BANK",
      statusReason: [
        {
          lang: "en",
          text: "Your request meets the requirements"
        },
        {
          lang: "fr",
          text: "Votre demande répond aux exigences"
        }
      ],
      attachments: [
        {
          documentUrl: "/api/v1/document/{documentUuid}/{originalName}",
          documentUuid: "35564ffdre6466dre",
          documentLabel: "Account receipt",
          originalName: "receipt.pdf",
          checksum: "c6779ec2960296ed9a04f08d67f64422",
          mimeType: "application/pdf",
          createdAt: "2022-03-24T15:10:40.629Z",
          updatedAt: "2022-03-24T15:10:40.629Z"
        }
      ],
      history: [],
      createdAt: "2022-03-28",
      updatedAt: "2022-04-28"
    }
  ]
};

export default payoutApprovalMockData;
