import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import MenuShare from "components/material/Menu/MenuShare";
import {
  Datagrid,
  FilterButton,
  List,
  ResourceComponentInjectedProps,
  ResourceContextProvider,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate
} from "react-admin";
import findPermission from "utils/findPermission";
import ProductDeleteButton from "./components/ProductDeleteButton";
import ProductEditButton from "./components/ProductEditButton";
import ProductPrice from "./components/ProductPrice";
import LinkField from "./components/LinkField";
import { Toolbar, Typography } from "@mui/material";
import useGetMerchantBalance from "hooks/useGetMerchantBalance";
import { MERCHANT_READ_OWN } from "constants/permissionsNames";
import { useUtils } from "context/UtilsContext";
import ShowButton from "components/ra/Button/ShowButton";
import CreateButton from "components/ra/Button/CreateButton";

const ProductList = (props: ResourceComponentInjectedProps) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const { balance: merchantBalance } = useGetMerchantBalance();
  const { merchantCCId } = useUtils();
  const ListActions = ({ permissions }: ResourceComponentInjectedProps) => {
    const prlProductCreate = findPermission(permissions, "prl_product_create");

    return (
      <TopToolbar>
        <FilterButton data-testid="filter-button" />
        {prlProductCreate && (
          <CreateButton
            label={translate("cc.button.create_product", { smart_count: 1 })}
            data-testid="create-button"
          />
        )}
      </TopToolbar>
    );
  };

  const filterProductList = [
    <TextInput data-testid="filter-name" source="name" />,
    <TextInput data-testid="filter-price" source="price" />
  ];

  const merchantReadOwnPermission = findPermission(permissions, MERCHANT_READ_OWN);

  let filter: { "merchant.coreCompanyId"?: string } = {};
  if (merchantCCId) {
    filter = {
      "merchant.coreCompanyId": merchantCCId
    };
  } else {
    filter = {};
  }

  return (
    <>
      <Toolbar
        variant={"regular"}
        disableGutters={true}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Breadcrumbs service={"product"} page={"list"} />
        {merchantReadOwnPermission && (
          <Typography data-testid={"merchant-balance"} variant={"h6"}>
            {translate("cc.merchant_balance.title")}: <strong>{merchantBalance}</strong>
          </Typography>
        )}
      </Toolbar>

      <ResourceContextProvider value="product">
        <List
          {...props}
          actions={<ListActions permissions={permissions} />}
          filters={filterProductList}
          sort={{ field: "createdAt", order: "DESC" }}
          hasCreate
          filter={filter}
        >
          <Datagrid bulkActionButtons={false} data-testid="datagrid">
            <TextField source="name" />
            <ProductPrice source="price" />
            <LinkField source="payment_link" sortable={false} />
            <MenuShare source="share" sortable={false} />
            <ProductEditButton dataTestId="edit-button" />
            <ShowButton data-testid="show-button" />
            <ProductDeleteButton redirect="/product" dataTestId="delete-button" />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default ProductList;
