/**
 * Utils function used for calculating total price of all of the product
 * It is the summary of product 'total' values
 *
 * @param {Object[]} productsContent - Array of product items
 * @returns {String} - total price
 */
const calculateProductsTotalPrice = (products) => {
  let total = 0;

  if (products) {
    // eslint-disable-next-line
    total = products?.reduce((acc, curr) => acc + curr?.total, 0);
    total = Number(total);
  }

  return total;
};

export default calculateProductsTotalPrice;
