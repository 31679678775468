import BomDeleteButton from "components/other/context/DeleteButton";
import { usePermissions, useRecordContext } from "react-admin";
import findPermission from "utils/findPermission";
import { PrlDeleteButtonProps } from "../../types/prlListTypes";

const PrlDeleteButton = (props: PrlDeleteButtonProps) => {
  const record = useRecordContext(props);
  const { dataTestId } = props;
  const { permissions } = usePermissions();
  const prlDeleteAny = findPermission(permissions, "prl_delete_any");
  const prlDeleteOwn = findPermission(permissions, "prl_delete_own");
  const canDeletePrl = prlDeleteAny || prlDeleteOwn;
  return <>{canDeletePrl && <BomDeleteButton record={record} dataTestId={dataTestId} />}</>;
};

export default PrlDeleteButton;
