import config from "config";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils, GetListParams, GetOneParams, ResourceProps } from "react-admin";
import { getStorageMerchantCCId, getStorageToken } from "utils/storageUtils";
import { stringify } from "query-string";
import splitFilterDateRangeKeys from "./utils/splitFilterDateRangeKeys";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const proxyUrl = config.REACT_APP_PROXY_BASE_URL;

const dataProvider = simpleRestProvider(proxyUrl, httpClient);

const paymentRequestHistoryDataProvider = {
  ...dataProvider,
  getList: (resource: ResourceProps, params: GetListParams) => {
    const { filter } = params;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    // Split date range keys
    const refinedFilter = splitFilterDateRangeKeys(filter);

    const query = {
      page: page,
      limit: perPage,
      filter: JSON.stringify(refinedFilter),
      sort: JSON.stringify([field, order])
    };
    const ccid = getStorageMerchantCCId();
    const url = `${proxyUrl}/merchant/${ccid}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      //eslint-disable-next-line
      // @ts-ignore: Object is possibly 'null'
      total: parseInt(headers.get("content-range").split("/").pop(), 10)
    }));
  },
  getOne: (resource: ResourceProps, params: GetOneParams) => {
    const ccid = getStorageMerchantCCId();
    const { id } = params;
    return httpClient(`${proxyUrl}/merchant/${ccid}/payment-report/view/${id}`).then(({ json }) => ({
      data: { ...json, id: json.id }
    }));
  }
};

export default paymentRequestHistoryDataProvider;
