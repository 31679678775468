import { Box, colors, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import AccordionBase from "components/material/Accordion/AccordionBase";
import { useTranslate } from "react-admin";
import { pick } from "lodash";
import { makeStyles, ThemeOfStyles } from "@mui/styles";
import { AccordionBaseType } from "components/types/material";

const useStyles = makeStyles<ThemeOfStyles<string>>({
  accordionHeading: {
    fontSize: 16,
    fontWeight: 600
  },
  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
});

interface AccordionProductsProps extends AccordionBaseType {
  headerItems: {
    title: string;
    xs: number;
  }[];
  content: {
    description: string;
    price: string;
    title: string;
    quantity: number;
    total: string;
    reference: string;
  }[];
}

const CardProducts = ({ headerItems, content }: AccordionProductsProps) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {/* Accordion Header */}
      <Box sx={{ display: "flex", alignItems: "center", padding: "0px 16px" }}>
        <Grid container spacing={2}>
          {headerItems.map(({ xs, title }, index) => (
            <Grid item xs={xs} key={index}>
              <Typography className={classes.accordionHeading}>{`${t(`cc.accordion.${title}`)}`}</Typography>
            </Grid>
          ))}
        </Grid>
        {/* Replacement for arrow icon */}
        <Box sx={{ width: 44, height: 48 }}></Box>
      </Box>
      {/* Accordion */}
      <Box sx={{ paddingBottom: 16 }}>
        {content?.map((item, index) => (
          <AccordionBase
            data-testid="accordion"
            key={index}
            defaultExpanded={index === 0}
            index={index}
            summaryContent={
              <Grid container spacing={2}>
                {Object.values(pick(item, ["title", "price", "quantity", "total"])).map((value, index) => (
                  <Grid item xs={headerItems[index]?.xs} key={index}>
                    <Tooltip title={value} placement="left">
                      <Typography noWrap className={classes.accordionHeading}>
                        {value}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            }
            detailsContent={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.valueKey}>{t("cc.accordion.description")}</Typography>
                  <Typography className={classes.valueText}>{item?.description}</Typography>
                </Grid>
              </Grid>
            }
          ></AccordionBase>
        ))}
      </Box>
    </Box>
  );
};

export default CardProducts;
