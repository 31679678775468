const jsonParser = <ReturnType>(key: string | null): ReturnType | null => {
  if (typeof key === "string") {
    return JSON.parse(key);
  } else {
    return null;
  }
};

// Get authentication token from the local storage
export const getStorageToken = () => jsonParser<string>(localStorage.getItem("authToken"));
// Get app bar autocomplete field option from localstorage
export const getStorageEntityOrganisationExternalName = () =>
  jsonParser<string>(localStorage.getItem("entityOrganisationExternalName"));
// Get authentication refresh token from the local storage
export const getStorageRefreshToken = () => jsonParser<string>(localStorage.getItem("refreshAuthToken"));
// Get merchant core company id from the local storage
export const getStorageMerchantCCId = () => jsonParser<string>(localStorage.getItem("MerchantCoreCompanyId"));
// Get agent id from the local storage
export const getStorageAgentId = () => jsonParser<string>(localStorage.getItem("agentId"));
// Get entity company id from the local storage
export const getStorageEntityOrganisationExternalId = () =>
  jsonParser<string>(localStorage.getItem("EntityOrganisationExternalId"));
// Get permissions from the local storage
export const getStoragePermissions = () => jsonParser<string[]>(localStorage.getItem("permissions"));
// Get username from the local storage
export const getStorageUsername = () => jsonParser<string>(localStorage.getItem("username"));
export const getStorageAlert = () => jsonParser<{ [key: string]: boolean }>(localStorage.getItem("alert"));
// Add authentication token to the local storage
export const setStorageToken = (token: string) => localStorage.setItem("authToken", JSON.stringify(token));
// Add authentication refreshtoken to the local storage
export const setStorageRefreshToken = (token: string) =>
  localStorage.setItem("refreshAuthToken", JSON.stringify(token));
// Add app bar auto complete field label
export const setStorageEntityOrganisationExternalName = (label: string) =>
  localStorage.setItem("entityOrganisationExternalName", JSON.stringify(label));
// Add username to the local storage
export const setStorageUsername = (username: string) =>
  localStorage.setItem("username", JSON.stringify(username));
// Add merchant core company id to the local storage
export const setStorageMerchantCCId = (id: string) =>
  localStorage.setItem("MerchantCoreCompanyId", JSON.stringify(id));
// Add agent id to the local storage
export const setStorageAgentId = (id: string) => localStorage.setItem("agentId", JSON.stringify(id));
// Add entityId company id
export const setStorageEntityOrganisationExternalId = (id: string) =>
  localStorage.setItem("EntityOrganisationExternalId", JSON.stringify(id));

export const setStorageAlert = (obj: { [key: string]: boolean }) =>
  localStorage.setItem("alert", JSON.stringify(obj));
// Add permissions to the local storage
export const setStoragePermissions = (roles: string[]) =>
  localStorage.setItem("permissions", JSON.stringify(roles));

// Remove token from the local storage
export const removeStorageToken = () => localStorage.removeItem("authToken");
// Remove refresh token from the local storage
export const removeStorageRefreshToken = () => localStorage.removeItem("refreshAuthToken");
// Remove storage merchant core company id from the local storage
export const removeStorageMerchantCCId = () => localStorage.removeItem("MerchantCoreCompanyId");
// Remove storage agent id from the local storage
export const removeStorageAgentId = () => localStorage.removeItem("agentId");
// Remove username from the local storage
export const removeStorageUsername = () => localStorage.removeItem("username");
export const removeStorageAlert = () => localStorage.removeItem("alert");
// Remove entityId
export const removeStorageEntityOrganisationExternalId = () =>
  localStorage.removeItem("EntityOrganisationExternalId");
// Remove app bar auto complete field label default value
export const removeStorageEntityOrganisationExternalName = () =>
  localStorage.removeItem("entityOrganisationExternalName");
