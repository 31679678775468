export const filterAgentPaymentsServices = (services: any, selectedCategories: any, searchValue: string) => {
  if (selectedCategories.length > 0 && searchValue === "") {
    return services.filter((option: any) => selectedCategories.includes(option.category));
  } else if (selectedCategories.length > 0 && searchValue !== "") {
    return services.filter(
      (option: any) =>
        selectedCategories.includes(option.category) &&
        option.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  } else if (selectedCategories.length === 0 && searchValue !== "") {
    return services.filter((option: any) => option.title.toLowerCase().includes(searchValue.toLowerCase()));
  } else {
    return services;
  }
};
