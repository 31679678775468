// ====== Status is indicated by binary flags =======================
// 1	Created	bulk payment was created in system
// 2	Ready	a file for bulk payment has been uploaded and the process is generally ready to be started
// 4	Approved	customer has approved the start of the processing
// 8	ImportingData	the file is being imported
// 16	ReadyForPaymentIdRetrieval	the file has been imported and the payment item records have been created
// 32	PaymentItemIdRetrievalInProcess	the payment item id for the payment items is being determined
// 64	PaymentItemIdRetrievalDone	the payment item id for the payment items is being determined
// 128	ProcessingPayments	the payment items are being processed vs smobilpay
// 256	AwaitingStatusUpdates	the payment items were all sent to smobilpay. waiting for all payment statuses to be updated to a final state
// 512	Ended	(deprecated)the bulk payment has been completely processed
// 1024	Errored	the bulk payment ran into an error somewhere
// 2048	Rejected	customer has rejected the start of the processing
// 4096	Success	the bulk payment was processed and all the payment were successfull
// 8192	PartialSuccess	The bulk payment was processed and only some of the payments were successfull, the others failed
// 16384	Error	The bulk payment was processed and all the payments failed
// ========================================================

import {
  APPROVED,
  AWAITING_STATUS_UPDATES,
  CREATED,
  ENDED,
  ERROR,
  ERRORED,
  IMPORTING_DATA,
  OPEN,
  PARTIAL_SUCCESS,
  PAYMENT_ITEM_ID_RETRIEVAL_DONE,
  PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS,
  PROCESSING_PAYMENTS,
  READY,
  READY_FOR_PAYMENT_ID_RETRIEVAL,
  REJECTED,
  SUCCESS
} from "constants/status";

const getStatusByStatusCode = (statusCode: string | number) => {
  switch (statusCode) {
    case 1:
      return CREATED;
    case 2:
      return READY;
    case 4:
      return APPROVED;
    case 8:
      return IMPORTING_DATA;
    case 16:
      return READY_FOR_PAYMENT_ID_RETRIEVAL;
    case 32:
      return PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS;
    case 64:
      return PAYMENT_ITEM_ID_RETRIEVAL_DONE;
    case 128:
      return PROCESSING_PAYMENTS;
    case 256:
      return AWAITING_STATUS_UPDATES;
    case 512:
      return ENDED;
    case 1024:
      return ERRORED;
    case 2048:
      return REJECTED;
    case 4096:
      return SUCCESS;
    case 8192:
      return PARTIAL_SUCCESS;
    case 16384:
      return ERROR;

    default:
      return OPEN;
  }
};

export default getStatusByStatusCode;
