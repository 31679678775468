import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from "@mui/material";
import CardBase from "components/material/Card/CardBase";
import React from "react";
import { headCells } from "../components/utils/OderColumnsBulkCollectHistoryTable";
import { stableSort, getComparator } from "../components/utils/BulkCollectHistoryTableSort";
import BulkCollectTableHead from "../components/BulkCollectTableHead";

const BulkCollectionHistoryTab = ({ record }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ptn");
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - record?.payments?.length) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CardBase noPadding variant="classic-white">
      <TableContainer>
        <Table size="small">
          <BulkCollectTableHead
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? stableSort(record?.payments, getComparator(order, orderBy))?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : stableSort(record?.payments, getComparator(order, orderBy))
            )?.map((payment) => (
              <TableRow>
                <TableCell align="left">{payment?.ptn}</TableCell>
                <TableCell align="left">{payment?.service_number}</TableCell>
                <TableCell align="left">{payment?.status}</TableCell>
                <TableCell align="left">{payment?.amount}</TableCell>
                <TableCell align="left">{payment?.phone_number}</TableCell>
                <TableCell align="left">{payment?.customer_name}</TableCell>
                <TableCell align="left">{payment?.customer_reference}</TableCell>
                <TableCell align="left">{payment?.trid}</TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                count={record?.payments?.length ? record?.payments?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page"
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonText="Previous"
                nextIconButtonText="Next"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </CardBase>
  );
};

export default BulkCollectionHistoryTab;
