import EuMoneyLogo from "../assets/eu.svg";
import MtnMoneyLogo from "../assets/mtn.png";
import OrangeMoneyLogo from "../assets/orange.png";
import YoomeeMoneyLogo from "../assets/yoomee.svg";
import YupMoneyLogo from "../assets/yup.png";
import { EU, MTN, ORANGE, YOOMEE, YUP } from "../constants/paymentMethodeKeys";

export const providerIsMatching = (providerName: string, key: string) =>
  `${providerName}`.toLocaleLowerCase().search(`${key}`.toLocaleLowerCase()) >= 0;

export const renderLogo = (providerName: string) => {
  if (providerIsMatching(providerName, MTN)) {
    return MtnMoneyLogo;
  } else if (providerIsMatching(providerName, ORANGE)) {
    return OrangeMoneyLogo;
  } else if (providerIsMatching(providerName, YUP)) {
    return YupMoneyLogo;
  } else if (providerIsMatching(providerName, YOOMEE)) {
    return YoomeeMoneyLogo;
  } else if (providerIsMatching(providerName, EU)) {
    return EuMoneyLogo;
  }
  return "";
};
