import BomEditButton from "components/other/context/EditButton";
import { usePermissions, useRecordContext } from "react-admin";
import findPermission from "utils/findPermission";
import { PrlDeleteButtonProps } from "../../types/prlListTypes";
// import BomEditButton from "components/other/context/EditButton";

const PrlEditButton = ({ dataTestId, ...rest }: PrlDeleteButtonProps) => {
  const record = useRecordContext(rest);

  const { permissions } = usePermissions();

  const prlUpdateAny = findPermission(permissions, "prl_update_any");

  const prlUpdateOwn = findPermission(permissions, "prl_update_own");
  const canEditPrl = prlUpdateAny || prlUpdateOwn;

  return canEditPrl ? <BomEditButton record={record} dataTestId={dataTestId} /> : null;
};

export default PrlEditButton;
