import { PictureAsPdfOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import queryErrorHandler from "api/queryErrorHandler";
import { DOWNLOAD_KYC_PDF } from "constants/dataProviderTypes";
import { saveAs } from "file-saver";
import { useDataProvider, useNotify, useRecordContext, useTranslate } from "react-admin";
import { useMutation, useQueryClient } from "react-query";

interface PdfButtonProps {
  type: "internal" | "external";
  variant: "contained" | "outlined";
}

const PdfButton = ({ type, variant }: PdfButtonProps): JSX.Element | null => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const t = useTranslate();

  const { mutate: mutateDownloadPdf } = useMutation(
    ["entity", DOWNLOAD_KYC_PDF],
    () => {
      return dataProvider.downloadPdf("entity", {
        id: record?.entityId,
        data: { type }
      });
    },
    {
      onSuccess: async (res: { data: string }) => {
        saveAs(res.data, record?.name?.displayName);
        await queryClient.invalidateQueries(["entity"]);
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const render = () => {
    switch (type) {
      case "internal":
      case "external":
        return (
          <Button
            disableElevation
            data-testid={type === "internal" ? "pdf-internal-button" : "pdf-external-button"}
            variant={variant}
            endIcon={<PictureAsPdfOutlined />}
            onClick={() => mutateDownloadPdf()}
          >
            {type === "external" ? t("cc.button.pdf_external") : t("cc.button.pdf_internal")}
          </Button>
        );
      default:
        return null;
    }
  };

  return render();
};

export default PdfButton;
