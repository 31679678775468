import { Box, Paper } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Toolbar } from "react-admin";
import { useQuery } from "react-query";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AgentPaymentsServicesGrid from "./components/AgentPaymentsServicesGrid";
import { useEffect, useState } from "react";
import AgentPaymentsSearchInput from "./components/AgentPaymentsSearchInput";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import { AGENT_PAYMENTS_HOME_SERVICE } from "constants/serviceNames";

const AgentPaymentsHome = () => {
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  // const [showCategories, setShowCategories] = useState(false);

  // const categoryOptions = uniq(top100films.map((option) => option.category));

  const { data, isLoading } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_HOME_SERVICE],
    () => dataProvider.getService(),
    {
      refetchOnWindowFocus: false
    }
  );

  const [services, setServices] = useState<any>(data);

  useEffect(() => {
    setServices(data);
  }, [data]);

  return (
    <>
      <Toolbar
        variant={"regular"}
        disableGutters={true}
        sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "transparent" }}
      >
        <Breadcrumbs service={"agent_payments"} page={"agent_payments_home"} />
      </Toolbar>
      <Paper sx={{ height: "100%", marginBottom: 2, paddingTop: 2, backgroundColor: "#FCFCFC" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box sx={{ width: "65%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {/* <Stack direction="row" spacing={1} alignItems="center">
            <Typography color={"secondary"} sx={{ fontWeight: !showCategories ? "bold" : "inherit" }}>
              Services
            </Typography>
            <Switch checked={showCategories} onChange={() => setShowCategories(!showCategories)} />
            <Typography color={"secondary"} sx={{ fontWeight: showCategories ? "bold" : "inherit" }}>
              Categories
            </Typography>
          </Stack> */}
            <AgentPaymentsSearchInput
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              setServices={(services: any) => {
                setServices(services);
              }}
              data={data}
            />
          </Box>
        </Box>

        {
          // showCategories ? (
          //   <AgentPaymentsCategoriesGrid
          //     selectedCategories={selectedCategories}
          //     categoryOptions={categoryOptions}
          //     setSelectedCategories={setSelectedCategories}
          //     setShowCategories={setShowCategories}
          //   />
          // ) :
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: 2 }}>
            {<AgentPaymentsServicesGrid services={services} isLoading={isLoading} />}
          </Box>
        }
      </Paper>
    </>
  );
};

export default AgentPaymentsHome;
