import { Alert, AlertTitle, Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import dataProvider from "api/dataProvider/remittanceDataProvider";
import Dialog from "components/material/Dialog";
import RemittanceSendStepOne from "components/ra/custom/RemittanceSend/steps/RemittanceSendStepOne";
import RemittanceSendStepTwo from "components/ra/custom/RemittanceSend/steps/RemittanceSendStepTwo";
import {
  DOWNLOAD_TRANSACTION_RECEIPT,
  GET_CONFIG,
  REMIT,
  SINGLE_STEP_PAYMENT,
  UPLOAD_REMITTANCE_DOCUMENTS
} from "constants/dataProviderTypes";
import useGetTranslation from "hooks/utils/useGetTranslation";
import { isEmpty, pick } from "lodash";
import * as React from "react";
import { useLocaleState, useNotify, useRedirect, useTranslate } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import formatCurrency from "utils/formatCurrency";
import { v4 as uuid } from "uuid";
import RemittanceStepper from "./RemittanceStepper";
import RemittanceSendStepFive from "./steps/RemittanceSendStepFive";
import RemittanceSendStepFour from "./steps/RemittanceSendStepFour";
import RemittanceSendStepThree from "./steps/RemittanceSendStepThree";
import { saveAs } from "file-saver";
import queryErrorHandler from "api/queryErrorHandler";

//import AlertBox from "components/material/Alert/AlertBox";

const RemittanceStepperWrapper = ({ activeStep, setActiveStep }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locale] = useLocaleState();
  const values: any = useWatch();
  const redirect = useRedirect();
  const [error, setError] = React.useState("");
  const [onConfirmOpen, setOnConfirmOpen] = React.useState(false);
  const [serviceId, setServiceId] = React.useState("");
  const getTranslation = useGetTranslation();
  const t = useTranslate();
  const notify = useNotify();
  const transaction = pick(values, ["current_transaction_id"]);
  const RSPID = 1; // 13, 19 for integration

  const { trigger, reset, register, setValue } = useFormContext();

  const handleNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    } else {
      notify("Please fill all required fields", { type: "error" });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const getErrorMessage = (customerMsg: any): string | undefined => {
    const errorMessage = customerMsg?.find((msg: any) => msg.language === locale);
    return errorMessage ? errorMessage.content : undefined;
  };

  useQuery(["remittance", GET_CONFIG], () => dataProvider.getConfig({ id: RSPID }), {
    refetchOnWindowFocus: false,
    staleTime: 359999,
    onError: (error: any) => {
      setError(
        getErrorMessage(error?.body?.customerMsg) || getTranslation("error_while_fetching_config_data")
      );
    }
  });

  const steps = [
    {
      label: t("cc.tab.amount_and_delivery_method"),
      component: (
        <RemittanceSendStepOne handleNext={handleNext} serviceId={serviceId} setServiceId={setServiceId} />
      )
    },
    {
      label: t("cc.tab.recipient_details"),
      component: <RemittanceSendStepTwo />
    },
    {
      label: t("cc.tab.sender_details"),
      component: <RemittanceSendStepThree />
    },
    {
      label: t("cc.tab.review_details"),
      component: <RemittanceSendStepFour />
    },
    {
      label: t("cc.tab.status"),
      component: <RemittanceSendStepFive />
    }
  ];

  const formValues = pick(values, [
    "sender_id_type",
    "sender_id_number",
    "sender_date_of_issue",
    "sender_country",
    "sender_expiry_date",
    "sender_first_name",
    "sender_last_name",
    "sender_city",
    "sender_state",
    "sender_phone_number",
    "sender_email",
    "sender_gender",
    "sender_date_of_birth",
    "sender_relationship_to_recipient",
    "sender_source_of_funds",
    "sender_amount",
    "service_charge",
    "total_amount",
    "recipient_amount",
    "recipient_city",
    "recipient_country",
    "recipient_first_name",
    "recipient_last_name",
    "recipient_phone_number",
    "recipient_state",
    "account_holder_number",
    "delivery_channel_name",
    "delivery_method",
    "provider",
    "sender_reason",
    "selected_partner_id",
    "total_amount",
    "document_1",
    "document_2"
  ]);

  const confirmData = {
    sourceId: uuid()
    //calculated_service_charge: formValues?.service_charge
  };

  // const attachMentData = {

  // }

  const { mutate: mutateConfirmStepOne, isLoading: isLoadingSingleStep } = useMutation(
    ["remittance", SINGLE_STEP_PAYMENT],
    (id: number) => dataProvider.singleStepPayment({ confirmData: confirmData, id: id }),
    {
      onSuccess: async ({ data }) => {
        register("transaction_status");
        setValue("transaction_status", String(data?.status));
        await handleNext();
      },
      onError: (error: any) => {
        setError(getErrorMessage(error?.body?.customerMsg) || getTranslation("error_occured"));
      }
    }
  );

  const uploadData = {
    number: formValues?.sender_id_number,
    issuing_country: formValues?.sender_country?.code,
    type: formValues.sender_id_type,
    issuing_date: formValues?.sender_date_of_issue,
    expiration_date: formValues?.sender_expiry_date
  };

  const uploadFile = (data: any, file: any) => {
    mutateUploadDocuments({
      id: data?.transactionId,
      documentObj: uploadData,
      file: file
    });
  };

  const singleStepConfirm = (data: any) => {
    mutateConfirmStepOne(data?.transactionId);
  };

  const { mutate: mutateUploadDocuments } = useMutation(
    ["remittance", UPLOAD_REMITTANCE_DOCUMENTS],
    ({ id, documentObj, file }: { id: number; documentObj: any; file: any }) =>
      dataProvider.uploadRemittanceDocuments({ id, documentObj, file }),
    {
      onError: (error: any) => {
        setError(getErrorMessage(error?.body?.customerMsg) || getTranslation("error_occured"));
      }
    }
  );

  const { mutate: mutateInitiatePayment, isLoading: loading } = useMutation(
    ["remittance", REMIT],
    () => dataProvider.remit({ data: formValues }),
    {
      onSuccess: ({ data }) => {
        register("current_transaction_id");
        setValue("current_transaction_id", data?.transactionId);
        if (!isEmpty(data)) {
          if (!isEmpty(formValues?.document_1)) uploadFile(data, formValues?.document_1);
          if (!isEmpty(formValues?.document_2)) uploadFile(data, formValues?.document_2);
          singleStepConfirm(data);
        }
      },
      onError: (error: any) => {
        setError(getErrorMessage(error?.body?.customerMsg) || getTranslation("error_occured"));
      }
    }
  );

  const handleConfirm = () => {
    mutateInitiatePayment();
    setOnConfirmOpen(false);
  };

  const handleReset = () => {
    setServiceId("");
    reset();
    setActiveStep(0);
  };

  const { mutate: handleDownload } = useMutation<any>(
    ["remittance", DOWNLOAD_TRANSACTION_RECEIPT],
    () => dataProvider.downloadTransactionReceipt({ id: String(transaction?.current_transaction_id) || "" }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  return (
    <>
      {error && (
        <Box ml={1} mb={3}>
          <Alert severity={"error"} variant={"standard"} onClose={() => setError("")}>
            <AlertTitle sx={{ fontWeight: "bold" }}>{getTranslation("error")}</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}

      <RemittanceStepper
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
        handleReset={handleReset}
        handleDownload={handleDownload}
        handlePrint={handleDownload}
        handleComplete={() => setOnConfirmOpen(true)}
        handleHistory={() => redirect("/remittance")}
        serviceId={serviceId}
        steps={steps}
      />
      <Dialog
        open={onConfirmOpen}
        onClose={() => setOnConfirmOpen(false)}
        onConfirm={handleConfirm}
        isloading={loading}
        title={getTranslation("you_are_about_to_trigger_a_transaction")}
        maxWidth="sm"
        fullWidth={true}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1
          }}
        >
          {/* <Typography variant={"h5"}>{getTranslation("transaction_confirmation")}</Typography> */}
          <Typography sx={{ color: "gray" }}>{getTranslation("please_confirm_collection_of")}</Typography>
          <Typography variant={"h5"} sx={{ fontWeight: "bold", fontSize: 44 }}>
            {formatCurrency(formValues?.total_amount)}
          </Typography>
        </Box>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading || isLoadingSingleStep}
      >
        <CircularProgress color={"primary"} />
      </Backdrop>
    </>
  );
};

export default RemittanceStepperWrapper;
