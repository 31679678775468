import { createContext, useContext, useReducer } from "react";
import { getStorageMerchantCCId, getStorageEntityOrganisationExternalId } from "utils/storageUtils";

const storageMerchantCCId = getStorageMerchantCCId();
const storageEntityOrganisationExternalId = getStorageEntityOrganisationExternalId();

const UtilsContext = createContext<any>(null);
const UtilsDispatchContext = createContext<any>(null);

export function UtilsProvider({ children }: any) {
  const [data, dispatch] = useReducer(utilsReducer, initialState);

  return (
    <UtilsContext.Provider value={data}>
      <UtilsDispatchContext.Provider value={dispatch}>{children}</UtilsDispatchContext.Provider>
    </UtilsContext.Provider>
  );
}

export function useUtils() {
  return useContext(UtilsContext);
}

export function useUtilsDispatch() {
  return useContext(UtilsDispatchContext);
}

function utilsReducer(data: any, action: any) {
  switch (action.type) {
    case "added": {
      return { ...data, ...action.payload };
    }
    case "updated":
      return { ...data, merchantCCId: action.payload.merchantCCId };
    default: {
      throw Error("error");
    }
  }
}

const initialState = {
  merchantCCId: storageMerchantCCId,
  entityOrganisationExternalId: storageEntityOrganisationExternalId
};
