import config from "config";
import { stringify } from "query-string";
import {
  CreateParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyReferenceParams,
  UpdateManyParams,
  UpdateParams
} from "react-admin";
import { getStorageMerchantCCId, getStorageToken } from "utils/storageUtils";
import { saveAs } from "file-saver";

const apiUrl: string = config.REACT_APP_PROXY_BASE_URL;

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json"
    });
  }
  const token = getStorageToken() || "";
  const ccid = getStorageMerchantCCId() || "";
  // eslint-disable-next-line
  // @ts-ignore
  options.headers.set("Authorization", `Bearer ${token}`);
  // eslint-disable-next-line
  // @ts-ignore
  options.headers.set("x-mpay-ccid", ccid);

  return fetchUtils.fetchJson(url, options);
};

const bulkDataProvider = {
  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { filter } = params;
    const query = {
      page: page,
      limit: perPage,
      ...filter
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json?.items || [],
      total: json.max
    }));
  },

  getOne: (resource: string, params: { id: string }) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    })),

  getMany: (resource: string, params: { ids: string[] }) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      // eslint-disable-next-line
      // @ts-ignore: Object is possibly 'null'
      total: parseInt(headers.get("content-range").split("/").pop(), 10)
    }));
  },

  update: (resource: string, params: UpdateParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json })),
  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: string, params: CreateParams) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    })),

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE"
    }).then((res) => ({ data: res?.status })),
  //eslint-disable-next-line
  deleteMany: (resource: string, params: { ids: string; data: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  uploadAttachment: (resource: string, { data: { files }, id }: { data: { files: File[] }; id: string }) => {
    const url = `${apiUrl}/${resource}/${id}/file`;
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("file", file);
      formData.append("documentLabel", file.name);
      return formData;
    });
    return httpClient(url, {
      headers: new Headers({
        Accept: "*/*"
      }),
      method: "POST",
      body: formData
    }).then(({ json }) => ({ data: json }));
  },

  approveBulkPayment: (
    resource: string,
    {
      id,
      data: { userId, username }
    }: {
      id: string;
      data: {
        userId: string;
        username: string;
      };
    }
  ) => {
    return httpClient(`${apiUrl}/${resource}/${id}/approve`, {
      method: "POST",
      body: JSON.stringify({
        mpay_reviewed_by_name: username,
        mpay_reviewed_by_uuid: userId
      })
    }).then(({ json }) => ({ data: json }));
  },

  rejectBulkPayment: (
    resource: string,
    {
      id,
      data: { userId, username, reason }
    }: {
      id: string;
      data: {
        userId: string;
        username: string;
        reason: string;
      };
    }
  ) => {
    return httpClient(`${apiUrl}/${resource}/${id}/reject`, {
      method: "POST",
      body: JSON.stringify({
        mpay_reviewed_by_name: username,
        mpay_reviewed_by_uuid: userId,
        rejection_reason: reason
      })
    }).then(({ json }) => ({ data: json }));
  },

  downloadDocument: async (resource: string, params: { id: string; data: { filename: string } }) => {
    const {
      id,
      data: { filename }
    } = params;

    const url = `${apiUrl}/bulk_payment/${id}/file?download=true`;
    const token = getStorageToken() || "";
    try {
      const request = new Request(url, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      });
      const response = await fetch(request);
      const data = await response.blob();

      saveAs(data, filename);

      return { data: [] };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
};

export default bulkDataProvider;
