import { colors } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import { ERRORED, PENDING, SUCCESS } from "../../../../../constants/status";

const styles = () => ({
  root: {
    display: "inline",
    padding: "5px 10px",
    borderRadius: 5
  },

  success: {
    backgroundColor: colors.green[500],
    color: "white"
  },

  warning: {
    backgroundColor: colors.yellow[700]
  },
  error: {
    backgroundColor: colors.red[500],
    color: "white"
  }
});

const TransactionStatus = ({ status, ...props }) => {
  const { classes } = props;

  const renderClass = () => {
    switch (status) {
      case SUCCESS:
        return classes.success;
      case ERRORED:
        return classes.error;
      case PENDING:
        return classes.warning;
      default:
        return classes.warning;
    }
  };

  return <div className={classNames(classes.root, renderClass())}>{status}</div>;
};

TransactionStatus.propTypes = {
  status: PropTypes.string
};

export default withStyles(styles)(TransactionStatus);
