import React from "react";
import {
  colors,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { Button, useRecordContext, useTranslate } from "react-admin";
import formatDate from "utils/formatDate";
import ChipStatus from "components/other/context/StatusField";
import EditIcon from "@mui/icons-material/Edit";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: colors.cyan[600],
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
    padding: "14px 24px"
  },
  body: {
    fontSize: 14,
    padding: "14px 24px",
    color: colors.grey[800]
  }
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    overflow: "hidden",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },

  tablePaper: {
    borderRadius: 8
  },
  rowStyle: {}
});

const PayoutApprovalHistory = (props) => {
  const { content = [], ...rest } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext(props);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper {...rest} className={classes.tablePaper} variant="outlined">
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{translate("payout.decision")}</StyledTableCell>
                <StyledTableCell align="left">{translate("payout.date_time")}</StyledTableCell>
                <StyledTableCell align="left">{translate("payout.approver_name")}</StyledTableCell>
                <StyledTableCell align="left">{translate("kyc.reason")}</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.history?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => (
                <TableRow key={i}>
                  <StyledTableCell align="left">
                    {" "}
                    <ChipStatus record={data} source="status" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {`${formatDate(data?.createdAt)}`} <br />{" "}
                    {`${new Date(data?.createdAt).toLocaleTimeString()}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">{data?.approver}</StyledTableCell>
                  <StyledTableCell align="left">{data?.reason}</StyledTableCell>
                  <StyledTableCell align="left">
                    {record?.history.at(-1) === data && data.status !== "APPROVED" && (
                      <Button startIcon={<EditIcon />} label={translate("ra.action.edit")} />
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={content.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonText="Previous"
          nextIconButtonText="Next"
        />
      </Paper>
    </>
  );
};

export default PayoutApprovalHistory;
