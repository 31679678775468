import { Box, Button, ButtonGroup, Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Show, useTranslate, useRecordContext, useNotify } from "react-admin";
import RemittanceTransactionShowLayout from "./RemittanceTransactionShowLayout";
import { Download, PrintOutlined } from "@mui/icons-material";
import { DOWNLOAD_TRANSACTION_RECEIPT } from "constants/dataProviderTypes";
// import findPermission from "utils/findPermission";
import { useMutation } from "react-query";
import remittanceDataProvider from "api/dataProvider/remittanceDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { saveAs } from "file-saver";

const TransactionAction = ({ basePath, data }: any) => {
  //   const translate = useTranslate();
  return <Box sx={{ marginTop: 5, justifyContent: "flex-end", display: "flex" }}></Box>;
};

const RemittanceTransactionShow = ({ permissions }: any) => {
  const t = useTranslate();
  const record = useRecordContext();
  const notify = useNotify();
  const { mutate: downloadTransactionReceipt } = useMutation<any>(
    ["remittance", DOWNLOAD_TRANSACTION_RECEIPT],
    () => remittanceDataProvider.downloadTransactionReceipt({ id: String(record?.id) || "" }),
    {
      onSuccess: ({ data, filename }: { data?: any; filename?: string }) => {
        saveAs(data, filename);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  return (
    <>
      <Toolbar disableGutters={true} sx={{ width: "100%", marginTop: 3 }}>
        <Breadcrumbs service={"remittance"} page={"show"} />
        <ButtonGroup sx={{ marginLeft: "auto" }}>
          <Button
            startIcon={<PrintOutlined />}
            variant={"outlined"}
            onClick={() => downloadTransactionReceipt()}
          >
            {t("cc.button.print")}
          </Button>
          <Button startIcon={<Download />} variant={"outlined"} onClick={() => downloadTransactionReceipt()}>
            {t("cc.button.download")}
          </Button>
        </ButtonGroup>
      </Toolbar>
      <Show component={"div"} resource="remittance" actions={<TransactionAction permissions={permissions} />}>
        <RemittanceTransactionShowLayout />
      </Show>
    </>
  );
};

export default RemittanceTransactionShow;
