import { Toolbar, Typography } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import ChipStatus from "components/other/context/StatusField";
import CurrencyField from "components/other/context/CurrencyField";
import LangField from "components/other/context/LangField";
import DatepickerRange from "components/other/Datepicker/DatepickerRange";
import { MERCHANT_READ_OWN } from "constants/permissionsNames";
import { CANCELED, EXPIRED, PAID, statusPrlReportArray } from "constants/status";
import useGetMerchantBalance from "hooks/useGetMerchantBalance";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  ResourceContextProvider,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate
} from "react-admin";
import findPermission from "utils/findPermission";
import ExportButton from "components/ra/Button/ExportButton";
import ShowButton from "components/ra/Button/ShowButton";

const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton data-testid="filter-button" />
      <ExportButton />
    </TopToolbar>
  );
};

const PaymentRequestHistoryList = ({ ...rest }) => {
  const { permissions } = usePermissions();
  const { balance: merchantBalance } = useGetMerchantBalance();
  const t = useTranslate();

  const merchantReadOwnPermission = findPermission(permissions, MERCHANT_READ_OWN);
  const statusChoices = statusPrlReportArray.map((status: string) => ({
    id: status,
    name: t(`resources.payment-transaction.fields.${status}`.toLowerCase()).toUpperCase()
  }));

  const filters = [
    <TextInput data-testid="filter-first_name" source="customerNameFirst" />,
    <TextInput data-testid="filter-last_name" source="customerNameLast" />,
    <TextInput data-testid="filter-order_reference" source="orderReference" />,
    <DatepickerRange source="prlCreatedAt" dataTestId="filter-created_at" />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="prlStatus"
      choices={statusChoices}
      data-testid="filter-status"
    />
  ];

  return (
    <ResourceContextProvider value="payment-report">
      <>
        <Toolbar
          variant={"regular"}
          disableGutters={true}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Breadcrumbs service={"payment_request_history"} page={"list"} />

          {merchantReadOwnPermission && (
            <Typography data-testid={"merchant-balance"} variant={"h6"}>
              {t("cc.merchant_balance.title")}: <strong>{merchantBalance}</strong>
            </Typography>
          )}
        </Toolbar>

        <List actions={<ListActions />} filters={filters} filter={{ prlStatus: [PAID, CANCELED, EXPIRED] }}>
          <Datagrid bulkActionButtons={false} data-testid={"datagrid"}>
            <TextField source="customerNameFirst" />
            <TextField source="customerNameLast" />
            <TextField source="orderReference" />
            <DateField source="prlCreatedAt" locales="fr-FR" />
            <DateField source="orderPaidAt" locales="fr-FR" />
            <ChipStatus source="prlStatus" />
            <CurrencyField source={"orderTotalAmount"} />
            <LangField source={"paymentProvider"} />
            <ShowButton data-testid={"show-button"} />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default PaymentRequestHistoryList;
