import { Box, Tooltip, Typography, colors } from "@mui/material";
import StackList from "components/material/custom/StackList/StackList";
// import getCountryNameFromCode from "components/ra/Input/AutocompleteInput/utils/getCountryNameFromCode";
import RemittanceDetailsLayout from "components/ra/layout/RemittanceDetailsLayout";
import { BANK, MOBILE_MONEY } from "constants/remittanceDeliveryMethods";
import { SUCCESS } from "constants/status";
import dayjs from "dayjs";
import useGetTranslation from "hooks/utils/useGetTranslation";
import React from "react";
import { useRecordContext } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const RemittanceTransactionDetailsTab = ({ setActiveTabIndex }: any) => {
  const record = useRecordContext();
  const getTranslation = useGetTranslation();
  //   const [locale] = useLocaleState();

  const moneyInformationTransformed = [
    {
      title: getTranslation("sender_amount"),
      value: formatCurrency(record?.send_amount, record?.corridor?.base)
    },
    {
      title: getTranslation("service_charge"),
      value: formatCurrency(record?.service_charge, "XAF") || 0
    },
    {
      title: getTranslation("recipient_amount"),
      value: formatCurrency(record?.receive_amount, record?.corridor?.target)
    }
  ];

  const getAccountNumberInformation = () => {
    switch (record?.partner?.delivery_method) {
      case BANK:
        return [
          {
            title: String(getTranslation("account_number")),
            value: record?.account?.accountNumber
          },
          {
            title: String(getTranslation("phone_number")),
            value: record?.account?.accountNumber || "N/A"
          }
        ];
      case MOBILE_MONEY:
        return [
          {
            title: String(getTranslation("account_number")),
            value: record?.account?.accountNumber || "N/A"
          },
          {
            title: String(getTranslation("phone_number")),
            value: record?.account?.accountNumber || "N/A"
          }
        ];
      default:
        return [
          {
            title: String(getTranslation("account_number")),
            value: record?.account?.accountNumber || "N/A"
          },
          {
            title: String(getTranslation("phone_number")),
            value: record?.account?.accountNumber || "N/A"
          }
        ];
    }
  };

  const deliveryChannelTransformed = [
    {
      title: getTranslation("delivery_method"),
      value: record?.partner?.delivery_method
    },
    {
      title: getTranslation("delivery_channel"),
      value: record?.account?.account_type
    },
    getAccountNumberInformation()[0],
    getAccountNumberInformation()[1]
  ];

  const recipientDetailsContent = {
    recipient_first_name: record?.receiver?.first_name || "",
    recipient_last_name: record?.receiver?.last_name || "",
    recipient_middle_name: record?.receiver?.middle_name || "",
    recipient_phone_number: record?.receiver?.msisdn || "",
    recipient_city: record?.receiver?.address?.city || "",
    recipient_state: record?.receiver?.address?.state || ""
  };
  const combinedSenderDetails = {
    sender_first_name: record?.sender?.first_name || "",
    sender_last_name: record?.sender?.last_name || "",
    sender_middle_name: record?.sender?.middle_name || "",
    sender_phone_number: record?.sender?.msisdn,
    sender_date_of_birth: dayjs(record?.sender?.dob).format("DD/MM/YYYY") || "",
    sender_gender: record?.sender?.gender || "",
    sender_id_number: record?.sender?.senderId || "",
    sender_place_of_issue: dayjs(record?.sender?.documents?.issuing_country).format("DD/MM/YYYY") || "",
    sender_date_of_issue: dayjs(record?.sender?.documents?.issuing_date).format("DD/MM/YYYY") || "",
    sender_expiry_date: dayjs(record?.sender?.documents?.expiration_date).format("DD/MM/YYYY") || "",
    occupation: record?.sender?.address?.occupation || "",
    sender_city: record?.sender?.address?.city || "",
    sender_state: record?.sender?.address?.state || "",
    sender_id_type: record?.sender?.senderId || ""
  };

  const additionalInformationContent = {
    sender_source_of_funds: record?.origin || "",
    sender_reason: record?.reason || "",
    sender_relationship_to_recipient: record?.relationship || ""
  };
  const attachmentInfoTransformed = {
    document_1: record?.sender?.documents?.type || "",
    document_2: record?.sender?.documents?.type || ""
  };

  const transactionInformationContent = [
    {
      title: getTranslation("ptn"),
      value: record?.ptn || ""
    },
    {
      title: "adasd",
      // record?.rsp[0]?.delivery_method === CASH_PICKUP && record?.status === "SUCCESS"
      //   ? getTranslation("mtnc")
      //   : ""
      value: "dadsa"
      // record?.rsp[0]?.delivery_method === CASH_PICKUP && record?.status === "SUCCESS" ? record?.mtnc : ""
    }
  ];

  return (
    <div>
      <RemittanceDetailsLayout
        moneyInformationCardFields={{
          total_amount: record?.send_amount ?? 0,
          list: moneyInformationTransformed || 0,
          paymentStatus: record?.status,
          statusTextLabel: record?.status !== SUCCESS ? "Not Paid" : "Paid"
        }}
        deliveryChannelFields={{
          list: deliveryChannelTransformed,
          addtionalInfo: record?.status === SUCCESS && (
            <StackList
              list={transactionInformationContent}
              valueStyles={{ fontWeight: "bold", color: colors.cyan[600] }}
              spacing={1}
              sx={{
                padding: "0 24px 0 24px"
              }}
            />
          )
        }}
        recipientDetailFields={{
          content: recipientDetailsContent,
          children: (
            <Tooltip title={"recipient_country" ?? ""} placement="left">
              <Box component="div">
                <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
                  {getTranslation("recipient_country")} :
                </Typography>
                <Typography>{record?.receiver?.address?.state}</Typography>
              </Box>
            </Tooltip>
          )
        }}
        senderDetailFields={{
          content: combinedSenderDetails,
          children: (
            <Tooltip title={"sender_country" ?? ""} placement="left">
              <Box component="div">
                <Typography style={{ color: colors.grey[700] }} variant={"body1"} noWrap>
                  {getTranslation("sender_country")} :
                </Typography>
                <Typography>{record?.sender?.address?.country}</Typography>
              </Box>
            </Tooltip>
          )
        }}
        additionalInformationfields={{
          content: additionalInformationContent
        }}
        attachmentFields={{
          content: attachmentInfoTransformed
        }}
      />
    </div>
  );
};

export default RemittanceTransactionDetailsTab;
