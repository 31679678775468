import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import {
  Datagrid,
  FilterButton,
  List,
  ResourceContextProvider,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  usePermissions,
  useTranslate
} from "react-admin";
import { Toolbar, Typography } from "@mui/material";
import findPermission from "utils/findPermission";
import { MERCHANT_READ_OWN } from "constants/permissionsNames";
import useGetMerchantBalance from "hooks/useGetMerchantBalance";
import ChipStatus from "components/other/context/StatusField";
import LangField from "components/other/context/LangField";
import CurrencyField from "components/other/context/CurrencyField";
import { statusTransactionArray } from "constants/status";
import { getStorageMerchantCCId } from "utils/storageUtils";
import { useMutation } from "react-query";
import { EXPORT_FILE } from "constants/dataProviderTypes";
import ShowButton from "components/ra/Button/ShowButton";
import Button from "components/material/Button/Button";

const ListActions = () => {
  const dataProvider = useDataProvider();
  const ccid = getStorageMerchantCCId();
  const t = useTranslate();

  const { mutate: mutateExportFile } = useMutation(["merchant", EXPORT_FILE], (format: "excel" | "pdf") => {
    return dataProvider.exportFile("merchant", { ccid, format });
  });

  return (
    <TopToolbar>
      <FilterButton data-testid="filter-button" />

      <Button
        variant="outlined"
        data-testid={"export-button-pdf"}
        onClick={() => mutateExportFile("pdf")}
        label={t("cc.button.export_pdf")}
      />

      <Button
        variant="outlined"
        data-testid={"export-button-excel"}
        onClick={() => mutateExportFile("excel")}
        label={t("cc.button.export_excel")}
      />
    </TopToolbar>
  );
};

const PaymentTransactionList = () => {
  const { permissions } = usePermissions();
  const { balance: merchantBalance } = useGetMerchantBalance();
  const t = useTranslate();

  const merchantReadOwnPermission = findPermission(permissions, MERCHANT_READ_OWN);
  const statusChoices = statusTransactionArray.map((status: string) => ({
    id: status,
    name: t(`resources.payment-transaction.fields.${status}`.toLowerCase()).toUpperCase()
  }));
  const filters = [
    <TextInput data-testid="filter-first_name" source="customerNameFirst" />,
    <TextInput data-testid="filter-last_name" source="customerNameLast" />,
    // <TextInput data-testid="filter-phone_number" source="customerPhone" />,
    <TextInput data-testid="filter-order_reference" source="orderReference" />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="orderStatus"
      choices={statusChoices}
      data-testid="filter-order_status"
    />,
    // <TextInput data-testid="filter-order_total_amount" source="orderTotalAmount" />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="transactionStatus"
      choices={statusChoices}
      data-testid="filter-transaction_status"
    />,
    // <TextInput data-testid="filter-transaction_amount" source="transactionAmount" />,
    <TextInput data-testid="filter-payment_provider" source="paymentProvider" />
  ];

  return (
    <ResourceContextProvider value="payment-transaction">
      <>
        <Toolbar
          variant={"regular"}
          disableGutters={true}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Breadcrumbs service={"payment_transaction"} page={"list"} />
          {merchantReadOwnPermission && (
            <Typography data-testid={"merchant-balance"} variant={"h6"}>
              {t("cc.merchant_balance.title")}: <strong>{merchantBalance}</strong>
            </Typography>
          )}
        </Toolbar>
        <List actions={<ListActions />} filters={filters}>
          <Datagrid bulkActionButtons={false} data-testid={"datagrid"}>
            <TextField source="customerNameFirst" />
            <TextField source="customerNameLast" />
            <TextField source="customerPhone" />
            <TextField source="orderReference" />
            <ChipStatus source="orderStatus" />
            <CurrencyField source={"orderTotalAmount"} />
            <ChipStatus source="transactionStatus" />
            <CurrencyField source={"transactionAmount"} />
            <LangField source={"paymentProvider"} />
            <ShowButton data-testid={"show-button"} />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default PaymentTransactionList;
