import { useFieldArray, useFormContext } from "react-hook-form";

const FieldArray = ({ render }: { render: (props: any) => JSX.Element }) => {
  const { control, getValues, setValue, watch } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "product"
  });

  return (
    <div>
      {render({
        fields,
        append,
        prepend,
        remove,
        swap,
        move,
        insert,
        update,
        getValues,
        setValue,
        watch
      })}
    </div>
  );
};

export default FieldArray;
