import Tooltip from "@mui/material/Tooltip";
import { useNotify, useRecordContext, useTranslate } from "react-admin";
import { copyToClipboard } from "utils/utils";

const LinkField = ({ source, sortable }: { source: string; sortable: boolean }) => {
  const record = useRecordContext({ source, sortable });
  const notify = useNotify();
  const translate = useTranslate();

  const handleClick = async () => {
    await copyToClipboard(record?.shortUrl || record?.url);
    notify(translate(`cc.notification.copied`), {
      type: "success"
    });
  };

  return (
    <Tooltip placement="right" title={translate("cc.tooltip.copy")}>
      <span style={{ width: 300, fontSize: 14, cursor: "pointer" }} onClick={handleClick}>
        {record?.shortUrl || record?.url}
      </span>
    </Tooltip>
  );
};

export default LinkField;
