import { Box, colors, Typography } from "@mui/material";
import React from "react";
import { useTranslate } from "react-admin";

const ProductsFooter = ({ totalPrice }) => {
  const t = useTranslate();
  return (
    <Box
      style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      data-testid="products-footer"
    >
      <Typography color="secondary" style={{ fontWeight: 700 }}>
        {t("resources.prl.fields.total")}:
      </Typography>
      <Typography color={colors.grey[600]} style={{ fontWeight: 600, color: colors.grey[600] }}>
        {totalPrice}
      </Typography>
    </Box>
  );
};

export default ProductsFooter;
