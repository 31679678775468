const bulkCollectionMockData = {
  bulk_collection: [
    {
      cnt_error: 0,
      cnt_invalid: 0,
      cnt_pending: 0,
      cnt_rows: 4,
      cnt_success: 4,
      cnt_total: 4,
      cnt_valid: 4,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 1,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "mmo",
        size: 0
      },
      id: 1,
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Mobile Money",
      payments: [
        {
          amount: 1000,
          created_at: "2022-02-04",
          customer_name: "John Doe",
          customer_reference: "BR35536999999",
          error: "False",
          id: 1,
          paid_amount: "3000.00",
          payItemId: "24425255353",
          processed_at: "2022-02-04",
          ptn: "SM2443er5",
          service_number: "8989930333",
          status: "Success",
          trid: "3664646646464"
        },
        {
          amount: 3000,
          created_at: "2022-05-04",
          customer_name: "John Joe",
          customer_reference: "BR865456446",
          error: "False",
          id: 2,
          paid_amount: "13000.00",
          payItemId: "24425255353",
          processed_at: "2022-02-04",
          ptn: "SM2443535",
          service_number: "8989930333",
          status: "Success",
          trid: "2222222222"
        },
        {
          amount: 2340,
          created_at: "2022-03-04",
          customer_name: "Zoe Zoe",
          customer_reference: "BR355363636",
          error: "False",
          id: 3,
          paid_amount: "93000.00",
          payItemId: "14425255353",
          processed_at: "2022-02-04",
          ptn: "SM24",
          service_number: "8989930333",
          status: "Success",
          trid: "11111111111111"
        },
        {
          amount: 80000,
          created_at: "2022-03-04",
          customer_name: "Zoe Zoe",
          customer_reference: "BR355363636",
          error: "False",
          id: 4,
          paid_amount: "23000.00",
          payItemId: "925255353",
          processed_at: "2022-02-04",
          ptn: "SM9993535",
          service_number: "8989930333",
          status: "Success",
          trid: "356454881111"
        }
      ],
      ref: "123546464644",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "SUCCESS",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 0,
      cnt_invalid: 0,
      cnt_pending: 0,
      cnt_rows: 10,
      cnt_success: 0,
      cnt_total: 10,
      cnt_valid: 0,
      ended_at: "",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 2,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "mmo",
        size: 0
      },
      id: 2,
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Mobile Money",
      payments: [],
      ref: "123546464645",
      reviewed_at: "2022-02-04",
      started_at: "",
      status: "READY",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 90,
      cnt_invalid: 90,
      cnt_pending: 0,
      cnt_rows: 90,
      cnt_success: 0,
      cnt_total: 90,
      cnt_valid: 0,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 3,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "airtime",
        size: 0
      },
      id: 3,
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Airtime",
      payments: [],
      ref: "123546464646",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "VALIDATIONERROR",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 0,
      cnt_invalid: 0,
      cnt_pending: 0,
      cnt_rows: 4,
      cnt_success: 0,
      cnt_total: 4,
      cnt_valid: 0,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 4,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "airtime",
        size: 0
      },
      id: 4,
      rejection_reason: "Unverified information",
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Airtime",
      payments: [],
      ref: "123546464649",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "REJECTED",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 0,
      cnt_invalid: 5,
      cnt_pending: 10,
      cnt_rows: 40,
      cnt_success: 25,
      cnt_total: 40,
      cnt_valid: 25,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 5,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "airtime",
        size: 0
      },
      id: 5,
      rejection_reason: "Unverified information",
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Airtime",
      payments: [],
      ref: "123546464649",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "PROCESSING",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 50,
      cnt_invalid: 0,
      cnt_pending: 0,
      cnt_rows: 50,
      cnt_success: 0,
      cnt_total: 50,
      cnt_valid: 50,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 6,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "airtime",
        size: 0
      },
      id: 6,
      rejection_reason: "Unverified information",
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Airtime",
      payments: [],
      ref: "123546464649",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "FAILED",
      uploaded_at: "2022-02-04"
    },
    {
      cnt_error: 2,
      cnt_invalid: 0,
      cnt_pending: 0,
      cnt_rows: 4,
      cnt_success: 2,
      cnt_total: 4,
      cnt_valid: 4,
      ended_at: "2022-02-04",
      file: {
        created_at: "2022-02-04",
        filename: "bulk_collection_mav",
        id: 7,
        mime: "text/csv",
        mpay_ccid: "CM1007",
        mpay_created_by_name: "Michael N.",
        mpay_created_by_uuid: "f434f15a-0e77-4714-b2c6-e9a2f3411edc",
        mpay_serviceId: "airtime",
        size: 0
      },
      id: 7,
      rejection_reason: "Unverified information",
      mpay_ccid: "CM1007",
      mpay_created_by_name: "John Doe",
      mpay_created_by_uuid: "1b912e03-9f0b-4cfd-a07b-7049fbb9bc82",
      mpay_reviewed_by_name: "Peter Jones",
      mpay_reviewed_by_uuid: "2f91e080-0183-4603-a241-3f5dd68ab154",
      mpay_serviceId: "Airtime",
      payments: [
        {
          amount: 1000,
          created_at: "2022-02-04",
          customer_name: "John Doe",
          customer_reference: "BR35536999999",
          error: "False",
          id: 1,
          paid_amount: "3000.00",
          payItemId: "24425255353",
          processed_at: "2022-02-04",
          ptn: "SM2443er5",
          service_number: "8989930333",
          status: "Success",
          trid: "3664646646464"
        },
        {
          amount: 3000,
          created_at: "2022-05-04",
          customer_name: "John Joe",
          customer_reference: "BR865456446",
          error: "False",
          id: 2,
          paid_amount: "13000.00",
          payItemId: "24425255353",
          processed_at: "2022-02-04",
          ptn: "SM2443535",
          service_number: "8989930333",
          status: "Success",
          trid: "2222222222"
        },
        {
          amount: 2340,
          created_at: "2022-03-04",
          customer_name: "Zoe Zoe",
          customer_reference: "BR355363636",
          error: "Error",
          id: 3,
          paid_amount: "93000.00",
          payItemId: "14425255353",
          processed_at: "2022-02-04",
          ptn: "SM24",
          service_number: "8989930333",
          status: "Error",
          trid: "11111111111111"
        },
        {
          amount: 80000,
          created_at: "2022-03-04",
          customer_name: "Zoe Zoe",
          customer_reference: "BR355363636",
          error: "Error",
          id: 4,
          paid_amount: "23000.00",
          payItemId: "925255353",
          processed_at: "2022-02-04",
          ptn: "SM9993535",
          service_number: "8989930333",
          status: "Error",
          trid: "356454881111"
        }
      ],
      ref: "123546464667",
      reviewed_at: "2022-02-04",
      started_at: "2022-02-04",
      status: "PARTIALSUCCESS",
      uploaded_at: "2022-02-04"
    }
  ]
};

export default bulkCollectionMockData;
