import {
  Box,
  Button,
  CardActions,
  ClassNameMap,
  colors,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import { ClassKeyOfStyles, withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import Card from "./CardBase";

const styles: ClassNameMap<ClassKeyOfStyles<object>> = {
  name: {
    marginRight: 30,
    fontWeight: 600,
    color: colors.grey[600]
  },
  time: {
    color: colors.grey[600]
  },
  listItem: {
    borderBottom: `1px solid ${colors.grey[300]}`,
    padding: "5px 0 6px 24px"
  },
  list: {
    width: "100%",
    padding: 0,
    overflowY: "auto",
    minHeight: "min-content",
    maxHeight: 400
  }
};

interface CardListTimelineProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  content: [];
  onClick: (arg: number) => void;
}

const CardListTimeline = ({ classes, content = [], onClick, ...cardProps }: CardListTimelineProps) => {
  const firstTimeLoad = 3;
  const t = useTranslate();

  const [displayedContent, setDisplayedContent] = useState([]);

  // Load on mount
  useEffect(() => {
    if (content && content.length > 0) {
      setDisplayedContent(content.slice(0, firstTimeLoad));
    }
  }, [content]);

  const hasMore = content?.length !== displayedContent?.length;

  const switchToCommentsTab = () => {
    onClick(1);
  };

  return (
    <Card
      // noBg
      {...cardProps}
    >
      <Box sx={{ width: "100%" }}>
        <List className={classes.list}>
          {displayedContent?.map(
            (item: { name: string; time: string; text: string; reason: string }, index) => (
              <ListItem className={classes.listItem} data-testid={"list-timeline-item"} key={index}>
                <ListItemText
                  disableTypography
                  primary={
                    <Box sx={{ display: "flex", marginBottom: 6 }}>
                      <Typography variant="body1" className={classes.name}>
                        {item?.name}
                      </Typography>
                      <Typography className={classes.time} variant="body1">
                        {item?.time}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box>
                      <Tooltip title={item?.text} placement="top">
                        <Typography noWrap>{item?.text}</Typography>
                      </Tooltip>
                      <Typography style={{ fontWeight: 600, color: colors.grey[700] }}>
                        {t("resources.entity.fields.reason")}: {item?.reason}
                      </Typography>
                    </Box>
                  }
                ></ListItemText>
              </ListItem>
            )
          )}
        </List>

        <CardActions>
          <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <Button disabled={!hasMore} color="primary" onClick={switchToCommentsTab}>
              {t("cc.button.more")}
            </Button>
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
};

export default withStyles(styles)(CardListTimeline);
