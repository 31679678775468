import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";

dayjs.extend(relativeTime);

const formatRelativeTime = (dateStr: string | number | dayjs.Dayjs | Date, locale = "en") =>
  dayjs().locale(locale).to(dayjs(dateStr));

export default formatRelativeTime;
