import dayjs from "dayjs";

/**
 *
 * @param date
 * @param formatType - upstream (server formatting) or downstream (client formatting)
 */
const formatDate = (
  date: Date | string,
  formatType: "upstream" | "downstream" = "downstream",
  time = false
): string => {
  const format =
    formatType === "downstream" ? "DD/MM/YYYY" : `YYYY-MM-DD ${time ? "HH:mm:ss" : ""}`.trimEnd();
  return dayjs(date).format(format);
};

export default formatDate;
