import { Box, Paper, PaperProps, SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import formatCurrency from "utils/formatCurrency";
import Chip from "../Chip/Chip";
import Divider from "../Divider";

interface CardDetailsProps {
  name?: string;
  amount: number;
  children: ReactNode;
  variant?: "filled" | "outlined" | undefined;
  label: string;
  status: string | boolean;
  chipStyle?: SxProps;
}

const CardDetails: React.FC<CardDetailsProps & PaperProps> = ({
  name = "",
  amount,
  children,
  label,
  status,
  variant = "filled",
  chipStyle,
  ...rest
}) => {
  return (
    <Paper sx={{ p: 2, m: 2 }} {...rest}>
      {name && <Typography fontWeight="bold">{name}</Typography>}
      <Box display={"flex"} marginBottom={2} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="body1" fontSize={32} fontWeight={"bold"}>
          {formatCurrency(amount)}
        </Typography>
        <Chip
          label={label}
          status={status}
          variant={variant as "filled" | "outlined" | undefined}
          sx={chipStyle ?? {}}
        />
      </Box>
      <Divider sx={{ backgroundColor: "transparent" }} />
      {children}
    </Paper>
  );
};

export default CardDetails;
