import { Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BackButton from "components/other/ButtonBack";
import { Show, useTranslate } from "react-admin";
import PaymentTransactionShowLayout from "components/ra/Show/paymentTransaction/PaymentTransactionShowLayout";
import { ArrowBackIos } from "@mui/icons-material";

const Actions = () => {
  const translate = useTranslate();
  return (
    <Toolbar variant={"dense"} disableGutters={true}>
      <BackButton startIcon={<ArrowBackIos />}>{translate("cc.button.back")}</BackButton>
    </Toolbar>
  );
};

const PaymentTransactionShow = () => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"payment_transaction"} page={"show"} />
      </Toolbar>
      <Show component={"div"} resource="payment-transaction" actions={<Actions />}>
        <PaymentTransactionShowLayout />
      </Show>
    </>
  );
};

export default PaymentTransactionShow;
