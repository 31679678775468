import React from "react";
import { useTranslate } from "react-admin";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

const BulkCollectTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const t = useTranslate();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((cell) => (
          <TableCell key={cell?.id} align={cell?.align}>
            {cell?.sortable ? (
              <TableSortLabel
                key={cell?.id}
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : "asc"}
                onClick={createSortHandler(cell.id)}
              >
                {t(cell.label)}
              </TableSortLabel>
            ) : (
              <span> {t(cell?.label)}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BulkCollectTableHead;
