import { useRecordContext, useTranslate } from "react-admin";
import CardGrid from "components/material/Card/CardGrid";
import ChipStatus from "components/other/context/StatusField";
import { LocalizedFieldObject } from "api/types/prlTypes";
import formatCurrency from "utils/formatCurrency";
import formatDate from "utils/formatDate";

const PaymentRequestHistoryShowLayout = () => {
  const record = useRecordContext();
  const t = useTranslate();
  const reportDetailsContent = [
    {
      status: <ChipStatus source={"prlStatus"} />
    },
    {
      total_amount: formatCurrency(record?.orderTotalAmount)
    },
    {
      payment_provider: record?.paymentProvider?.find(
        (provider: LocalizedFieldObject) => provider?.lang === "en"
      )?.text
    },
    {
      first_name: record?.customerNameFirst
    },
    {
      last_name: record?.customerNameLast
    },
    {
      order_reference: record?.orderReference
    },
    {
      created_at: formatDate(record?.prlCreatedAt)
    },
    {
      paid_at: formatDate(record?.orderPaidAt)
    }
  ];
  return (
    <div>
      <CardGrid
        columns={3}
        resource={"payment-report"}
        content={reportDetailsContent}
        cardTitle={t("cc.card.payment_details")}
      />
    </div>
  );
};

export default PaymentRequestHistoryShowLayout;
