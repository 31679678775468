import { CloudDownloadOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Form, FormDataConsumer, useTranslate } from "react-admin";
import DatesPickerGroup from "./components/DatesPickerGroup";
import { DownloadButton, IDownloadButton } from "./components/DownloadButton";

const RemittanceCollectorCompany = () => {
  const t = useTranslate();

  const actionsButtons: IDownloadButton[] = [
    {
      name: t(`resources.${["remittance"]}.fields.download_csv`),
      icon: <CloudDownloadOutlined />,
      format: "CSV",
      type: "CC"
    },
    {
      name: t(`resources.${["remittance"]}.fields.download_excel`),
      icon: <CloudDownloadOutlined />,
      format: "EXCEL",
      type: "CC"
    }
  ];

  return (
    <Box height={"100%"}>
      <Box sx={{ height: "100%" }}>
        <Box mb={2}>
          <Typography fontWeight={700} fontSize={20}>
            {t(`resources.${["remittance"]}.fields.collector_company`)}
          </Typography>
          <Typography fontSize={16}>
            {t(`resources.${["remittance"]}.fields.download_report_instruction`)}
          </Typography>
        </Box>
        <Form mode="onChange" defaultValues={{ createdAt_start: "", createdAt_end: "" }}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <>
                  <DatesPickerGroup {...rest} formData={formData} />
                  <Box sx={{ position: "absolute", bottom: 15, right: 15 }}>
                    {actionsButtons.map((item, idx) => (
                      <DownloadButton key={idx} {...item} />
                    ))}
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </Form>
      </Box>
    </Box>
  );
};

export default RemittanceCollectorCompany;
