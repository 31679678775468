import { HttpError } from "react-admin";

const fetchHttpClient = (url: string, options: RequestInit, responseType = "json") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const json = await response.json();
        throw new HttpError(response.statusText, response.status, json);
      }
      if (responseType === "blob") {
        const blob = await response.blob();
        resolve({
          data: blob,
          filename: response.headers.get("Content-Disposition")?.split("=")[1]
        });
      } else {
        const json = await response.json();
        resolve({ data: json });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export default fetchHttpClient;
