import { makeStyles } from "@mui/styles";
import ChipStatus from "components/other/context/StatusField";
import PayoutRequestProvider from "components/other/context/ServiceProvider";
import Datepicker from "components/other/Datepicker";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  RaRecord,
  ResourceContextProvider,
  SelectArrayInput,
  SelectArrayInputProps,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate
} from "react-admin";
import PaymentAmount from "./components/PaymentAmount";
import ShowButton from "components/ra/Button/ShowButton";
import ExportButton from "components/ra/Button/ExportButton";

const useStyles = makeStyles({
  root: { minWidth: 220 }
});

const StyledSelectArrayInput = (props: SelectArrayInputProps) => {
  const classes = useStyles();
  return <SelectArrayInput className={classes.root} {...props} />;
};

const paymentStatusChoices = [
  { id: "APPROVED", name: "APPROVED" },
  { id: "PROCESSING", name: "PROCESSING" },
  { id: "PAID", name: "PAID" }
];

const accountTypeChoice = [
  { id: "Mobile Money", name: "Mobile Money" },
  { id: "Bank", name: "Bank" }
];

const PaymentsList = (props: RaRecord) => {
  const translate = useTranslate();
  // const { permissions } = usePermissions();

  const ListActions = () => {
    return (
      <TopToolbar>
        <FilterButton />
        <ExportButton />
      </TopToolbar>
    );
  };

  const filterPaymentsList = [
    <TextInput label={translate("payout.company_name")} source="coreCompanyId" />,
    <Datepicker label={translate("payout.request_date")} source="startedAt" />,
    <TextInput label={translate("payout.amount")} source="amount" />,
    <StyledSelectArrayInput
      label={translate("payout.account_type")}
      source="accountType"
      choices={accountTypeChoice}
    />,
    <TextInput label={translate("payout.account_number")} source="accountNumber" />,
    <TextInput label={translate("payout.fin_service_provider")} source="paymentMethodId" />,
    <Datepicker label={translate("payout.approval_date")} source="updatedAt" />,
    <StyledSelectArrayInput
      label={translate("payout.status")}
      source="status"
      choices={paymentStatusChoices}
    />
  ];

  return (
    <ResourceContextProvider value="payments">
      <>
        <List
          {...props}
          actions={<ListActions />}
          filters={filterPaymentsList}
          sort={{ field: "createdAt", order: "DESC" }}
          hasCreate
        >
          <Datagrid bulkActionButtons={false}>
            <TextField label={translate("payout.company_name")} source="coreCompanyId" />
            <DateField label={translate("payout.request_date")} source="createdAt" />
            <PaymentAmount label={translate("payout.amount")} source="amount" />
            <TextField label={translate("payout.account_type")} source="accountType" />
            <TextField />
            <PayoutRequestProvider
              label={translate("payout.fin_service_provider")}
              source="paymentMethodId"
            />
            <TextField label={translate("payout.account_number")} source="accountNumber" />
            <DateField label={translate("payout.approval_date")} source="updatedAt" />
            <ChipStatus label={translate("payout.status")} source="status" />
            <ShowButton label="" />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default PaymentsList;
