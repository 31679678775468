import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Button, colors } from "@mui/material";
import { withStyles } from "@mui/styles";
import { FC } from "react";
import { useTranslate } from "react-admin";
import { BulkCollectListDownloadTypes } from "../../types/bulkCollectionListTypes";

const styles = () => ({
  btn: {
    color: colors.cyan[600],
    fontSize: 14
  }
});

const BulkCollectListDownload: FC<BulkCollectListDownloadTypes> = (props) => {
  const t = useTranslate();
  const { classes } = props;
  // const record = useRecordContext(props);
  // const notify = useNotify();

  // const downloadAttachment = async (id: Identifier, fileName: string) => {
  //   try {
  //     let response;

  //     response = await fetch(`${config?.REACT_APP_PROXY_BASE_URL}/bulk_payment/${id}/file?download=true`, {
  //       headers: {
  //         Authorization: `Bearer ${getStorageToken()}`
  //       }
  //     });

  //     response = await response.arrayBuffer();
  //     const blob = new Blob([response], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //     });

  //     saveAs(blob, fileName);
  //   } catch (error) {
  //     let message;
  //     if (error instanceof Error) message = error.message;
  //     else message = String(error);
  //     notify(message, { type: "error" });
  //   }
  // };

  return (
    <Button startIcon={<ArrowDownwardOutlined />} className={classes.btn} variant="text">
      {t("bulk_pay.download")}
    </Button>
  );
};

export default withStyles(styles)(BulkCollectListDownload);
