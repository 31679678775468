import { colors } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { BulkPayStatusTypes } from "components/ra/List/types/bulkPaymentListTypes";
import {
  APPROVED,
  CREATED,
  ENDED,
  ERROR,
  ERRORED,
  OPEN,
  PARTIAL_SUCCESS,
  READY,
  REJECTED,
  SUCCESS
} from "constants/status";
import { FC } from "react";
import { useRecordContext, useTranslate } from "react-admin";
import getStatusByStatusCode from "./utils/getStatusByStatusCode";
import getStatusTitleByStatusCode from "./utils/getStatusTitleByStatusCode";

const styles: any = () => ({
  root: {
    display: "inline",
    padding: "5px 10px",
    borderRadius: 5,
    whiteSpace: "nowrap"
  },

  created: {
    background: colors.brown[50],
    color: colors.brown[500]
  },

  borderCreated: {
    border: `1px solid ${colors.brown[500]}`,
    color: colors.brown[500]
  },

  info: {
    backgroundColor: colors.blue[50],
    color: colors.blue[500]
  },
  borderInfo: {
    backgroundColor: colors.yellow[50],
    color: colors.yellow[900]
  },

  success: {
    backgroundColor: colors.green[50],
    color: colors.green[500]
  },
  borderSuccess: {
    border: `1px solid ${colors.green[500]}`,
    color: colors.green[500]
  },

  borderError: {
    border: `1px solid ${colors.red[500]}`,
    color: colors.red[500]
  },

  error: {
    backgroundColor: colors.red[50],
    color: colors.red[500]
  },

  hidden: {
    display: "none"
  }
});

const BulkpayStatus: FC<BulkPayStatusTypes> = (props) => {
  const { classes, source } = props;
  const record = useRecordContext(props);

  const statusCode = source && record && record[source];
  const t = useTranslate();

  const renderClass = () => {
    switch (getStatusByStatusCode(statusCode)) {
      case CREATED:
      case READY:
      case OPEN:
        return classes.created;
      case ERROR:
      case ERRORED:
      case REJECTED:
        return classes.error;
      case ENDED:
        return classes.borderCreated;
      case SUCCESS:
      case APPROVED:
        return classes.success;
      case PARTIAL_SUCCESS:
        return classes.borderSuccess;

      default:
        return classes.info;
    }
  };

  return (
    <div data-testid="bulk-pay-status" className={classNames(classes.root, renderClass())}>
      {getStatusTitleByStatusCode({ statusCode, t }).toUpperCase()}
    </div>
  );
};

export default withStyles(styles)(BulkpayStatus);
