import { ServiceChargeConfig } from "api/types/agentPaymentsTypes";

const calculateServiceCharge = (
  serviceCharge: ServiceChargeConfig = {
    ranges: [
      {
        to: 0,
        from: 0,
        fixed: 10,
        percentage: 10
      }
    ],
    roundDenom: 20
  },
  amount = 0
) => {
  const { ranges, roundDenom } = serviceCharge;
  const range = ranges?.find((range) => {
    return amount >= range.from && amount <= (range.to || Infinity);
  });
  if (range) {
    const serviceCharge = (amount * range?.percentage) / 100;
    const totalServiceCharge = Number(range?.fixed) + serviceCharge;

    const roundValueToAdd = roundDenom - ((amount + totalServiceCharge) % roundDenom);
    const serviceChargeRounded = totalServiceCharge + roundValueToAdd;
    return serviceChargeRounded;
  } else {
    return 0;
  }
};

export default calculateServiceCharge;
