// ====== Status is indicated by binary flags =======================
// 1	Created	bulk payment was created in system
// 2	Ready	a file for bulk payment has been uploaded and the process is generally ready to be started
// 4	Approved	customer has approved the start of the processing
// 8	ImportingData	the file is being imported
// 16	ReadyForPaymentIdRetrieval	the file has been imported and the payment item records have been created
// 32	PaymentItemIdRetrievalInProcess	the payment item id for the payment items is being determined
// 64	PaymentItemIdRetrievalDone	the payment item id for the payment items is being determined
// 128	ProcessingPayments	the payment items are being processed vs smobilpay
// 256	AwaitingStatusUpdates	the payment items were all sent to smobilpay. waiting for all payment statuses to be updated to a final state
// 512	Ended	(deprecated)the bulk payment has been completely processed
// 1024	Errored	the bulk payment ran into an error somewhere
// 2048	Rejected	customer has rejected the start of the processing
// 4096	Success	the bulk payment was processed and all the payment were successfull
// 8192	PartialSuccess	The bulk payment was processed and only some of the payments were successfull, the others failed
// 16384	Error	The bulk payment was processed and all the payments failed
// ========================================================

const PARTIAL_SUCCESS = "PARTIAL_SUCCESS";
const ENDED = "ENDED";
const AWAITING_STATUS_UPDATES = "AWAITING_STATUS_UPDATES";
const PROCESSING_PAYMENTS = "PROCESSING_PAYMENTS";
const PAYMENT_ITEM_ID_RETRIEVAL_DONE = "PAYMENT_ITEM_ID_RETRIEVAL_DONE";
const PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS = "PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS";
const READY_FOR_PAYMENT_ID_RETRIEVAL = "READY_FOR_PAYMENT_ID_RETRIEVAL";
const IMPORTING_DATA = "IMPORTING_DATA";
const REJECTED = "REJECTED";
const APPROVED = "APPROVED";
const CREATED = "CREATED";
const COMPLETED = "COMPLETED";
const NEW = "NEW";
const PAID = "PAID";
const PENDING = "PENDING";
const PROCESSING = "PROCESSING";
const READY = "READY";
const OPEN = "OPEN";
const REVERSED = "REVERSED";
const DEBITED = "DEBITED";
const INPROCESS = "INPROCESS";
const ERRORED = "ERRORED";
const UNDERINVESTIGATION = "UNDERINVESTIGATION";
const ERROREDREFUNDED = "ERROREDREFUNDED";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";
const CANCELED = "CANCELED";
const EXPIRED = "EXPIRED";
const VALIDATION_ERROR = "VALIDATION_ERROR";
const FAILED = "FAILED";

export {
  REJECTED,
  APPROVED,
  CREATED,
  COMPLETED,
  NEW,
  PAID,
  PENDING,
  PROCESSING,
  DEBITED,
  ERRORED,
  ERROREDREFUNDED,
  INPROCESS,
  OPEN,
  READY,
  REVERSED,
  SUCCESS,
  UNDERINVESTIGATION,
  AWAITING_STATUS_UPDATES,
  ENDED,
  IMPORTING_DATA,
  PARTIAL_SUCCESS,
  PAYMENT_ITEM_ID_RETRIEVAL_DONE,
  PAYMENT_ITEM_ID_RETRIEVAL_IN_PROCESS,
  PROCESSING_PAYMENTS,
  READY_FOR_PAYMENT_ID_RETRIEVAL,
  ERROR,
  CANCELED,
  EXPIRED,
  VALIDATION_ERROR,
  FAILED
};

export const statusTransactionArray = [
  PENDING,
  SUCCESS,
  OPEN,
  ERRORED,
  DEBITED,
  ERROREDREFUNDED,
  INPROCESS,
  REVERSED,
  UNDERINVESTIGATION
];

export const statusPrlReportArray = [PAID, CANCELED, EXPIRED];
export const statusRemittanceHistory = [SUCCESS, PENDING, FAILED];
