import { withStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Show } from "react-admin";
import BulkPaymentDataShowLayout from "./BulkPaymentDataShowLayout";
import { Toolbar } from "@mui/material";

const styles = {
  card: {
    boxShadow: "none"
  }
};

const BulkPaymentDataShow = ({ classes }) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"bulk_payment"} page={"show"} />
      </Toolbar>
      <Show classes={{ card: classes.card }}>
        <BulkPaymentDataShowLayout />
      </Show>
    </>
  );
};

export default withStyles(styles)(BulkPaymentDataShow);
