import { ImportContacts, Lightbulb } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, AlertProps, AlertTitle, Collapse, colors, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getStorageAlert, setStorageAlert } from "utils/storageUtils";

interface AlertGuideProps extends AlertProps {
  title: string;
  subtitle?: string;
  children: JSX.Element;
  type: "instruction" | "tip";
  service: string;
  severity?: "success" | "info" | "warning" | "error";
}

const getValueFromLocalStorage = (key: string) => {
  const alertObj = getStorageAlert();
  const newVal = alertObj && alertObj[key];

  return newVal;
};
const AlertGuide = ({
  title,
  subtitle,
  children,
  service,
  type = "instruction",
  severity = "info",
  ...rest
}: AlertGuideProps) => {
  const key = `${type}-${service}`;
  const newExpandedValue = getValueFromLocalStorage(key);

  const [expanded, setExpanded] = useState<boolean>(newExpandedValue || false);

  const toggleAlert = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const obj = getStorageAlert() || {};

    obj[key] = expanded;

    // Store values inside 'alert' key on local storage
    setStorageAlert(obj);
  }, [expanded]);

  return (
    <Alert
      {...rest}
      data-testid={`alert-guide-${type}`}
      action={
        expanded ? (
          <IconButton onClick={toggleAlert} sx={{ fontSize: 28 }} data-testid="alert-toggle-less-btn">
            <ExpandLessIcon data-testid={"expand-less-icon"} />
          </IconButton>
        ) : (
          <IconButton onClick={toggleAlert} sx={{ fontSize: 28 }} data-testid="alert-toggle-more-btn">
            <ExpandMoreIcon data-testid={"expand-more-icon"} />
          </IconButton>
        )
      }
      severity={severity}
      iconMapping={{
        info:
          type === "tip" ? (
            <Lightbulb sx={{ fontSize: 28, color: colors.purple[700] }} data-testid={"tip-icon"} />
          ) : (
            <ImportContacts sx={{ fontSize: 28 }} data-testid={"instruction-icon"} />
          )
      }}
    >
      <AlertTitle sx={{ fontSize: 22, fontWeight: "bold" }}>{title}</AlertTitle>
      <Collapse in={expanded}>
        <Typography variant="body1" color="text.primary">
          {subtitle}
        </Typography>
        {children}
      </Collapse>
    </Alert>
  );
};

export default AlertGuide;
