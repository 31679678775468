import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  colors,
  Skeleton,
  Tooltip,
  Typography
} from "@mui/material";
import { Service } from "api/types/agentPaymentsTypes";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";

const AgentPaymentsServicesGrid = ({ services, isLoading }: { services: Service[]; isLoading: boolean }) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const onServiceClick = (service: any) => {
    navigate(`/agent_payments/process/${service.serviceId}`);
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={4} mx={10} data-testid="service-grid">
      {isLoading
        ? [1, 2, 3, 4, 5].map((item: any, index: number) => (
            <Box gridColumn="span 2" width={"100%"}>
              <Skeleton key={index} variant="rectangular" width={165} height={75} />
            </Box>
          ))
        : services?.map((service: any) => (
            <Box gridColumn="span 2" data-testid="service-card">
              <Tooltip title={service?.title}>
                <Card
                  key={service?.title}
                  onClick={() => onServiceClick(service)}
                  sx={{ height: 70, width: 200, backgroundColor: "#f7fafe" }}
                  elevation={4}
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      px: 1,
                      height: "100%"
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 48 }}
                      image={`http://localhost:8080/service-listing-api/logo/${service?.logo?.hash}`}
                      alt={t(`resources.${["agent_payments"]}.fields.service_image_alt_text`)}
                    />
                    <CardContent sx={{ display: "flex", flexDirection: "column", width: 135, px: 1 }}>
                      <Typography
                        noWrap
                        variant={"body2"}
                        sx={{ textTransform: "uppercase", fontWeight: "bolder", fontSize: 12, width: "100%" }}
                      >
                        {service.title}
                      </Typography>
                      <Typography noWrap variant={"body2"} sx={{ color: colors.grey[600], width: "100%" }}>
                        {service?.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Tooltip>
            </Box>
          ))}
    </Box>
  );
};

export default AgentPaymentsServicesGrid;
