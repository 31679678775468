const calculateServiceCharge = ({
  denomination = 0,
  ranges = [],
  senderAmount = 0
}: {
  denomination: number;
  ranges: any;
  senderAmount: number;
}) => {
  const range = ranges?.find((range: any) => {
    return senderAmount >= range?.from && senderAmount <= (range?.to || Infinity);
  });
  if (range) {
    const serviceCharge = (senderAmount * range?.percentage) / 100;
    const totalServiceCharge = Number(range?.fixed) + serviceCharge;

    const serviceChargeRounded = Math.ceil(totalServiceCharge / denomination) * denomination;

    return serviceChargeRounded;
  } else {
    return 0;
  }
};

export default calculateServiceCharge;
