import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import queryErrorHandler from "api/queryErrorHandler";
import Dialog from "components/material/Dialog";
import {
  APPROVE_BULK_PAYMENT,
  APPROVE_KYC_DATA,
  REJECT_BULK_PAYMENT,
  REJECT_KYC_DATA
} from "constants/dataProviderTypes";
import { useState } from "react";
import {
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useResourceContext,
  useTranslate
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getStorageMerchantCCId } from "utils/storageUtils";

interface ApproveRejectButtonProps extends ButtonProps {
  buttonType: "approve" | "reject";
  disabled?: boolean;
  title?: string;
}

const ApproveRejectButton = ({
  size = "medium",
  disabled,
  title,
  buttonType,
  variant = "outlined"
}: ApproveRejectButtonProps): JSX.Element | null => {
  const queryClient = useQueryClient();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const t = useTranslate();
  const notify = useNotify();
  const [dialogOpen, setDialogOpen] = useState(false);
  const resource = useResourceContext();
  const merchantCoreCompanyId = getStorageMerchantCCId();
  const { identity } = useGetIdentity();
  const handleOnMutationSuccess = async () => {
    if (resource === "bulk_payment") {
      await queryClient.invalidateQueries(["bulk_payment"]);
      navigate("/bulk_payment");
    } else {
      await queryClient.invalidateQueries(["entity"]);
      navigate(-1);
    }
    // Close dialog
    setDialogOpen(false);

    //   Display notify approved or rejected message based on buttonType
    notify(buttonType === "approve" ? t("cc.notification.approved") : t("cc.notification.rejected"), {
      type: "success"
    });
  };

  const getMutationKey = () => {
    if (resource === "bulk_payment") {
      return ["bulk_payment", buttonType === "approve" ? APPROVE_BULK_PAYMENT : REJECT_BULK_PAYMENT];
    }
    return ["entity", buttonType === "approve" ? APPROVE_KYC_DATA : REJECT_KYC_DATA];
  };

  const mutationFn = ({ reason }: { reason?: string }) => {
    if (resource === "bulk_payment") {
      return dataProvider[buttonType === "approve" ? "approveBulkPayment" : "rejectBulkPayment"](
        "bulk_payment",
        {
          id: record?.id,
          data: { userId: merchantCoreCompanyId, username: identity?.fullName, reason }
        }
      );
    }
    return dataProvider[buttonType === "approve" ? "approveKycData" : "rejectKycData"]("entity", {
      id: record?.id,
      data: { reason }
    });
  };

  const { mutate: mutateApprove } = useMutation(getMutationKey(), mutationFn, {
    onSuccess: async () => {
      return await handleOnMutationSuccess();
    },
    onError: (error) => {
      return queryErrorHandler({ error, notify });
    }
  });

  const { mutate: mutateReject } = useMutation(getMutationKey(), mutationFn, {
    onSuccess: async () => {
      return handleOnMutationSuccess();
    },
    onError: (error) => {
      return queryErrorHandler({ error, notify });
    }
  });

  const handleOnDialogConfirm = (reason: string) => {
    //   Call mutateApprove or mutateReject based on buttonType and service
    if (buttonType === "approve") {
      if (resource === "bulk_payment") {
        return mutateApprove({});
      }
      return mutateApprove({});
    }

    if (buttonType === "reject") {
      if (resource === "bulk_payment") {
        return mutateReject({ reason });
      }
      return mutateReject({ reason });
    }
  };

  const isApproveType = buttonType === "approve";

  const getTitle = () => {
    if (isApproveType) {
      return title || t("cc.dialog.approve_text");
    }
    return title || t("cc.dialog.reject_text");
  };

  const render = () => {
    const title = getTitle();
    switch (buttonType) {
      case "approve":
      case "reject":
        return (
          <>
            <Dialog
              open={dialogOpen}
              title={title}
              type={isApproveType ? "confirm" : "input"}
              onClose={() => setDialogOpen(false)}
              onConfirm={handleOnDialogConfirm}
            />
            <Button
              color={isApproveType ? "success" : "error"}
              size={size}
              disabled={disabled}
              disableElevation
              data-testid={isApproveType ? "approve-button" : "reject-button"}
              variant={variant}
              endIcon={isApproveType ? <ThumbUp /> : <ThumbDown />}
              onClick={() => setDialogOpen(true)}
            >
              {isApproveType ? t("cc.button.approve") : t("cc.button.reject")}
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return render();
};

export default ApproveRejectButton;
