import html2canvas from "html2canvas";

const exportQRCodeAsImage = async (element: HTMLElement, imageFileName: string) => {
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL("image/png", 1.0);
  // download the image
  const fakeLink = document.createElement("a");
  fakeLink.href = image;
  fakeLink.setAttribute("download", `${imageFileName}.png`);
  document.body.appendChild(fakeLink);
  fakeLink.click();
  fakeLink?.parentNode?.removeChild(fakeLink);
};
export default exportQRCodeAsImage;
