import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ClassNameMap,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  colors
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ClassKeyOfStyles, makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { TextInputProps, useTranslate } from "react-admin";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { InfoOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  textField: {
    width: "100%",
    minWidth: 500
  }
});

interface DialogProps {
  onClose?: () => void;
  open: boolean;
  type?: "input" | "confirm";
  title?: string;
  isloading?: boolean;
  onConfirm?: (e: string) => void;
  inputProps?: TextInputProps;
  contentText?: string;
  children?: JSX.Element;
  classes?: ClassNameMap<ClassKeyOfStyles<string>>;
  maxWidth?: "lg" | "md" | "xl" | "sm" | "xs";
  fullWidth?: boolean;
}

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const Dialog = ({
  onClose,
  onConfirm,
  open,
  type,
  contentText,
  children,
  title,
  inputProps,
  isloading = false,
  maxWidth,
  fullWidth
}: DialogProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslate();

  const textRequired =
    type === "input"
      ? {
          text: yup.string().required(t("validation.required"))
        }
      : {
          text: yup.string()
        };

  const schema = yup.object().shape({
    ...textRequired
  });

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      text: ""
    },

    resolver: yupResolver(schema)
  });

  // Clean form values on modal close
  useEffect(() => {
    if (!open) {
      reset({ text: "" });
    }
  }, [open, reset]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = async ({ text }: { text: string }) => {
    await trigger("text");
    if (onConfirm) {
      onConfirm(text);
    }
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}
        {...other}
      >
        {children && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => colors.cyan[800]
            }}
          >
            <InfoOutlined />
          </IconButton>
        )}
        <Box>{children}</Box>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <MuiDialog
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth ?? "lg"}
        fullWidth={fullWidth}
        data-testid="dialog"
      >
        <BootstrapDialogTitle onClose={handleClose}>{title}</BootstrapDialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          <DialogContentText>{contentText}</DialogContentText>
          {type === "input" && (
            <form>
              <Controller
                name="text"
                control={control}
                render={({ field }) => (
                  <TextField
                    data-testid="dialog-textfield"
                    variant="outlined"
                    className={classes.textField}
                    error={!!errors?.text?.message}
                    helperText={errors?.text?.message}
                    {...field}
                    {...inputProps}
                  />
                )}
              />
            </form>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          {onClose && (
            <Button onClick={handleClose} data-testid="dialog-close-button">
              {t("cc.button.close")}
            </Button>
          )}
          {onConfirm && (
            <Button
              variant="contained"
              // Disable eslint reason https://github.com/react-hook-form/react-hook-form/discussions/8020
              // eslint-disable-next-line
              onClick={handleSubmit(handleConfirm)}
              data-testid="dialog-confirm-button"
              disabled={isloading}
            >
              {t("cc.button.confirm")}
            </Button>
          )}
        </DialogActions>
      </MuiDialog>
    </>
  );
};

export default Dialog;
