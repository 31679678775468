const queryErrorHandler = ({ error, notify, locale = "en" }) => {
  if (error?.body?.customerMsg && error?.body?.customerMsg?.length > 0) {
    const msg = error?.body?.customerMsg.find((item) => item.language === locale)?.content;
    notify(msg, { type: "error" });
  } else if (error?.body?.raw) {
    const msg = error?.body?.raw;
    notify(msg, { type: "error" });
  } else if (error?.customerMsg) {
    const msg = error?.customerMsg.find((item) => item.language === locale)?.content;
    notify(msg, { type: "error" });
  } else if (error?.message) {
    notify(error?.message, { type: "error" });
  } else if (error?.body?.devMsg) {
    notify(error?.devMsg, { type: "error" });
  } else if (error?.devMsg) {
    notify(error?.devMsg, { type: "error" });
  } else {
    notify(error, { type: "error" });
  }
};

export default queryErrorHandler;
