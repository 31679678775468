import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { useTranslate } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { withStyles } from "@mui/styles";

const styles = {
  bold: {
    fontWeight: "bold"
  },
  approveButton: {
    backgroundColor: colors.cyan[600],
    color: "white",
    fontWeight: "bold",
    marginRight: 16,
    "&:hover": {
      backgroundColor: colors.cyan[800]
    }
  },

  rejectButton: {
    backgroundColor: colors.red[500],
    color: "white",
    fontWeight: "bold",
    marginRight: 10,
    "&:hover": {
      backgroundColor: colors.red[900]
    }
  },
  actionBox: {
    display: "flex",
    alignItems: "center"
  }
};

const PayoutApprovalActions = ({ classes, ...props }) => {
  const t = useTranslate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [reasonForRejection, setReasonForRejection] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleCancelClick = () => {
    setOpenDialog(false);
    setReasonForRejection("");
  };

  return (
    <>
      <Box {...props} className={classes.actionBox}>
        <Typography className={classes.bold} style={{ paddingRight: 10 }}>
          {t("payout.process_request")}
        </Typography>
        <Button
          className={classes.rejectButton}
          startIcon={<CloseIcon />}
          onClick={() => setOpenDialog(true)}
          variant="contained"
        >
          {t("kyc.reject")}
        </Button>
        <Button className={classes.approveButton} startIcon={<CheckIcon />} variant="contained">
          {t("kyc.approve")}
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCancelClick}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label={t("kyc.reason_of_rejection")}
              autoFocus
              value={reasonForRejection}
              onChange={(e) => setReasonForRejection(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Ok</Button>
            <Button onClick={handleCancelClick}>{t("ra.action.cancel")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(PayoutApprovalActions);
