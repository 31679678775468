import { PaymentsOutlined } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Button, Card, Typography, colors } from "@mui/material";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { Bill, Service } from "api/types/agentPaymentsTypes";
import { AGENT_PAYMENTS_GET_SERVICE } from "constants/dataProviderTypes";
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  TextField,
  useNotify,
  useTranslate
} from "react-admin";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const CustomAction = ({ onClick, ...rest }: { onClick: () => void }) => (
  <Button data-testid="pay-button" onClick={onClick} startIcon={<PaymentsOutlined />} {...rest}>
    <Typography fontWeight={"bold"}>Pay</Typography>
  </Button>
);

type Props = {
  billsSearchList: Bill[] | undefined;
  setBillItem: (bill: Bill) => void;
  handleMove: (num: number) => void;
  handleExit?: (num: number) => void;
};
const AgentPaymentsBillSearchResultList = ({ billsSearchList, setBillItem, handleMove }: Props) => {
  const sort = { field: "billNumber", order: "ASC" };
  const t = useTranslate();
  const { id } = useParams();
  const notify = useNotify();

  const { data: service }: { data?: Service } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_GET_SERVICE],
    () => dataProvider.getBillService({ serviceId: id || "" }),
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  return (
    <>
      <Box sx={{ paddingX: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", marginBottom: 5 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: 4, minWidth: 100, maxWidth: 100 }}>
              <img
                data-testid="service-image"
                src={service?.logo?.url}
                loading="lazy"
                alt="service logo"
                width="100%"
              />
            </Box>
            <Box>
              <Typography data-testid="service-title" sx={{ fontWeight: "bold", fontSize: 26 }}>
                {service?.title}
              </Typography>
              <Typography
                data-testid="service-description"
                sx={{ fontWeight: "bold", fontSize: 12, color: colors.grey[600] }}
              >
                {service?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginBottom: 3 }}>
          <HelpIcon color="primary" />
          <Typography marginLeft={1} sx={{ fontStyle: "italic", color: colors.grey[600] }}>
            {`${t(`resources.${["agent_payments"]}.fields.search`)} - ${t(
              `resources.${["agent_payments"]}.fields.results`
            )}`}
          </Typography>
        </Box>
        <ListContextProvider value={{ data: billsSearchList, sort }}>
          <Card>
            <Datagrid data-testid="datagrid">
              <TextField source="customerName" />
              <TextField source="amount" />
              <TextField source="billNumber" />
              <TextField source="serviceNumber" />
              <TextField source="customerNumber" />
              <DateField source="billDueDate" locales="fr-FR" />
              <DateField source="billDate" locales="fr-FR" />
              <FunctionField
                render={(record: any) => (
                  <CustomAction
                    onClick={() => {
                      handleMove(1);
                      setBillItem(record);
                    }}
                  />
                )}
              />
            </Datagrid>
          </Card>
        </ListContextProvider>
      </Box>
    </>
  );
};

export default AgentPaymentsBillSearchResultList;
