import {
  Box,
  ClassNameMap,
  colors,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { ClassKeyOfStyles, withStyles } from "@mui/styles";
import { FC } from "react";
import { useRecordContext, useTranslate } from "react-admin";
import CardBase from "../../../../material/Card/CardBase";
import { BulkCollectionListExpandTypes } from "../../types/bulkCollectionListTypes";

const styles: ClassNameMap<ClassKeyOfStyles<object>> = {
  topMenuTitle: {
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
    padding: 10
  },
  rejectTitle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#fff",
    padding: 10
  },
  leftTopPane: {
    borderRight: `1px solid #fff`
  },
  centerPane: {
    borderRight: `1px solid #fff`
  },
  rejectContent: {
    padding: 10
  },
  topBox: {
    borderBottom: `1px solid #fff`,
    backgroundColor: colors.cyan[600]
  },
  tableCell: {
    fontWeight: "bold"
  }
};

const BulkCollectionListExpand: FC<BulkCollectionListExpandTypes> = (props) => {
  const { classes } = props;
  const t = useTranslate();
  const record = useRecordContext(props);

  const TopMenu: FC = () => {
    return (
      <Box className={classes.topBox}>
        {record?.status === "REJECTED" ? (
          <>
            <Typography className={classes.rejectTitle}>{t("bulk_pay.reason_for_rejection")}</Typography>
          </>
        ) : (
          <Grid
            container
            // noPadding
          >
            <Grid item xs className={classes.leftTopPane}>
              <Typography className={classes.topMenuTitle}>{t("bulk_collection.validated")}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerPane}>
              <Typography className={classes.topMenuTitle}>{t("bulk_pay.processing")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.topMenuTitle}>{t("bulk_collection.results")}</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  const Content: FC = () => {
    return (
      <>
        <TopMenu />
        {record?.status === "REJECTED" ? (
          <Box component="div" className={classes.rejectContent}>
            <Typography>{record?.rejection_reason}</Typography>
          </Box>
        ) : (
          <Grid
            container
            // noPadding
          >
            <Grid item xs className={classes.leftTopPane}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.imported")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.valid")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.invalid")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{record?.cnt_rows}</TableCell>
                      <TableCell align="center">{record?.cnt_valid}</TableCell>
                      <TableCell align="center">{record?.cnt_invalid}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={2} className={classes.centerPane}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.pending")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{record?.cnt_pending}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.success")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_pay.errors")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("bulk_collection.total")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{record?.cnt_success}</TableCell>
                      <TableCell align="center">{record?.cnt_error}</TableCell>
                      <TableCell align="center">{record?.cnt_total}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <CardBase
        // noPadding
        variant="classic-white"
      >
        <Content />
      </CardBase>
    </>
  );
};

export default withStyles(styles)(BulkCollectionListExpand);
