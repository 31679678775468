import React from "react";
import { Edit, EditProps } from "react-admin";
import ProductCreateEditForm from "../Form/ProductCreateEditForm";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Toolbar } from "@mui/material";
import { FORM_TYPE_EDIT } from "constants/formTypes";

const ProductEdit = (props: EditProps) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"product"} page={"edit"} />
      </Toolbar>
      {/*FIXME: Align product edit page with action buttons*/}
      <Edit component={"div"} {...props} resource="product">
        <ProductCreateEditForm formType={FORM_TYPE_EDIT} />
      </Edit>
    </>
  );
};

export default ProductEdit;
