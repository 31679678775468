import { ArrowBackIos } from "@mui/icons-material";
import { Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BackButton from "components/other/ButtonBack";
import { Show, useTranslate } from "react-admin";
import PaymentRequestHistoryShowLayout from "./PaymentRequestHistoryShowLayout";

const Actions = () => {
  const translate = useTranslate();
  return (
    <Toolbar variant={"dense"} disableGutters={true}>
      <BackButton startIcon={<ArrowBackIos />}>{translate("cc.button.back")}</BackButton>
    </Toolbar>
  );
};

const PaymentRequestHistoryShow = () => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"payment_request_history"} page={"show"} />
      </Toolbar>
      <Show component={"div"} resource="payment-report" actions={<Actions />}>
        <PaymentRequestHistoryShowLayout />
      </Show>
    </>
  );
};

export default PaymentRequestHistoryShow;
