const payoutAccountMockData = {
  "payout-account": [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      fspId: "BANK",
      type: "external",
      coreCompanyId: "str1235",
      accountName: "BICEC Bank",
      countryCode: "CMR",
      accountNumber: "1002 5000 5038 3010 4418 618",
      accountHolderName: "Cameron Williamson",
      accountSwiftCode: "745654645",
      address: "Immeuble Bonadjo",
      state: "Littoral",
      city: "Douala",
      information: "Additional information about account",
      isPreferred: true
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afafffffss6",
      fspId: "BANK",
      type: "internal",
      coreCompanyId: "str1235fdsfsd",
      accountName: "YoomeeMoney",
      countryCode: "CMR",
      accountNumber: "10000 ADFGG 4455 QDDS",
      accountHolderName: "Jack luck",
      accountSwiftCode: "BICECMCFX",
      address: "Immeuble Bonadjo",
      state: "Littoral",
      city: "Douala",
      information: "Additional information about account",
      isPreferred: false
    },
    {
      id: "2c963f66afa6-5717-4562-b3fc-3fa85f64",
      fspId: "MOBILEMONEY",
      type: "external",
      coreCompanyId: "str1235fdsfsd",
      accountName: "OrangeMoney",
      countryCode: "CMR",
      accountNumber: "2004 3000 4538 2010 2315 456",
      accountHolderName: "Brooklyn Simmons",
      accountSwiftCode: "BICECMCFX",
      address: "Immeuble Bonadjo",
      state: "Littoral",
      city: "Douala",
      information: "Additional information about account",
      isPreferred: false
    },
    {
      id: "525sdfsqfa6-5717-4562-b3fc-3fa85f64-hh5766",
      fspId: "MOBILEMONEY",
      type: "internal",
      coreCompanyId: "str1235fdsfsd",
      accountName: "MTN Mobile Money",
      countryCode: "CMR",
      accountNumber: "680457691",
      accountHolderName: "Brooklyn Simmons",
      accountSwiftCode: "BICECMCFX",
      address: "Immeuble Bonadjo",
      state: "Littoral",
      city: "Douala",
      information: "Additional information about account",
      isPreferred: false
    }
  ]
};

export default payoutAccountMockData;
