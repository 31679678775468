import React, { ReactNode } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  Typography,
  SxProps
} from "@mui/material";
import getButtonWidth from "utils/getButtonWidth";

interface ButtonProps extends MuiButtonProps {
  customWidth?: "normal" | "large" | "fullWidth";
  isLoading?: boolean;
  loadingMessage?: string;
  minWidth?: string;
  variant: "text" | "contained" | "outlined";
  label?: string;
  children?: ReactNode;
  sx?: SxProps;
}

const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  loadingMessage,
  minWidth = "150px",
  customWidth = "normal",
  label,
  sx,
  variant = "contained",
  endIcon,
  startIcon,
  children = null,
  ...otherProps
}) => {
  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <>
          <CircularProgress size={20} color="inherit" style={{ marginRight: 10 }} />
          {loadingMessage && <Typography variant="body1">{loadingMessage}</Typography>}
        </>
      );
    }
    return children || label;
  };

  const renderIcon = (icon: React.ReactNode) => {
    if (!isLoading && icon) {
      return icon;
    }
    return null;
  };

  return (
    <MuiButton
      variant={variant}
      disabled={isLoading || otherProps.disabled}
      startIcon={renderIcon(startIcon)}
      endIcon={renderIcon(endIcon)}
      sx={{ minWidth: getButtonWidth(customWidth), ...sx }}
      {...(customWidth === "fullWidth" ? { fullWidth: true } : {})}
      {...otherProps}
    >
      {renderButtonContent()}
    </MuiButton>
  );
};

export default Button;
