import { ButtonGroup, Grid } from "@mui/material";
import Tabs from "components/material/Tabs";
import React from "react";
import { usePermissions, useTranslate } from "react-admin";
import BulkPaymentAuditTab from "./tabs/BulkPaymentAuditTab";
import BulkPaymentGeneralTab from "./tabs/BulkPaymentGeneralTab";
import BulkPaymentHistoryTab from "./tabs/BulkPaymentHistoryTab";
import ApproveRejectButton from "components/other/ApproveRejectButton";

const BulkPaymentDataShowLayout = (props) => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  return (
    <>
      <Grid container direction={"row"} alignItems={"center"}>
        <Grid>
          {permissions === "bulkpay_approver" && (
            <ButtonGroup sx={{ marginLeft: 5 }}>
              <ApproveRejectButton buttonType="approve" />
              <ApproveRejectButton buttonType="reject" />
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
      <Tabs
        tabLabels={[
          `${t("cc.tab.general_information")}`,
          `${t("cc.tab.payment_history")}`,
          `${t("cc.tab.audit")}`
        ]}
        onChange={handleChange}
        activeTabIndex={activeTabIndex}
      >
        <BulkPaymentGeneralTab {...props} setActiveTabIndex={setActiveTabIndex} />
        <BulkPaymentHistoryTab {...props} />
        <BulkPaymentAuditTab {...props} />
      </Tabs>
    </>
  );
};

export default BulkPaymentDataShowLayout;
