import { CreateButton as CreateBtn, CreateButtonProps } from "react-admin";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { SxProps } from "@mui/material";
import getButtonWidth from "utils/getButtonWidth";

interface ButtonProps extends CreateButtonProps {
  customWidth?: "normal" | "large" | "fullWidth";
  minWidth?: string;
  variant?: "text" | "contained" | "outlined";
  sx?: SxProps;
}

const CreateButton = ({
  minWidth = "150px",
  customWidth = "normal",
  sx,
  variant = "outlined",
  ...props
}: ButtonProps) => {
  return (
    <CreateBtn
      variant={variant}
      icon={<CreateOutlinedIcon color="inherit" />}
      sx={{
        minWidth: getButtonWidth(customWidth),
        borderWidth: 1,
        ...sx
      }}
      {...(customWidth === "fullWidth" ? { fullWidth: true } : {})}
      size="medium"
      {...props}
    />
  );
};

export default CreateButton;
