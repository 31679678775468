import { useEffect, useState } from "react";
import { Box, Chip, colors, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { filterAgentPaymentsServices } from "../utils/filterAgentPaymentsServices";

const AgentPaymentsSearchInput = ({ selectedCategories, setSelectedCategories, setServices, data }: any) => {
  const [searchValue, setSearchValue] = useState("");
  const onChange = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleChipDelete = (chipToDelete: any) => () => {
    setSelectedCategories((categories: any) => {
      return categories.filter((category: any) => {
        return category !== chipToDelete;
      });
    });
  };

  // This is the main logic for filtering the services
  useEffect(() => {
    const result = filterAgentPaymentsServices(data, selectedCategories, searchValue);
    setServices(result);
  }, [searchValue, selectedCategories]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }} data-testid="search-input">
      <TextField
        InputProps={{
          startAdornment: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start"
              }}
            >
              {selectedCategories.map((category: any) => (
                <Chip
                  sx={{ marginRight: 1 }}
                  label={`category: ${category}`}
                  onDelete={handleChipDelete(category)}
                />
              ))}
            </Box>
          ),
          endAdornment: <Search sx={{ color: colors.cyan[600] }} />
        }}
        sx={{ width: 600, marginBottom: 5 }}
        value={searchValue}
        placeholder={"search..."}
        onChange={onChange}
        variant={"outlined"}
      />
    </Box>
  );
};

export default AgentPaymentsSearchInput;
