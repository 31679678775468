import { colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mapValues } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";
import isEmptyCollection from "utils/isEmptyCollection";
import Card from "./CardBase";
import KeyValueText from "../KeyValueText/KeyValueText";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gap: "10px",
    rowGap: "16px",
    gridTemplateColumns: (props: { columns: number }) => `repeat(${props.columns}, minmax(150px, 1fr))`,
    width: "100%"
  },

  item: {
    overflow: "hidden",
    lineHeight: "28px"
  },

  itemStack: {
    overflow: "hidden",
    lineHeight: "28px",
    padding: "0 10px"
  },

  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
});

interface CardGridProps {
  content?: {
    [key: string]: string | JSX.Element | JSX.Element[] | undefined;
  }[];
  children?: React.ReactNode;
  columns?: number;
  cardTitle?: string;
  resource?: string;
  variant?: string;
  isVertical?: boolean;
}

const CardGrid = ({
  content = [],
  resource,
  children,
  variant,
  columns = 4,
  isVertical = true,
  ...cardProps
}: CardGridProps) => {
  const classes = useStyles({ columns });
  let items;
  items = content?.map((item) => {
    const noUndefinedValues = mapValues(item, (value) => (value ? value : ""));
    return Object.entries(noUndefinedValues);
  });

  const t = useTranslate();

  items = items?.reduce((acc, curr) => acc.concat(curr), []);

  const isEmpty = isEmptyCollection(content);

  return (
    <Card {...cardProps} isEmpty={isEmpty} variant={variant}>
      <div className={classes.grid}>
        {items?.map(([key, value]: any, index: React.Key | null | undefined) => {
          return (
            <div className={classes.item} key={index}>
              <KeyValueText
                key={key}
                title={t(`resources.${resource}.fields.${key}`)}
                value={value || "N/A"}
                isVertical={isVertical}
              />
            </div>
          );
        })}
        {children}
      </div>
    </Card>
  );
};

export default CardGrid;
