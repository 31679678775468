import {
  CloudDownload,
  CropFree,
  Facebook,
  FileCopy,
  Instagram,
  Mail,
  MoreHorizRounded,
  Twitter,
  WhatsApp
} from "@mui/icons-material";
import {
  Box,
  colors,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Typography
} from "@mui/material";
import { makeStyles, ThemeOfStyles } from "@mui/styles";
import * as React from "react";
import { useRef, useState } from "react";
import { useNotify, useRecordContext, useTranslate } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import exportQRCodeAsImage from "utils/exportQRCodeAsImage";
import useGetShareLinks from "hooks/useGetShareLinks";

const useStyles = makeStyles<ThemeOfStyles<string>, ShareButtonProps>({
  dialog: {
    p: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  qrCodeWrapper: {
    borderRadius: 10,
    borderColor: colors.cyan[600],
    border: "2px solid",
    padding: 10
  },
  social: {
    fontSize: 50,
    color: colors.cyan[600]
  },
  text: {
    fontWeight: "bold",
    fontSize: 20,
    color: colors.cyan[600],
    marginBottom: 20
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    p: 7
  }
});
interface ShareButtonProps extends IconButtonProps {
  sortable?: boolean;
  // Used for translation purpose
  source?: string;
}

const ShareButton = (props: Omit<ShareButtonProps, "label">) => {
  const notify = useNotify();
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles(props);
  const { getLink, isDisabled, singleLink } = useGetShareLinks();

  const instagramLink = getLink("instagram");
  const emailLink = getLink("email");
  const twitterLink = getLink("twitter");
  const whatsappLink = getLink("whatsapp");
  const facebookLink = getLink("facebook");

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    handleMenuClose();
    setOpenModal(true);
  };
  const exportRef = useRef(null);
  const translate = useTranslate();
  const handleDownloadClick = async () => {
    handleCloseModal();
    if (exportRef.current !== null) await exportQRCodeAsImage(exportRef.current, "QRCode Falcon");
  };

  const record = useRecordContext(props);
  const link =
    record?.shortUrl ||
    record?.merchantLinkShortened ||
    record?.url ||
    record?.merchantLink ||
    singleLink ||
    "";

  const handleOnCopy = () => {
    notify("cc.notification.copied", { type: "success" });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <CopyToClipboard text={link} onCopy={handleOnCopy}>
          <IconButton
            style={{ backgroundColor: "transparent" }}
            size="small"
            disabled={isDisabled}
            data-testid="share-copy-button"
          >
            <FileCopy color={isDisabled ? "disabled" : "primary"}></FileCopy>
          </IconButton>
        </CopyToClipboard>
        <IconButton
          size="small"
          data-testid="share-whatsapp-button"
          disabled={isDisabled}
          href={whatsappLink}
          data-action="share/whatsapp/share"
          rel="noopener noreferrer"
          target="_blank"
          style={{ backgroundColor: "transparent" }}
        >
          <WhatsApp color={isDisabled ? "disabled" : "primary"}></WhatsApp>
        </IconButton>
        <IconButton
          data-testid="share-dots-button"
          size="small"
          disabled={isDisabled}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenuClick}
          style={{ backgroundColor: "transparent" }}
        >
          <MoreHorizRounded color={isDisabled ? "disabled" : "primary"}></MoreHorizRounded>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem onClick={handleMenuClose}>
            <IconButton
              href={emailLink}
              disabled={isDisabled}
              size="large"
              style={{ backgroundColor: "transparent" }}
            >
              <Mail color={isDisabled ? "disabled" : "primary"}></Mail>
            </IconButton>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <IconButton
              href={facebookLink}
              rel="noopener noreferrer"
              target="_blank"
              size="large"
              style={{ backgroundColor: "transparent" }}
            >
              <Facebook color={isDisabled ? "disabled" : "primary"}></Facebook>
            </IconButton>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <IconButton
              href={twitterLink}
              disabled={isDisabled}
              rel="noopener noreferrer"
              target="_blank"
              size="large"
              style={{ backgroundColor: "transparent" }}
            >
              <Twitter color={isDisabled ? "disabled" : "primary"}></Twitter>
            </IconButton>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <IconButton
              href={instagramLink}
              rel="noopener noreferrer"
              target="_blank"
              size="large"
              style={{ backgroundColor: "transparent" }}
            >
              <Instagram color={isDisabled ? "disabled" : "primary"}></Instagram>
            </IconButton>
          </MenuItem>
          <MenuItem onClick={handleOpenModal}>
            <IconButton size="large" style={{ backgroundColor: "transparent" }}>
              <CropFree color={isDisabled ? "disabled" : "primary"}></CropFree>
            </IconButton>
          </MenuItem>
        </Menu>
        <Modal
          data-testid="modal"
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            className={classes.modal}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.text}>
                {translate("cc.modal_success.download_qrcode")}
              </Typography>
              <div ref={exportRef} className={classes.qrCodeWrapper}>
                <QRCode value={link} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}
              >
                <IconButton onClick={handleDownloadClick} size="large">
                  <CloudDownload className={classes?.social} />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </div>
    </>
  );
};

export default ShareButton;
