import { Alert, colors, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Title, usePermissions, useTranslate } from "react-admin";
import ServicePicker from "./components/ServicePicker/ServicePicker";
import getPermittedServices from "./utils/getPermittedServices";

const BomDashboard = () => {
  const { permissions } = usePermissions();
  const services = getPermittedServices(permissions);
  const t = useTranslate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        marginTop: 5
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        <Title title="BOM 2.0" />
        <Box sx={{ marginBottom: 3, display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Typography variant="h3">{t("cc.dashboard.title_1").toUpperCase()}</Typography>
          <Typography variant="h2" color={colors.cyan[700]}>
            {t("cc.dashboard.subtitle")}
          </Typography>
          <Typography variant="h3">{t("cc.dashboard.title_2").toUpperCase()}</Typography>
        </Box>
        <Box sx={{ marginTop: 3, width: "60%" }}>
          {services?.length > 0 ? (
            <ServicePicker permissions={permissions} />
          ) : (
            <Box>
              <Alert sx={{ fontSize: 20 }} severity="warning">
                {t("cc.dashboard.access_denied")}
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BomDashboard;
