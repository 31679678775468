import * as React from "react";
import { useRecordContext } from "react-admin";
import Alert from "@mui/lab/Alert";
import { AlertStatusTypes } from "components/types/otherComponentTypes";
import getColorByStatus from "utils/getColorByStatus";

const AlertStatus: React.FC<AlertStatusTypes> = (props) => {
  const { source, label } = props;
  const record = useRecordContext(props);

  const data = record[source];

  return <Alert severity={getColorByStatus(data)}>{label}</Alert>;
};

export default AlertStatus;
