import { Box, Button, ClassNameMap, colors, Typography } from "@mui/material";
import { ClassKeyOfStyles, withStyles } from "@mui/styles";
import { useTranslate } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const styles: ClassNameMap<ClassKeyOfStyles<object>> = () => ({
  topGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `20px 0 0 0`
  },
  amount: {
    fontWeight: "bold",
    fontSize: 22,
    color: colors.cyan[600]
  },
  descText: {
    fontWeight: "bold",
    fontSize: 12
  },
  downloadLink: {
    padding: "2px 4px",
    textTransform: "uppercase",
    color: colors.cyan[600],
    "&:hover": {
      textDecoration: "none",
      borderRadius: 3,
      backgroundColor: colors.grey[300]
    }
  }
});

const BulkCollectTopContent = (props: { classes: ClassNameMap<ClassKeyOfStyles<string>> }) => {
  const { classes } = props;
  const t = useTranslate();

  // const templateUrl = `${config.REACT_APP_PROXY_BASE_URL}/template/bulk_payment`;
  // const saveTemplate = () => {
  //   saveAs(templateUrl, `template.xlsx`);
  // };

  return (
    <Box component="div" className={classes.topGrid}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.descText}>{t("bulk_collection.template_text")}</Typography> :
        <Button color="primary">{t("bulk_pay.here")}</Button>
      </Box>
      <Box>
        <Typography className={classes.amount}>{formatCurrency(100000)}</Typography>
        <Typography className={classes.descText}>{t("bulk_pay.available_balance_text")}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(BulkCollectTopContent);
