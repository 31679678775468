import config from "config";
import bulkPaymentMockData from "mockapi/bulkPaymentMockData";
import businessSectorMockData from "mockapi/businessSectorMockData";
import merchantMockData from "mockapi/merchantMockData";
import paymentsMockData from "mockapi/paymentsMockData";
import payoutAccountMockData from "mockapi/payoutAccountMockData";
import payoutApprovalMockData from "mockapi/payoutApprovalMockData";
import payoutRequestMockData from "mockapi/payoutRequestMockData";
import prlMockData from "mockapi/prlMockData";
import productMockData from "mockapi/productMockData";
import simpleRestProvider from "ra-data-simple-rest";

import bulkCollectionMockData from "mockapi/bulkCollectionMockData";
import { combineDataProviders, fetchUtils } from "react-admin";
import bulkPaymentFakeDataProvider from "../mockapi/fakeDataProvider/bulkPaymentFakeDataProvider";
import bulkCollectionDataProvider from "./dataProvider/bulkCollectionDataProvider";
import bulkPaymentDataProvider from "./dataProvider/bulkPaymentDataProvider";
import kycDataProvider from "./dataProvider/kycDataProvider";
import merchantDataProvider from "./dataProvider/merchantDataProvider";
import paymentMethodDataProvider from "./dataProvider/paymentMethodDataProvider";
import prlDataProvider from "./dataProvider/prlDataProvider";
import paymentTransactionDataProvider from "./dataProvider/paymentTransactionDataProvider";
import productDataProvider from "./dataProvider/productDataProvider";
import pssDataProvider from "./dataProvider/pssDataProvider";
import fakeDataProvider from "./fakeDataProvider";
import paymentRequestHistoryDataProvider from "./dataProvider/paymentRequestHistoryDataProvider";
import remittanceDataProvider from "api/dataProvider/remittanceDataProvider";
import agentPaymentsDataProvider from "./dataProvider/agentPaymentsDataProvider";
import companyManagementDataProvider from "./dataProvider/companyManagementDataProvider";

const fakePrlProvider = fakeDataProvider(prlMockData, true);
const fakeMerchantProvider = fakeDataProvider(merchantMockData);
const fakeBusinessSectorProvider = fakeDataProvider(businessSectorMockData);
const fakeProductProvider = fakeDataProvider(productMockData);
const payoutAccountProvider = fakeDataProvider(payoutAccountMockData);
const fakePayoutRequestProvider = fakeDataProvider(payoutRequestMockData);
const fakePayoutApprovalProvider = fakeDataProvider(payoutApprovalMockData);
const fakeBulkPaymentDataProvider = bulkPaymentFakeDataProvider(bulkPaymentMockData);
const fakeBulkCollectionDataProvider = fakeDataProvider(bulkCollectionMockData);
const fakePaymentsProvider = fakeDataProvider(paymentsMockData);

const prlUseMockData = config.REACT_APP_PRL_USE_MOCK_DATA;
const merchantUseMockData = config.REACT_APP_MERCHANT_USE_MOCK_DATA;
const businessSectorUseMockData = config.REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA;
const productUseMockData = config.REACT_APP_PRODUCT_USE_MOCK_DATA;
const payoutAccountUseMockData = config.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA;
const payoutRequestUseMockData = config.REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA;
const payoutApprovalUseMockData = config.REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA;
const bulkPaymentUseMockData = config.REACT_APP_BULK_PAYMENT_USE_MOCK_DATA;
const bulkCollectionUseMockData = config.REACT_APP_BULK_COLLECTION_USE_MOCK_DATA;
const paymentsUseMockData = config.REACT_APP_PAYMENTS_USE_MOCK_DATA;

const prlHttpClient = (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${JSON.parse(localStorage.getItem("authToken"))}`
  };
  return fetchUtils.fetchJson(url, options);
};

const dataProviderManager = combineDataProviders((resource) => {
  switch (resource) {
    case "entity":
      return kycDataProvider;
    case "prl":
      return prlUseMockData === "true" ? fakePrlProvider : prlDataProvider;
    case "product":
      return productUseMockData === "true" ? fakeProductProvider : productDataProvider;
    case "payout-request":
      return payoutRequestUseMockData === "true" ? fakePayoutRequestProvider : pssDataProvider;
    case "fsp":
      return pssDataProvider;
    case "payout-approval":
      return payoutApprovalUseMockData === "true"
        ? fakePayoutApprovalProvider
        : simpleRestProvider(`${config.REACT_APP_PROXY_BASE_URL}`);
    case "bulk_payment":
      return bulkPaymentUseMockData === "true" ? fakeBulkPaymentDataProvider : bulkPaymentDataProvider;
    case "bulk_collection":
      return bulkCollectionUseMockData === "true"
        ? fakeBulkCollectionDataProvider
        : bulkCollectionDataProvider;
    case "business_management":
      return businessSectorUseMockData === "true"
        ? fakeBusinessSectorProvider
        : simpleRestProvider(`${config.REACT_APP_PROXY_BASE_URL}/business-management`);
    case "merchant":
      return merchantUseMockData === "true" ? fakeMerchantProvider : merchantDataProvider;
    case "payment-transaction":
      return paymentTransactionDataProvider;
    case "payment-report":
      return paymentRequestHistoryDataProvider;
    case "request-account":
      return payoutAccountUseMockData === "true" ? payoutAccountProvider : pssDataProvider;
    case "remittance":
      return remittanceDataProvider;
    case "payments":
      return paymentsUseMockData === "true"
        ? fakePaymentsProvider
        : simpleRestProvider(`${config.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA}`, prlHttpClient);
    case "payment-method":
      return paymentMethodDataProvider;
    case "agent-payments":
      return agentPaymentsDataProvider;
    case "company-management":
      return companyManagementDataProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

export default dataProviderManager;
