import { Box } from "@mui/material";
import DatePickerInput from "components/ra/Input/DatePickerInput/DatePickerInput";
import dayjs from "dayjs";
import { maxValue, minValue, required } from "react-admin";

const DatesPickerGroup = ({ formData, ...rest }: any) => {
  return (
    <Box display={"flex"} width={"100%"}>
      <Box marginRight={4} width={"30%"}>
        <DatePickerInput
          {...rest}
          label={`resources.${["remittance"]}.fields.start_date`}
          source="createdAt_start"
          size="medium"
          maxDate={dayjs()}
          validate={[required(), maxValue(dayjs())]}
        />
      </Box>
      <Box width={"30%"}>
        <DatePickerInput
          {...rest}
          label={`resources.${["remittance"]}.fields.end_date`}
          source="createdAt_end"
          disabled={!formData.createdAt_start}
          minDate={dayjs(formData.createdAt_start)}
          maxDate={dayjs()}
          size="medium"
          validate={[required(), minValue(formData.createdAt_start), maxValue(dayjs())]}
        />
      </Box>
    </Box>
  );
};

export default DatesPickerGroup;
