import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import React from "react";
import { Show, useTranslate } from "react-admin";
import PayoutAccountShowLayout from "./PayoutAccountShowLayout";
import EditButton from "components/ra/Button/EditButton";
import DeleteButton from "components/ra/Button/DeleteButton";

const PayoutAccountShowActions = ({ basePath, data }) => {
  return (
    <Box sx={{ marginBottom: 15, marginTop: 20, justifyContent: "flex-end", display: "flex" }}>
      <EditButton basePath={basePath} record={data} />
      <DeleteButton basePath={basePath} record={data} />
    </Box>
  );
};

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "inherit"
  }
};

const PayoutAccountShow = ({ permissions, classes }) => {
  const translate = useTranslate();
  return (
    <>
      <Breadcrumbs />
      <Show
        classes={{ card: classes.card }}
        title={translate("payout.payout_account_details")}
        actions={<PayoutAccountShowActions permissions={permissions} />}
      >
        <PayoutAccountShowLayout permissions={permissions} />
      </Show>
    </>
  );
};

export default withStyles(styles)(PayoutAccountShow);
