import { Box, colors, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useDataProvider, useRecordContext, useTranslate } from "react-admin";
import { useQuery } from "react-query";

import CardBase from "components/material/Card/CardBase";
import CardGrid from "components/material/Card/CardGrid";
import BomAttachment from "components/material/List/ListAttachment";
import { GET_MANY_ATTACHMENT } from "constants/dataProviderTypes";
import { isEmpty } from "lodash";
import formatCurrency from "utils/formatCurrency";

const styles = {
  productDescription: {
    gridRow: 2,
    gridColumn: "1 /4"
  },
  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
};

const ProductShowLayout = ({ classes }) => {
  const t = useTranslate();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data: dataAttachment, isLoading: loadingAttachment } = useQuery(
    ["product", GET_MANY_ATTACHMENT, { id: record?.id }],
    () => dataProvider.getManyAttachment("product", { id: record?.id }),

    { enabled: !!record?.id }
  );

  const attachmentContent = dataAttachment?.data?.map((item) => ({
    url: item?.documentUrl,
    mimeType: item?.mimeType,
    label: item?.documentLabel
  }));

  const productInformationContent = [
    { name: record?.name },
    {
      price: record?.price ? formatCurrency(record?.price) : ""
    },
    {
      productExtId: record?.productExtId
    }
  ];

  return (
    <Grid container spacing={3} style={{ padding: 10 }}>
      <Grid item xs={12}>
        <CardGrid
          resource={"product"}
          cardTitle={t("cc.card.product_information")}
          content={productInformationContent}
        >
          {/*Auto translation is not performed for CardGrid children elements*/}
          <Box className={classes.productDescription}>
            <Typography className={classes.valueKey}>{t("resources.product.fields.description")}:</Typography>
            <Typography className={classes.valueText}>{record?.description}</Typography>
          </Box>
        </CardGrid>
      </Grid>
      <Grid item xs={4}>
        <CardBase
          cardTitle={t("cc.card.attachment", { smart_count: 0 })}
          isEmpty={isEmpty(dataAttachment?.data)}
          isLoading={loadingAttachment}
          noPadding
        >
          <BomAttachment content={attachmentContent} />
        </CardBase>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProductShowLayout);
