import { PowerSettingsNew, AccountCircle } from "@mui/icons-material";
import { Button, colors, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, useState } from "react";
import { useLogout, useTranslate, MenuItemLink, useGetIdentity } from "react-admin";

export default function BomUserMenu() {
  const [anchorEl, setAnchorEl] = useState();
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = async () => {
      handleClose();
      await logout();
    };
    return (
      <MenuItem onClick={handleClick} ref={ref} data-testid="logout-usermenu-item">
        <PowerSettingsNew color="secondary" sx={{ marginRight: 2 }} />{" "}
        <Typography style={{ color: colors.grey[600], fontWeight: "bold" }} data-testid="logout-button">
          {translate("cc.menu.logout")}
        </Typography>
      </MenuItem>
    );
  });

  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        sx={{ color: "#fff" }}
        startIcon={<AccountCircle />}
        data-testid="user-identity"
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Typography sx={{ fontSize: 14, textTransform: "none" }}>{identity?.fullName}</Typography>
          {identity?.agentId && (
            <Typography data-testid="user-identity-agentId" sx={{ fontSize: 12 }}>
              {identity?.agentId}
            </Typography>
          )}
        </Box>
      </Button>
      <Menu
        data-testid="menu-items-wrapper"
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {identity?.agentId && (
          <MenuItemLink
            data-testid="menu-item-profile"
            onClick={handleClose}
            to={`/merchant/${identity?.merchantCCId}`}
            primaryText={translate("cc.menu.my_profile")}
            leftIcon={<AccountCircle />}
            style={{ color: colors.grey[600] }}
          />
        )}
        <MyLogoutButton />
      </Menu>
    </div>
  );
}
