import { Box, Button, colors, TableCell, TableRow } from "@mui/material";
import CardGrid from "components/material/Card/CardGrid";
import ButtonBack from "components/other/ButtonBack";
import BulkpayStatus from "components/ra/List/BulkPaymentList/components/BulkpayStatus";
import {
  DateField,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useTranslate
} from "react-admin";
import Table from "components/material/Table";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import { DOWNLOAD_ATTACHMENT } from "constants/dataProviderTypes";
import queryErrorHandler from "api/queryErrorHandler";

const BulkPaymentGeneralTab = (props) => {
  const record = useRecordContext(props);
  const t = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { mutate: mutateDownloadDocument } = useMutation(
    ["bulk_payment", DOWNLOAD_ATTACHMENT],
    () => {
      return dataProvider.downloadDocument("bulk_payment", {
        id: record?.id,
        data: record?.file
      });
    },
    {
      onSuccess: () => {
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const bulkPayInformationContent = [
    {
      reference: record?.ref
    },
    {
      status: <BulkpayStatus source="status" record={record} />
    },
    {
      started: <DateField showTime source="started_at" locales="fr-FR" />
    },
    {
      ended: <DateField showTime source="ended_at" locales="fr-FR" />
    },
    {
      description: <TextField showTime source="desciption" locales="fr-FR" />
    },
    {
      file: (
        <Button
          onClick={() => mutateDownloadDocument()}
          color="primary"
          variant="outlined"
          data-testid="download-button"
        >
          {t("cc.button.download")}
        </Button>
      )
    }
  ];
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CardGrid
            cardTitle={t("cc.card.details")}
            resource={"bulk_payment"}
            content={bulkPayInformationContent}
          ></CardGrid>
        </Grid>
        <Grid item xs={12}>
          <Table
            align={"center"}
            renderSecondaryHeader={() => (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={3}
                  sx={{
                    background: colors.grey[50],
                    fontWeight: "bold"
                  }}
                >
                  {t("cc.table.pre_validated")}
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={4}
                  sx={{
                    background: colors.grey[200],
                    fontWeight: "bold"
                  }}
                >
                  {t("cc.table.processing")}
                </TableCell>
              </TableRow>
            )}
            size={"small"}
            headers={[
              t("cc.table.imported"),
              t("cc.table.valid"),
              t("cc.table.invalid"),
              t("cc.table.pending"),
              t("cc.table.success"),
              t("cc.table.errors"),
              t("cc.table.total_processed")
            ]}
            rows={[
              {
                imported: record?.cnt_rows,
                valid: record?.cnt_valid,
                invalid: record?.cnt_invalid,
                pending: record?.cnt_pending,
                success: record?.cnt_success,
                errors: record?.cnt_error,
                total: record?.cnt_total
              }
            ]}
          />
        </Grid>
      </Grid>
      <Box style={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
        <ButtonBack color="primary" variant="contained">
          {t("ra.action.back")}
        </ButtonBack>
      </Box>
    </>
  );
};

export default BulkPaymentGeneralTab;
