import { MuiTelInput, MuiTelInputContinent, MuiTelInputCountry, MuiTelInputInfo } from "mui-tel-input";
import { useInput } from "react-admin";

const PhoneInput = ({
  label,
  defaultCountry,
  variant,
  validate,
  disableDropdown,
  size,
  ...rest
}: {
  source: string;
  label: string;
  variant: "standard" | "filled" | "outlined";
  defaultCountry?: MuiTelInputCountry;
  disableDropdown?: boolean;
  validate?: any;
  size?: any;
}) => {
  const {
    field: { onChange, value, ...field },
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({
    ...rest,
    validate
  });

  const handleChange = (newValue: string, info: MuiTelInputInfo) => {
    onChange(newValue);
  };

  const continents: MuiTelInputContinent[] = ["AF"];
  const excludedCountries: MuiTelInputCountry[] = ["FR"];

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <MuiTelInput
      {...field}
      value={value}
      label={label}
      fullWidth
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error?.message : ""}
      onChange={handleChange}
      defaultCountry={defaultCountry}
      continents={continents}
      excludedCountries={excludedCountries}
      disableDropdown={disableDropdown}
      forceCallingCode
      variant={variant}
      required={isRequired}
      size={size}
    />
  );
};

export default PhoneInput;
