import config from "config";
import decodeJwt from "jwt-decode";
import { i18nProvider } from "../i18n/i18nProvider";
import {
  getStorageAgentId,
  getStorageMerchantCCId,
  getStoragePermissions,
  getStorageToken,
  getStorageUsername,
  removeStorageAgentId,
  removeStorageAlert,
  removeStorageEntityOrganisationExternalId,
  removeStorageEntityOrganisationExternalName,
  removeStorageMerchantCCId,
  removeStorageRefreshToken,
  removeStorageToken,
  removeStorageUsername,
  setStorageAgentId,
  setStorageMerchantCCId,
  setStoragePermissions,
  setStorageRefreshToken,
  setStorageToken,
  setStorageUsername
} from "utils/storageUtils";
import isTokenExpired from "utils/isTokenExpired";

const proxyBaseUrl: string = config.REACT_APP_PROXY_BASE_URL;

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }: { username: string; password: string }) => {
    // eslint-disable-next-line
    console.log("***********login***********");

    const requestOptions = {
      method: "GET"
    };

    try {
      const response = await fetch(
        `${proxyBaseUrl}/token?grant_type=password&username=${username}&password=${password}`,
        requestOptions
      );
      const { access_token, refresh_token, error_description } = await response.json();

      if (response.status < 200 || response.status >= 300) {
        throw new Error(error_description);
      }
      if (access_token && refresh_token) {
        const { roles, org, agentId }: { org?: string; roles: string[]; agentId?: string } =
          decodeJwt(access_token);

        const hasBomAccess = !!roles?.find((item) => item === "bom_access");

        if (hasBomAccess) {
          setStoragePermissions(roles);
          setStorageToken(access_token);
          setStorageRefreshToken(refresh_token);
          setStorageUsername(username);
          if (org) {
            setStorageMerchantCCId(org);
          }
          if (agentId) {
            setStorageAgentId(agentId);
          }
        } else {
          throw new Error("Unauthorized");
        }
      }
    } catch (error) {
      // TODO: Implement error handling
      if (error instanceof Error) {
        throw new Error(error?.message);
      } else {
        throw new Error("Bad Request");
      }
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    // eslint-disable-next-line
    console.log("***********logout***********");
    removeStorageToken();
    removeStorageRefreshToken();
    localStorage.removeItem("permissions");
    removeStorageUsername();
    removeStorageMerchantCCId();
    removeStorageAgentId();
    removeStorageAlert();
    removeStorageEntityOrganisationExternalName();
    removeStorageEntityOrganisationExternalId();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: { status: number }) => {
    // eslint-disable-next-line
    console.log("***********checkError***********");
    const token = getStorageToken() || "";
    const { exp }: { exp: number | undefined } = decodeJwt(token);

    const tokenExpired = isTokenExpired(exp);

    const removeUserCredentials = () => {
      removeStorageToken();
      removeStorageRefreshToken();
      removeStorageUsername();
      removeStorageMerchantCCId();
      removeStorageAgentId();
      removeStorageEntityOrganisationExternalName();
    };
    if (status === 401 || status === 403) {
      removeUserCredentials();

      return Promise.reject();
    }

    if (tokenExpired) {
      removeUserCredentials();

      return Promise.reject(i18nProvider.translate("notification.auth.token_expired"));
    }

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // eslint-disable-next-line
    console.log("***********checkAuth***********");

    return getStorageToken() ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    // eslint-disable-next-line
    console.log("***********getPermissions***********");
    const permissions = getStoragePermissions();
    return getStorageToken() && permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  // called to display user profile
  getIdentity: () => {
    try {
      const username = getStorageUsername();
      const agentId = getStorageAgentId();
      const merchantCCId = getStorageMerchantCCId();
      return Promise.resolve({ id: "", fullName: username, avatar: "", agentId, merchantCCId });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default authProvider;
