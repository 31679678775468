import { Chip as MUIChip, ChipProps } from "@mui/material";
import getColorByStatus from "utils/getColorByStatus";

interface Props extends ChipProps {
  label: string;
  status: string | boolean;
  variant?: "outlined" | "filled";
}

const Chip = ({ label, status, variant = "filled", sx, ...rest }: Props) => {
  return (
    <MUIChip
      {...rest}
      sx={{ ...sx, borderRadius: 1, paddindX: 2, fontWeight: "bold" }}
      label={label}
      color={getColorByStatus(status)}
      variant={variant}
      data-testid="chip-testid"
    />
  );
};

export default Chip;
