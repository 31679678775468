import { useQuery } from "react-query";
import { DOWNLOAD_ATTACHMENT } from "constants/dataProviderTypes";
import { useDataProvider, useRecordContext, useResourceContext } from "react-admin";
import { DocumentSearchObject } from "api/types/prlTypes";

const useQueryPrlAttachment = () => {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const record = useRecordContext();
  const { data, isLoading } = useQuery(
    [
      resource,
      DOWNLOAD_ATTACHMENT,
      {
        items: record?.attachments?.map((item: DocumentSearchObject) => {
          return {
            link: item?.documentUrl,
            id: item?.id,
            label: item?.documentLabel,
            name: item?.originalName
          };
        })
      }
    ],

    () =>
      dataProvider.downloadAttachment(resource, {
        items: record?.attachments?.map((item: DocumentSearchObject) => {
          return {
            link: item?.documentUrl,
            id: item?.id,
            label: item?.documentLabel,
            name: item?.originalName
          };
        })
      }),

    {
      enabled: record?.attachments?.length > 0
    }
  );

  return { data, isLoading };
};
export default useQueryPrlAttachment;
