import { useEffect, useState } from "react";
import { GET_BALANCE } from "constants/dataProviderTypes";
import { useDataProvider } from "react-admin";
import { getStorageMerchantCCId } from "utils/storageUtils";
import { useQuery } from "react-query";
import formatCurrency from "utils/formatCurrency";

//TODO: Write tests for useGetMerchantBalance (requires 13.1 version of @testing-library)
const useGetMerchantBalance = () => {
  const [balance, setBalance] = useState<string>("0");
  const dataProvider = useDataProvider();
  const merchantCCID = getStorageMerchantCCId();

  const { data, isLoading, isError } = useQuery(
    ["merchant", GET_BALANCE],
    () => dataProvider.getBalance("merchant", { id: merchantCCID }),
    {
      enabled: !!merchantCCID
    }
  );

  useEffect(() => {
    if (isLoading) {
      setBalance("...");
    } else if (isError) {
      setBalance("err");
    } else {
      setBalance(formatCurrency(data?.data?.balance));
    }
  }, [data, isLoading, isError]);

  return { balance };
};

export default useGetMerchantBalance;
