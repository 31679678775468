// in src/i18n/en.js
import englishMessages from "ra-language-english";

//Business Keywords - Special type of keywords usually specified by business team
const special = {
  prl: "Payment Request |||| Payment Requests",
  product: "Product |||| Products",
  kyc: "KYC Profile |||| KYC Profiles",
  history_and_report: "History & Report |||| History & Reports",
  payment_transaction: "Payment Transaction |||| Payment Transactions",
  payment_request_history: "Payment Request History",
  merchant_payment: "Merchant Payment |||| Merchant Payments",
  merchant: "Merchant",
  entity: "Entity |||| Entities",
  agent: "Agent KYC |||| Agents KYC",
  company: "Company KYC |||| Companies KYC",
  instruction: "Instruction |||| Instructions",
  tip: "Tip |||| Tips",
  collection: "Collection |||| Collections",
  bulk_collection: "Bulk Collection |||| Bulk Collections",
  disbursement: "Disbursement",
  bulk_payment: "Bulk Payment |||| Bulk Payments",
  company_prl: "Merchant Payment",
  remittance: "Remittance",
  send: "Send",
  history: "History",
  agent_payments: "Agent Payments",
  agent_payments_home: "Home",
  agent_payments_history: "History",
  reports: "Reports"
};

// Common keywords used in application
// All values inside common object must match it's key
const common = {
  next: "Next",
  add: "Add",
  additional_information: "Additional Information",
  amount: "Amount",
  copy: "Copy",
  another: "Another",
  select: "Select",
  my_profile: "My Profile",
  save: "Save",
  cancel: "Cancel",
  export_pdf: "Export PDF",
  export_excel: "Export Excel",
  money_information: "Money Information",
  delivery_channel: "Delivery Channel",
  delivery_information: "Delivery Information",
  age_must_be_18_or_above: "Age must be 18 or above",
  close: "Close",
  confirm: "Confirm",
  pdf_external: "PDF External",
  pdf_internal: "PDF Internal",
  country: "Country",
  region: "Region",
  more: "More",
  city: "City",
  street: "Street",
  address_type: "Address Type",
  address: "Address",
  state: "State",
  building_name: "Building Name",
  postal_code: "Postal Code",
  valid: "Valid",
  approved: "Approved",
  draft: "Draft",
  total_processed: "Total Processed",
  article_number: "Article Number",
  documents: "Documents",
  reason: "Reason",
  attachment: "Attachment",
  recipients_bank_details: "Recipient Bank Details",
  recipients_mobile_money_details: "Recipient Mobile Money Details",
  attachments: "Attachments",
  errors: "Errors",
  processed: "Processed",
  pre_validated: "Pre Validated",
  imported: "Imported",
  invalid: "Invalid",
  await_status_update: "Awaiting Status Updates",
  back: "Back",
  comment: "Comment",
  company_address: "Company Address",
  company_name: "Company Name",
  completed: "Completed",
  copied: "Copied",
  create: "Create",
  created: "Created",
  created_at: "Created At",
  customer_comment: "Customer Comment |||| Customer Comments",
  customer_information: "Customer Information",
  date: "Date",
  debited: "Debited",
  description: "Description",
  details: "Details",
  account_holder_number: "Account Holder Number",
  account_number: "Account Number",
  amount_and_delivery_method: "Amount & Delivery Method",
  recipient_information: "Recipient Information",
  email: "Email",
  empty: "Empty",
  ended: "Ended",
  error: "Error",
  errored: "Errored",
  errored_refunded: "Errored Refunded",
  failed: "Failed",
  failure: "Failure",
  fee: "Fee",
  fees: "Fees",
  first_name: "First Name",
  fulfilled: "Fulfilled",
  history: "History",
  importing_data: "Importing Data",
  in_process: "In Process",
  reversed: "Reversed",
  internal_comment: "Internal Comment  |||| Internal Comments",
  last_name: "Last Name",
  name: "Name",
  updated: "Updated",
  new: "New",
  not_paid: "Not Paid",
  open: "Open",
  total_available_balance: "Total Available Balance",
  order_reference: "Order Reference",
  order_status: "Order Status",
  order_total_amount: "Order Total Amount",
  transaction_details: "Transaction Details",
  payment_details: "Payment Details",
  paid: "Paid",
  paid_at: "Paid At",
  partial_success: "Partial Success",
  payment_link: "Payment Link",
  payment_method: "Payment Method",
  payment_provider: "Payment Provider",
  provider: "Provider",
  payment_status: "Payment Status",
  pending: "Pending",
  phone: "Phone",
  phone_number: "Phone Number",
  mobile_money: "Mobile Money",
  cash_pickup: "Cash Pickup",
  bank_transfer: "Bank Transfer",
  deleted: "Deleted",
  price: "Price",
  processing: "Processing",
  product_information: "Product Information",
  product_name: "Product Name",
  profile: "Profile",
  ptn: "PTN",
  mtnc: "MTNC",
  time: "Time",
  user: "User",
  quantity: "Quantity",
  ready: "Ready",
  title: "Title",
  download: "Download",
  print: "Print",
  downloaded: "Downloaded",
  download_template: "Download Template",
  reference: "Reference",
  reference_number: "Reference Number",
  rejected: "Rejected",
  remove: "Remove",
  status: "Status",
  started_at_from: "Started At From",
  ended_at_from: "Ended At From",
  ended_at_to: "Ended At To",
  started_at_to: "Started At To",
  invalid_image_dimensions: "Invalid Image Dimensions",
  success: "Success",
  service_id: "Service ID",
  display_name: "Display Name",
  send: "Send",
  receive: "Receive",
  customer_name: "Customer Name",
  customer_reference: "Customer Reference",
  trid: "TrID",
  service_number: "Service Number",
  exchange_rate: "Exchange Rate",
  audit: "Audit",
  comments: "Comments",
  general_information: "General Information",
  entity_id: "Entity ID",
  middle_name: "Middle Name",
  gender: "Gender",
  date_of_birth: "Date Of Birth",
  nationality: "Nationality",
  business_sector: "Business Sector",
  document: "Document",
  document_type: "Document Type",
  contact_information: "Contact Information",
  other_address: "Other Address",
  business_address: "Business Address",
  place_of_business: "Place Of Business",
  id_number: "ID Number",
  date_of_issue: "Date Of Issue",
  phone_alt: "Phone Alt",
  phone_main: "Phone Main",
  phone_second: "Phone Second",
  place_of_issue: "Place Of Issue",
  personal_information: "Personal Information",
  internal_comments: "Internal Comments",
  customer_comments: "Customer Comments",
  last_modified: "Last Modified",
  payment_history: "Payment History",
  service_label: "Service Label",
  submitted_at: "Submitted At",
  message: "Message",
  identification_documents: "Identification Documents",
  national_id: "National ID",
  tax_id: "Tax ID",
  birth_certificate: "Birth Certificate",
  citizenship: "Citizenship",
  drivers_licence: "Drivers License",
  uploading: "Uploading",
  merchant_logo: "Merchant Logo",
  company_id: "Company ID",
  approve: "Approve",
  agent_id: "Agent ID",
  loading: "Loading",
  file: "File",
  started: "Started",
  passport: "Passport",
  reject: "Reject",
  create_another: "Create Another",
  bulk_payment_request: "Bulk Payment Request",
  supported_extensions: "Supported Extensions",
  sign_in: "Sign In",
  started_at: "Started At",
  ended_at: "Ended At",
  localization: "Localization",
  total: "Total",
  total_amount: "Total Amount",
  transaction_amount: "Transaction Amount",
  transaction_status: "Transaction Status",
  under_investigation: "Under Investigation",
  uploaded: "Uploaded",
  validation_error: "Validation Error",
  salary: "Salary",
  pension: "Pension",
  investment: "Investment",
  school_fees: "School Fees",
  house_rent: "House Rent",
  insurance: "Insurance",
  allowance: "Allowance",
  gift: "Gift",
  mother: "Mother",
  father: "Father",
  uncle: "Uncle",
  sibling: "Sibling",
  aunt: "Aunt",
  logout: "Logout",
  source_of_funds: "Source of Funds",
  relation_to_recipient: "Relation to Recipient",
  senders_information: "Sender Information",
  your_transaction_has_been_successfully_processed: "Your transaction has been successfully processed",
  share: "Share",
  username: "Username",
  password: "Password",
  canceled: "Canceled",
  commentable: "Commentable",
  expired: "Expired",
  payment_link_information: "Payment Link Information",
  active: "Active",
  review_details: "Review Details",
  inactive: "Inactive",
  sender_amount: "Sender Amount",
  delivery_method: "Delivery Method",
  recipient_amount: "Recipient Amount",
  bank: "Bank",
  select_country: "Select Country",
  sender_details: "Sender Details",
  id_type: "ID Type",
  expiry_date: "Expiry Date",
  enable: "Enable",
  disable: "Disable",
  enabled: "Enabled",
  disabled: "Disabled",
  merchant_specific_code: "Merchant specific code",
  male: "Male",
  female: "Female",
  other: "Other",
  invalid_format: "Invalid format",
  date_of_birth_invalid: "Date of birth is invalid: Cannot be in the future",
  document_1: "Document 1",
  document_2: "Document 2",
  occupation: "Occupation",
  transaction_confirmation: "Transaction Confirmation",
  please_confirm_the_payment_of: "Please confirm the payment of",
  total_amount_to_pay: "Total amount to pay",
  select_delivery_channel: "Select Delivery Channel",
  // TODO: (delete) The line below does not follow the pattern of the common project
  download_printable_qr_code: "Download QRcode %{format}",
  sender_sends: "Sender Sends",
  or: "or",
  recipient_gets: "Recipient Gets",
  select_delivery_method: "Select Delivery Method",
  select_bank: "Select Bank",
  select_provider: "Select Provider",
  recipient_address: "Recipient Address",
  service_charge: "Service Charge",
  recipient_details: "Recipient Details",
  amount_and_delivery_methods: "Amount and Delivery Methods",
  // TODO: To be renamed, key bellow doesn't follow the pattern
  context_switched: "User Context Switched",
  company_prl_enabled: "Company Prl Enabled",
  company_prl_disabled: "Company Prl Disabled",
  global_context: "Global Context",
  list: "List",
  edit: "Edit",
  show: "Show",
  home: "Home",
  how_to_pay: "How to pay",
  steps_to_follow: "Steps to follow",
  search: "Search",
  results: "Results",
  Please_provide_those_informations: "Please provide those informations",
  optional: "Optional",
  payment_confirmation: "Payment Confirmation",
  biller: "Biller",
  service: "Service",
  due_date: "Due Date",
  generation_date: "Generation Date",
  payment_reference: "Payment Reference",
  biller_number: "Biller Number",
  contract_number: "Contract Number",
  service_amount: "Service Amount",
  proceed: "Proceed",
  authentification_proof: "Authentification Proof",
  signature_type: "Signature Type",
  error_type: "Error Type",
  error_message: "Error Message",
  error_code: "Error Code",
  error_log: "Error Log",
  log: "Log",
  select_all: "Select all",
  unselect_all: "Unselect all",
  clear_selection: "Clear selection",
  company: "Company",
  download_csv: "Download CSV",
  download_excel: "Download Excel",
  month: "Month",
  year: "Year",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  rsp_report: "RSP Report",
  management_reports: "Management reports",
  regulatory_reports: "Regulatory reports",
  start_date: "Start date",
  end_date: "End date",
  collector_company: "Collector Company",
  transaction_report: "Transaction Report",
  reports: "Reports",
  you_are_about_to_trigger_a_transaction: "You are about to trigger a transaction",
  please_confirm_collection_of: "Please confirm payment of",
  filter: "Filter"
};

// Common fields inside app
const fields = {
  ...common,
  //Special case of fields (e.g. name and value are totally different or name casing is pascalCase)
  ref: common.reference,
  createdAt: common.created_at,
  customerCompanyAddress: common.company_address,
  mpay_service_label: common.service_label,
  customerCompanyName: common.company_name,
  customerNameFirst: common.first_name,
  customerNameLast: common.last_name,
  customerPhone: common.phone_number,
  desciption: common.description,
  isFulfilled: common.fulfilled,
  mpay_serviceId: common.service_id,
  nameFirst: common.first_name,
  nameLast: common.last_name,
  orderReference: common.order_reference,
  orderStatus: common.order_status,
  orderTotalAmount: common.order_total_amount,
  paymentProvider: common.payment_provider,
  paymentStatus: common.payment_status,
  phonenumber: common.phone_number,
  transactionAmount: common.transaction_amount,
  transactionStatus: common.transaction_status,
  recipient_first_name: common.first_name,
  recipient_last_name: common.last_name,
  recipient_phone_number: common.phone_number,
  recipient_email: common.email,
  recipient_middle_name: common.middle_name,
  recipient_city: common.city,
  recipient_country: common.country,
  recipient_state: common.state,
  sender_first_name: common.first_name,
  sender_last_name: common.last_name,
  sender_phone_number: common.phone_number,
  sender_email: common.email,
  sender_date_of_birth: common.date_of_birth,
  sender_gender: common.gender,
  sender_date_of_issue: common.date_of_issue,
  sender_source_of_funds: common.source_of_funds,
  sender_reason: common.reason,
  sender_relationship_to_recipient: common.relation_to_recipient,
  sender_id_type: common.id_type,
  sender_id_number: common.id_number,
  sender_place_of_issue: common.place_of_issue,
  sender_expiry_date: common.expiry_date,
  sender_address: common.address,
  sender_middle_name: common.middle_name,
  sender_city: common.city,
  sender_country: common.country,
  sender_state: common.state
};

export const en = {
  ...englishMessages,

  resources: {
    merchant: {
      name: special.merchant,
      fields: {
        ...fields
      }
    },
    entity: {
      name: special.kyc,
      fields: {
        ...fields,
        birt_cert: common.birth_certificate,
        //Duplication is presented here as filters are not stored inside name object
        name: {
          givenName: common.first_name,
          familyName: common.last_name,
          displayName: common.display_name
        }
      }
    },
    product: {
      name: special.product,
      fields: {
        ...fields,
        // Unique name based on resource
        productExtId: common.article_number
      }
    },
    prl: {
      name: special.prl,
      fields: {
        ...fields,
        customer: {
          nameFirst: fields.nameFirst,
          nameLast: fields.nameLast,
          phonenumber: fields.phonenumber,
          customerCompanyName: fields.customerCompanyName,
          customerCompanyAddress: fields.customerCompanyAddress,
          email: fields.email
        }
      }
    },
    remittance: {
      name: special.remittance,
      fields: {
        ...fields,
        recipient_name_must_match_account_holders_name: "Recipient name must match account holder name",
        recipient_name_must_match_valid_id: "Recipient name must match valid ID",
        please_ensure_all_information_above_is_correct_then_confirm_and_collect_the_total_amount_from_the_customer:
          "Please ensure all information above is correct, then CONFIRM and COLLECT the total amount from the customer.",
        send_sms_notification: "Send SMS notification about this transfer",
        unsupported_file_type_please_upload_a_pdf_jpg_or_png:
          "Unsupported file type: Please upload a pdf, jpg or png file",
        enter_senders_name: "Enter sender name as it appears on valid ID",
        senders_address: "Sender Address",
        senders_id_info: "Sender Identification Information",
        email_notification: "Notification about this transfer will be sent to your email address if provided",
        your_transaction_is_not_yet_processed:
          "Your transaction is not yet processed. A print of this page is not a receipt",
        please_take_a_moment: "Please take a moment to review the information before submitting",
        your_id_has_expired: "Your ID has expired: Transaction cannot be processed",
        issue_and_expired_cannot_be_identical: "Issue and expiry date cannot be identical",
        expiry_date_cannot_be_less_than_issue_date:
          "Expiry date cannot be less than issue date: Enter a valid date",
        issue_date_invalid: "Issue date is invalid: date must be before today",
        drop_a_file: "drag and drop some file here or click to select file",
        supported_remittance_files: "Supported extensions: .pdf .jpeg .png",
        invalid_format_max_lenght: "This field cannot contain more than 35 characters.",
        invalid_format_non_alphabetic: "This field cannot contain non-alphabetic.",
        invalid_format_alphabetic_accent: "This field cannot contain accented characters",
        recipient_bank_details: "Recipient bank details",
        invalid_phone_number: "Phone number not valid: Enter a valid phone number",
        invalid_account_number: "Account number not valid: Enter a valid account number",
        maviance_internal_report: "Maviance Internal Report",
        detailed_management_report: "Detailed management report",
        detailed_management_report_description:
          "To view a report, simply choose the report and filter from the quick links that you will see",
        download_report_instruction: "To view a report, simply  select the start and end date and download",
        transaction_report_description:
          "To view a report, simply  select the start and end date and download",
        error_while_fetching_config_data:
          "An error occurred while fetching configuration data. Please try again later or contact Smobilpay customer service.",
        error_occured: "An error occurred. Please try again later or contact Smobilpay customer service."
      }
    },
    "company-prl": {
      fields: {
        ...fields,
        download_printable_qr_code: "Download QRcode %{format}"
      }
    },
    "payment-transaction": {
      name: special.payment_transaction,
      fields: {
        ...fields
      }
    },
    "payment-report": {
      name: special.payment_request_history,
      fields: {
        ...fields
      }
    },
    bulk_payment: {
      name: special.bulk_payment,
      fields: {
        ...fields
      }
    },
    agent_payments: {
      fields: {
        ...fields,
        transaction_secondary_text: "Please ensure the payment details are correct before confirming!",
        any_errors_after_this_step_cannot_be_corrected: "Any errors after this step cannot be corrected!",
        the_payment_was_successfully_processed: "The payment was successfully processed",
        this_payment_could_not_be_processed: "This payment could not be processed",
        the_payment_is_being_processed: "The payment is being processed",
        please_wait_for_the_confirmation: "Please wait for the confirmation",
        due_date_has_passed_of: "Due date has passed of %{smart_count}",
        your_bill_has_expired: "Your bill has expired",
        due_date: "Due date - %{smart_count}",
        your_bill_is_due_soon: "Your bill is due soon",
        please_pay_now_to_avoid_being_cut_off: "Please pay now to avoid being cut off",
        payment_request_created: "Payment request created",
        service_image_alt_text: "Service image alt text"
      }
    }
  },

  // custom components
  cc: {
    button: {
      ...common,
      create_product: `${common.create} ${special.product}`,
      create_bulk_payment: `${common.create} ${special.bulk_payment}`,
      add_comment: `${common.add} ${common.comment}`
    },
    dropzone: {
      title: "Drop files or click to select",
      supported_extensions: common.supported_extensions
    },
    card: {
      ...fields,
      attachment: `${common.attachment} |||| ${common.attachments}`,
      empty: common.empty,
      comment: `${common.comment} |||| ${common.comments}`,
      document: `${common.document} |||| ${common.documents}`,
      logo_merchant: common.merchant_logo
    },
    table: { ...fields },
    breadcrumbs: {
      ...common,
      ...special
    },
    tab: {
      ...fields
    },
    typography: {
      auth: {
        rights: "all rights reserved",
        statement: "Smobilpay e-commerce is a registered trademark of the maviance PLC.",
        powered: "Powered by",
        terms: "Privacy and terms"
      },
      bulk_payment: {
        step_1: "Step One: Configure Payment Request",
        step_2: "Step Two: Upload Payment File",
        must_be_unique: "Must be unique"
      },
      merchant: {
        dimension_requirement: "Dimensions must be 125x100"
      }
    },
    tooltip: {
      ...fields,
      unique_reference: "Enter a unique text name for bulk payment reference"
    },
    dialog: {
      ...fields,
      approve_text: "Do you want to start processing the payment?",
      reject_text: "Please provide reason for rejection",
      payment_confirmation: "Payment Confirmation",
      bill_confirm_text: "Please confirm the payment of",
      bill_cancel_text: "Do you want to cancel this transaction?"
    },
    accordion: {
      ...fields
    },
    notification: {
      ...englishMessages.ra.notification,
      ...common,
      // Combining pluralization key with another one works only if you add key which contains pluralization at the end
      uploaded_attachment: `${common.uploaded} ${common.attachment}`,
      updated_prl: `${common.updated} ${special.prl}`,
      updated_product: `${common.updated} ${special.product}`,
      updated_profile: `${common.updated} ${common.profile}`,
      updated_attachment: `${common.updated} ${common.attachment}`,
      created_prl: `${common.created} ${special.prl}`,
      created_bulk_payment: `${common.created} ${special.bulk_payment}`,
      created_product: `${common.created} ${special.product}`,
      deleted_attachment: `${common.deleted} ${common.attachment}`,
      approved_bulk_payment: `${common.approved} ${special.bulk_payment}`,
      rejected_dropzone:
        "The file you are trying to upload has an unsupported file format. Only files of the type %{supportedExtensions} can be uploaded.",
      rejected_bulk_payment: `${common.rejected} ${special.bulk_payment}`
    },
    status: {
      success: common.success,
      approved: common.approved,
      completed: common.completed,
      paid: common.paid,
      failure: common.failure,
      uploading: common.uploading,
      errored: common.errored,
      rejected: common.rejected,
      pending: common.pending,
      not_paid: common.not_paid,
      open: common.open,
      debited: common.debited,
      in_process: common.in_process,
      under_investigation: common.under_investigation,
      errored_refunded: common.errored_refunded,
      created: common.created,
      ready: common.ready,
      error: common.error,
      partial_success: common.partial_success,
      processing: common.processing,
      validation_error: common.validation_error,
      ended: common.ended,
      await_status_update: common.await_status_update,
      importing_data: common.importing_data,
      new: common.new,
      failed: common.failed,
      draft: common.draft
    },

    alert: {
      tip: {
        title: special.tip,
        prl: {
          tip_1: "You can add one or more products for a PRL just by clicking on the [+] button",
          tip_2:
            "You can create a PRL with a new product (not existing in your product list) or with products existing in your product list ",
          tip_3:
            "By clicking on the (Name) field you can enter a completely new product or select an existing product from the dropdown list",
          tip_4:
            "Customer information are not mandatory but if you decide to provide those information then (last name - first name - phone number) are mandatory fields "
        }
      },
      instruction: {
        title: special.instruction,
        bulk_payment: {
          title: "Instructions",
          subtitle: "Please include the following headers in the first row of your file",
          step_1:
            "Phone Number - Required. The recipient’s phone number in international format, but no plus sign. e.g : 237695187645",
          step_2:
            "Amount - Required. The amount to be sent to each recipient. Format it as a number, not text.",
          step_3: "Description - Required. The description of the payment. ",
          step_4: "First Name - Optional. The first name of the recipient.",
          step_5: "Last Name - Optional. The lastname of the recipient.",
          step_6: "Reference - Optional. The reference"
        },
        prl: {
          step_1: "Click on + (Add Product) under Product Information section",
          step_2: "Fill information about (Name - Description - Price - Quantity) of the product",
          step_3: "Fill information about the customer",
          step_4: "Fill (Order reference) if necessary (this step is not mandatory)",
          step_5:
            "Enter a (label) name and attach one or more images about the product if necessary (this step is not mandatory)",
          step_6: "Click on Save"
        }
      }
    },

    modal_success: {
      instruction: {
        title: "Note",
        description:
          "Copy the link generated on the right corner to share with friends & customers on web and social network to help them purchase the item"
      },
      download_qrcode: "Download QR code"
    },
    merchant_balance: {
      title: common.total_available_balance
    },

    menu: {
      ...special,
      my_profile: common.my_profile,
      logout: common.logout
    },
    dashboard: {
      ...special,
      title_1: "Welcome to your",
      subtitle: "DIGITAL PAYMENT",
      title_2: "Platform",
      access_denied:
        "Access denied! The page or functionality you are trying to access requires permissions you do not have"
    },

    expand_panel: {
      bulk_payment: {
        reason: common.reason,
        pre_validated: common.pre_validated,
        processing: common.processing,
        imported: common.imported,
        valid: common.valid,
        total_processed: common.total_processed,
        invalid: common.invalid,
        pending: common.pending,
        success: common.success,
        error: `${common.error} |||| ${common.errors}`,
        processed: common.processed
      }
    },

    share: {
      prl: {
        whatsapp: {
          message:
            "Dear Customer, \n \n You would like to pay %{amount} FCFA for a purchase done with %{merchantName}. Please click on the link below and proceed to make the payment securely. \nQuick Payment Link: %{link}. \nThank you for your confidence, \n \n Maviance"
        },

        email: {
          body: "Dear Customer, \n \n You would like to pay %{amount} FCFA for a purchase done with %{merchantName}. Please click on the link below and proceed to make the payment securely. \n\t-Quick Payment Link: %{link} \n\t-Merchant Name: %{merchantName} \n\t-Merchant Address: %{merchantAddress} . \n\nThank you for your confidence, \n \n Maviance",
          subject: "Hello %{fullName} please pay with this link"
        }
      },

      product: {
        whatsapp: {
          message:
            "Dear Customer, \n \n You would like to make a purchase with %{merchantName}. Please click on the link below and proceed to make the payment securely. \nQuick Payment Link: %{link}. \nThank you for your confidence, \n \n Maviance"
        },
        email: {
          subject: "Hello! please pay with this link",
          body: "Dear Customer, \n \n You would like to make a purchase with %{merchantName}. Please click on the link below and proceed to make the payment securely. \n\t-Quick Payment Link: %{link} \n\t-Merchant Name: %{merchantName} \n\t-Merchant Address: %{merchantAddress} . \n\nThank you for your confidence, \n \n Maviance"
        }
      }
    }
  },
  //  validation
  validation: {
    ...englishMessages.ra.validation,
    alpha: "Must match alphabetic characters."
  }
};
