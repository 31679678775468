import { Switch } from "@mui/material";
import { SwitchFulfilledTypes } from "components/types/otherComponentTypes";
import { useRecordContext } from "react-admin";
import ChipStatus from "./StatusField";

const SwitchFulfilled: React.FC<SwitchFulfilledTypes> = ({ onToggle, dataTestId }) => {
  const record = useRecordContext();
  return (
    <>
      {record?.paymentStatus === "SUCCESS" ? (
        <Switch checked={record?.isFulfilled} onClick={onToggle} data-testid="fulfilled-switch-button" />
      ) : (
        <ChipStatus label="Fulfilled" source="isFulfilled" dataTestId={dataTestId} />
      )}
    </>
  );
};

export default SwitchFulfilled;
