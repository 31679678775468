import React from "react";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { useMutation, useQueryClient } from "react-query";
import queryErrorHandler from "api/queryErrorHandler";
import { DOWNLOAD_COMPANY_PRL_QRCODE } from "constants/dataProviderTypes";
import { useDataProvider, useNotify, useRecordContext, useTranslate } from "react-admin";
import { getStorageMerchantCCId } from "utils/storageUtils";

type Props = {
  format: "A4" | "A5";
  variant: "contained" | "outlined";
  disabled?: boolean;
};
const DownloadButton = ({ format, variant, disabled = false }: Props) => {
  const t = useTranslate();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const merchantCoreCompanyId = getStorageMerchantCCId();
  const queryClient = useQueryClient();

  const { mutate: downloadQrCode } = useMutation(
    ["merchant", DOWNLOAD_COMPANY_PRL_QRCODE],
    () =>
      dataProvider.downloadQrCode("merchant", {
        ccid: merchantCoreCompanyId,
        data: format
      }),
    {
      onSuccess: async (res: { data: string }) => {
        saveAs(res.data, record?.name?.displayName);
        await queryClient.invalidateQueries(["merchant"]);
        notify("cc.notification.downloaded", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  return (
    <Button
      disableElevation
      style={{ fontWeight: "bold" }}
      data-testid={"download-qrcode-" + format}
      variant={variant}
      onClick={() => downloadQrCode()}
      startIcon={<Download />}
      disabled={disabled}
    >
      {t(`resources.${["company-prl"]}.fields.download_printable_qr_code`, { format })}
    </Button>
  );
};

export default DownloadButton;
