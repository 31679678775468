import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Create, CreateProps } from "react-admin";
import ProductCreateEditForm from "../Form/ProductCreateEditForm";
import { Toolbar } from "@mui/material";
import { FORM_TYPE_CREATE } from "constants/formTypes";

const ProductCreate = (props: CreateProps) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"product"} page={"create"} />
      </Toolbar>
      {/*FIXME: Fix product details alignment with action buttons*/}
      <Create component={"div"} {...props} resource="product">
        <ProductCreateEditForm formType={FORM_TYPE_CREATE} />
      </Create>
    </>
  );
};

export default ProductCreate;
