import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { RaRecord, useRecordContext, UseRecordContextParams } from "react-admin";
import formatDate from "utils/formatDate";

const styles = {
  text: {
    fontSize: 14
  }
};

const PayoutRequestActionDate = (props: UseRecordContextParams<RaRecord>) => {
  const { classes } = props;
  const record = useRecordContext(props);

  const statusChoice = (currStatus: string) => {
    switch (currStatus) {
      case "NEW":
      case "PENDING":
        return false;
      default:
        return true;
    }
  };
  return (
    <Typography className={classes.text}>
      {statusChoice(record?.status) && formatDate(record?.updatedAt)}
    </Typography>
  );
};

export default withStyles(styles)(PayoutRequestActionDate);
