import { CloseOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  colors,
  DialogProps
} from "@mui/material";
import { useState } from "react";
import GridSelectionActions, { IGridSelectionPageData } from "./GridSelectionActions";
import { useTranslate } from "react-admin";
import { clearSelection } from "../utils/clearSelection";

interface IGridDialog extends DialogProps {
  open: boolean;
  onClose?: () => void;
  data: IGridSelectionPageData[];
  activeTab: any;
  setActiveTab: any;
  setData: (data: IGridSelectionPageData[]) => void;
}

const GridSelector = ({ open, onClose, data, activeTab = {}, setActiveTab, setData }: IGridDialog) => {
  const [maxWidth] = useState<DialogProps["maxWidth"]>("xl");
  const [searchValue, setSearchValue] = useState("");
  const t = useTranslate();

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const setAllCheck = (checked: boolean) => {
    const temp = data[activeTab?.index]?.options?.map((item) => ({ ...item, isChecked: checked }));

    const tempOp = data;
    tempOp[activeTab?.index] = {
      ...tempOp[activeTab?.index],
      isSelectAll: checked,
      options: temp
    };

    setData([...tempOp]);
  };

  const filterOptions = (term: string) => {
    const tempOptions = data[activeTab?.index || 0]?.options?.filter((item) =>
      item.label.toLowerCase().includes(term.toLowerCase())
    );

    return tempOptions;
  };

  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose}>
      <Box height={"80vh"} position={"relative"}>
        <Box sx={{ position: "absolute", right: 5, top: 10 }}>
          <Button onClick={onClose}>
            <CloseOutlined />
          </Button>
        </Box>
        <GridSelectionActions
          my={4}
          ml={4}
          data={data}
          onClearSelection={() => clearSelection(data, setData)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Divider />
        <Box px={4} pt={2} pb={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data[activeTab?.index]?.isSelectAll}
                    onChange={(event) => {
                      setAllCheck(event.target.checked ? true : false);
                    }}
                  />
                }
                label={t(`resources.${["remittance"]}.fields.select_all`)}
              />
              <Button
                onClick={() => {
                  setAllCheck(false);
                }}
                startIcon={<CloseOutlined />}
                sx={{ color: colors.grey[900] }}
              >
                {t(`resources.${["remittance"]}.fields.unselect_all`)}
              </Button>
            </Box>
            <TextField
              InputProps={{
                endAdornment: <Search sx={{ color: colors.cyan[600] }} />
              }}
              sx={{ width: 600 }}
              value={searchValue}
              placeholder={`${t(`resources.${["remittance"]}.fields.search`)}...`}
              onChange={onSearchInputChange}
              variant={"outlined"}
            />
          </Box>
          <Paper sx={{ padding: 2, minHeight: "50vh" }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
              {filterOptions(searchValue)?.map((option) => (
                <Grid item xs={2} sm={4} md={2} key={option.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          const tempData = data;
                          const tempOp = tempData[activeTab?.index]?.options;
                          const optionN: any = tempOp.find((item) => item.id === option.id);
                          if (!optionN) return;
                          const realIndex = tempOp.indexOf(optionN);
                          tempOp[realIndex] = {
                            ...tempOp[realIndex],
                            isChecked: !option.isChecked
                          };
                          tempData[activeTab?.index] = {
                            ...tempData[activeTab?.index],
                            options: tempOp
                          };
                          setData([...tempData]);
                        }}
                      />
                    }
                    label={option?.label}
                    checked={option?.isChecked}
                    sx={{
                      wordWrap: "anywhere"
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Dialog>
  );
};

export default GridSelector;