// in src/MyMenu.js
import { Menu, useDataProvider, useGetOne, usePermissions, useTranslate } from "react-admin";
import { getStorageMerchantCCId } from "utils/storageUtils";
import { PaymentsOutlined } from "@mui/icons-material";
import {
  AGENT_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_PRL_SERVICE,
  COMPANY_SERVICE,
  MERCHANT_SERVICE,
  PAYMENT_REQUEST_HISTORY_SERVICE,
  PAYMENT_TRANSACTION_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE,
  REMITTANCE_HISTORY_SERVICE,
  REMITTANCE_SEND_SERVICE,
  AGENT_PAYMENTS_HOME_SERVICE,
  AGENT_PAYMENTS_HISTORY_SERVICE,
  REMITTANCE_REPORTS_SERVICE
} from "constants/serviceNames";
import renderServiceIcon from "utils/renderServiceIcon";
import { ChildMenuType, ParentMenuType } from "components/ra/other/BomLayout/components/MenuDrawer/types";
import {
  Box,
  CircularProgress,
  Collapse,
  colors,
  Divider,
  List,
  ListSubheader,
  Typography
} from "@mui/material";
import ExpandableButtonItem from "components/ra/other/BomLayout/components/MenuDrawer/components/ExpandableButtonItem";
import ButtonItem from "components/ra/other/BomLayout/components/MenuDrawer/components/ButtonItem";
import getServiceAccess from "utils/getServiceAccess";
import {
  DISBURSEMENT_CATEGORY,
  HISTORY_AND_REPORT_CATEGORY,
  KYC_CATEGORY,
  MERCHANT_PAYMENTS_CATEGORY,
  REMITTANCE_CATEGORY,
  AGENT_PAYMENTS_CATEGORY
} from "constants/categoryNames";
import renderServiceTitle from "utils/renderServiceTitle";
import { useQuery } from "react-query";
import { GET_FILE } from "constants/dataProviderTypes";

export const MenuDrawer = () => {
  const merchantCcId = getStorageMerchantCCId();
  const t = useTranslate();
  const { data: merchantData } = useGetOne(
    "merchant",
    {
      id: merchantCcId
    },
    { enabled: !!merchantCcId, refetchOnWindowFocus: false }
  );
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const { data: dataLogo, isLoading: isLoadingLogo } = useQuery(
    ["merchant", GET_FILE],
    () => dataProvider.getLogo("merchant", { id: merchantData?.merchantId }),
    { enabled: !!merchantData?.merchantId, refetchOnWindowFocus: false }
  );

  const items: ParentMenuType[] = [
    {
      title: renderServiceTitle(KYC_CATEGORY, t),
      icon: renderServiceIcon(KYC_CATEGORY),
      depth: 0,
      dataTestId: "kyc-menu-item",
      nested: [
        {
          title: renderServiceTitle(AGENT_SERVICE, t),
          nested: [],
          depth: 1,
          serviceName: AGENT_SERVICE,
          dataTestId: "agent-menu-item",
          icon: renderServiceIcon(AGENT_SERVICE),
          to: "/entity_agent"
        },
        {
          title: renderServiceTitle(COMPANY_SERVICE, t),
          serviceName: COMPANY_SERVICE,
          depth: 1,
          nested: [],
          dataTestId: "company-menu-item",
          icon: renderServiceIcon(COMPANY_SERVICE),
          to: "/entity_company"
        }
      ]
    },
    {
      title: renderServiceTitle(MERCHANT_PAYMENTS_CATEGORY, t),
      icon: renderServiceIcon(MERCHANT_PAYMENTS_CATEGORY),
      depth: 0,
      dataTestId: "merchant_payment-menu-item",
      nested: [
        {
          icon: renderServiceIcon(PRODUCT_SERVICE),
          title: renderServiceTitle(PRODUCT_SERVICE, t),
          serviceName: PRODUCT_SERVICE,
          dataTestId: "product-menu-item",
          depth: 1,
          to: "/product"
        },
        {
          icon: renderServiceIcon(PRL_SERVICE),
          depth: 1,
          title: renderServiceTitle(PRL_SERVICE, t),
          serviceName: PRL_SERVICE,
          dataTestId: "prl-menu-item",
          to: "/prl"
        },
        {
          icon: renderServiceIcon(COMPANY_PRL_SERVICE),
          title: renderServiceTitle(COMPANY_PRL_SERVICE, t),
          serviceName: COMPANY_PRL_SERVICE,
          dataTestId: "company_prl-menu-item",
          depth: 1,
          to: `/company-prl/${merchantCcId}`
        },
        {
          icon: renderServiceIcon(HISTORY_AND_REPORT_CATEGORY),
          depth: 1,
          title: renderServiceTitle(HISTORY_AND_REPORT_CATEGORY, t),
          dataTestId: "history_and_reports-menu-item",
          nested: [
            {
              serviceName: MERCHANT_SERVICE,
              title: renderServiceTitle(PAYMENT_TRANSACTION_SERVICE, t),
              dataTestId: "payment_transaction-menu-item",
              depth: 2,
              icon: renderServiceIcon(PAYMENT_TRANSACTION_SERVICE),
              to: "/payment-transaction"
            },
            {
              serviceName: MERCHANT_SERVICE,
              title: renderServiceTitle(PAYMENT_REQUEST_HISTORY_SERVICE, t),
              dataTestId: "payment_request-history-menu-item",
              depth: 2,
              icon: renderServiceIcon(PAYMENT_REQUEST_HISTORY_SERVICE),
              to: "/payment-report"
            }
          ]
        }
      ]
    },
    {
      icon: renderServiceIcon(DISBURSEMENT_CATEGORY),
      title: renderServiceTitle(DISBURSEMENT_CATEGORY, t),
      depth: 0,
      dataTestId: "disbursement-menu-item",
      nested: [
        {
          dataTestId: "bulk_payment-menu-item",
          title: renderServiceTitle(BULK_PAYMENT_SERVICE, t),
          depth: 1,
          serviceName: BULK_PAYMENT_SERVICE,
          icon: <PaymentsOutlined />,
          to: "/bulk_payment"
        }
      ]
    },

    {
      icon: renderServiceIcon(REMITTANCE_CATEGORY),
      title: renderServiceTitle(REMITTANCE_CATEGORY, t),
      depth: 0,
      dataTestId: "remittance-menu-item",
      nested: [
        {
          dataTestId: "remittance_send-menu-item",
          title: renderServiceTitle(REMITTANCE_SEND_SERVICE, t),
          depth: 1,
          serviceName: REMITTANCE_SEND_SERVICE,
          icon: renderServiceIcon(REMITTANCE_SEND_SERVICE),
          to: "/remittance_send"
        },
        {
          dataTestId: "remittance_history-menu-item",
          title: renderServiceTitle(REMITTANCE_HISTORY_SERVICE, t),
          depth: 1,
          serviceName: REMITTANCE_HISTORY_SERVICE,
          icon: renderServiceIcon(REMITTANCE_HISTORY_SERVICE),
          to: "/remittance"
        },

        {
          dataTestId: "remittance_reports-menu-item",
          title: renderServiceTitle(REMITTANCE_REPORTS_SERVICE, t),
          depth: 1,
          serviceName: REMITTANCE_REPORTS_SERVICE,
          icon: renderServiceIcon(REMITTANCE_REPORTS_SERVICE),
          to: "/remittance_reports"
        }
      ]
    },
    {
      icon: renderServiceIcon(AGENT_PAYMENTS_CATEGORY),
      title: renderServiceTitle(AGENT_PAYMENTS_CATEGORY, t),
      depth: 0,
      dataTestId: "agent_payments-menu-item",
      nested: [
        {
          dataTestId: "agent_payments_home-menu-item",
          title: renderServiceTitle(AGENT_PAYMENTS_HOME_SERVICE, t),
          depth: 1,
          serviceName: AGENT_PAYMENTS_HOME_SERVICE,
          icon: renderServiceIcon(AGENT_PAYMENTS_HOME_SERVICE),
          to: "/agent_payments/home"
        },
        {
          dataTestId: "agent_payments_history-menu-item",
          title: renderServiceTitle(AGENT_PAYMENTS_HISTORY_SERVICE, t),
          depth: 1,
          serviceName: AGENT_PAYMENTS_HISTORY_SERVICE,
          icon: renderServiceIcon(AGENT_PAYMENTS_HISTORY_SERVICE),
          to: "/agent_payments/history"
        }
      ]
    }
  ];

  const doesAtLeastOneNestedItemHasPermission = (items: ChildMenuType[]) => {
    const filtered = items?.filter((item: ChildMenuType) => {
      //  Ignore expandable items
      if (!item.serviceName) {
        return true;
      }

      return getServiceAccess(item.serviceName, permissions);
    });
    return filtered?.length > 0;
  };

  const Item = ({ data }: { data: ParentMenuType[] }) => {
    return (
      <>
        {data.map((item: ChildMenuType) => {
          const atLeastOneNestedItemHasPermission = doesAtLeastOneNestedItemHasPermission(item.nested || []);
          // Depth is used to properly add padding to the items
          const depth = Number(item.depth) + 1;
          const paddingLeft = depth * 2;
          return (
            <div key={item.title}>
              {/*Render expandable item if nested key has length > 0, otherwise render normal item*/}
              {item.nested && item.nested?.length > 0
                ? // Render expandable item only if at least one of it's nested items has permission
                  atLeastOneNestedItemHasPermission && (
                    <ExpandableButtonItem
                      key={item.title}
                      sx={{ paddingLeft: paddingLeft + 0.4 }}
                      dataTestId={item.dataTestId}
                      icon={item.icon}
                      title={item.title}
                    >
                      {!!item.nested?.length ? <Item data={item.nested} /> : null}
                    </ExpandableButtonItem>
                  )
                : // Render normal item only if user has permission
                  getServiceAccess(item.serviceName, permissions) && (
                    <ButtonItem
                      key={item.title}
                      to={item.to}
                      sx={{ paddingLeft }}
                      linkable={true}
                      icon={item.icon}
                      dataTestId={item.dataTestId}
                      title={item.title}
                    />
                  )}
            </div>
          );
        })}
      </>
    );
  };

  const renderLogo = () => {
    if (isLoadingLogo) {
      return (
        <Box
          sx={{
            display: "flex",
            minHeight: 80,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress data-testid={"circular-progress"} />
        </Box>
      );
    } else if (dataLogo?.data) {
      return (
        <Box
          className={"menu-logo"}
          data-testid={"menu-logo"}
          sx={{
            marginTop: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: 80,
            alignItems: "center",
            backgroundImage: `url(${dataLogo?.data})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
        ></Box>
      );
    } else {
      return <Box />;
    }
  };

  const drawerWidth = 300;
  return (
    <Menu
      open
      variant={"permanent"}
      sx={{
        width: drawerWidth,
        borderRight: `1px solid ${colors.grey[200]}`,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box"
        }
      }}
    >
      <List
        sx={{ width: "100%", height: "100vh", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" sx={{ padding: 0 }} id="nested-list-subheader">
            <Typography sx={{ textAlign: "center" }}>{merchantData?.name}</Typography>
          </ListSubheader>
        }
      >
        <Collapse in={!!merchantData}>
          {renderLogo()}
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Collapse>
        <Item data={items} />
      </List>
    </Menu>
  );
};

export default MenuDrawer;
