import dayjs from "dayjs";
import formatDate from "utils/formatDate";

const getUpstreamRefinedDates = (dates: [Date, Date | null]): string[] => {
  let newDates: any = dates;

  // Set time 00:00:00 to first item
  newDates[0] = dayjs(newDates[0]).startOf("day");

  // Auto-assign value to second item
  if (!newDates[1]) {
    // Set time 23:59:59 to first item and assign that value to second item
    newDates[1] = dayjs(newDates[0]).endOf("day");
  } else {
    // Set time 23:59:59 to second item
    newDates[1] = dayjs(newDates[1]).endOf("day");
  }

  // Convert date values to strings
  newDates = newDates.map((d: Date) => formatDate(d, "upstream", true));

  return newDates;
};

export default getUpstreamRefinedDates;
