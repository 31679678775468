import { Alert, Box, Button, colors, Divider, Typography } from "@mui/material";
import { PaymentConfirmationStatus, Service } from "api/types/agentPaymentsTypes";
import { useTranslate } from "react-admin";
import { useFormState } from "react-hook-form";

type Props = {
  children: React.ReactNode;
  service?: Service;
  onCancel: () => void;
  onProceed: () => void;
  onCancelText?: string;
  onProceedText?: string;
  screen?: string;
  confirmResult?: boolean;
  paymentConfirmationStatus?: PaymentConfirmationStatus;
};

const BillServiceWrapper = ({
  children,
  service,
  onCancel,
  onProceed,
  onCancelText,
  onProceedText,
  screen,
  confirmResult = false
}: Props) => {
  const t = useTranslate();

  const ProceedButton = () => {
    const { isValid } = useFormState();
    return (
      <Button
        data-testid="proceed-button"
        variant="contained"
        size="large"
        sx={{ fontWeight: "bold" }}
        onClick={onProceed}
        disabled={!isValid}
      >
        {onProceedText || t(`resources.${["agent_payments"]}.fields.proceed`)}
      </Button>
    );
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", paddingBottom: 5 }}>
      <Box>
        {screen === "confirm" && !confirmResult && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginRight: 1 }}>
            <Alert severity="warning" variant="filled">
              {t(`resources.${["agent_payments"]}.fields.any_errors_after_this_step_cannot_be_corrected`)}
            </Alert>
          </Box>
        )}
        <Box sx={{ paddingX: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start", marginBottom: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: 4, minWidth: 100, maxWidth: 100 }}>
                <img
                  data-testid="service-image"
                  src={service?.logo?.url}
                  loading="lazy"
                  alt="service logo"
                  width="100%"
                />
              </Box>
              <Box>
                <Typography data-testid="service-title" sx={{ fontWeight: "bold", fontSize: 26 }}>
                  {service?.title}
                </Typography>
                <Typography
                  data-testid="service-description"
                  sx={{ fontWeight: "bold", fontSize: 12, color: colors.grey[600] }}
                >
                  {service?.description}
                </Typography>
              </Box>
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
      <Box sx={{ position: "absolute", bottom: 20, width: "100%" }}>
        <Divider />
        <Box sx={{ paddingX: "100px", paddingY: 2 }}>
          {!confirmResult ? (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                data-testid="cancel-button"
                variant="outlined"
                size="large"
                color="error"
                onClick={onCancel}
              >
                {onCancelText || t(`resources.${["agent_payments"]}.fields.cancel`)}
              </Button>
              <ProceedButton />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" size="large" onClick={() => alert("Print Document")}>
                {t(`resources.${["agent_payments"]}.fields.print`)}
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{ fontWeight: "bold" }}
                onClick={() => alert("Download document")}
              >
                {t(`resources.${["agent_payments"]}.fields.download`)}
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ fontWeight: "bold" }}
                onClick={() => alert("GO TO HISTORY")}
              >
                {t(`resources.${["agent_payments"]}.fields.history`)}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BillServiceWrapper;
