import { useRecordContext, useTranslate } from "react-admin";
import CardGrid from "components/material/Card/CardGrid";
import ChipStatus from "components/other/context/StatusField";
import { LocalizedFieldObject } from "api/types/prlTypes";
import formatCurrency from "utils/formatCurrency";
import formatDate from "utils/formatDate";

const PaymentTransactionShowLayout = () => {
  const record = useRecordContext();
  const t = useTranslate();
  const transactionDetailsContent = [
    {
      first_name: record?.customerNameFirst
    },
    {
      last_name: record?.customerNameLast
    },
    {
      phone_number: record?.customerPhone
    },
    {
      transaction_status: <ChipStatus source={"transactionStatus"} />
    },
    {
      ptn: record?.ptn
    },
    {
      order_status: <ChipStatus source={"orderStatus"} />
    },
    {
      payment_provider: record?.paymentProvider.find(
        (provider: LocalizedFieldObject) => provider.lang === "en"
      )?.text
    },
    {
      total_amount: formatCurrency(record?.orderTotalAmount)
    },
    {
      reference_number: record?.orderReference
    },
    {
      paid_at: formatDate(record?.orderPaidAt)
    },
    {
      created_at: formatDate(record?.transactionCreatedAt)
    },
    {
      transaction_amount: formatCurrency(record?.transactionAmount)
    }
  ];
  return (
    <div>
      <CardGrid
        resource={"payment-transaction"}
        content={transactionDetailsContent}
        cardTitle={t("cc.card.transaction_details")}
      />
    </div>
  );
};

export default PaymentTransactionShowLayout;
