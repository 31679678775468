import { AutocompleteRenderInputParams, Box, CircularProgress, TextField } from "@mui/material";
import queryErrorHandler from "api/queryErrorHandler";
import { EntityObject } from "api/types/kycTypes";
import AutocompleteAsync from "components/material/Autocomplete/AutocompleteAsync";
import { SWITCH_ADMIN_CONTEXT } from "constants/dataProviderTypes";
import { useState } from "react";
import { useDataProvider, useGetList, useNotify, useRedirect, useTranslate } from "react-admin";
import { useMutation } from "react-query";
import {
  getStorageEntityOrganisationExternalName,
  getStorageMerchantCCId,
  removeStorageEntityOrganisationExternalId,
  setStorageEntityOrganisationExternalName,
  setStorageEntityOrganisationExternalId,
  removeStorageEntityOrganisationExternalName
} from "utils/storageUtils";
import { useUtilsDispatch } from "context/UtilsContext";

const AutocompleteAppBar = ({ ...props }) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const merchantCCId = getStorageMerchantCCId();
  const [textQuery, setTextQuery] = useState<string>(merchantCCId || "");
  const dispatch = useUtilsDispatch();

  const { data: companyData = [], isLoading: isCompanyDataLoading } = useGetList(
    "entity",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "createdAt", order: "DESC" },
      filter: { entityType: "ORGANIZATION", filter: textQuery }
    },
    {
      refetchOnWindowFocus: false
    }
  );
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={t("resources.entity.fields.customerCompanyName")}
      sx={{ backgroundColor: "white", position: "relative", borderRadius: 1 }}
      variant={"outlined"}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isCompanyDataLoading && (
              <CircularProgress sx={{ position: "absolute", right: 10 }} color="inherit" size={20} />
            )}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );

  const { mutate: mutateSwitchAdminContext } = useMutation(
    ["entity", SWITCH_ADMIN_CONTEXT],
    ({ org }: { org: number | string }) => {
      return dataProvider.switchAdminContext("entity", { org });
    }
  );

  const handleOnChange = (option: EntityObject) => {
    mutateSwitchAdminContext(
      { org: option?.entityOrganisationExternalId || "" },
      {
        onSuccess: () => {
          if (window.location.pathname.includes("company-prl")) {
            redirect(`/company-prl/${option?.entityOrganisationExternalId || "undefined"}`);
          }
          setStorageEntityOrganisationExternalId(option?.entityId || "");
          if (!option.entityOrganisationExternalId) {
            removeStorageEntityOrganisationExternalId();
            removeStorageEntityOrganisationExternalName();
          }
          dispatch({
            type: "updated",
            payload: { merchantCCId: option?.entityOrganisationExternalId }
          });
          return notify("cc.notification.user_context_switched", { type: "success" });
        },
        onError: (error) => {
          return queryErrorHandler({ error, notify });
        }
      }
    );
    setStorageEntityOrganisationExternalName(option?.entityOrganisationExternalName || "");
  };

  const globalContextOption = {
    entityOrganisationExternalId: "",
    entityOrganisationExternalName: t("cc.tab.global_context")
  };
  const defaultOption: any = {
    entityOrganisationExternalId: getStorageMerchantCCId() || "",
    entityOrganisationExternalName: getStorageEntityOrganisationExternalName() || ""
  };

  const options: any = [globalContextOption, ...companyData];

  return (
    <Box style={{ width: 300, marginRight: 10 }}>
      <AutocompleteAsync
        {...props}
        loading={isCompanyDataLoading}
        options={options}
        renderInput={renderInput}
        inputValue={textQuery}
        onInputChange={(e, text) => setTextQuery(text)}
        isOptionEqualToValue={(option: EntityObject, value: EntityObject) =>
          option?.entityOrganisationExternalId === value?.entityOrganisationExternalId
        }
        getOptionLabel={(option: EntityObject) =>
          `${option?.entityOrganisationExternalId}${option?.entityOrganisationExternalId ? " - " : ""}${
            option?.entityOrganisationExternalName
          }`
        }
        onChange={(e, option) => handleOnChange(option)}
        defaultValue={defaultOption}
        filterSelectedOptions={true}
      />
    </Box>
  );
};

export default AutocompleteAppBar;
