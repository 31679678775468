import { Box, colors, Grid, Toolbar } from "@mui/material";
import { PropsOfStyles, withStyles } from "@mui/styles";
import Card from "components/material/Card/CardBase";
import ModalSuccess from "components/material/Modal/ModalSuccess";
import Dropzone from "components/other/Dropzone/Dropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  DeleteButton,
  Form,
  ListButton,
  NotificationType,
  NumberInput,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useTranslate,
  useUpdate
} from "react-admin";
import queryErrorHandler from "api/queryErrorHandler";
import useHandleModalSuccess from "hooks/useHandleModalSuccess";
import useMutatePrlAttachment from "hooks/api/useMutatePrlAttachment";
import useHandlePrlAttachment from "hooks/utils/useHandlePrlAttachment";
import useQueryPrlAttachment from "hooks/api/useQueryPrlAttachment";
import { FORM_TYPE_CREATE } from "constants/formTypes";
import { FieldValues } from "react-hook-form";
import { DropzoneFileType } from "components/types/otherComponentTypes";

const styles: PropsOfStyles<string | object> = {
  paper: {
    padding: 20,
    height: "100%",
    boxSizing: "border-box"
  },
  attachmentsDisable: {
    pointerEvents: "none"
  },
  attachmentsEnable: {
    pointerEvents: "auto"
  },
  inputLabels: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold"
  }
};

const ProductCreateEditForm = ({ formType }: { formType: "edit" | "create" }) => {
  const t = useTranslate();
  const [createProduct, { isLoading: loadingCreateProduct, data: dataProduct }] = useCreate();
  const [updateProduct, { isLoading: loadingUpdateProduct }] = useUpdate();
  const [link, setLink] = useState("");
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  // Local state for attachments
  const [attachments, setAttachments] = useState<DropzoneFileType[]>([]);
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    handleCreate: handleModalCreate,
    handleClose: handleModalClose
  } = useHandleModalSuccess({ setAttachments });
  const {
    add: handleAddAttachment,
    remove: handleRemoveAttachment,
    update: handleUpdateAttachment
  } = useHandlePrlAttachment({
    formType: formType,
    setModalOpen,
    attachments,
    setAttachments
  });

  const { data: attachmentData, isLoading: isLoadingQueryPrlAttachment } = useQueryPrlAttachment();
  const { mutateUploadAttachment } = useMutatePrlAttachment({
    formType: formType,
    setModalOpen
  });
  // Schema validation
  const schema = yup
    .object()
    .shape({
      name: yup.string().required(t("validation.required")),
      price: yup
        .number()
        .min(
          1,
          t("validation.minValue", {
            min: 0
          })
        )
        .required(t("validation.required")),
      productExtId: yup
        .string()
        .required(t("validation.required"))
        .matches(/^[\d]*[a-z_\d-\d\s][a-z\d_\d-\d\s]*$/gi, t("validation.alpha")),
      description: yup.string().required(t("validation.required"))
    })
    .required();

  const goToListPage = () => {
    redirect(`/product`, resource);
  };

  const submit = async (values: FieldValues) => {
    if (formType === FORM_TYPE_CREATE) {
      //Create
      await handleCreateProduct(values);
    } else {
      //Edit
      await handleEditProduct(values);
    }
  };

  const record = useRecordContext();

  const showNotify = (action: string, key: string, smart_count = 1, type: NotificationType = "success") =>
    notify(t(`cc.notification.${action}_${key}`, { smart_count }), {
      type
    });

  const handleCreateProduct = async (values: FieldValues) => {
    const { name, price, description, productExtId } = values;
    await createProduct(
      resource,
      {
        data: {
          name,
          price: parseFloat(String(price)),
          description,
          productExtId,
          currency: "XAF",
          isActive: true
        }
      },
      {
        onSuccess: (data) => {
          //FIXME: Fix modal opening if attachment upload fails
          setLink(data?.shortUrl || data?.url);
          mutateUploadAttachment({ files: attachments, id: data?.id });
        },
        onError: (error) => queryErrorHandler({ error, notify })
      }
    );
  };

  const handleEditProduct = async (values: FieldValues) => {
    try {
      const { name, price, description, productExtId } = values;

      await updateProduct(
        resource,
        {
          id: record.id,
          data: {
            name,
            price: parseFloat(String(price)),
            description,
            productExtId,
            currency: "XAF",
            isActive: true
          }
        },
        {
          onError: (error) => queryErrorHandler({ error, notify }),
          onSuccess: () => {
            showNotify("updated", resource);
            goToListPage();
          }
        }
      );
    } catch (error) {
      queryErrorHandler({ error, notify });
    }
  };

  return (
    <>
      <Form onSubmit={submit} resolver={yupResolver(schema)}>
        <Box p="1em">
          <Box display="flex" sx={{ height: "100%" }}>
            <Box flex={1} sx={{ height: "auto" }}>
              <Card cardTitle={t("cc.card.product_information")}>
                <Grid container spacing={3} style={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <TextInput source="name" type="text" fullWidth data-testid="name" />
                  </Grid>

                  <Grid item xs={4}>
                    <NumberInput
                      source="price"
                      format={(e) => Number(Math.round(e)).toString()}
                      min={0}
                      fullWidth
                      defaultValue={0}
                      data-testid="price"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextInput source="productExtId" type="text" fullWidth data-testid="article_number" />
                  </Grid>

                  <Grid item xs={4}>
                    <TextInput
                      source="description"
                      multiline
                      minRows={7}
                      resource="prl"
                      type="text"
                      fullWidth
                      data-testid="description"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Box>

          <Box display="flex" mt={3}>
            <Box flex={1} sx={{ height: "auto" }}>
              <Card cardTitle={t("cc.card.attachment", { smart_count: 0 })}>
                <Dropzone
                  files={formType === FORM_TYPE_CREATE ? attachments : attachmentData?.data}
                  onDrop={handleAddAttachment}
                  onDelete={handleRemoveAttachment}
                  onUpdate={handleUpdateAttachment}
                  loading={isLoadingQueryPrlAttachment || loadingCreateProduct || loadingUpdateProduct}
                />
              </Card>
            </Box>
          </Box>
        </Box>
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ListButton
            variant="outlined"
            size="medium"
            label={t("ra.action.cancel")}
            icon={<></>}
            data-testid="cancel-button"
          ></ListButton>
          <SaveButton
            color="primary"
            sx={{ marginLeft: 1 }}
            label={t("ra.action.save")}
            data-testid="submit-button"
          />
          <DeleteButton variant="outlined" size="medium" record={record} sx={{ marginLeft: 1 }} />
        </Toolbar>
        <ModalSuccess
          open={modalOpen}
          onCreate={handleModalCreate}
          onBack={goToListPage}
          onClose={handleModalClose}
          link={link}
          data={dataProduct}
        />
      </Form>
    </>
  );
};

export default withStyles(styles)(ProductCreateEditForm);
