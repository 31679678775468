const productMockData = {
  product: [
    {
      id: 248,
      productUuid: "2d0b7e43-9e59-458d-a284-eb9a455efcca",
      url: "https://prlpaymentwebui.smobilpay.acceptance.maviance.info/product/2d0b7e43-9e59-458d-a284-eb9a455efcca",
      name: "Shampoo",
      productExtId: "asdf",
      description: "Very nice",
      price: 234324,
      currency: "XAF",
      isActive: true,
      createdAt: "2022-08-19T10:37:20+0000",
      updatedAt: "2022-08-19T10:37:21+0000",
      attachments: [
        {
          id: 62,
          documentUuid: "dad0ba81-7c31-4d6c-b52d-847e5038c74d",
          documentUrl: "/payment-api/v1/document/dad0ba81-7c31-4d6c-b52d-847e5038c74d/download",
          documentLabel: "Hirsuit-Hippo_WP_1920x1080.jpg",
          originalName: "Hirsuit-Hippo_WP_1920x1080.jpg",
          mimeType: "image/jpeg",
          checksum: "7e0023768c68c9757360092697b42809",
          createdAt: "2022-08-19 10:37:21",
          updatedAt: "2022-08-19 10:37:21"
        }
      ]
    },
    {
      id: 245,
      productUuid: "2d3f2fbd-bfa2-4814-8a59-4d00f6729447",
      url: "https://prlpaymentwebui.smobilpay.acceptance.maviance.info/product/2d3f2fbd-bfa2-4814-8a59-4d00f6729447",
      name: "Soap",
      productExtId: "662",
      description: "Cool one",
      price: 414,
      currency: "XAF",
      isActive: true,
      createdAt: "2022-08-16T10:34:57+0000",
      updatedAt: "2022-08-16T10:35:21+0000",
      attachments: []
    }
  ]
};

export default productMockData;
