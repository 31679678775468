import dayjs from "dayjs";

export const generateYearsOptions = (start: Date) => {
  const currentDate = new Date();
  const difference = dayjs(currentDate).add(1, "years").diff(start, "years");

  return Array.from({ length: difference }, (_, idx) => ({
    label: String(new Date(currentDate).getFullYear() - idx),
    isChecked: false,
    value: new Date(currentDate).getFullYear() - idx,
    id: idx
  }));
};
