import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import ChipStatus from "components/other/context/StatusField";
import Datepicker from "components/other/Datepicker";
import ExportButton from "components/ra/Button/ExportButton";
import ShowButton from "components/ra/Button/ShowButton";
import { ORGANIZATION } from "constants/entityType";
import { APPROVED, COMPLETED, CREATED, REJECTED } from "constants/status";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  ResourceContextProvider,
  SelectArrayInput,
  SelectArrayInputProps,
  TextField,
  TextInput,
  TopToolbar
} from "react-admin";

const statusChoices = [
  { id: APPROVED, name: APPROVED },
  { id: REJECTED, name: REJECTED },
  { id: CREATED, name: CREATED },
  { id: COMPLETED, name: COMPLETED }
];

const useStyles = makeStyles({
  root: { minWidth: 220 }
});

const StyledSelectArrayInput = (props: SelectArrayInputProps) => {
  const classes = useStyles();
  return <SelectArrayInput className={classes.root} {...props} />;
};

const KycDataListCompany = () => {
  const postFiltersCompany = [
    <StyledSelectArrayInput data-testid="filter-status" source="status" choices={statusChoices} />,
    <Datepicker dataTestId="filter-submittedAt" source="submittedAt" />,
    <Datepicker dataTestId="filter-createdAt" source="createdAt" />,
    <TextInput data-testid="filter-entityOrganisationExternalId" source="entityOrganisationExternalId" />,
    <TextInput data-testid="filter-entityOrganisationExternalName" source="entityOrganisationExternalName" />
  ];
  const ListActions = () => (
    <TopToolbar>
      <FilterButton data-testid="filter-button" />
      <ExportButton data-testid="export-button" maxResults={500} />
    </TopToolbar>
  );

  return (
    <ResourceContextProvider value="entity">
      <>
        <Toolbar disableGutters={true}>
          <Breadcrumbs service={"company"} page={"list"} />
        </Toolbar>
        <List actions={<ListActions />} filter={{ entityType: ORGANIZATION }} filters={postFiltersCompany}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="entityOrganisationExternalId" />
            <TextField source="entityOrganisationExternalName" />
            <ChipStatus source="status" />
            <DateField source="submittedAt" locales="fr-FR" />
            <DateField source="createdAt" locales="fr-FR" />
            <ShowButton data-testid={"show-button"} />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default KycDataListCompany;
