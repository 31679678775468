import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, colors, Grid, Tooltip, Typography } from "@mui/material";
import dataProviderAgentPayment from "api/dataProvider/agentPaymentsDataProvider";
import Divider from "components/material/Divider";
import calculateExchangeRate from "components/ra/custom/RemittanceSend/utils/calculateExchangeRate";
import calculateServiceCharge from "components/ra/custom/RemittanceSend/utils/calculateServiceCharge";
import AutocompleteInput from "components/ra/Input/AutocompleteInput/AutocompleteInput";
import { GET_CONFIG } from "constants/dataProviderTypes";
import { BANK, CASH_PICKUP, MOBILE_MONEY } from "constants/remittanceDeliveryMethods";
import { AGENT_PAYMENTS_HOME_SERVICE } from "constants/serviceNames";
import useGetTranslation from "hooks/utils/useGetTranslation";
import { isEmpty, pick } from "lodash";
import { useEffect, useState } from "react";
import {
  FormDataConsumer,
  NumberInput,
  regex,
  // regex,
  required,
  SelectInput,
  TextInput,
  useLocaleState
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import formatCurrency from "utils/formatCurrency";

function RemittanceSendStepOne({ handleNext, serviceId, setServiceId, ...props }: any) {
  // State
  const [totalAmount, setTotalAmount] = useState(0);
  // Hooks
  const [locale] = useLocaleState();
  const getTranslation = useGetTranslation();
  const { setValue, register, trigger, resetField } = useFormContext();
  // Watchers
  const recipientCountry = useWatch({ name: "recipient_country" });
  const senderAmount = useWatch({ name: "sender_amount" });
  const recipientAmount = useWatch({ name: "recipient_amount" });
  const deliveryMethod = useWatch({ name: "delivery_method" });
  const receiverCountry = useWatch({ name: "receiver_country" });
  const serviceChargeInit = useWatch({ name: "service_charge" });
  const queryClient = useQueryClient();

  const data: any = queryClient.getQueryData(["remittance", GET_CONFIG]);

  const configData = data || [];

  // eslint-disable-next-line
  const { data: dataServices } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_HOME_SERVICE],
    () => dataProviderAgentPayment.getService(),
    {
      refetchOnWindowFocus: false,
      staleTime: 3600000
    }
  );

  const handleResetFields = (fields: string[]) => {
    fields.forEach((fieldName) => {
      resetField(fieldName);
    });
  };

  const handleInputFormat = (value: number) => {
    if (isNaN(value)) {
      return "";
    } else {
      return value;
    }
  };

  // Selectors
  const services = dataServices || [];

  const service = services.find((element) => element.serviceId === serviceId);
  const currentServiceCharge = service?.serviceCharge || null;

  console.log("current service", service, serviceId);

  const partners = configData?.data?.partners?.filter(
    (partner: { delivery_method: string }) => partner?.delivery_method === deliveryMethod
  );
  const accounts = partners?.map((provider: string) =>
    pick(provider, ["account_label", "account_hint", "account_validation", "delivery_method", "serviceId"])
  );
  const rate = configData?.data?.rates?.find(
    (rate: { target: string }) => rate?.target === recipientCountry?.label
  );

  const hasExchangeRate = (corridor: any): boolean => {
    const rateExists = configData?.data?.rates?.find(
      (rate: any) => rate?.target === corridor?.target && rate?.base === corridor?.base
    );
    return rateExists !== undefined;
  };

  const partnerCorridorIds = configData?.data?.partners?.map((partner: any) => partner?.corridorId);

  const recipientCountryOptions = configData?.data?.corridors?.flatMap((corridor: any) =>
    corridor?.country_from === "CM" &&
    partnerCorridorIds?.includes(corridor?.corridorId) &&
    hasExchangeRate(corridor)
      ? [{ code: corridor?.country_to, label: corridor?.target }]
      : []
  );

  const selectedCorridor = configData?.data?.corridors?.find(
    (corridor: { country_to: string; target: string }) =>
      corridor?.country_to === recipientCountry?.code && corridor?.target === recipientCountry?.label
  );

  const selectedRecipientPartner = configData?.data?.partners?.find(
    (partner: { serviceId: number }) => partner?.serviceId === service?.serviceId
  );

  const sendMinAmount = selectedRecipientPartner?.send_amount_min;
  const sendMaxAmount = selectedRecipientPartner?.send_amount_max;
  const validateSenderMaxMinAmount =
    (receiverCountry || senderAmount) && senderAmount < sendMinAmount
      ? `Minimum ${formatCurrency(sendMinAmount)} allowed at a time`
      : senderAmount > sendMaxAmount
      ? `Maximum ${formatCurrency(sendMaxAmount)} allowed at a time`
      : "";
  const invalidMinMax = validateSenderMaxMinAmount?.length > 0;

  useEffect(() => {
    register("data_service_charge");
    if (!isEmpty(currentServiceCharge)) {
      const calculatedServiceCharge = senderAmount
        ? calculateServiceCharge({
            denomination: currentServiceCharge?.roundDenom,
            ranges: currentServiceCharge?.ranges,
            senderAmount: senderAmount
          })
        : 0;
      register("selected_partner_id");
      setValue("selected_partner_id", selectedRecipientPartner?.partnerId);
      register("service_charge");
      setValue("service_charge", Number(calculatedServiceCharge ?? 0));
    }
  }, [currentServiceCharge, senderAmount, serviceId]);

  // Checks if delivery channels exist for corridor pair based on delivery
  const hasDevliveryChannels = (deliveryMethod: string) => {
    const rst = configData?.data?.partners?.filter(
      (provider: { delivery_method: string; corridorId: number }) =>
        provider?.delivery_method === deliveryMethod && provider?.corridorId === selectedCorridor?.corridorId
    );
    return !isEmpty(rst);
  };

  const deleiveryMethodOptions = [
    hasDevliveryChannels(BANK) && { id: BANK, name: getTranslation("bank_transfer") },
    hasDevliveryChannels(MOBILE_MONEY) && { id: MOBILE_MONEY, name: getTranslation("mobile_money") },
    hasDevliveryChannels(CASH_PICKUP) && { id: CASH_PICKUP, name: getTranslation("cash_pickup") }
  ];

  // Effects
  // FIXME: Think of a better approach
  useEffect(() => {
    if (recipientCountry && rate) {
      setValue("recipient_amount", calculateExchangeRate(senderAmount, rate?.rate));
    }
  }, [rate, recipientCountry, senderAmount]);

  useEffect(() => {
    setTotalAmount(Number(serviceChargeInit ?? 0) + Number(senderAmount));

    register("total_amount");
    setValue("total_amount", Number(serviceChargeInit ?? 0) + Number(senderAmount));
    register("service_charge_loading");
  }, [serviceChargeInit, senderAmount, register]);

  // Effects
  useEffect(() => {
    if (recipientCountry && rate) {
      setValue("recipient_amount", calculateExchangeRate(senderAmount, rate?.rate));
    }
  }, [rate, recipientCountry, senderAmount]);

  useEffect(() => {
    if (deliveryMethod) {
      setValue("service_charge_loading", true);
    }
  }, [deliveryMethod]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await trigger("sender_amount", { shouldFocus: true });
    })();
  }, []);

  useEffect(() => {
    if (deliveryMethod) {
      setValue("service_charge_loading", true);
    }
  }, [deliveryMethod]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await trigger("sender_amount", { shouldFocus: true });
    })();
  }, []);

  return (
    <div>
      <Grid container columnSpacing={5}>
        <Grid item xs={8}>
          <TextInput
            data-testid="sender_amount"
            source="sender_amount"
            validate={[required(), () => validateSenderMaxMinAmount]}
            onChange={(e) => {
              if (rate) {
                setValue("recipient_amount", calculateExchangeRate(e.target.value, rate?.rate));
              }
            }}
            format={handleInputFormat}
            parse={(value) => {
              if (isNaN(value)) {
                return null;
              }
              return Number(value);
            }}
            variant={"outlined"}
            fullWidth
            label={getTranslation("sender_sends")}
            size="medium"
            inputProps={{ maxLength: 9 }}
          />
          {/*<div>base denomination {rate?.base_denomintion}</div>*/}
          {/*<div>target denomination {rate?.target_denomination}</div>*/}

          {/*<div>rate {rate?.rate}</div>*/}
        </Grid>
        <Grid item xs>
          <AutocompleteInput
            isOptionEqualToValue={(option: { code: string }, value: { code: string }) => {
              return option?.code === value?.code;
            }}
            defaultValue={{ code: "CM", label: "XAF" }}
            placeholder={getTranslation("select_country")}
            dataTestId={"sender_country"}
            readOnly
            variant={"outlined"}
            source="sender_country"
            type={"country"}
            label={getTranslation("country")}
            getOptionLabel={(option: { label: string }) => option?.label}
            size="medium"
          />
        </Grid>
      </Grid>
      {/* <Divider variant={"middle"} sx={{ marginBottom: 2 }}>
        {getTranslation("or")}
      </Divider> */}
      {recipientCountry && (
        <Box mb={3}>
          <Typography sx={{ color: colors.cyan[600], display: "flex", fontStyle: "italic", gap: 1 }}>
            <AutorenewIcon sx={{ transform: "rotate(55deg)" }} />
            {getTranslation("exchange_rate")}
            <Typography sx={{ fontWeight: "bold" }}>{`1 ${rate?.base ?? "XAF"} = ${rate?.rate ?? "N/A"} ${
              rate?.target ?? ""
            }`}</Typography>
          </Typography>
        </Box>
      )}
      <Grid container columnSpacing={5}>
        <Grid item xs={8}>
          <NumberInput
            defaultValue={0}
            data-testid={"recipient_amount"}
            validate={[required()]}
            format={(value) => {
              if (Number.isInteger(calculateExchangeRate(senderAmount, rate?.rate))) {
                return value?.toFixed(0);
              }
              return value?.toFixed(2);
            }}
            variant={"outlined"}
            source="recipient_amount"
            disabled={true}
            fullWidth
            label={getTranslation("recipient_gets")}
            size="medium"
          />
        </Grid>
        <Grid item xs>
          {/* {JSON.stringify(recipientCountryOptions)} */}
          <AutocompleteInput
            source="recipient_country"
            dataTestId={"recipient_country"}
            options={recipientCountryOptions}
            validate={[required()]}
            disableClearable
            variant={"outlined"}
            type={"country"}
            label={getTranslation("country")}
            getOptionLabel={(option: { label: string }) => option?.label}
            onChange={async () => {
              setServiceId("");
              handleResetFields(["delivery_method", "service_charge", "recipient_amount"]);
              await trigger("sender_amount", { shouldFocus: true });
            }}
            size="medium"
          />
        </Grid>
      </Grid>
      {!isEmpty(deleiveryMethodOptions?.filter(Boolean)) && (
        <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      )}
      <Grid container columnSpacing={5}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const account = accounts?.find(
              (acc: { delivery_method: string }) => acc?.delivery_method === formData?.delivery_method
            );

            const providerOptions = configData?.data?.partners
              ?.filter(
                (provider: { delivery_method: string; corridorId: number }) =>
                  provider?.delivery_method === formData?.delivery_method &&
                  provider?.corridorId === selectedCorridor?.corridorId
              )
              ?.map((provider: { code: string; id: string; display_name: string }) => ({
                id: provider?.code,
                name: provider?.display_name
              }));

            return (
              !isEmpty(deleiveryMethodOptions?.filter(Boolean)) && (
                <>
                  <Grid item xs={4}>
                    <SelectInput
                      source="delivery_method"
                      data-testid={"delivery_method"}
                      label={getTranslation("select_delivery_method")}
                      validate={[required()]}
                      fullWidth
                      // choices={deliveryMethodOptions || [{ id: "", name: "" }]}
                      choices={deleiveryMethodOptions}
                      variant={"outlined"}
                      // isLoading={!deliveryMethodOptions}
                      disabled={invalidMinMax}
                      size="medium"
                      onChange={() => {
                        handleResetFields(["account_holder_number", "provider", "service_charge"]);
                      }}
                    />
                  </Grid>
                  {formData?.delivery_method && (
                    <>
                      <Grid item xs={4}>
                        <SelectInput
                          source="provider"
                          data-testid={"delivery_channel"}
                          label={getTranslation("select_delivery_channel")}
                          validate={[required()]}
                          variant={"outlined"}
                          fullWidth
                          choices={providerOptions ?? []}
                          onChange={(e) => {
                            const selectedDelivery = partners?.find(
                              (p: { code: string }) => p?.code === e.target.value
                            );
                            if (isEmpty(e.target.value)) {
                              handleResetFields(["service_charge"]);
                            }
                            register("delivery_channel_name");
                            setValue("delivery_channel_name", String(selectedDelivery?.display_name));
                            setServiceId(selectedDelivery?.serviceId);
                          }}
                          disabled={isEmpty(providerOptions) || invalidMinMax}
                          size="medium"
                        />
                      </Grid>
                      {formData?.delivery_method !== CASH_PICKUP && (
                        <Grid item xs>
                          <Tooltip
                            title={
                              account?.account_hint?.find((hint: { lang: string }) => hint?.lang === locale)
                                ?.value || ""
                            }
                            placement={"top-start"}
                          >
                            <TextInput
                              variant={"outlined"}
                              label={
                                account?.account_label?.find(
                                  (label: { lang: string }) => label?.lang === locale
                                )?.value
                              }
                              data-testid={"account_holder_number"}
                              validate={[
                                required(),
                                regex(
                                  new RegExp(account?.account_validation),
                                  formData?.delivery_method === BANK
                                    ? getTranslation("invalid_account_number")
                                    : getTranslation("invalid_phone_number")
                                )
                              ]}
                              parse={(value) => {
                                if (isNaN(value)) {
                                  return null;
                                }
                                return Number(value);
                              }}
                              type="number"
                              fullWidth
                              source={"account_holder_number"}
                              disabled={isEmpty(providerOptions) || invalidMinMax}
                              size="medium"
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )
            );
          }}
        </FormDataConsumer>
      </Grid>
      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Grid container spacing={0} sx={{ marginTop: 3 }}>
        <Grid item xs={6}>
          <Typography variant={"h6"}>{getTranslation("sender_sends")}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Typography variant={"h6"}>{senderAmount?.toLocaleString(locale) ?? 0} XAF</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={"h6"}>{getTranslation("service_charge")}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Typography variant={"h6"}>{`${serviceChargeInit?.toLocaleString(locale) ?? 0} XAF`}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontWeight={"bold"} variant={"h6"}>
            {getTranslation("total_amount")}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Typography fontWeight={"bold"} variant={"h6"}>
            {Number.isNaN(totalAmount) ? 0 : totalAmount?.toLocaleString(locale)} XAF
          </Typography>
        </Grid>
      </Grid>
      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Grid container>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"} variant={"h6"}>
            {getTranslation("recipient_gets")}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <Typography fontWeight={"bold"} variant={"h6"}>
            {recipientAmount?.toLocaleString(locale) ?? 0} {rate?.target}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default RemittanceSendStepOne;
