import { Box, colors, Grid, TextField, Typography } from "@mui/material";
import Divider from "components/material/Divider";
import getCountryNameFromCode from "components/ra/Input/AutocompleteInput/utils/getCountryNameFromCode";
import PhoneInput from "components/ra/Input/PhoneInput";
import { IMAGE_JPEG, IMAGE_PNG, PDF_FILE } from "constants/acceptedFileTypes";
import useGetTranslation from "hooks/utils/useGetTranslation";
import {
  email,
  FileField,
  FileInput,
  maxLength,
  minLength,
  regex,
  required,
  SelectInput,
  TextInput,
  useLocaleState,
  useNotify
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import getDateDifference from "../utils/getDateDifference";
import DatePickerInput from "components/ra/Input/DatePickerInput/DatePickerInput";
import dayjs from "dayjs";
import formatDate from "utils/formatDate";

function RemittanceSendStepThree() {
  const getTranslation = useGetTranslation();
  const [locale] = useLocaleState();
  const notify = useNotify();
  const { register } = useFormContext();

  const nameFieldRules = {
    maxLength: {
      value: 35,
      message: getTranslation("invalid_format_max_lenght")
    }
  };

  const addressFieldRules = {
    maxLength: {
      value: 35,
      message: getTranslation("invalid_format_max_lenght")
    }
  };

  const validateNameFields = regex(/^[a-zA-Z0-9À-ÿ\s]+$/, getTranslation("invalid_format"));
  const validateSpacedNameFields = regex(/^[a-zA-Z0-9À-ÿ\s]+$/, getTranslation("invalid_format"));
  const validateRequiredNameFields = [required(), validateNameFields];
  const validateAddressFields = [required(), validateSpacedNameFields];

  const watchedSenderIdType = useWatch({ name: "sender_id_type" });
  const watchedIssueDate = useWatch({ name: "sender_date_of_issue" });
  const watchedExpiryDate = useWatch({ name: "sender_expiry_date" });
  const watchSenderCountry = useWatch({ name: "sender_country" });
  // const watchDateOfBirth = useWatch({ name: "sender_date_of_birth" });

  const getIDPassportValidation = () => {
    switch (watchedSenderIdType) {
      case "passport":
        return [required()];
      case "national_id":
        return [required(), maxLength(17), minLength(17)];
      default:
        return [required()];
    }
  };

  const getExpiryDateValidation = () => {
    const issueDate = new Date(watchedIssueDate).toDateString();
    const expiryDate = new Date(watchedExpiryDate).toDateString();
    const today = new Date();
    if (watchedIssueDate) {
      return [
        required(),
        getDateDifference(issueDate, expiryDate) === 0
          ? () => getTranslation("issue_and_expired_cannot_be_identical")
          : getDateDifference(issueDate, expiryDate) < 1
          ? getDateDifference(today, expiryDate) > 1
            ? () => getTranslation("your_id_has_expired")
            : required()
          : () => getTranslation("expiry_date_cannot_be_less_than_issue_date")
      ];
    } else {
      return [required()];
    }
  };

  const getIssueDateValidation = () => {
    const issueDate = new Date(watchedIssueDate).getTime();
    const today = new Date();
    if (watchedIssueDate) {
      return [
        required(),
        issueDate > today.getTime() ? () => getTranslation("issue_date_invalid") : required()
      ];
    } else {
      return [required()];
    }
  };

  const isIssueDateToday = (): boolean => {
    const today = new Date();
    return watchedIssueDate === formatDate(today, "upstream");
  };

  const acceptedFileTypes = [PDF_FILE, IMAGE_PNG, IMAGE_JPEG];

  const validateFile = (fileType: string, files: any) => {
    const maxFileSize = 1000000;
    switch (fileType) {
      case "document_1":
        if (!acceptedFileTypes.includes(files[0]?.type)) {
          notify(getTranslation("unsupported_file_type_please_upload_a_pdf_jpg_or_png", "remittance"), {
            type: "error",
            autoHideDuration: 6000
          });
        }
        if (files[0]?.size > maxFileSize) {
          notify(getTranslation("file_size_exceeded"), { type: "error", autoHideDuration: 6000 });
        }
        break;
      case "document_2":
        if (!acceptedFileTypes.includes(files[0]?.type)) {
          notify(getTranslation("unsupported_file_type_please_upload_a_pdf_jpg_or_png", "remittance"), {
            type: "error",
            autoHideDuration: 6000
          });
        }
        if (files[0]?.size > maxFileSize) {
          notify(getTranslation("file_size_exceeded"), { type: "error", autoHideDuration: 6000 });
        }
        break;
      default:
        return "";
    }
  };

  const onFileDrop = (fileType: string, files: any) => {
    validateFile(fileType, files);
  };

  const FilePlaceHolder = () => {
    return (
      <Box>
        <Typography
          sx={{
            color: colors.grey[800]
          }}
        >
          {getTranslation("drop_a_file")}
        </Typography>
        <Typography
          sx={{
            color: colors.grey[800],
            fontSize: 12,
            fontStyle: "italic"
          }}
        >
          {getTranslation("supported_remittance_files")}
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
        {getTranslation("senders_information")}
      </Typography>
      <Typography sx={{ color: colors.grey[500] }}>{getTranslation("enter_senders_name")}</Typography>

      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <TextInput
            variant="outlined"
            validate={validateRequiredNameFields}
            fullWidth
            source="sender_first_name"
            size="medium"
            inputProps={{
              ref: {
                ...register("sender_first_name", nameFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="outlined"
            validate={validateRequiredNameFields}
            fullWidth
            source="sender_last_name"
            size="medium"
            inputProps={{
              ref: {
                ...register("sender_last_name", nameFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="outlined"
            validate={validateNameFields}
            fullWidth
            source="sender_middle_name"
            size="medium"
            inputProps={{
              ref: {
                ...register("sender_middle_name", nameFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneInput
            source={"sender_phone_number"}
            variant="outlined"
            label={getTranslation("phone_number")}
            defaultCountry={watchSenderCountry?.code}
            size="medium"
            validate={[required()]}
          />
          {/* <BooleanInput
            label={<Typography variant={"body2"}>{getTranslation("send_sms_notification")}</Typography>}
            source="sender_send_sms"
          /> */}
        </Grid>
        <Grid item xs={4}>
          <DatePickerInput
            label={getTranslation("sender_date_of_birth")}
            views={["year", "month", "day"]}
            validate={[required()]}
            source="sender_date_of_birth"
            fullWidth
            disableFuture={true}
            maxDate={dayjs().subtract(18, "year")}
            minDate={dayjs().subtract(100, "year")}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="sender_gender"
            variant="outlined"
            fullWidth
            validate={[required()]}
            choices={[
              { id: "MALE", name: getTranslation("male") },
              { id: "FEMALE", name: getTranslation("female") },
              { id: "OTHER", name: getTranslation("other") }
            ]}
            size="medium"
          />
        </Grid>
      </Grid>
      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
        {getTranslation("sender_address")}
      </Typography>

      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <TextInput
            validate={validateAddressFields}
            variant="outlined"
            fullWidth
            source="sender_city"
            size="medium"
            inputProps={{
              ref: {
                ...register("sender_city", addressFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            validate={validateAddressFields}
            variant="outlined"
            fullWidth
            source="sender_state"
            size="medium"
            inputProps={{
              ref: {
                ...register("sender_state", addressFieldRules)
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={getTranslation("country")}
            defaultValue={getCountryNameFromCode(watchSenderCountry?.code, locale)}
            variant="outlined"
            fullWidth
            // source="sender_country"
            disabled
            required
            size="medium"
          />
        </Grid>
      </Grid>
      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
        {getTranslation("senders_id_info")}
      </Typography>

      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <SelectInput
            source="sender_id_type"
            validate={[required()]}
            fullWidth
            variant="outlined"
            choices={[
              { id: "NATIONAL_ID", name: getTranslation("national_id") },
              { id: "PASSPORT", name: getTranslation("passport") }
            ]}
            size="medium"
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            validate={getIDPassportValidation()}
            variant="outlined"
            fullWidth
            source="sender_id_number"
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            validate={[required()]}
            variant="outlined"
            fullWidth
            source="sender_place_of_issue"
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <DatePickerInput
            validate={getIssueDateValidation()}
            label={getTranslation("sender_date_of_issue")}
            variant="outlined"
            source="sender_date_of_issue"
            fullWidth
            disableFuture={true}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <DatePickerInput
            label={getTranslation("sender_expiry_date")}
            validate={getExpiryDateValidation()}
            source="sender_expiry_date"
            disabled={!watchedIssueDate}
            disablePast={true}
            minDate={isIssueDateToday() && dayjs().add(1, "day")}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput validate={[required()]} variant="outlined" fullWidth source="occupation" size="medium" />
        </Grid>
      </Grid>
      <Grid container columnSpacing={5}>
        <Grid item xs>
          <FileInput
            source="document_1"
            placeholder={<FilePlaceHolder />}
            accept={acceptedFileTypes}
            options={{ multiple: false, onDrop: (e) => onFileDrop("document_1", e) }}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
        <Grid item xs>
          <FileInput
            placeholder={<FilePlaceHolder />}
            accept={acceptedFileTypes}
            source="document_2"
            options={{ multiple: false, onDrop: (e) => onFileDrop("document_2", e) }}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </Grid>

      <Divider dashed={true} sx={{ marginBottom: 3, marginTop: 3 }} />
      <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
        {getTranslation("additional_information")}
      </Typography>

      <Grid container columnSpacing={5}>
        <Grid item xs={4}>
          <SelectInput
            source="sender_source_of_funds"
            fullWidth
            validate={[required()]}
            variant="outlined"
            choices={[
              { id: getTranslation("salary"), name: getTranslation("salary") },
              { id: getTranslation("pension"), name: getTranslation("pension") },
              { id: getTranslation("investment"), name: getTranslation("investment") }
            ]}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="sender_reason"
            fullWidth
            validate={[required()]}
            variant="outlined"
            choices={[
              { id: getTranslation("school_fees"), name: getTranslation("school_fees") },
              { id: getTranslation("house_rent"), name: getTranslation("house_rent") },
              { id: getTranslation("insurance"), name: getTranslation("insurance") },
              { id: getTranslation("allowance"), name: getTranslation("allowance") },
              { id: getTranslation("gift"), name: getTranslation("gift") }
            ]}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="sender_relationship_to_recipient"
            fullWidth
            validate={[required()]}
            variant="outlined"
            choices={[
              { id: getTranslation("mother"), name: getTranslation("mother") },
              { id: getTranslation("father"), name: getTranslation("father") },
              { id: getTranslation("sibling"), name: getTranslation("sibling") },
              { id: getTranslation("aunt"), name: getTranslation("aunt") },
              { id: getTranslation("uncle"), name: getTranslation("uncle") }
            ]}
            size="medium"
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            validate={[email(), required()]}
            variant="outlined"
            fullWidth
            source="sender_email"
            size="medium"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default RemittanceSendStepThree;
