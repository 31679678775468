export const headCells = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.first_name",
    sortable: true
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.last_name",
    sortable: true
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.phone_number",
    sortable: true
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.amount",
    sortable: true
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.description",
    sortable: true
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: true,
    align: "left",
    label: "bulk_collection.reference",
    sortable: true
  }
];
