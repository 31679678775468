import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import React from "react";
import { Create } from "react-admin";
import PayoutAccountCreateEditForm from "../Form/PayoutAccountCreateEditForm";
import { withStyles } from "@mui/styles";

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};

const PayoutAccountCreate = ({ classes, permissions, ...props }) => {
  return (
    <>
      <Breadcrumbs />
      <Create {...props} classes={{ card: classes.card }}>
        <PayoutAccountCreateEditForm permissions={permissions} />
      </Create>
    </>
  );
};
export default withStyles(styles)(PayoutAccountCreate);
