import {
  AGENT_SERVICE,
  BULK_COLLECTION_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_SERVICE,
  PAYMENT_REQUEST_HISTORY_SERVICE,
  PAYMENT_TRANSACTION_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE,
  COMPANY_PRL_SERVICE,
  REMITTANCE_SEND_SERVICE,
  REMITTANCE_HISTORY_SERVICE,
  AGENT_PAYMENTS_HISTORY_SERVICE,
  AGENT_PAYMENTS_HOME_SERVICE,
  REMITTANCE_REPORTS_SERVICE
} from "constants/serviceNames";
import { Translate } from "react-admin";
import {
  AGENT_PAYMENTS_CATEGORY,
  DISBURSEMENT_CATEGORY,
  HISTORY_AND_REPORT_CATEGORY,
  KYC_CATEGORY,
  MERCHANT_PAYMENTS_CATEGORY,
  REMITTANCE_CATEGORY
} from "constants/categoryNames";

const renderServiceTitle = (service: string, t: Translate) => {
  switch (service) {
    // Categories
    case KYC_CATEGORY:
      return t("cc.menu.kyc", { smart_count: 0 });
    case MERCHANT_PAYMENTS_CATEGORY:
      return t("cc.menu.merchant_payment", { smart_count: 0 });
    case DISBURSEMENT_CATEGORY:
      return t("cc.menu.disbursement", { smart_count: 0 });
    case HISTORY_AND_REPORT_CATEGORY:
      return t("cc.menu.history_and_report", { smart_count: 0 });
    case REMITTANCE_CATEGORY:
      return t("cc.menu.remittance", { smart_count: 0 });
    // Services
    case AGENT_PAYMENTS_CATEGORY:
      return t("cc.menu.agent_payments", { smart_count: 0 });
    // Services
    case AGENT_SERVICE:
      return t("cc.dashboard.agent", { smart_count: 1 });
    case COMPANY_SERVICE:
      return t("cc.dashboard.company", { smart_count: 1 });
    case AGENT_PAYMENTS_HOME_SERVICE:
      return t("cc.dashboard.agent_payments_home", { smart_count: 1 });
    case AGENT_PAYMENTS_HISTORY_SERVICE:
      return t("cc.dashboard.agent_payments_history", { smart_count: 1 });
    // Services
    case PRL_SERVICE:
      return t("cc.dashboard.prl", { smart_count: 1 });
    case PRODUCT_SERVICE:
      return t("cc.dashboard.product", { smart_count: 1 });
    case PAYMENT_TRANSACTION_SERVICE:
      return t("cc.dashboard.payment_transaction", { smart_count: 1 });
    case PAYMENT_REQUEST_HISTORY_SERVICE:
      return t("cc.dashboard.payment_request_history", { smart_count: 1 });
    case BULK_COLLECTION_SERVICE:
      return t("cc.dashboard.bulk_collection", { smart_count: 1 });
    case BULK_PAYMENT_SERVICE:
      return t("cc.dashboard.bulk_payment", { smart_count: 1 });
    case COMPANY_PRL_SERVICE:
      return t("cc.dashboard.company_prl", { smart_count: 1 });
    case REMITTANCE_SEND_SERVICE:
      return t("cc.dashboard.send", { smart_count: 1 });
    case REMITTANCE_HISTORY_SERVICE:
      return t("cc.dashboard.history", { smart_count: 1 });
    case REMITTANCE_REPORTS_SERVICE:
      return t("cc.dashboard.reports", { smart_count: 1 });
    default:
      return "";
  }
};

export default renderServiceTitle;
