import DeleteButton from "components/ra/Button/DeleteButton";
import React from "react";
import { usePermissions, useRecordContext } from "react-admin";
import findPermission from "utils/findPermission";

const ProductDeleteButton = (props: { dataTestId: string; redirect?: string }) => {
  const { permissions } = usePermissions();
  const { dataTestId } = props;
  const record = useRecordContext();
  const prlProductDelete = findPermission(permissions, "prl_product_delete_own");
  return <>{prlProductDelete && <DeleteButton record={record} data-testid={dataTestId} {...props} />}</>;
};

export default ProductDeleteButton;
