import getTotalOfProducts from "utils/getTotalOfProducts";
import { useGetOne, useRecordContext, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import { getStorageMerchantCCId } from "utils/storageUtils";
import { PrlObject, ProductObject } from "api/types/prlTypes";

const useGetShareLinks = (createResponseData?: ProductObject | PrlObject) => {
  const t = useTranslate();
  const record = useRecordContext();
  const ccid = getStorageMerchantCCId();
  const location = useLocation();

  const { data: merchantData } = useGetOne(
    "merchant",
    {
      id: ccid
    },
    { enabled: !!ccid }
  );
  // createResponseData is used for create flows where record doesn't exist
  const data = record || createResponseData || merchantData;
  // Service detection is performed done based on productExtId key
  const serviceType = !!data?.productExtId ? "product" : "prl";

  const link = data?.shortUrl || data?.merchantLinkShortened || data?.url || data?.merchantLink || "";
  const price = serviceType === "prl" ? getTotalOfProducts(data?.product) : data?.price;

  const emailBody = encodeURIComponent(
    t(`cc.share.${serviceType}.email.body`, {
      amount: price,
      merchantName: merchantData?.name,
      link,
      merchantAddress: merchantData?.address
    })
  );

  const emailSubject = encodeURIComponent(
    t(`cc.share.${serviceType}.email.subject`, {
      fullName: `${data?.customer?.nameFirst} ${data?.customer?.nameLast}`
    })
  );

  const whatsappBody = encodeURIComponent(
    t(`cc.share.${serviceType}.whatsapp.message`, {
      amount: price,
      merchantName: merchantData?.name,
      link
    })
  );

  let isDisabled = false;
  if (location.pathname.includes("company-prl")) {
    isDisabled = !data?.isCompanySpecificPrlActive;
  } else {
    isDisabled = data?.isFulfilled;
  }

  const links = [
    {
      name: "twitter",
      href: `https://twitter.com/intent/tweet?text=${link}`
    },
    {
      name: "whatsapp",
      href: `https://wa.me/?text=${whatsappBody}`
    },
    {
      name: "instagram",
      href: `https://www.instagram.com/?url=${link}`
    },
    {
      name: "facebook",
      href: `https://www.facebook.com/sharer/sharer.php?u=${link}`
    },
    {
      name: "email",
      href: `mailto:test@gmail.com?subject=${emailSubject}&body=${emailBody}`
    }
  ];

  const getLink = (service: string) => {
    const item = links.find((link) => link.name === service);

    return item?.href || "";
  };

  const singleLink = link;

  return { links, getLink, isDisabled, singleLink };
};

export default useGetShareLinks;
