import React from "react";
import Table from "components/material/Table/Table";
import { useRecordContext, useTranslate } from "react-admin";
import { mapValues } from "lodash";
import formatDate from "utils/formatDate";
import dayjs from "dayjs";

const KycCommentsTab = () => {
  const t = useTranslate();
  const record = useRecordContext();

  const headers = [
    t("resources.entity.fields.user"),
    t("resources.entity.fields.message"),
    t("resources.entity.fields.createdAt")
  ];
  const rows = record?.comments.map((comment) => {
    // Format dates properly
    return mapValues(comment, (value) => {
      if (dayjs(value).isValid()) {
        return formatDate(value, "downstream");
      }
      return value;
    });
  });
  return <Table headers={headers} rows={rows || []}></Table>;
};

export default KycCommentsTab;
