import { colors, Tooltip, TooltipProps } from "@mui/material";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: colors.cyan[600]
  },
  arrow: {
    color: colors.cyan[600]
  },
  icon: {
    color: colors.cyan[600],
    fontWeight: 700
  }
}));

type TooltipHelpProps = Omit<TooltipProps, "children">;

const TooltipHelp = (props: TooltipHelpProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement={"top"}
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
      {...props}
    >
      <HelpIcon className={classes.icon} />
    </Tooltip>
  );
};

export default TooltipHelp;
