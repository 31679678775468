const Flag = ({ code }: { code: string }) => {
  return (
    <img
      loading="lazy"
      src={`https://flagcdn.com/w20/${code}.png`}
      srcSet={`https://flagcdn.com/w40/${code}.png 2x`}
      alt={code}
      data-testid="option-flag"
    />
  );
};

export default Flag;
