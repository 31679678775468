import React from "react";
import { Show, useTranslate } from "react-admin";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import PayoutApprovalShowLayout from "./PayoutApprovalShowLayout";
import { withStyles } from "@mui/styles";

const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "inherit"
  }
};

const PayoutApprovalShow = ({ permissions, classes }) => {
  const t = useTranslate();
  return (
    <>
      <Breadcrumbs />
      <Show classes={{ card: classes.card }} title={t("payout.payout_detail")}>
        <PayoutApprovalShowLayout permissions={permissions} />
      </Show>
    </>
  );
};

export default withStyles(styles)(PayoutApprovalShow);
