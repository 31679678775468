import { Cancel, Check, Image, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  ClassNameMap,
  colors,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { ClassKeyOfStyles, makeStyles } from "@mui/styles";
import { DropzoneFileType, DropzoneType, FileRejection } from "components/types/otherComponentTypes";
import { useEffect, useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import { useDropzone } from "react-dropzone";
import { useFormState } from "react-hook-form";
import extensionExtractors from "./utils/extensionExtractor";

const useStyles = makeStyles({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
  },

  dropzone: {
    border: (props: { isError: boolean }) =>
      `2px dashed ${props.isError ? colors.red[500] : colors.cyan[500]}`,
    borderRadius: 6,
    padding: 16,
    height: 50,
    display: "flex",
    alignItems: "center"
  },

  thumb: {
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 170,
    height: 170,
    padding: 4,
    boxSizing: "border-box"
  },

  thumbInner: {
    display: "flex",
    minWidth: 0,
    flex: 1,
    overflow: "hidden",
    width: "100%"
  },

  thumbInput: {
    marginTop: 10
  },

  label: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold",
    marginBottom: 7
  },

  img: {
    display: "block",
    width: "100%",
    height: "100%"
  }
});

const Dropzone = (props: DropzoneType) => {
  const {
    files = [],
    onDelete,
    isRequired = false,
    maxFiles = 5,
    multiple = true,
    onUpdate,
    onDrop,
    loading,
    supportedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/gif",
      ".docx"
    ]
  } = props;
  const [errorMessage, setErrorMessage] = useState<string>();
  const notify = useNotify();
  const translate = useTranslate();
  const formProps = useFormState();

  const isError = isRequired && files.length === 0 && formProps.isSubmitted;

  const classes: ClassNameMap<ClassKeyOfStyles<string>> = useStyles({ isError });

  const getUnsupportedFiles = (items: FileRejection[] | DataTransferItemList) => {
    const unsupportedFiles: string[] = [];

    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      // Check if file is supported
      if (!supportedTypes.find((type: string) => type === element.type)) {
        const supported = element.type ?? "";
        unsupportedFiles.push(supported);
      }
    }

    return unsupportedFiles;
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    maxFiles: maxFiles,
    accept: supportedTypes,

    onDragOver: ({ dataTransfer }) => {
      const { items } = dataTransfer;
      const unsupportedFiles = getUnsupportedFiles(items);

      if (unsupportedFiles.length > 0) {
        setErrorMessage(unsupportedFiles.toString());
      }
    },
    onDragLeave: () => {
      setErrorMessage("");
    },
    onDrop: (file) => {
      setErrorMessage("");
    },
    onDropAccepted: (acceptedFiles) => {
      const newFiles: DropzoneFileType[] = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          label: file.name
        });
      });

      onDrop(newFiles);
    },
    onDropRejected: (files: FileRejection[]) => {
      const supportedExtensions = extensionExtractors(supportedTypes).join(" ");
      // Display error notification
      notify(translate("cc.notification.rejected_dropzone", { supportedExtensions }), { type: "error" });
    }
  });

  // Icon rendered based on file.type
  const renderPreviewIcon = (file: DropzoneFileType) => {
    const style = { fontSize: 60, color: colors.cyan[200] };
    switch (file.type) {
      case "image/png":
      case "image/jpeg":
        return <Image style={style} />;

      case "application/pdf":
        return <PictureAsPdf style={style} />;

      default:
        return <InsertDriveFile style={style} />;
    }
  };

  // Preview component
  const Thumb = (props: { file: DropzoneFileType; index: number }) => {
    const { file, index } = props;
    const [labelValue, setLabelValue] = useState<string>(file.label);
    return (
      <Paper
        sx={{ padding: 1, backgroundColor: colors.grey[50], marginRight: 2 }}
        data-testid="attachment-preview-thumb"
      >
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            size="small"
            className={classes.closeButton}
            onClick={() => {
              onDelete(file, index);
            }}
            data-testid="preview-thumb-remove-button"
          >
            <Cancel />
          </IconButton>

          <Box
            sx={{ width: 100, height: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {renderPreviewIcon(file)}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Tooltip title={labelValue}>
              <TextField
                style={{ width: 150 }}
                value={labelValue}
                variant="standard"
                onChange={(e) => setLabelValue(e.target.value)}
              />
            </Tooltip>
            <IconButton
              size="small"
              data-testid="update-button"
              disableFocusRipple
              onClick={() => onUpdate(labelValue, file, index)}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              <Check />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    );
  };

  const thumbs = files?.map((file: DropzoneFileType, index: number) => (
    <Thumb file={file} index={index} key={file.name} />
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files?.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, [files]);

  const supportedExtensions = extensionExtractors(supportedTypes).join(" ");

  return (
    <>
      <section className={classes.container}>
        <div
          {...getRootProps({ className: classes.dropzone })}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          data-testid="dropzone"
        >
          <input
            {...getInputProps()}
            style={{ display: process.env.TEST_MODE === "true" ? "block" : "none" }}
          />

          {loading ? (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : errorMessage ? (
            <span>
              {translate("bom.dropzone_not_supported_types")}:{" "}
              <span style={{ color: colors.deepOrange[600] }}>{errorMessage}</span>
            </span>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="body1" color={colors.grey[500]}>
                {translate("cc.dropzone.title")}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontStyle: "italic", color: colors.grey[500], marginTop: 5 }}
              >
                {translate("cc.dropzone.supported_extensions")} <strong>{supportedExtensions}</strong>
              </Typography>
            </Box>
          )}
        </div>

        <aside className={classes.thumbsContainer}>{thumbs}</aside>
      </section>

      {isError && (
        <Typography color={colors.red[500]} variant="body2">
          {translate("validation.required", {
            field: translate("form_field_labels.attachment")
          })}
        </Typography>
      )}
    </>
  );
};

export { Dropzone };
export default Dropzone;
