import dayjs from "dayjs";

const isTokenExpired = (exp: number | undefined) => {
  if (exp) {
    const expObjectMilliseconds = dayjs(exp * 1000);
    const timeNow = dayjs();
    const diffInMilliseconds = expObjectMilliseconds.diff(timeNow);

    if (diffInMilliseconds > 0) {
      return false;
    }
  }
  return true;
};

export default isTokenExpired;
