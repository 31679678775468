import { CheckCircle } from "@mui/icons-material";
import { colors, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { RaRecord, useRecordContext, UseRecordContextParams } from "react-admin";
import { renderLogo } from "utils/logoUtils";

const styles = {
  text: {
    fontSize: 14,
    fontWeight: "bold"
  },
  image: {
    height: 29,
    width: 40,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(25, 105, 135, .3)"
  },
  icon: {
    color: colors.green[600]
  }
};

const ListPayoutAccountProvider = (props: UseRecordContextParams<RaRecord>) => {
  const { classes } = props;
  const record = useRecordContext(props);
  return (
    <Grid container alignItems="center" spacing={4}>
      <Grid item xs={1}>
        {record?.isPreferred && <CheckCircle className={classes.icon} />}
      </Grid>
      <Grid item xs={2}>
        {record?.fspId === "MOBILEMONEY" ? (
          <img
            className={classes.image}
            src={renderLogo(record?.accountName)}
            alt={record?.accountName || ""}
          />
        ) : (
          <Typography className={classes.text}>{record?.accountName}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ListPayoutAccountProvider);
