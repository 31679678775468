import { Layout } from "react-admin";
import BomAppBar from "./components/BomAppBar/BomAppBar";
import MenuDrawer from "components/ra/other/BomLayout/components/MenuDrawer";
import { ReactQueryDevtools } from "react-query/devtools";

const BomLayout = (props) => {
  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <Layout {...props} menu={MenuDrawer} appBar={BomAppBar} />
    </>
  );
};

export default BomLayout;
