const extensionExtractors = (supportedTypes: string[]) => {
  return supportedTypes.map((type: string) => {
    const match = type.match(/\/(\w+)/);
    const str = match ? match[1] : "";

    let extension = "";

    if (str === "vnd") {
      // vnd types need to be extracted separately
      switch (type) {
        case "application/vnd.ms-excel":
          extension = ".xls";
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          extension = ".docx";
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          extension = ".xlsx";
          break;
      }
    } else {
      extension = str.length > 0 ? `.${str}` : extension;
    }

    return extension;
  });
};

export default extensionExtractors;
