export const IMAGE_JPEG = "image/jpeg";
export const IMAGE_PNG = "image/png";
export const IMAGE_GIF = "image/gif";
export const IMAGE_BMP = "image/bmp";
export const IMAGE_TIFF = "image/tiff";
export const IMAGE_WEBP = "image/webp";
export const IMAGE_SVG = "image/svg+xml";
export const VIDEO_MP4 = "video/mp4";
export const VIDEO_WEBM = "video/webm";
export const VIDEO_OGG = "video/ogg";
export const VIDEO_MPEG = "video/mpeg";
export const PDF_FILE = "application/pdf";
