export const UPDATE_FULFILLED = "UPDATE_FULFILLED";
export const UPDATE_UNFULFILLED = "UPDATE_UNFULFILLED";
export const CREATE_PRODUCT_ATTACHMENT = "CREATE_PRODUCT_ATTACHMENT";
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
export const GET_FILE = "GET_FILE";

export const EXPORT_FILE = "EXPORT_FILE";
export const GET_MANY_ATTACHMENT = "GET_MANY_ATTACHMENT";
export const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
export const DOWNLOAD_KYC_PDF = "DOWNLOAD_KYC_PDF";
export const DOWNLOAD_KYC_DOCUMENT = "DOWNLOAD_KYC_DOCUMENT";
export const GET_CONFIG = "GET_CONFIG";
export const GET_SERVICE_CHARGE = "GET_SERVICE_CHARGE";
export const REMIT = "REMIT";
export const SINGLE_STEP_PAYMENT = "SINGLE_STEP_PAYMENT";
export const UPLOAD_REMITTANCE_DOCUMENTS = "UPLOAD_REMITTANCE_DOCUMENTS";
export const GET_REMITTANCE_TRANSACTION = "GET_REMITTANCE_TRANSACTION";
export const APPROVE_KYC_DATA = "APPROVE_KYC_DATA";
export const REJECT_KYC_DATA = "REJECT_KYC_DATA";
export const UPLOAD_MERCHANT_LOGO = "UPLOAD_MERCHANT_LOGO";
export const CONFIRM_MERCHANT_PAYMENT = "CONFIRM_MERCHANT_PAYMENT";
export const ADD_CONFIRMATION_NOTE = "ADD_CONFIRMATION_NOTE";
export const APPROVE_PAYOUT_REQUEST = "APPROVE_PAYOUT_REQUEST";
export const REJECT_PAYOUT_REQUEST = "REJECT_PAYOUT_REQUEST";
export const APPROVE_BULK_PAYMENT = "APPROVE_BULK_PAYMENT";
export const REJECT_BULK_PAYMENT = "REJECT_BULK_PAYMENT";
export const GET_BALANCE = "GET_BALANCE";
export const GET_MERCHANT = "GET_MERCHANT";
export const ENABLE_COMPANY_PRL = "ENABLE_COMPANY_PRL";
export const DISABLE_COMPANY_PRL = "DISABLE_COMPANY_PRL";
export const DOWNLOAD_COMPANY_PRL_QRCODE = "DOWNLOAD_COMPANY_PRL_QRCODE";
export const SWITCH_ADMIN_CONTEXT = "SWITCH_ADMIN_CONTEXT";
export const AGENT_PAYMENTS_GET_SERVICE = "AGENT_PAYMENTS_GET_SERVICE";
export const BILL_SINGLE_STEP_CONFIRM = "BILL_SINGLE_STEP_CONFIRM";
export const BILL_VERIFY = "BILL_VERIFY";
export const COLLECTOR_COMPANY_MANAGEMENT_SERVICE = "COLLECTOR_COMPANY_MANAGEMENT_SERVICE";
export const REMITTANCE_RSP_REPORTS_SERVICE = "REMITTANCE_RSP_REPORTS_SERVICE";
export const DOWNLOAD_INTERNAL_REPORTS = "DOWNLOAD_INTERNAL_REPORTS";
export const DOWNLOAD_COLLECTOR_COMPANY_REPORTS = "DOWNLOAD_COLLECTOR_COMPANY_REPORTS";
export const DOWNLOAD_TRANSACTION_REPORTS = "DOWNLOAD_TRANSACTION_REPORTS";
export const DOWNLOAD_RSP_REPORTS = "DOWNLOAD_RSP_REPORTS";
export const DOWNLOAD_TRANSACTION_RECEIPT = "DOWNLOAD_TRANSACTION_RECEIPT";
