import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FC } from "react";
import NoData from "./components/NoData";
import { CardBaseTypes } from "./types/cardTypes";

const useStyles = makeStyles({
  card: {
    minWidth: (props: { variant: string; type: string }) => {
      switch (props.type) {
        case "data-display":
          return 50;

        default:
          return 400;
      }
    },
    borderRadius: 8
  },

  cardHeader: {
    background: (props) => {
      switch (props.variant) {
        case "outlined":
          return "white";
        case "classic-dashed":
          return "white";
        default:
          return colors.cyan[600];
      }
    },
    color: (props) => {
      switch (props.variant) {
        case "outlined":
          return colors.grey[800];
        case "classic-dashed":
          return colors.grey[800];
        default:
          return "white";
      }
    },
    padding: "20px 24px",
    paddingBottom: 12,
    fontWeight: 700,
    fontSize: 18,
    // (props) => (props.type === "data-display" ? 18 : 18),
    borderBottom: (props) => {
      switch (props.variant) {
        case "outlined":
          return `1px solid ${colors.grey[400]}`;
        case "classic-dashed":
          return `1px dashed ${colors.grey[400]}`;
        default:
          return "none";
      }
    }
  },

  cardHeaderAction: {
    margin: 0
  },

  cardHeaderContent: {
    display: "flex",
    justifyContent: (props) => (props.type === "data-display" ? "center" : "flex-start")
  },

  footerContent: {
    padding: "18px 24px"
  },

  cardContent: {
    padding: "18px 24px",
    backgroundColor: (props) => {
      switch (props.variant) {
        case "outlined":
        case "classic-white":
          return "white";
        case "classic-dashed":
          return "white";
        default:
          return colors.cyan[50];
      }
    }
  },

  cardContentNoPadding: {
    padding: 0,
    "&:last-child": {
      padding: 0
    }
  }
});

//TODO: CardBase props should be moved into separate file and used for extend functionality of depended components
const CardBase: FC<CardBaseTypes> = ({
  cardContentProps,
  cardTitle,
  children,
  footerContent,
  footerContentProps,
  headerActionContent,
  isEmpty,
  dataTestId,
  isLoading,
  noPadding,
  // Type can be data-display, default
  type = "default",
  // Variant can be classic, classic-white, outlined
  variant = "classic-white",
  ...props
}) => {
  const classes = useStyles({ variant, type });
  const calculateElevation = () => {
    switch (variant) {
      case "outlined":
        return 4;
      case "classic-dashed":
        return 0;
      default:
        return 2;
    }
  };

  const renderContent = () => {
    if (isEmpty) {
      return <NoData />;
    }
    if (isLoading) {
      return (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 120 }}
        >
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    return (
      <CardContent
        {...cardContentProps}
        className={classNames(classes.cardContent, { [classes.cardContentNoPadding]: noPadding })}
      >
        {children}
      </CardContent>
    );
  };

  return (
    <Card {...props} className={classes.card} elevation={calculateElevation()} data-testid={dataTestId}>
      {cardTitle && (
        <CardHeader
          data-testid="card-header"
          disableTypography
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction,
            content: classes.cardHeaderContent
          }}
          title={cardTitle}
          action={headerActionContent}
        ></CardHeader>
      )}

      {renderContent()}

      {footerContent && (
        <CardActions className={classes.footerContent} {...footerContentProps}>
          {footerContent}
        </CardActions>
      )}
    </Card>
  );
};

export default CardBase;
