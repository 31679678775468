import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Create, CreateProps } from "react-admin";
import BulkPaymentCreateForm from "../Form/BulkPaymentCreateForm";
import { Toolbar } from "@mui/material";

const BulkPaymentCreate = (props: CreateProps) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"bulk_payment"} page={"create"} />
      </Toolbar>
      <Create resource="bulk_payment" component="div" {...props}>
        <BulkPaymentCreateForm />
      </Create>
    </>
  );
};

export default BulkPaymentCreate;
