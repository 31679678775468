import FileCopy from "@mui/icons-material/FileCopy";
import { Box, IconButton, Link, Paper, Tooltip } from "@mui/material";
import { CopyLinkToClipboardTypes } from "components/types/otherComponentTypes";
import React from "react";
import { useNotify } from "react-admin";
import { copyToClipboard } from "utils/utils";

const CopyLinkToClipboard: React.FC<CopyLinkToClipboardTypes> = ({
  url,
  maxWidth = 450
}: CopyLinkToClipboardTypes) => {
  const notify = useNotify();
  const handleClick = async () => {
    await copyToClipboard(url);
    notify("cc.notification.copied", { type: "success" });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} data-testid="copy-component">
      <Paper sx={{ padding: 1, display: "flex", alignItems: "center" }}>
        <Tooltip title={url}>
          <Link href={url} noWrap sx={{ maxWidth }}>
            {url}
          </Link>
        </Tooltip>
        <IconButton onClick={handleClick}>
          <FileCopy />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default CopyLinkToClipboard;
