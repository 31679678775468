import { Link, List, ListItem, ListItemIcon } from "@mui/material";
import { AttachFileOutlined, ImageOutlined, PictureAsPdfOutlined } from "@mui/icons-material";
import { saveAs } from "file-saver";
import React from "react";
import config from "config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 16
  },
  listItemIcon: {
    minWidth: 40
  }
}));

const BomAttachment = ({ content }) => {
  const classes = useStyles();
  const renderAttachmentIcon = (type) => {
    switch (type) {
      case "image/jpeg":
      case "image/png":
        return <ImageOutlined />;

      case "application/pdf":
        return <PictureAsPdfOutlined />;

      default:
        return <AttachFileOutlined />;
    }
  };

  const downloadAttachment = (item) => {
    const fullUrl = `${config.REACT_APP_PROXY_BASE_URL}${item?.url}`;
    let fileName = item?.label;
    if (item.mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      fileName = `${fileName}.docx`;
    }
    saveAs(fullUrl, fileName);
  };

  return (
    <List style={{ width: "100%" }}>
      {content?.map((item, index) => {
        return (
          <ListItem key={index}>
            <ListItemIcon className={{ root: classes.listItemIcon }}>
              {renderAttachmentIcon(item?.mimeType)}
            </ListItemIcon>
            <Link
              noWrap
              color="textPrimary"
              component="button"
              className={classes.link}
              variant="body2"
              onClick={() => downloadAttachment(item)}
            >
              {item?.label}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};

export default BomAttachment;
