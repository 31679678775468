// NOTE
// This hook requires component to be wrapped with ResourceContextProvider
// Or to have access to ResourceContext in some other way

import { useResourceContext, useTranslate } from "react-admin";

export const useGetTranslation = () => {
  const translate = useTranslate();
  const resource = useResourceContext();

  return (key: string, component?: string) => {
    let str = "";
    // Use cc translations if component is passed
    if (component) {
      str = `cc.${component}.${key}}`;
    }

    // Use resource translations if component is not passed
    str = `resources.${resource}.fields.${key}`;

    return translate(str);
  };
};

export default useGetTranslation;
