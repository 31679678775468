import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Create } from "react-admin";
import BulkCollectionCreateForm from "../Form/BulkCollectionCreateForm";
import { withStyles } from "@mui/styles";
const styles = {
  card: {
    boxShadow: "none",
    backgroundColor: "transparent"
  }
};
const BulkCollectionCreate = ({ classes, ...props }) => {
  return (
    <>
      <Breadcrumbs />
      <Create {...props} classes={{ card: classes.card }}>
        <BulkCollectionCreateForm flow="create" />
      </Create>
    </>
  );
};

export default withStyles(styles)(BulkCollectionCreate);
