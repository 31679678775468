import React from "react";
import { Edit, EditProps } from "react-admin";
import PrlCreateEditForm from "components/ra/Form/PrlCreateEditForm";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import { Toolbar } from "@mui/material";
import { FORM_TYPE_EDIT } from "constants/formTypes";

const PrlCreate = (props: EditProps) => {
  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"prl"} page={"edit"} />
      </Toolbar>
      <Edit {...props} resource="prl">
        <PrlCreateEditForm formType={FORM_TYPE_EDIT} />
      </Edit>
    </>
  );
};

export default PrlCreate;
