export const AGENT_SERVICE = "AGENT_SERVICE";
export const COMPANY_SERVICE = "COMPANY_SERVICE";
export const PRODUCT_SERVICE = "PRODUCT_SERVICE";
export const PRL_SERVICE = "PRL_SERVICE";
export const BULK_PAYMENT_SERVICE = "BULK_PAYMENT_SERVICE";

export const REMITTANCE_HISTORY_SERVICE = "REMITTANCE_HISTORY_SERVICE";

export const REMITTANCE_SEND_SERVICE = "REMITTANCE_SEND_SERVICE";
export const REMITTANCE_REPORTS_SERVICE = "REMITTANCE_REPORTS_SERVICE";
export const BULK_COLLECTION_SERVICE = "BULK_COLLECTION_SERVICE";
export const PAYMENT_TRANSACTION_SERVICE = "PAYMENT_TRANSACTION_SERVICE";
export const PAYMENT_REQUEST_HISTORY_SERVICE = "PAYMENT_REQUEST_HISTORY_SERVICE";
export const MERCHANT_SERVICE = "MERCHANT_SERVICE";
export const COMPANY_PRL_SERVICE = "COMPANY_PRL_SERVICE";
export const AGENT_PAYMENTS_HOME_SERVICE = "AGENT_PAYMENTS_HOME_SERVICE";
export const AGENT_PAYMENTS_HISTORY_SERVICE = "AGENT_PAYMENTS_HISTORY_SERVICE";
