import { makeStyles } from "@mui/styles";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import MenuShare from "components/material/Menu/MenuShare";
import ChipStatus from "components/other/context/StatusField";
import { MERCHANT_READ_OWN, PRL_CREATE, PRL_READ_OWN } from "constants/permissionsNames";
import { statusTransactionArray } from "constants/status";
import { i18nProvider } from "i18n/i18nProvider";

import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  ResourceComponentInjectedProps,
  ResourceContextProvider,
  SelectArrayInput,
  SelectInput,
  SelectInputProps,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  usePermissions,
  useTranslate
} from "react-admin";

import findPermission from "utils/findPermission";
import ProviderField from "components/ra/List/PrlList/components/ProviderField";
import PrlDeleteButton from "./components/PrlDeleteButton";
import PrlEditButton from "./components/PrlEditButton";
import { Toolbar, Typography } from "@mui/material";
import useGetMerchantBalance from "hooks/useGetMerchantBalance";
import DatepickerRange from "components/other/Datepicker/DatepickerRange";
import { useUtils } from "context/UtilsContext";
import ExportButton from "components/ra/Button/ExportButton";
import ShowButton from "components/ra/Button/ShowButton";
import CreateButton from "components/ra/Button/CreateButton";

const useStyles = makeStyles({
  root: { minWidth: 220 }
});

const StyledSelectInput = (props: SelectInputProps) => {
  const classes = useStyles();
  return <SelectInput className={classes.root} {...props} />;
};

const ListActions = ({ permissions }: ResourceComponentInjectedProps) => {
  const prlCreate = findPermission(permissions, PRL_CREATE);
  const prlReadOwn = findPermission(permissions, PRL_READ_OWN);
  return (
    <TopToolbar>
      <FilterButton data-testid="filter-button" />
      {prlCreate && <CreateButton data-testid="create-button" />}
      {prlReadOwn && <ExportButton data-testid="export-button" />}
    </TopToolbar>
  );
};

export const PrlDataList = ({ ...rest }) => {
  const { permissions } = usePermissions();
  const { balance: merchantBalance } = useGetMerchantBalance();
  const t = useTranslate();
  const paymentStatusChoices = statusTransactionArray.map((status) => ({
    id: status,
    name: t(`resources.prl.fields.${status}`.toLowerCase()).toUpperCase()
  }));
  const locale = i18nProvider.getLocale();
  const { merchantCCId } = useUtils();

  const fulfilledChoices = [
    { id: true, name: `${t("ra.boolean.true")}` },
    { id: false, name: `${t("ra.boolean.false")}` }
  ];

  const { data: paymentMethodData } = useGetList("payment-method");

  const filters = [
    <TextInput source="customer.nameFirst" data-testid="filter-nameFirst" />,
    <TextInput source="customer.nameLast" data-testid="filter-nameLast" />,
    // <NumberInput label={t("prl.list.amount")} source="product.price" />,
    <DatepickerRange source={"createdAt"} dataTestId={"filter-createdAt"} />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="paymentStatus"
      choices={paymentStatusChoices}
      data-testid="filter-paymentStatus"
    />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      data-testid="filter-provider"
      source="provider"
      choices={
        paymentMethodData &&
        Object.values(paymentMethodData).map((paymentMethod) => ({
          id: paymentMethod.paymentProviderId,
          name: paymentMethod?.nameFriendly?.find((name: { lang: string }) => name.lang === locale)?.text
        }))
      }
    />,
    <StyledSelectInput data-testid="filter-fulfilled" source="isFulfilled" choices={fulfilledChoices} />
  ];

  const merchantReadOwnPermission = findPermission(permissions, MERCHANT_READ_OWN);

  let filter: { entityOrganisationExternalId?: string } = {};
  if (merchantCCId) {
    filter = {
      entityOrganisationExternalId: merchantCCId
    };
  } else {
    filter = {};
  }
  return (
    <ResourceContextProvider value="prl">
      <>
        <Toolbar
          variant={"regular"}
          disableGutters={true}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Breadcrumbs service={"prl"} page={"list"} />
          {merchantReadOwnPermission && (
            <Typography data-testid={"merchant-balance"} variant={"h6"}>
              {t("cc.merchant_balance.title")}: <strong>{merchantBalance}</strong>
            </Typography>
          )}
        </Toolbar>
        <List
          {...rest}
          // syncWithLocation
          actions={<ListActions permissions={permissions} />}
          filters={filters}
          filter={filter}
          // exporter={(items) => prlExporter({ t, items })}
          sort={{ field: "createdAt", order: "DESC" }}
          hasCreate
        >
          <Datagrid bulkActionButtons={false} data-testid="datagrid">
            <TextField source="customer.nameFirst" />
            <TextField source="customer.nameLast" />
            <TextField source="reference" noWrap sx={{ width: 200, display: "block" }} />
            {/* TODO: [PRLSVC-934] Add order reference */}
            {/* <TotalAmount label={t("prl.list.amount")} source="amount" /> */}
            <DateField source="createdAt" locales="fr-FR" />
            <ChipStatus source="paymentStatus" />
            <ChipStatus source="isFulfilled" />
            <ProviderField source={"provider"} sortable={false} />
            <MenuShare />
            <ShowButton />
            <PrlEditButton dataTestId="edit-button" />
            <PrlDeleteButton dataTestId="delete-button" />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default PrlDataList;
