import {
  Box,
  colors,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useRecordContext, useTranslate } from "react-admin";

const styles = {
  topMenuTitle: {
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
    padding: 10
  },
  rejectTitle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#fff",
    padding: 10
  },
  leftTopPane: {
    borderRight: `1px solid #fff`
  },
  centerPane: {
    borderRight: `1px solid #fff`
  },
  rejectContent: {
    padding: 10
  },
  topBox: {
    borderBottom: `1px solid #fff`,
    backgroundColor: colors.cyan[600]
  },
  tableCell: {
    fontWeight: "bold"
  }
};

const BulkCollectValidationTable = (props) => {
  const t = useTranslate();
  const record = useRecordContext(props);
  const { classes } = props;

  const TopMenu = () => {
    return (
      <Box className={classes.topBox}>
        {record?.status === "REJECTED" ? (
          <>
            <Typography className={classes.rejectTitle}>{t("bulk_pay.reason_for_rejection")}</Typography>
          </>
        ) : (
          <Grid container noPadding>
            <Grid item xs className={classes.leftTopPane}>
              <Typography className={classes.topMenuTitle}>{t("bulk_collection.validated")}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.centerPane}>
              <Typography className={classes.topMenuTitle}>{t("bulk_pay.processing")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.topMenuTitle}>{t("bulk_collection.results")}</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <>
      <TopMenu />
      {record?.status === "REJECTED" ? (
        <Box variant="div" className={classes.rejectContent}>
          <Typography>{record?.rejection_reason}</Typography>
        </Box>
      ) : (
        <Grid container noPadding>
          <Grid item xs className={classes.leftTopPane}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.imported")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.valid")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.invalid")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{record?.cnt_rows}</TableCell>
                    <TableCell align="center">{record?.cnt_valid}</TableCell>
                    <TableCell align="center">{record?.cnt_invalid}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={2} className={classes.centerPane}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.pending")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{record?.cnt_pending}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.success")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_pay.errors")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {t("bulk_collection.total")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{record?.cnt_success}</TableCell>
                    <TableCell align="center">{record?.cnt_error}</TableCell>
                    <TableCell align="center">{record?.cnt_total}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(BulkCollectValidationTable);
