import { Typography } from "@mui/material";
import ProviderLogo from "components/other/context/ProviderLogo";
import React from "react";
import { RaRecord, useRecordContext, UseRecordContextParams } from "react-admin";
import { renderLogo } from "utils/logoUtils";

const PayoutRequestProvider = (props: UseRecordContextParams<RaRecord>) => {
  const record = useRecordContext(props);
  return (
    <>
      {renderLogo(record?.requestInfo?.fspId) ? (
        <ProviderLogo provider={record?.requestInfo?.fspId} />
      ) : (
        <Typography>{record?.requestInfo?.fspId}</Typography>
      )}
    </>
  );
};

export default PayoutRequestProvider;
