/* eslint-disable @typescript-eslint/consistent-type-assertions */
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};

/**
 * Supported amount type for the payment |Type|Description| |FIXED|   Payment needs to made paid in full, (Payment amount = value provided in the \"amount\" field)| |PARTIAL| Partial payment can be made (Payment amount < value provided in the \"amount\" field))| |OVERPAY| More than the actual amount owed can be paid. (Payment amount > value provided in the \"amount\" field). Overpayments are subject to country specific regulations and may be limited to a certain threshold| |CUSTOM|  Amount can be freely entered, independent of any value provided in the \"amount\" field|
 * @export
 * @enum {string}
 */
export enum AmountTypeEnum {
  FIXED = <any>"FIXED",
  CUSTOM = <any>"CUSTOM",
  PARTIAL = <any>"PARTIAL",
  OVERPAY = <any>"OVERPAY"
}
/**
 *
 * @export
 * @interface Bill
 */
export interface Bill {
  /**
   *
   * @type {BillTypeEnum}
   * @memberof Bill
   */
  billType: BillTypeEnum;
  /**
   * Late payment penalty amount in local currency.
   * @type {number}
   * @memberof Bill
   */
  penaltyAmount: number;
  /**
   * Payment order. The bill with the lowest number has to be paid first, starting with 1. If no payment order is enforced, all bills have the order 0.
   * @type {number}
   * @memberof Bill
   */
  payOrder: number;
  /**
   * Service number with merchant (e.g. meter number in bills from a utility provider or a phone number for a mobile operator) for which to perform the bill payment
   * @type {string}
   * @memberof Bill
   */
  serviceNumber: string;
  /**
   * Unique Smobilpay Service Identifier
   * @type {string}
   * @memberof Bill
   */
  serviceId: string;
  /**
   * Unique Smobilpay merchant code
   * @type {string}
   * @memberof Bill
   */
  merchant: string;
  /**
   * Unique Smobilpay Payment Item ID identifying the topup package to be purchased
   * @type {string}
   * @memberof Bill
   */
  payItemId: string;
  /**
   *
   * @type {AmountTypeEnum}
   * @memberof Bill
   */
  amountType: AmountTypeEnum;
  /**
   * Local currency of service. (Format: ISO 4217)
   * @type {string}
   * @memberof Bill
   */
  currency: string;
  /**
   * Open bill amount in local currency (only searchable bills). Empty for NON-SEARCHABLE bills. This amount also includes any applicable late payment penalties.
   * @type {number}
   * @memberof Bill
   */
  amount: number;
  /**
   * Unique bill number in selected merchant service
   * @type {string}
   * @memberof Bill
   */
  billNumber: string;
  /**
   * Customer number with merchant
   * @type {string}
   * @memberof Bill
   */
  customerNumber: string;
  /**
   * Customer name of bill
   * @type {string}
   * @memberof Bill
   */
  customerName?: string;
  /**
   * Month of bill generation. Format: MM e.g. \"03\" for March
   * @type {string}
   * @memberof Bill
   */
  billMonth: string;
  /**
   * Year of bill generation. Format: YYYY e.g. \"2016\"
   * @type {string}
   * @memberof Bill
   */
  billYear: string;
  /**
   * Exact date of bill generation (Format: ISO 8601)
   * @type {string}
   * @memberof Bill
   */
  billDate: string;
  /**
   * Bill due date (Format: ISO 8601)
   * @type {string}
   * @memberof Bill
   */
  billDueDate: string;
  /**
   * Contains optional description about payment details (e.g. merchant provided bill types)
   * @type {string}
   * @memberof Bill
   */
  payItemDescr?: string;
}
/**
 * Type classification of the bill |Type|Description| |REGULAR| Regular Bill| |LATE|    Late bill|
 * @export
 * @enum {string}
 */
export enum BillTypeEnum {
  REGULAR = <any>"REGULAR",
  LATE = <any>"LATE"
}
/**
 * Unique identifier for every request. Can be used for tracking down answers with technical support.  Note: this will be different for every request.
 * @export
 */
export type CorrelationIdObject = string;
/**
 * Account of customer if customer was identifiable
 * @export
 * @interface CustomerAccount
 */
export interface CustomerAccount {
  /**
   * Status of customer account. Indicates if account was: - verified (account has been positivly crosschecked against service provider) - validated (account syntax has been internally confirmed - e.g. against a regex check) - unknown (authenticity of account could be neither verified nor validated)
   * @type {string}
   * @memberof CustomerAccount
   */
  status: CustomerAccount.StatusEnum;
  /**
   * Customer name - if found
   * @type {string}
   * @memberof CustomerAccount
   */
  name?: string;
  /**
   * Account destination identifier used during account lookup
   * @type {string}
   * @memberof CustomerAccount
   */
  destination: string;
}

/**
 * @export
 * @namespace CustomerAccount
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomerAccount {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    UNKNOWN = <any>"UNKNOWN",
    VALIDATED = <any>"VALIDATED",
    VERIFIED = <any>"VERIFIED"
  }
}
/**
 * Defines supported delivery methods | Type  | Meaning| |----|----| |BANK| Remittance to Bank Account| |CASHPU | Cash Pickup in Receiving Country| |MMO| Remittance to Mobile Money Account|
 * @export
 * @enum {string}
 */
export enum DeliveryMethodEnum {
  BANK = <any>"BANK",
  CASHPU = <any>"CASHPU",
  MMO = <any>"MMO"
}
/**
 *
 * @export
 * @interface ErrorObject
 */
export interface ErrorObject {
  /**
   * error code
   * @type {string}
   * @memberof ErrorObject
   */
  respCode: string;
  /**
   * Will describe the error
   * @type {string}
   * @memberof ErrorObject
   */
  devMsg: string;
  /**
   * optional user friendly error message for presentation
   * @type {string}
   * @memberof ErrorObject
   */
  customerMsg?: string;
  /**
   *
   * @type {CorrelationIdObject}
   * @memberof ErrorObject
   */
  cId: CorrelationIdObject;
}
/**
 *
 * @export
 * @interface ErrorObjectIssues
 */
export interface ErrorObjectIssues {
  /**
   * Description of the problem
   * @type {string}
   * @memberof ErrorObjectIssues
   */
  issue?: string;
  /**
   * Will describe the field or data location of the issue
   * @type {string}
   * @memberof ErrorObjectIssues
   */
  issueLocation: string;
}
/**
 *
 * @export
 * @interface I18NValue
 */
export interface I18NValue {
  /**
   * Language of value text in iso notation (en | fr)
   * @type {string}
   * @memberof I18NValue
   */
  lang: string;
  /**
   * Label translation for UI presentation on device
   * @type {string}
   * @memberof I18NValue
   */
  value: string;
}
/**
 *
 * @export
 * @interface PaymentCallbackRequest
 */
export interface PaymentCallbackRequest {
  /**
   * Timestamp when payment entered final state
   * @type {Date}
   * @memberof PaymentCallbackRequest
   */
  timestamp?: Date;
  /**
   * Transaction Identifier generated in external system. Will be set to an empty string if no transaction identifier was provided
   * @type {string}
   * @memberof PaymentCallbackRequest
   */
  trid: string;
  /**
   *
   * @type {StatusEnum}
   * @memberof PaymentCallbackRequest
   */
  status: StatusEnum;
  /**
   * Unique error response code identifying the issue. We recommend you use this code for internal error handling.
   * @type {number}
   * @memberof PaymentCallbackRequest
   */
  errorCode?: number;
}
/**
 *
 * @export
 * @interface PaymentConfirmationRequest
 */
export interface PaymentConfirmationRequest {
  /**
   * optional callback url to notify when payment changes to a final state
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  callbackUrl?: string;
  /**
   * Unique quote number (UUID) of the related quote that was previously requested.
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  quoteId: string;
  /**
   * custom external transaction reference - custom field to be freely used for internal payment collection referencing. Should be unique. **NOTE:** Smobilpay does not manage transaction references (e.g. run unique validation) - this value needs to be managed by the client system.
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  trid: string;
  /**
   *
   * @type {Array<SubmitData>}
   * @memberof PaymentConfirmationRequest
   */
  submitData: Array<SubmitData>;
  /**
   * System provide payment authorization token to reference the payment method authorization
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  payAuthToken: string;
  /**
   * Custom field to be freely used for internal payment collection referencing and tagging
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  tag?: string;
  /**
   * remittance cdata object - must comply to contract
   * @type {string}
   * @memberof PaymentConfirmationRequest
   */
  cdata?: string;
  /**
   * latitude information of collection
   * @type {number}
   * @memberof PaymentConfirmationRequest
   */
  lat?: number;
  /**
   * longitude information of collection
   * @type {number}
   * @memberof PaymentConfirmationRequest
   */
  lng?: number;
}
/**
 *
 * @export
 * @interface PaymentConfirmationStatus
 */
export interface PaymentConfirmationStatus {
  /**
   * Unique payment collection transaction number
   * @type {string}
   * @memberof PaymentConfirmationStatus
   */
  ptn: string;
  /**
   * custom external transaction reference provided during payment collection
   * @type {string}
   * @memberof PaymentConfirmationStatus
   */
  trid: string;
  /**
   *
   * @type {StatusEnum}
   * @memberof PaymentConfirmationStatus
   */
  status: StatusEnum;
  /**
   *
   * @type {PaymentError}
   * @memberof PaymentConfirmationStatus
   */
  error?: PaymentError;
}
/**
 *
 * @export
 * @interface PaymentError
 */
export interface PaymentError {
  /**
   * Unique error response code identifying the issue. We recommend you use this code for internal error handling.
   * @type {number}
   * @memberof PaymentError
   */
  respCode: number;
  /**
   * Verbose, plain language description of the problem for the app developer with hints about how to fix it, if applicable.
   * @type {string}
   * @memberof PaymentError
   */
  devMsg: string;
  /**
   *
   * @type {Array<I18NValue>}
   * @memberof PaymentError
   */
  customerMsg: Array<I18NValue>;
}
/**
 * Supported payment methods |Type|Description| |502| Cash Collection|
 * @export
 * @enum {string}
 */
export enum PaymentMethodEnum {
  _502 = <any>"502"
}
/**
 *
 * @export
 * @interface Quote
 */
export interface Quote {
  /**
   * Unique quote number (UUID)
   * @type {string}
   * @memberof Quote
   */
  quoteId: string;
  /**
   * timestamp of expiration of conversion
   * @type {Date}
   * @memberof Quote
   */
  expiresAt: Date;
  /**
   * Unique Smobilpay Payment Item ID identifying the item for which the quote was requested
   * @type {string}
   * @memberof Quote
   */
  payItemId: string;
  /**
   * Local currency of service. (Format: ISO 4217)
   * @type {string}
   * @memberof Quote
   */
  currency: string;
  /**
   * Service fee amount in local currency
   * @type {number}
   * @memberof Quote
   */
  feeAmount: number;
  /**
   * Extra charges for paying with the selected payment method
   * @type {number}
   * @memberof Quote
   */
  payMethodSurCharge: number;
  /**
   * Original amount
   * @type {number}
   * @memberof Quote
   */
  amount: number;
  /**
   * Total amount paid (amount + fee + pay method charges) in local currency
   * @type {number}
   * @memberof Quote
   */
  totalAmount: number;
}
/**
 *
 * @export
 * @interface QuoteRequest
 */
export interface QuoteRequest {
  /**
   * Unique Smobilpay Payment Item ID identifying the item for which the quote was requested
   * @type {string}
   * @memberof QuoteRequest
   */
  payItemId: string;
  /**
   * Amount to be collected during the payment (in local currency of the payment item). Value depends on the payment items amountType
   * @type {number}
   * @memberof QuoteRequest
   */
  amount: number;
  /**
   *
   * @type {PaymentMethodEnum}
   * @memberof QuoteRequest
   */
  payMethod: PaymentMethodEnum;
}
/**
 * Defines remittance payload. Can either be Send OR Receive. not both
 * @export
 * @interface Remit
 */
export interface Remit {
  /**
   *
   * @type {DeliveryMethodEnum}
   * @memberof Remit
   */
  deliveryMethod: DeliveryMethodEnum;
  /**
   * Delivery Partner Code
   * @type {string}
   * @memberof Remit
   */
  deliveryPartnerCode: string;
  /**
   * remittance cdata object - must comply to contract
   * @type {string}
   * @memberof Remit
   */
  cdata: string;
  /**
   * SEND Country ISO 3166
   * @type {string}
   * @memberof Remit
   */
  sendCountry?: string;
  /**
   * SEND Amount transferred in operation
   * @type {number}
   * @memberof Remit
   */
  sendAmount?: number;
  /**
   * RECEIVE Country ISO 3166
   * @type {string}
   * @memberof Remit
   */
  receiveCountry?: string;
  /**
   * RECEIVE Amount transferred in operation
   * @type {number}
   * @memberof Remit
   */
  receiveAmount?: number;
}
/**
 *
 * @export
 * @interface RemittancePaymentConfirmationRequest
 */
export interface RemittancePaymentConfirmationRequest {
  /**
   * optional callback url to notify when payment changes to a final state
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  callbackUrl?: string;
  /**
   *
   * @type {Remit}
   * @memberof RemittancePaymentConfirmationRequest
   */
  remit: Remit;
  /**
   * Unique quote number (UUID) of the related quote that was previously requested.
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  quoteId: string;
  /**
   * custom external transaction reference - custom field to be freely used for internal payment collection referencing. Should be unique. **NOTE:** Smobilpay does not manage transaction references (e.g. run unique validation) - this value needs to be managed by the client system.
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  trid: string;
  /**
   * System provide payment authorization token to reference the payment method authorization
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  payAuthToken: string;
  /**
   * Custom field to be freely used for internal payment collection referencing and tagging
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  tag?: string;
  /**
   * remittance cdata object - must comply to contract
   * @type {string}
   * @memberof RemittancePaymentConfirmationRequest
   */
  cdata?: string;
  /**
   * latitude information of collection
   * @type {number}
   * @memberof RemittancePaymentConfirmationRequest
   */
  lat?: number;
  /**
   * longitude information of collection
   * @type {number}
   * @memberof RemittancePaymentConfirmationRequest
   */
  lng?: number;
}
/**
 *
 * @export
 * @interface RemittanceProduct
 */
export interface RemittanceProduct {
  /**
   * Unique Smobilpay service Identifier. Identifies the service this product belongs to.
   * @type {string}
   * @memberof RemittanceProduct
   */
  serviceId: string;
  /**
   * Unique Smobilpay Payment Item ID for payment item identification
   * @type {string}
   * @memberof RemittanceProduct
   */
  payItemId: string;
}
/**
 *
 * @export
 * @interface SingleStepPaymentRequest
 */
export interface SingleStepPaymentRequest {
  /**
   * optional callback url to notify when payment changes to a final state
   * @type {string}
   * @memberof SingleStepPaymentRequest
   */
  callbackUrl?: string;
  /**
   * PRE-calculated service charge to be paid by sender in the client
   * @type {number}
   * @memberof SingleStepPaymentRequest
   */
  calculatedServiceCharge: number;
  /**
   * custom external transaction reference - custom field to be freely used for internal payment collection referencing. Should be unique. **NOTE:** Smobilpay does not manage transaction references (e.g. run unique validation) - this value needs to be managed by the client system.
   * @type {string}
   * @memberof SingleStepPaymentRequest
   */
  trid: string;
  /**
   *
   * @type {Array<SubmitData>}
   * @memberof SingleStepPaymentRequest
   */
  submitData: Array<SubmitData>;
  /**
   * System provide payment authorization token to reference the payment method authorization
   * @type {string}
   * @memberof SingleStepPaymentRequest
   */
  payAuthToken: string;
  /**
   * Custom field to be freely used for internal payment collection referencing and tagging
   * @type {string}
   * @memberof SingleStepPaymentRequest
   */
  tag?: string;
  /**
   * remittance cdata object - must comply to contract
   * @type {string}
   * @memberof SingleStepPaymentRequest
   */
  cdata?: string;
  /**
   * latitude information of collection
   * @type {number}
   * @memberof SingleStepPaymentRequest
   */
  lat?: number;
  /**
   * longitude information of collection
   * @type {number}
   * @memberof SingleStepPaymentRequest
   */
  lng?: number;
}
/**
 *
 * @export
 * @interface SingleStepRemittancePaymentRequest
 */
export interface SingleStepRemittancePaymentRequest {
  /**
   * optional callback url to notify when payment changes to a final state
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  callbackUrl?: string;
  /**
   * PRE-calculated service charge to be paid by sender in the client
   * @type {number}
   * @memberof SingleStepRemittancePaymentRequest
   */
  calculatedServiceCharge: number;
  /**
   * Unique Smobilpay Payment Item ID identifying the item for which the quote was requested
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  payItemId: string;
  /**
   * Amount to be collected during the payment (in local currency of the payment item). Value depends on the payment items amountType
   * @type {number}
   * @memberof SingleStepRemittancePaymentRequest
   */
  amount: number;
  /**
   *
   * @type {PaymentMethodEnum}
   * @memberof SingleStepRemittancePaymentRequest
   */
  payMethod: PaymentMethodEnum;
  /**
   * custom external transaction reference - custom field to be freely used for internal payment collection referencing. Should be unique. **NOTE:** Smobilpay does not manage transaction references (e.g. run unique validation) - this value needs to be managed by the client system.
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  trid: string;
  /**
   * System provide payment authorization token to reference the payment method authorization
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  payAuthToken: string;
  /**
   * Custom field to be freely used for internal payment collection referencing and tagging
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  tag?: string;
  /**
   * remittance cdata object - must comply to contract
   * @type {string}
   * @memberof SingleStepRemittancePaymentRequest
   */
  cdata?: string;
  /**
   * latitude information of collection
   * @type {number}
   * @memberof SingleStepRemittancePaymentRequest
   */
  lat?: number;
  /**
   * longitude information of collection
   * @type {number}
   * @memberof SingleStepRemittancePaymentRequest
   */
  lng?: number;
  /**
   *
   * @type {Remit}
   * @memberof SingleStepRemittancePaymentRequest
   */
  remit: Remit;
}
/**
 * Genders | Type  | Meaning| |PENDING| Pending| |ERROR|   Error| |SUCCESS| Successfully executed|
 * @export
 * @enum {string}
 */
export enum StatusEnum {
  PENDING = <any>"PENDING",
  ERROR = <any>"ERROR",
  ERRORED = <any>"ERRORED",
  SUCCESS = <any>"SUCCESS"
}
/**
 * Data set captured from user entry
 * @export
 * @interface SubmitData
 */
export interface SubmitData {
  /**
   *
   * @type {SubmitFieldTypeEnum}
   * @memberof SubmitData
   */
  type: SubmitFieldTypeEnum;
  /**
   * Value as requested in the corresponding service configuration
   * @type {string}
   * @memberof SubmitData
   */
  value: string;
}
/**
 * Type of data field to capture from user during payment submission - \"SVCNUMB\": Service Number - \"CUSNUMB\": Customer Number - \"REF\": Custom Reference - \"CUSEMAIL\": Customer Email - \"CUSPHONE\": Customer Phone - \"CUSADDR\": Customer Address - \"CUSNAME\": Customer Name
 * @export
 * @enum {string}
 */
export enum SubmitFieldTypeEnum {
  REF = <any>"REF",
  SVCNUMB = <any>"SVCNUMB",
  CUSNUMB = <any>"CUSNUMB",
  CUSPHONE = <any>"CUSPHONE",
  CUSEMAIL = <any>"CUSEMAIL",
  CUSADDR = <any>"CUSADDR",
  CUSNAME = <any>"CUSNAME"
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   * service number with merchant (e.g. policy number with an insurance company or tax number for a governmental institution)
   * @type {string}
   * @memberof Subscription
   */
  serviceNumber: string;
  /**
   * Unique Smobilpay Service Identifier
   * @type {string}
   * @memberof Subscription
   */
  serviceId: string;
  /**
   * Unique Smobilpay merchant code
   * @type {string}
   * @memberof Subscription
   */
  merchant: string;
  /**
   * Unique Smobilpay Payment Item ID identifying the topup package to be purchased
   * @type {string}
   * @memberof Subscription
   */
  payItemId: string;
  /**
   * Contains optional description about payment details (e.g. merchant provided bill types)
   * @type {string}
   * @memberof Subscription
   */
  payItemDescr?: string;
  /**
   * Local currency of service. (Format: ISO 4217)
   * @type {string}
   * @memberof Subscription
   */
  currency: string;
  /**
   *
   * @type {AmountTypeEnum}
   * @memberof Subscription
   */
  amountType: AmountTypeEnum;
  /**
   * Subscription name
   * @type {string}
   * @memberof Subscription
   */
  name: string;
  /**
   * Amount to pay in local currency
   * @type {number}
   * @memberof Subscription
   */
  amount: number;
  /**
   * Customer number with merchant
   * @type {string}
   * @memberof Subscription
   */
  customerNumber?: string;
  /**
   * Customer reference with merchant
   * @type {string}
   * @memberof Subscription
   */
  customerReference?: string;
  /**
   * Customer name
   * @type {string}
   * @memberof Subscription
   */
  customerName?: string;
  /**
   * Exact date of subscription generation (Format: ISO 8601)
   * @type {string}
   * @memberof Subscription
   */
  startDate?: string;
  /**
   * Subscription due date (Format: ISO 8601)
   * @type {string}
   * @memberof Subscription
   */
  dueDate?: string;
  /**
   * Subscription end date (Format: ISO 8601)
   * @type {string}
   * @memberof Subscription
   */
  endDate?: string;
  /**
   * Optional string field to carry additional information
   * @type {string}
   * @memberof Subscription
   */
  optStrg?: string;
  /**
   * Optional number field to carry additional information
   * @type {number}
   * @memberof Subscription
   */
  optNmb?: number;
}
/**
 * @export
 * @enum {string}
 */
export enum AuthProofTypeEnum {
  NONE = <any>"NONE",
  PIN = <any>"PIN",
  SWIPE = <any>"SWIPE",
  NFC = <any>"NFC",
  EXTERNAL = <any>"EXTERNAL",
  OTP = <any>"OTP",
  POSTPAYTRANSFEROTP = <any>"POSTPAYTRANSFEROTP"
}

/**
 *
 * @export
 * @interface Logo
 */
export interface Logo {
  /**
   * Points to a URL with the logo of the service
   * @type {string}
   * @memberof Logo
   */
  url: string;
  /**
   * MD5 Hash of the logo provided via URL. This field stores the hash of the logo. If the logo is updated the hash will change.
   * @type {string}
   * @memberof Logo
   */
  hash: string;
}

/**
 * Type of accepted input (e.g. for keypad support) - \"NUMERICAL\": input will only be numerical - \"ALPHA_CS\": input will contain letters and numbers and is case sensitive - \"ALPHA_CI\": input will contain letters and numbers and is case insensitive - \"QRCODE\": scan qr code
 * @export
 * @enum {string}
 */
export enum InputEnum {
  NUMERICAL = <any>"NUMERICAL",
  ALPHACS = <any>"ALPHA_CS",
  ALPHACI = <any>"ALPHA_CI",
  QRCODE = <any>"QRCODE"
}

/**
 * Type of field - \"SVCNMB\": Service Number - \"CUSNMB\": Customer Number - \"BILLNMB\": Bill Number - \"QRCODE\": QR Code
 * @export
 * @enum {string}
 */
export enum QueryFieldId {
  SVCNMB = <any>"SVCNMB",
  CUSNMB = <any>"CUSNMB",
  BILLNMB = <any>"BILLNMB",
  QRCODE = <any>"QRCODE"
}

/**
 *
 * @export
 * @interface QueryField
 */
export interface QueryField {
  /**
   * Field label
   * @type {Array<I18NValue>}
   * @memberof QueryField
   */
  label: Array<I18NValue>;
  /**
   * Field hint
   * @type {Array<I18NValue>}
   * @memberof QueryField
   */
  hint?: Array<I18NValue>;
  /**
   * Regex for front end field validation
   * @type {string}
   * @memberof QueryField
   */
  regex?: string;
  /**
   *
   * @type {InputEnum}
   * @memberof QueryField
   */
  input: InputEnum;
  /**
   *
   * @type {QueryFieldId}
   * @memberof QueryField
   */
  fieldId: QueryFieldId;
  /**
   * Defines if the field is required
   * @type {boolean}
   * @memberof QueryField
   */
  mandatory: boolean;
}

/**
 * Due Date Alert Information to present to user
 * @export
 * @interface DueDateAlert
 */
export interface DueDateAlert {
  /**
   * If set to true, show a due date alarm {{message}} to customer when the number of {{days}} before the due date has been reached
   * @type {boolean}
   * @memberof DueDateAlert
   */
  isEnabled: boolean;
  /**
   * Number of days before due date to show warning
   * @type {number}
   * @memberof DueDateAlert
   */
  days: number;
  /**
   * Field hint
   * @type {Array<I18NValue>}
   * @memberof DueDateAlert
   */
  message: Array<I18NValue>;
}

/**
 *
 * @export
 * @interface AuthProofConfig
 */
export interface AuthProofConfig {
  /**
   * Input length
   * @type {number}
   * @memberof AuthProofConfig
   */
  length: number;
  /**
   *
   * @type {InputEnum}
   * @memberof AuthProofConfig
   */
  input: InputEnum;
  /**
   * Optional mask for the service number entered during a payment for client side validations. All service numbers must comply to the mask in order to pass. The mask is a regular expression
   * @type {string}
   * @memberof AuthProofConfig
   */
  validationMask: string;
  /**
   * Translation of the instructions
   * @type {Array<I18NValue>}
   * @memberof AuthProofConfig
   */
  instructions: Array<I18NValue>;
}

/**
 *
 * @export
 * @interface Amount
 */
export interface Amount {
  /**
   * Field label
   * @type {Array<I18NValue>}
   * @memberof Amount
   */
  label: Array<I18NValue>;
  /**
   * Field hint
   * @type {Array<I18NValue>}
   * @memberof Amount
   */
  hint: Array<I18NValue>;
  /**
   * If set to true, then the user is required to enter a custom amount to be paid within the min/max bounds stepped by the denomination
   * @type {boolean}
   * @memberof Amount
   */
  allowCustomAmountEntry: boolean;
  /**
   * If set to true, then less than the amount for the payment item can be paid
   * @type {boolean}
   * @memberof Amount
   */
  allowPartial: boolean;
  /**
   * If set to true, then more than the amount for the payment item can be paid
   * @type {boolean}
   * @memberof Amount
   */
  allowOverpay: boolean;
  /**
   * Minimum amount that needs to be paid for this service in local currency
   * @type {number}
   * @memberof Amount
   */
  min: number;
  /**
   * Maximum amount that can be paid for this service in local currency
   * @type {number}
   * @memberof Amount
   */
  max: number;
  /**
   * Service payment denomination. The payment amount must be a multiple of the denomination value. Example:  |Denomination|Amount|Valid| |-----|-----|-------| |1|100|true| |5|100|true| |200|100|false| |50|60|false|
   * @type {number}
   * @memberof Amount
   */
  denomination: number;
}
/**
 *
 * @export
 * @interface Service
 */
export interface Service {
  /**
   * Unique Smobilpay service Identifier
   * @type {string}
   * @memberof Service
   */
  serviceId: string;
  /**
   *
   * @type {ServiceTypeEnum}
   * @memberof Service
   */
  type: ServiceTypeEnum;
  /**
   * Public name of service
   * @type {string}
   * @memberof Service
   */
  title: string;
  /**
   * Service description
   * @type {string}
   * @memberof Service
   */
  description: string;
  /**
   *
   * @type {ServiceChargeConfig}
   * @memberof Service
   */
  serviceCharge: ServiceChargeConfig;
  /**
   *
   * @type {Logo}
   * @memberof Service
   */
  logo: Logo;
  /**
   *
   * @type {Array<QueryField>}
   * @memberof Service
   */
  queryFields: Array<QueryField>;
  /**
   *
   * @type {Array<SubmitField>}
   * @memberof Service
   */
  submitFields: Array<SubmitField>;
  /**
   * If set to true , the agent wallet will be debitted by collections for this service
   * @type {boolean}
   * @memberof Service
   */
  hasDebitImpact: boolean;
  /**
   *
   * @type {SignatureTypeEnum}
   * @memberof Service
   */
  signatureType: SignatureTypeEnum;
  /**
   *
   * @type {AuthProofTypeEnum}
   * @memberof Service
   */
  authProofType: AuthProofTypeEnum;
  /**
   *
   * @type {AuthProofConfig}
   * @memberof Service
   */
  authProofConfig?: AuthProofConfig;
  /**
   * Default favorite priority of the service. The lower the value the higher the priority. Favorite services are more frequented and need to be easier accessible than other services.
   * @type {number}
   * @memberof Service
   */
  defaultfavoritePrio: number;
  /**
   * Category this service is assigned to
   * @type {number}
   * @memberof Service
   */
  categoryId: number;
  /**
   *
   * @type {DueDateAlert}
   * @memberof Service
   */
  dueDateAlert?: DueDateAlert;
  /**
   * Country of operation (ISO 3166-1 alpha-3)
   * @type {string}
   * @memberof Service
   */
  country: string;
  /**
   * Local currency of service (Format: ISO 4217)
   * @type {string}
   * @memberof Service
   */
  currency: string;
  /**
   * Gateway operator used to facilitate the service interface
   * @type {string}
   * @memberof Service
   */
  gatewayOperator: string;
  /**
   * Service availability status
   * @type {string}
   * @memberof Service
   */
  status: Service.StatusEnum;
  /**
   * If set to true , the the service supports multiple payments in one collection
   * @type {boolean}
   * @memberof Service
   */
  isMultiPaymentSupported: boolean;
  /**
   *
   * @type {Amount}
   * @memberof Service
   */
  amount: Amount;
  /**
   * Minimum KYC level required by agent to use this service
   * @type {number}
   * @memberof Service
   */
  kycMinLevel: number;
  /**
   * Service Payment Steps to present to the user/agent
   * @type {Array<PaymentSteps>}
   * @memberof Service
   */
  stepHints: Array<PaymentSteps>;
}

/**
 *
 * @export
 * @interface PaymentSteps
 */
export interface PaymentSteps {
  /**
   * Language of value text in iso notation (en | fr)
   * @type {string}
   * @memberof PaymentSteps
   */
  lang: string;
  /**
   *
   * @type {Array<Step>}
   * @memberof PaymentSteps
   */
  steps: Array<Step>;
}

/**
 * @export
 * @namespace Service
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Service {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Active = <any>"Active",
    Inactive = <any>"Inactive",
    Maintainance = <any>"Maintainance"
  }
}
/**
 *
 * @export
 * @interface ServiceChargeConfig
 */
export interface ServiceChargeConfig {
  /**
   * Round up to this full denominator
   * @type {number}
   * @memberof ServiceChargeConfig
   */
  roundDenom: number;
  /**
   * Ranges
   * @type {Array<ServiceChargeRange>}
   * @memberof ServiceChargeConfig
   */
  ranges: Array<ServiceChargeRange>;
}
/**
 *
 * @export
 * @interface ServiceChargeRange
 */
export interface ServiceChargeRange {
  /**
   * Range from
   * @type {number}
   * @memberof ServiceChargeRange
   */
  from: number;
  /**
   * Range to. If no \"to\" is provided then the range is valid for any amounts larger than the \"from\"
   * @type {number}
   * @memberof ServiceChargeRange
   */
  to?: number;
  /**
   * fixed amount service fee if amount falls in this range
   * @type {number}
   * @memberof ServiceChargeRange
   */
  fixed: number;
  /**
   * percentage amount service fee if amount falls in this range
   * @type {number}
   * @memberof ServiceChargeRange
   */
  percentage: number;
}
/**
 * Type of service
 * @export
 * @enum {string}
 */
export enum ServiceTypeEnum {
  SEARCHABLEBILL = <any>"SEARCHABLE_BILL",
  NONSEARCHABLEBILL = <any>"NON_SEARCHABLE_BILL",
  PRODUCT = <any>"PRODUCT",
  TOPUP = <any>"TOPUP",
  VOUCHER = <any>"VOUCHER",
  SUBSCRIPTION = <any>"SUBSCRIPTION",
  MABSCASHIN = <any>"MABS_CASHIN",
  MABSCASHOUT = <any>"MABS_CASHOUT",
  MABSMINISTMT = <any>"MABS_MINISTMT",
  MABSFULLSTMT = <any>"MABS_FULLSTMT",
  MOMOCASHIN = <any>"MOMO_CASHIN",
  MOMOCASHOUT = <any>"MOMO_CASHOUT",
  TRANSFERINTERNAL = <any>"TRANSFER_INTERNAL",
  TRANSFEREXTERNAL = <any>"TRANSFER_EXTERNAL",
  DEPOSITINTERNAL = <any>"DEPOSIT_INTERNAL",
  DEPOSITEXTERNAL = <any>"DEPOSIT_EXTERNAL"
}
/**
 * Type of customer signature required for this service  |Type|Category| |-----|-----| |NONE|no signature required| |ONPRINTOUT|Customer needs to sign receipt (extra field in printout)| |ONSCREEN|Customer signs on the smartphone screen|
 * @export
 * @enum {string}
 */
export enum SignatureTypeEnum {
  NONE = <any>"NONE",
  ONPRINTOUT = <any>"ONPRINTOUT",
  ONSCREEN = <any>"ONSCREEN"
}
/**
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   * Step order starting at 1
   * @type {number}
   * @memberof Step
   */
  order: number;
  /**
   * Step
   * @type {string}
   * @memberof Step
   */
  value: string;
}
/**
 *
 * @export
 * @interface SubmitField
 */
export interface SubmitField {
  /**
   * Field label
   * @type {Array<I18NValue>}
   * @memberof SubmitField
   */
  label: Array<I18NValue>;
  /**
   * Field hint
   * @type {Array<I18NValue>}
   * @memberof SubmitField
   */
  hint?: Array<I18NValue>;
  /**
   * Regex for front end field validation
   * @type {string}
   * @memberof SubmitField
   */
  regex?: string;
  /**
   *
   * @type {InputEnum}
   * @memberof SubmitField
   */
  input: InputEnum;
  /**
   *
   * @type {SubmitFieldId}
   * @memberof SubmitField
   */
  fieldId: SubmitFieldId;
  /**
   * Defines if the field is required
   * @type {boolean}
   * @memberof SubmitField
   */
  mandatory: boolean;
}
/**
 * Type of field - \"SVCNMB\": Service Number - \"CUSNMB\": Customer Number - \"PAYREF\": Payment Reference - \"PHONENMB\": Phonenumber - \"EMAIL\": Email
 * @export
 * @enum {string}
 */
export enum SubmitFieldId {
  SVCNMB = <any>"SVCNMB",
  CUSNMB = <any>"CUSNMB",
  PAYREF = <any>"PAYREF",
  PHONENMB = <any>"PHONENMB",
  EMAIL = <any>"EMAIL"
}
