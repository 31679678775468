import { IGridSelectionPageData } from "../components/GridSelectionActions";

export const clearSelection = (
  data: IGridSelectionPageData[],
  setData: (data: IGridSelectionPageData[]) => void
) => {
  const tempData = data;

  data.forEach((item, idx) => {
    tempData[idx] = {
      ...item,
      isSelectAll: false,
      options: item.options.map((option) => ({
        ...option,
        isChecked: false
      }))
    };
  });

  setData([...tempData]);
};
