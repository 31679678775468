import { Card, CardActionArea, CardContent, CardProps, Typography, colors } from "@mui/material";

interface ICardButton extends CardProps {
  text: string;
  onClick?: () => void;
}

const CardButton = ({ text, onClick, ...rest }: ICardButton) => {
  return (
    <Card
      sx={[
        {
          "&:hover": {
            backgroundColor: colors["cyan"][50],
            color: colors["cyan"][600],
            fontWeight: "bold"
          }
        },
        {
          marginRight: "32px",
          width: "306px",
          fontWeight: "normal",
          textAlign: "left",
          color: colors["grey"][900]
        }
      ]}
      onClick={onClick}
      {...rest}
    >
      <CardActionArea>
        <CardContent>
          <Typography fontSize={16} gutterBottom variant="h5" component="div" mb={0}>
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardButton;
