import { ChevronLeft } from "@mui/icons-material";
import { Box, ButtonGroup, Grid, Skeleton, Stack, Switch, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import queryErrorHandler from "api/queryErrorHandler";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import CardBase from "components/material/Card/CardBase";
import ShareButton from "components/material/Menu/MenuShare";
import BackButton from "components/other/ButtonBack";
import { DISABLE_COMPANY_PRL, ENABLE_COMPANY_PRL } from "constants/dataProviderTypes";
import { useDataProvider, useGetOne, useNotify, usePermissions, useRefresh, useTranslate } from "react-admin";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import findPermission from "utils/findPermission";
import { getStorageMerchantCCId } from "utils/storageUtils";
import DownloadButton from "./components/DownloadButton";
import CopyLinkToClipboard from "components/other/CopyLinkToClipboard";

const CompanyPrlLayout = () => {
  const t = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const merchantCcId = getStorageMerchantCCId();
  const { permissions } = usePermissions();
  const prlReadAny = findPermission(permissions, "prl_read_any");
  const { id } = useParams();

  const {
    data: record,
    isLoading,
    isError
  } = useGetOne(
    "merchant",
    { id },
    {
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  const { mutate: enableCompanyPrl } = useMutation(
    ["merchant", ENABLE_COMPANY_PRL],
    () => dataProvider.enableCompanyPrl("merchant", { id: record?.coreCompanyId || merchantCcId }),
    {
      onSuccess: () => {
        refresh();
        notify("cc.notification.company_prl_enabled", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );
  const { mutate: mutateDisableCompanyPrl } = useMutation(
    ["merchant", DISABLE_COMPANY_PRL],
    () => dataProvider.disableCompanyPrl("merchant", { id: record?.coreCompanyId || merchantCcId }),
    {
      onSuccess: () => {
        refresh();
        notify("cc.notification.company_prl_disabled", { type: "success" });
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const toggleCompanyPrlStatus = () => {
    if (!record?.isCompanySpecificPrlActive) {
      enableCompanyPrl();
    } else {
      mutateDisableCompanyPrl();
    }
  };

  return (
    <Box paddingTop={2} sx={{ width: "100%" }}>
      <Box mb={"1rem"}>
        <Breadcrumbs service="merchant" page="show" />
      </Box>
      {isLoading ? (
        <Stack spacing={1}>
          <Skeleton variant={"rectangular"} height={50}></Skeleton>
          <Skeleton variant={"rectangular"} height={200}></Skeleton>
        </Stack>
      ) : isError && !isLoading ? (
        <BackButton startIcon={<ChevronLeft />}>{t("ra.action.back")}</BackButton>
      ) : (
        <>
          {merchantCcId && prlReadAny && (
            <Box data-testid="switch-company-prl" mb={"1rem"} display={"flex"} alignItems={"center"}>
              <Switch checked={record?.isCompanySpecificPrlActive} onChange={toggleCompanyPrlStatus} />
              <Typography fontWeight={"bold"}>{`${
                record?.isCompanySpecificPrlActive
                  ? t(`resources.${["company-prl"]}.fields.enabled`)
                  : t(`resources.${["company-prl"]}.fields.disabled`)
              }`}</Typography>
            </Box>
          )}
          <CardBase cardTitle={t(`cc.card.payment_link_information`, { smart_count: 0 })}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box>
                    <Typography fontWeight={"bold"} mb={1}>
                      {t(`resources.${["company-prl"]}.fields.status`)}:
                    </Typography>
                    <Chip
                      style={{ color: "#fff" }}
                      label={
                        record?.isCompanySpecificPrlActive
                          ? t(`resources.${["company-prl"]}.fields.active`).toUpperCase()
                          : t(`resources.${["company-prl"]}.fields.inactive`).toUpperCase()
                      }
                      color={record?.isCompanySpecificPrlActive ? "success" : "error"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {record?.isCompanySpecificPrlActive && (
                      <CopyLinkToClipboard
                        url={
                          (record?.isCompanySpecificPrlActive && record?.merchantLinkShortened) ||
                          record?.merchantLink
                        }
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Typography data-testid="merchant-ccid" variant="h6" component={"h6"} fontWeight="bold">
                      {t(`resources.${["company-prl"]}.fields.merchant_specific_code`)}:{" "}
                    </Typography>
                    <Typography color={"GrayText"}>{record?.numericCode}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} justifyContent="flex-end">
                    <ButtonGroup data-testid="download-qrcode-button-group" size="medium">
                      <DownloadButton
                        disabled={!record?.isCompanySpecificPrlActive}
                        format="A4"
                        variant="outlined"
                      />
                      <DownloadButton
                        disabled={!record?.isCompanySpecificPrlActive}
                        format="A5"
                        variant="contained"
                      />
                    </ButtonGroup>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"} mb={1}>
                    {t(`resources.${["company-prl"]}.fields.share`)}:{" "}
                  </Typography>
                  <Box>
                    <ShareButton source={"share"} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardBase>
        </>
      )}
    </Box>
  );
};

export default CompanyPrlLayout;
