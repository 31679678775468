export const KYC_BOM_ACCESS = "kyc_bom_access";
export const KYC_MARK_AS_APPROVED = "entity_markAsApproved";
export const KYC_MARK_AS_REJECTED = "entity_markAsRejected";
export const ENTITY_READ_OWN = "entity_read_own";
export const ENTITY_READ_ANY = "entity_read_any";
export const PRL_PRODUCT_READ_ANY = "prl_product_read_any";
export const PRL_PRODUCT_READ_OWN = "prl_product_read_own";
export const PRL_READ_ANY = "prl_read_any";
export const PRL_READ_OWN = "prl_read_own";
export const PRL_CREATE = "prl_create";
export const PRL_BOM_ACCESS = "prl_bom_access";
export const BULKPAY_ACCESS = "bulkpay_access";
export const BULKCOL_ACCESS = "bulkcol_access";
export const MERCHANT_READ_OWN = "merchant_read_own";
export const ENABLE_AGENT_PAYMENT = "enable_agent_payment";
export const ENABLE_AGENT_PAYMENT_TRANSACTION = "enable_agent_payment_transaction";
export const ENABLE_AGENT_PAYMENT_HISTORY = "enable_agent_payment_history";
export const REMIT_REPORT_ALL = "remit_report_all";
export const REMIT_REPORT_OWN = "remit_report_own";
export const REMIT_TRANSACT = "remit_transact";
export const REMIT_ACCESS = "remit_access";
