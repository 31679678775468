import React, { useEffect, useState } from "react";
import AgentPaymentsBillPreview from "../AgentPaymentsBillPreview";
import AgentPaymentsBillConfirm from "../AgentPaymentsBillConfirm";
import AgentPaymentsBillSearchResultList from "../AgentPaymentsBillSearchResultList";
import { Form } from "react-admin";
import { Bill } from "api/types/agentPaymentsTypes";
import { makeStyles } from "@mui/styles";

type Props = {
  billItem?: Bill;
  billsSearchList: Bill[];
  setBillItem: (bill: Bill) => void;
  handleMove: (num: number) => void;
};

const SubmitStepsWrapper = ({ billItem, billsSearchList, setBillItem, handleMove }: Props) => {
  const [localStep, setLocalStep] = useState(1);

  const handleLocalMove = (stp: number) => {
    if (stp > 3) return;
    if (stp < 1) return;
    setLocalStep(stp);
  };

  useEffect(() => {
    if (billsSearchList.length > 1) {
      handleLocalMove(3);
    } else if (billsSearchList.length === 1) {
      setBillItem(billsSearchList[0]);
      handleLocalMove(1);
    }
  }, []);

  const subSteps = [
    {
      id: 2,
      component: (
        <AgentPaymentsBillPreview handleMove={handleLocalMove} billItem={billItem} handleExit={handleMove} />
      ),
      name: "billPreview"
    },
    {
      id: 3,
      component: (
        <AgentPaymentsBillConfirm handleMove={handleLocalMove} billItem={billItem} handleExit={handleMove} />
      ),
      name: "billConfirm"
    },
    {
      id: 4,
      component: (
        <AgentPaymentsBillSearchResultList
          billsSearchList={billsSearchList}
          handleMove={handleLocalMove}
          setBillItem={setBillItem}
          handleExit={handleMove}
        />
      ),
      name: "billSearchResultList"
    }
  ];

  const useStyles = makeStyles({
    form: { minWidth: 220 }
  });

  const classes = useStyles();
  return (
    <Form mode="onBlur" className={classes.form}>
      {subSteps[localStep - 1].component}
    </Form>
  );
};

export default SubmitStepsWrapper;
