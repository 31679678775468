import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import { useInput } from "react-admin";
import ReactDatepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatDate from "utils/formatDate";

const styles = {
  textField: {
    marginBottom: 8
  }
};

interface DatepickerProps {
  label?: string;
  classes: {
    textField: string;
  };
  source: string;
  dataTestId?: string;
}

const Datepicker = ({ label, classes, dataTestId = "datepicker", source, ...rest }: DatepickerProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date());

  const {
    field: { onChange, name, ref, ...restInput }
  } = useInput({ source, ...rest });

  const handleOnSelect = (date: Date) => {
    if (date) {
      const formattedDate = formatDate(date, "upstream");

      onChange(formattedDate);
    }
  };

  const handleOnChange = (date: Date) => {
    setStartDate(date);
  };

  return (
    <div data-testid={dataTestId}>
      <ReactDatepicker
        {...restInput}
        ref={ref}
        name={name}
        placeholderText="dd/mm/yyyy"
        dateFormat="dd/MM/yyyy"
        selected={restInput.value ? new Date(restInput.value) : startDate}
        // onChange event enables input search
        onChange={handleOnChange}
        // onSelect event occurs after user selects date by click
        onSelect={handleOnSelect}
        customInput={
          <TextField className={classes.textField} label={label} inputProps={{ autoComplete: "off" }} />
        }
      />
    </div>
  );
};
export default withStyles(styles)(Datepicker);
