const businessSectorMockData = {
  business_management: [
    {
      id: 1,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 2,
      name: "Call Center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-02-26",
      createAt: "2022-02-26"
    },
    {
      id: 3,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 4,
      name: "Call Center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-23",
      createAt: "2022-01-23"
    },
    {
      id: 5,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 6,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 7,
      name: "Call Center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 8,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 9,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 10,
      name: "Call Center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    },
    {
      id: 11,
      name: "Call Box",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.diam in ultricies ornare, nunc purus bibendum tellus, id semper metus turpis a ex.",
      lastModifiedDate: "2022-01-26",
      createAt: "2022-01-26"
    }
  ]
};

export default businessSectorMockData;
