const extractReason = (comment: string | { text: string }[]) => {
  let reason;

  reason = typeof comment === "string" ? comment : comment[comment?.length - 1]?.text;
  reason = reason?.match(/Reason:(\s?.*)/);
  reason = reason ? reason[1].trim() : "";

  return reason;
};

export default extractReason;
