import { fetchUtils } from "react-admin";
import { getStorageToken } from "utils/storageUtils";
interface HttpClientResponseType {
  status: number;
  body: string;
  // eslint-disable-next-line
  json: any;
  headers: globalThis.Headers;
}

interface AttachmentFileType extends File {
  label: string;
  preview: string;
  id: string;
}

const attachmentDataProvider = (
  apiUrl: string,
  httpClient: (url: string, options?: fetchUtils.Options) => Promise<HttpClientResponseType>
) => ({
  getManyAttachment: (
    resource: string,
    params: {
      id: string;
    }
  ) => {
    const { id } = params;
    const url = `${apiUrl}/${resource}/${id}/attachment`;
    return httpClient(url).then(({ json }) => {
      return { data: json };
    });
  },
  downloadAttachment: async (
    resource: string,
    params: {
      items: {
        link: string;
        id: string;
        name: string;
        label: string;
      }[];
    }
  ) => {
    const { items } = params;

    const arr = items.map((item) => ({
      url: `${apiUrl}${item?.link}`,
      ...item
    }));
    // Files array
    const files: File[] = [];

    try {
      const responsePromises = arr.map(async ({ url }, index) => {
        const token = getStorageToken() || "";
        const request = new Request(url, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          })
        });

        return fetch(request);
      });
      // Resolve all promises
      const responses = await Promise.all(responsePromises);
      // Create array of blob promises
      const blobPromises = responses.map((response) => response.blob());
      // Resolve blob promises
      const blobs = await Promise.all(blobPromises);
      // Create files
      blobs.forEach((blob, index) => {
        const file = new File([blob], arr[index].name, { type: blob.type });
        Object.assign(file, {
          preview: URL.createObjectURL(blob),
          label: arr[index].label,
          id: arr[index].id
        });

        files.push(file);
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
    return { data: files };
  },

  uploadAttachment: (
    resource: string,
    { id, data: { files } }: { id: string; data: { files: AttachmentFileType[] } }
  ) => {
    const url = `${apiUrl}/${resource}/${id}/attachment`;
    const requests: Promise<HttpClientResponseType>[] = [];

    files.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("documentLabel", file.label);

      requests.push(
        httpClient(url, {
          method: "POST",
          body: formData
        })
      );
    });
    return Promise.all(requests).then((responses) => ({ data: responses }));
  },

  updateAttachment: (
    resource: string,
    {
      id,
      data
    }: {
      id: string;
      data: {
        file: AttachmentFileType;
        label: string;
      };
    }
  ) => {
    const { file, label } = data;
    const url = `${apiUrl}/${resource}/${id}/attachment/${file.id}`;

    const d = { documentLabel: label };

    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify(d)
    }).then(({ json }) => ({ data: json }));
  },
  deleteAttachment: (
    resource: string,
    { id, data: { file } }: { id: string; data: { file: AttachmentFileType } }
  ) => {
    const url = `${apiUrl}/${resource}/${id}/attachment/${file.id}`;

    return httpClient(url, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json }));
  }
});

export default attachmentDataProvider;
