import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { RaRecord, useRecordContext, UseRecordContextParams, useTranslate } from "react-admin";
const styles = {
  text: {
    fontSize: 14,
    fontWeight: "bold"
  }
};

const ListPayoutAccountSetAsDefault = (props: UseRecordContextParams<RaRecord>) => {
  const translate = useTranslate();
  const record = useRecordContext(props);

  // const [setAsDefault, { loading }] = useMutation({
  //   type: "update",
  //   resource: "payout-account",
  //   payload: { id: record.id, data: { isPreferred: true } }
  // });

  return (
    <>
      <Button
        style={{ display: record?.type === "internal" ? "none" : "block" }}
        variant="outlined"
        color="secondary"
        // onClick={setAsDefault}
        // disabled={loading || record?.isPreferred}
      >
        {translate("payout.set_as_default")}
      </Button>
    </>
  );
};

export default withStyles(styles)(ListPayoutAccountSetAsDefault);
