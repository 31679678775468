import { Box, Toolbar } from "@mui/material";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BackButton from "components/other/ButtonBack";
import DeleteButton from "components/other/context/DeleteButton";
import EditButton from "components/other/context/EditButton";
import { Show, usePermissions, useTranslate } from "react-admin";
import findPermission from "utils/findPermission";
import PrlDataShowLayout from "./PrlDataShowLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const PrlShowActions = ({ basePath, data, permissions }) => {
  const translate = useTranslate();
  const prlDeleteAny = findPermission(permissions, "prl_delete_any");
  const prlDeleteOwn = findPermission(permissions, "prl_delete_own");
  const prlUpdateAny = findPermission(permissions, "prl_update_any");
  const prlUpdateOwn = findPermission(permissions, "prl_update_own");

  return (
    <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
      <BackButton sx={{ marginRight: "auto" }} startIcon={<ArrowBackIosIcon />}>
        {translate("ra.action.back")}
      </BackButton>

      {(prlUpdateAny || prlUpdateOwn) && (
        <EditButton basePath={basePath} record={data} dataTestId="edit-button" />
      )}
      {(prlDeleteAny || prlDeleteOwn) && (
        <DeleteButton basePath={basePath} record={data} dataTestId="delete-button" />
      )}
    </Box>
  );
};

export const PrlDataShow = () => {
  const { permissions } = usePermissions();

  return (
    <>
      <Toolbar disableGutters={true}>
        <Breadcrumbs service={"prl"} page={"show"} />
      </Toolbar>
      <Show resource="prl" actions={<PrlShowActions permissions={permissions} />}>
        <PrlDataShowLayout />
      </Show>
    </>
  );
};

export default PrlDataShow;
