import { colors } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles, ThemeOfStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { AccordionBaseType } from "components/types/material";

interface StyleProps {
  index: number;
}

const useStyles = makeStyles<ThemeOfStyles<string>, StyleProps>({
  root: {
    width: "100%"
  },

  accordion: {
    backgroundColor: colors.cyan[600],
    color: "white",
    width: "100%"
  },

  expanded: {
    // Do not added top expanded margin for first item
    marginTop: (prop) => (prop.index === 0 ? "0px !important" : 2)
  },

  accordionDetails: {
    backgroundColor: "white",
    color: colors.grey[800],
    fontSize: 14
  }
});

const AccordionBase = ({ summaryContent, detailsContent, index, ...accordionProps }: AccordionBaseType) => {
  const classes = useStyles({ index });

  return (
    <MuiAccordion
      {...accordionProps}
      color="secondary"
      classes={{ root: classes.accordion, expanded: classes.expanded }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        {summaryContent}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{detailsContent}</AccordionDetails>
    </MuiAccordion>
  );
};
export default AccordionBase;
