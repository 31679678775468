import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { Bill, Service, SubmitFieldId } from "api/types/agentPaymentsTypes";
import { AGENT_PAYMENTS_GET_SERVICE } from "constants/dataProviderTypes";
import {
  FormDataConsumer,
  TextInput,
  maxValue,
  minValue,
  number,
  regex,
  required,
  useLocaleState,
  useNotify,
  useTranslate
} from "react-admin";
import daysjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import BillServiceWrapper from "../components/BillServiceWrapper";
import ServiceChargeDetails from "../components/ServiceChargeDetails";
import { daysDifference } from "../utils/daysDifference";

type Props = {
  handleMove: (num: number) => void;
  billItem?: Bill;
  handleExit: (num: number) => void;
};

const AgentPaymentsBillPreview = ({ handleMove, billItem, handleExit }: Props) => {
  const [locale] = useLocaleState();
  const t = useTranslate();
  const { id } = useParams();
  const notify = useNotify();

  const { data: service }: { data?: Service } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_GET_SERVICE],
    () => dataProvider.getBillService({ serviceId: id || "" }),
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  const days = daysDifference(new Date(billItem?.billDueDate || ""), new Date());
  daysjs.extend(relativeTime);

  return (
    <Box height={"100%"}>
      <BillServiceWrapper
        service={service}
        onCancel={() => handleExit(1)}
        onProceed={() => {
          handleMove(2);
        }}
      >
        {Math.ceil(days) < 5 && (
          <Box mb={5} data-testid="due-date-warning">
            {Math.ceil(days) < 0 ? (
              <Alert sx={{ color: "#fff" }} severity={"info"} variant="filled">
                <AlertTitle sx={{ fontWeight: "bold" }}>
                  {t(`resources.${["agent_payments"]}.fields.due_date_has_passed_of`, {
                    smart_count: daysjs(new Date(billItem?.billDueDate || "")).fromNow(true)
                  })}
                </AlertTitle>
                {t(`resources.${["agent_payments"]}.fields.your_bill_has_expired`)}
              </Alert>
            ) : (
              <Alert sx={{ color: "#fff" }} severity={"info"} variant="filled">
                <AlertTitle sx={{ fontWeight: "bold" }}>
                  {t(`resources.${["agent_payments"]}.fields.due_date`, {
                    smart_count: daysjs(new Date(billItem?.billDueDate || "")).fromNow(true)
                  })}
                </AlertTitle>
                {t(`resources.${["agent_payments"]}.fields.please_pay_now_to_avoid_being_cut_off`)}
              </Alert>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box width={"100%"}>
            <Box mb={5} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Grid item xs={6}>
                <Box width={600}>
                  <ServiceChargeDetails billItem={billItem} serviceCharge={service?.serviceCharge} />
                  {service?.amount?.allowCustomAmountEntry && (
                    <Box width={630}>
                      <Typography textAlign={"end"} fontSize={14} color="#00A6B5">
                        {t(`resources.${["agent_payments"]}.fields.optional`)}
                      </Typography>
                      <TextInput
                        data-testid="amount-input"
                        source="amount"
                        label={service?.amount?.label?.find((label) => label.lang === locale)?.value}
                        size="small"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        validate={[
                          regex(new RegExp(/[0-9]/)),
                          number(),
                          minValue(service?.amount?.min),
                          maxValue(service?.amount?.max)
                        ]}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box width={600} mb={3}>
                  <Typography fontWeight="bold" marginBottom={1}>
                    {t(`resources.${["agent_payments"]}.fields.additional_information`)}
                  </Typography>
                  <Typography fontSize={14}>
                    {t(`resources.${["agent_payments"]}.fields.Please_provide_those_informations`)}
                  </Typography>
                </Box>

                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    return (
                      <>
                        {service?.submitFields?.map((field, idx: number) => (
                          <Box data-testid="service-submit-input" key={idx} sx={{ width: 600 }}>
                            {field && (
                              <>
                                {!field?.mandatory && (
                                  <Typography textAlign={"end"} fontSize={14} color="#00A6B5">
                                    {t(`resources.${["agent_payments"]}.fields.optional`)}
                                  </Typography>
                                )}
                                <TextInput
                                  {...rest}
                                  data-testid={field?.fieldId}
                                  id={`${SubmitFieldId[field?.fieldId] || ""}-${idx}`}
                                  label={field?.label?.find((label) => label.lang === locale)?.value}
                                  source={SubmitFieldId[field?.fieldId]}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  variant="outlined"
                                  validate={[
                                    regex(new RegExp(field?.regex || "")),
                                    field?.mandatory ? required() : () => undefined
                                  ]}
                                />
                              </>
                            )}
                          </Box>
                        ))}
                      </>
                    );
                  }}
                </FormDataConsumer>
              </Grid>
            </Box>
          </Box>
        </Box>
      </BillServiceWrapper>
    </Box>
  );
};

export default AgentPaymentsBillPreview;
