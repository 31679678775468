import { flatten, isEmpty } from "lodash";

type CollectionType = {
  [key: string]: string | JSX.Element | JSX.Element[] | undefined;
};

const isEmptyCollection = (collection: CollectionType[]) => {
  const arr = flatten(
    collection.map((item) => {
      const entries = Object.entries(item);

      return entries.map(([key, value]) => isEmpty(value));
    })
  );

  return arr.every((item) => item === true);
};

export default isEmptyCollection;
