import { colors } from "@mui/material";
import { defaultTheme } from "react-admin";

const theme = {
  ...defaultTheme,

  palette: {
    primary: {
      main: colors.cyan[600],
      contrastText: "#fff"
    },
    secondary: {
      main: colors.grey[600]
    },
    success: {
      main: colors.green[500]
    },
    error: {
      main: colors.deepOrange[500]
    },
    info: {
      main: colors.cyan[600]
    },
    warning: {
      main: colors.orange[600]
    },
    tip: {
      main: colors.purple[100]
    }
  },
  sidebar: {
    width: 300,
    closedWidth: 50
  },

  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#EAECF0",
            fontSize: "16px",
            color: "rgba(17, 17, 17, 0.6)",
            fontWeight: "300",
            fontStyle: "normal",
            flex: "none",
            order: 0,
            flexGrow: 0
          },
          "& .RaDatagrid-rowCell": {
            color: "#111111",
            fontWeight: "400",
            fontStyle: "normal",
            flex: "none",
            textAlign: "left",
            order: 0,
            flexGrow: 0
          }
        }
      }
    },
    RaFilterButton: {
      styleOverrides: {
        root: {
          padding: "3px 16px",
          borderRadius: "4px",
          border: `0.5px solid ${colors.cyan[300]}`
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          marginRight: "15px !important"
        }
      }
    },

    RaSidebar: {
      styleOverrides: {
        root: {
          "& .RaSidebar-fixed": {
            height: "100%",
            overflowY: "hidden !important"
          }
        }
      }
    },

    RaFileInput: {
      styleOverrides: {
        root: {
          "& .RaFileInput-dropZone": {
            border: `1px dashed ${colors.cyan[600]}`,
            color: "black"
          }
        }
      }
    },
    RaMenu: {
      styleOverrides: {
        root: {
          "& .MuiListItemIcon-root": {
            minWidth: "40px !important"
          },
          // Styles which regulate behavior of minimized menu
          "&.RaMenu-closed": {
            "& .MuiMenuItem-root": {
              paddingLeft: "12px !important"
            },
            "& .MuiListItemButton-root": {
              display: "none !important"
            },
            "& .MuiListSubheader-root": {
              display: "none !important"
            },
            "& .menu-logo": {
              backgroundSize: "150% !important"
            }
          }
        }
      }
    },

    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: "inherit",
          borderLeft: "3px solid transparent",
          minHeight: "48px !important",
          "&.RaMenuItemLink-active": {
            color: colors.cyan[700],
            backgroundColor: colors.grey[200],
            "& .RaMenuItemLink-icon": {
              color: colors.cyan[700]
            }
          }
        }
      }
    },
    MuiAlert: {
      variants: [
        {
          props: { type: "tip" },
          style: {
            backgroundColor: colors.purple[50]
          }
        },
        {
          props: { type: "instruction" },
          style: {
            backgroundColor: colors.blue[50]
          }
        }
      ]
    },

    RaDeleteWithUndoButton: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit"
        }
      }
    }
  }
};

export default theme;
