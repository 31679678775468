import config from "config";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { getStorageToken } from "utils/storageUtils";

const companyManagementHttpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const proxyUrl: string = config.REACT_APP_PROXY_BASE_URL;
const companyManagementApi = `${config.REACT_APP_PROXY_BASE_URL}/company-management-api`;

const dataProvider = simpleRestProvider(proxyUrl, companyManagementHttpClient);

const companyManagementDataProvider = {
  ...dataProvider,

  getCollectorCompanies: () => {
    const url = `${companyManagementApi}/listing`;
    return companyManagementHttpClient(url).then(({ headers, json }) => json);
  }
};

export default companyManagementDataProvider;
