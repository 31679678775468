import { DropzoneFileType } from "components/types/otherComponentTypes";
import useMutatePrlAttachment from "hooks/api/useMutatePrlAttachment";
import { useRecordContext } from "react-admin";
import { FORM_TYPE_CREATE } from "constants/formTypes";

const useHandlePrlAttachment = ({
  formType,
  setModalOpen,
  attachments,
  setAttachments
}: {
  formType: "create" | "edit";
  setModalOpen: (e: boolean) => void;
  attachments: DropzoneFileType[];
  setAttachments: (e: DropzoneFileType[]) => void;
}) => {
  const { mutateUploadAttachment, mutateUpdateAttachment, mutateDeleteAttachment } = useMutatePrlAttachment({
    formType,
    setModalOpen
  });
  const record = useRecordContext();
  const add = (files: DropzoneFileType[]) => {
    // Handle state locally
    if (formType === FORM_TYPE_CREATE) {
      const concatenated = attachments.concat(files);
      setAttachments(concatenated);
    } else {
      // Handle state on server
      mutateUploadAttachment({ files: files, id: record?.id });
    }
  };

  const remove = (file: DropzoneFileType, index: number) => {
    // Handle state locally
    if (formType === FORM_TYPE_CREATE) {
      const newFiles = [...attachments];
      newFiles.splice(index, 1);
      setAttachments(newFiles);
    } else {
      // Handle state on server
      mutateDeleteAttachment({ file, id: record?.id });
    }
  };

  const update = (label: string, file: DropzoneFileType, index: number) => {
    // Handle state locally
    if (formType === FORM_TYPE_CREATE) {
      const newFiles = [...attachments];
      newFiles[index].label = label;
      setAttachments(newFiles);
    } else {
      // Handle state on server
      mutateUpdateAttachment({ label, file, id: record?.id });
    }
  };

  return { add, update, remove };
};

export default useHandlePrlAttachment;
