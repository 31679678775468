import { Box, colors, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import AccordionProducts from "components/material/Accordion/AccordionProducts";
import CardBase from "components/material/Card/CardBase";
import CardGrid from "components/material/Card/CardGrid";
// import CardGrid from "components/material/Card/CardGrid";
import Dialog from "components/material/Dialog";
import BomAttachment from "components/material/List/ListAttachment";
import ChipStatus from "components/other/context/StatusField";
import SwitchFulfilled from "components/other/context/SwitchFulfilled";
import CopyComponent from "components/other/CopyLinkToClipboard";
import { GET_MANY_ATTACHMENT, UPDATE_FULFILLED, UPDATE_UNFULFILLED } from "constants/dataProviderTypes";
import { SUCCESS } from "constants/status";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Button, useDataProvider, useNotify, useRecordContext, useTranslate, useUpdate } from "react-admin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import formatCurrency from "utils/formatCurrency";
import ProductsFooter from "../components/ProductsFooter";
import PrlTransactionHistoryList from "./../components/TableTransactionHistory";
import calculateProductsTotalPrice from "./../utils/calculateProductsTotalPrice";

const styles = {
  instructionText: {
    width: 330,
    fontSize: "12px",
    fontWeight: "bold",
    color: colors.grey[700]
  },
  productDescription: {
    gridRow: 2,
    gridColumn: "1 /4"
  },

  valueKey: {
    color: colors.grey[800],
    fontWeight: 800,
    marginBottom: 3
  },

  valueText: {
    color: colors.grey[700]
  }
};

const PrlDetailsTab = (props) => {
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const record = useRecordContext(props);
  const t = useTranslate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const dataProvider = useDataProvider();
  const customerInformationContent = [
    { nameFirst: record?.customer?.nameFirst },
    {
      nameLast: record?.customer?.nameLast
    },
    {
      phonenumber: record?.customer?.phonenumber
    },
    {
      email: record?.customer?.email
    }
  ];

  // const productInformationContent = [
  //   { [`${t("prl.name")}`]: record?.product?.name },
  //   {
  //     [`${t("prl.price")}`]: record?.product?.price ? String(record?.product?.price) : ""
  //   },
  //   {
  //     [`${t("prl.reference")}`]: record?.product?.reference
  //   }
  // ];

  const { mutate: mutateFulfilled } = useMutation(["prl", UPDATE_FULFILLED], () =>
    dataProvider.updateFulfilled("prl", { id: record?.id })
  );
  const { mutate: mutateUnfulfilled } = useMutation(["prl", UPDATE_UNFULFILLED], () =>
    dataProvider.updateUnfulfilled("prl", { id: record?.id })
  );

  const toggleFulfilledStatus = (e) => {
    const checked = e.target.checked;

    if (checked) {
      mutateFulfilled(
        {
          type: UPDATE_FULFILLED,
          resource: "prl",
          payload: {
            id: record?.id
          }
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(["prl"]);
          },
          onFailure: (err) => {
            return notify(err?.message, { type: "error" });
          }
        }
      );
    } else {
      mutateUnfulfilled(
        {
          type: UPDATE_UNFULFILLED,
          resource: "prl",
          payload: {
            id: record?.id
          }
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(["prl"]);
          },
          onFailure: (err) => {
            return notify(err?.message, { type: "error" });
          }
        }
      );
    }
  };

  const { data: dataAttachment, loading: loadingAttachment } = useQuery(
    ["prl", GET_MANY_ATTACHMENT, { id: record?.id }],
    () => dataProvider.getManyAttachment("prl", { id: record?.id }),
    { enabled: !!record?.id }
  );

  const [updateComment, { isLoading: isLoadingComment }] = useUpdate();

  const addComment = async (value) => {
    await updateComment("prl", { id: record?.id, data: { notes: value }, previousData: record });
    setOpenCommentDialog(false);
  };

  const attachmentContent = dataAttachment?.data?.map((item) => ({
    url: item?.documentUrl,
    mimeType: item?.mimeType,
    label: item?.documentLabel
  }));

  const productsHeaderItems = [
    {
      title: "name",
      xs: 5
    },
    {
      title: "price",
      xs: 3
    },
    {
      title: "quantity",
      xs: 2
    },
    {
      title: "total",
      xs: 2
    }
  ];

  const productsContent = record?.product?.map((item) => ({
    description: item?.description,
    price: formatCurrency(item?.price),
    title: item?.name,
    quantity: item?.quantity,
    total: formatCurrency(item?.total),
    reference: item?.reference
  }));

  return (
    <>
      <Dialog
        title={t("prl.internal_comment.add_comment")}
        type="input"
        open={openCommentDialog}
        onClose={() => setOpenCommentDialog(false)}
        onConfirm={(e) => addComment(e)}
        inputProps={{
          multiline: true,
          minRows: 4
        }}
      ></Dialog>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 5
                  }}
                >
                  <Typography variant="h5" style={{ marginRight: 5 }} data-testid="payment-status-label">
                    {t("resources.prl.fields.paymentStatus")}:
                  </Typography>
                  <ChipStatus record={record} source="paymentStatus" dataTestId="payment-status-value" />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography style={{ marginRight: 5 }} variant="h5" data-testid="fulfilled-status-label">
                    {t("resources.prl.fields.isFulfilled")}:
                  </Typography>
                  <SwitchFulfilled
                    record={record}
                    onToggle={toggleFulfilledStatus}
                    dataTestId="fulfilled-status-value"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <CopyComponent url={record?.shortUrl || record?.url} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CardBase
                dataTestId="order-reference-card"
                variant={"classic-white"}
                cardTitle={
                  <Box style={{ display: "flex" }}>
                    <div>{t("cc.card.reference")}</div>
                    <div style={{ fontWeight: 500, color: colors.grey[600], marginLeft: 10 }}>
                      {record?.reference}
                    </div>
                  </Box>
                }
                cardContentProps={{ style: { overflowY: "scroll", maxHeight: 350 } }}
                footerContent={
                  <ProductsFooter totalPrice={formatCurrency(calculateProductsTotalPrice(record?.product))} />
                }
                footerContentProps={{ style: { borderTop: `1px solid ${colors.grey[400]}` } }}
              >
                <AccordionProducts content={productsContent} headerItems={productsHeaderItems} />
              </CardBase>
            </Grid>
            {/* <Grid item xs={12}>
              <CardGrid

                cardTitle={t("prl.product_information")}
                content={productInformationContent}
              >
                <Box className={classes.productDescription}>
                  <Typography className={classes.valueKey}>{t("prl.description")}</Typography>
                  <Typography className={classes.valueText}>{record?.product?.description}</Typography>
                </Box>
              </CardGrid>
            </Grid> */}

            <Grid item xs={12}>
              <PrlTransactionHistoryList record={record} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CardGrid
                dataTestId="customer-information-card"
                resource={"prl"}
                columns={2}
                cardTitle={t("cc.card.customer_information")}
                content={customerInformationContent}
              />
            </Grid>

            <Grid item xs={12}>
              <CardBase
                dataTestId="internal-comment-card"
                isEmpty={isEmpty(record?.comments)}
                cardTitle={t("cc.card.internal_comments")}
              >
                <Typography>{record?.comments}</Typography>
              </CardBase>
            </Grid>
            <Grid item xs={12}>
              <CardBase
                dataTestId="customer-comment-card"
                isLoading={isLoadingComment}
                headerActionContent={
                  !!record?.paymentStatus && record?.paymentStatus !== SUCCESS ? (
                    <Button
                      data-testid="add-comment-button"
                      variant="contained"
                      color="secondary"
                      label={t("cc.button.add_comment")}
                      onClick={() => setOpenCommentDialog(true)}
                    ></Button>
                  ) : null
                }
                isEmpty={isEmpty(record?.notes)}
                cardTitle={t("cc.card.customer_comments")}
              >
                <Typography>{record?.notes}</Typography>
              </CardBase>
            </Grid>
            <Grid item xs={12}>
              <CardBase
                dataTestId="attachment-card"
                cardTitle={t("cc.card.attachment", { smart_count: 0 })}
                noPadding
                isEmpty={isEmpty(dataAttachment?.data)}
                isLoading={loadingAttachment}
              >
                <BomAttachment fileResource="prl" content={attachmentContent} />
              </CardBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(PrlDetailsTab);
