import { ListItemButton, ListItemIcon, ListItemText, SxProps } from "@mui/material";
import { Menu } from "react-admin";

const ButtonItem = ({
  icon,
  dataTestId,
  title,
  children,
  linkable,
  to,
  sx,
  onClick
}: {
  icon: JSX.Element;
  onClick?: () => void;
  children?: JSX.Element;
  linkable?: boolean;
  dataTestId: string;
  to?: string;
  title: string;
  sx?: SxProps;
}) => {
  if (linkable) {
    return (
      <Menu.Item
        data-testid={dataTestId}
        to={{
          pathname: to,
          search: `filter=`
        }}
        sx={sx}
        disableRipple={true}
        disableTouchRipple={true}
        leftIcon={icon}
        primaryText={title}
      />
    );
  } else {
    return (
      <ListItemButton sx={sx} onClick={onClick} data-testid={dataTestId}>
        <ListItemIcon sx={{ minWidth: 35 }}>{icon}</ListItemIcon>
        <ListItemText>{title}</ListItemText>
        {children}
      </ListItemButton>
    );
  }
};

export default ButtonItem;
