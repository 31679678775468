import {
  BusinessOutlined,
  CollectionsBookmarkOutlined,
  CurrencyExchangeOutlined,
  HistoryOutlined,
  ListAltOutlined,
  LocalAtmOutlined,
  LocalMallOutlined,
  PaidOutlined,
  PaymentsOutlined,
  PersonSearchOutlined,
  QuestionMarkOutlined,
  ReceiptLong,
  SendOutlined,
  Storefront,
  SupportAgentOutlined,
  Info,
  SegmentOutlined,
  SettingsBackupRestoreOutlined,
  CardTravelOutlined
} from "@mui/icons-material";
import { SxProps } from "@mui/material";
import {
  AGENT_PAYMENTS_CATEGORY,
  COLLECTION_CATEGORY,
  DISBURSEMENT_CATEGORY,
  HISTORY_AND_REPORT_CATEGORY,
  KYC_CATEGORY,
  MERCHANT_PAYMENTS_CATEGORY,
  REMITTANCE_CATEGORY
} from "constants/categoryNames";
import {
  AGENT_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_SERVICE,
  PAYMENT_REQUEST_HISTORY_SERVICE,
  PAYMENT_TRANSACTION_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE,
  COMPANY_PRL_SERVICE,
  REMITTANCE_SEND_SERVICE,
  REMITTANCE_HISTORY_SERVICE,
  AGENT_PAYMENTS_HOME_SERVICE,
  AGENT_PAYMENTS_HISTORY_SERVICE,
  REMITTANCE_REPORTS_SERVICE
} from "constants/serviceNames";

/**
 *
 * @param {String} service
 * @param {Object} sx - styles
 * @returns
 */
const renderServiceIcon = (service: string, sx?: SxProps): JSX.Element => {
  switch (service) {
    // Groups
    case KYC_CATEGORY:
      return <PersonSearchOutlined sx={sx} data-testid="service-icon-kyc" />;
    case COLLECTION_CATEGORY:
      return <CollectionsBookmarkOutlined sx={sx} data-testid="service-icon-bulk_collection" />;
    case DISBURSEMENT_CATEGORY:
      return <PaidOutlined sx={sx} data-testid="service-icon-bulk_payment" />;
    case AGENT_PAYMENTS_CATEGORY:
      return <CardTravelOutlined sx={sx} data-testid="service-icon-agent_payment" />;
    case MERCHANT_PAYMENTS_CATEGORY:
      return <Storefront sx={sx} data-testid={"service-icon-merchant_service"} />;
    case HISTORY_AND_REPORT_CATEGORY:
      return <HistoryOutlined sx={sx} data-testid={"service-icon-history_and_reports"} />;
    case REMITTANCE_CATEGORY:
      return <CurrencyExchangeOutlined sx={sx} data-testid={"service-icon-remittance"} />;

    case AGENT_SERVICE:
      return <SupportAgentOutlined sx={sx} data-testid={"service-icon-agent"} />;
    case COMPANY_SERVICE:
      return <BusinessOutlined sx={sx} data-testid={"service-icon-company"} />;
    //  Services
    case PRODUCT_SERVICE:
      return <LocalMallOutlined sx={sx} data-testid={"service-icon-product"} />;
    case PRL_SERVICE:
      return <LocalAtmOutlined sx={sx} data-testid={"service-icon-prl"} />;
    case PAYMENT_TRANSACTION_SERVICE:
      return <ReceiptLong sx={sx} data-testid={"service-icon-payment_transaction"} />;
    case PAYMENT_REQUEST_HISTORY_SERVICE:
      return <ReceiptLong sx={sx} data-testid={"service-icon-payment-request-history"} />;
    case BULK_PAYMENT_SERVICE:
      return <PaymentsOutlined sx={sx} data-testid={"service-icon-bulk_payment"} />;
    case COMPANY_PRL_SERVICE:
      return <Info sx={sx} data-testid={"service-icon-company-prl"} />;
    case REMITTANCE_SEND_SERVICE:
      return <SendOutlined sx={sx} data-testid={"service-icon-remittance_send"} />;
    case REMITTANCE_HISTORY_SERVICE:
      return <ListAltOutlined sx={sx} data-testid={"service-icon-remittance_history"} />;
    case AGENT_PAYMENTS_HOME_SERVICE:
      return <SegmentOutlined sx={sx} data-testid={"service-icon-agent_payment"} />;
    case AGENT_PAYMENTS_HISTORY_SERVICE:
      return <SettingsBackupRestoreOutlined sx={sx} data-testid={"service-icon-agent_payment"} />;
    case REMITTANCE_REPORTS_SERVICE:
      return <SettingsBackupRestoreOutlined sx={sx} data-testid={"service-icon-remittance_reports"} />;
    default:
      return <QuestionMarkOutlined sx={sx} />;
  }
};

export default renderServiceIcon;
