import config from "config";

export const businessSectorDisabled = config.REACT_APP_BUSINESS_SECTOR_DISABLED === "true";
export const agentDisabled = config.REACT_APP_KYC_AGENT_DISABLED === "true";
export const companyDisabled = config.REACT_APP_KYC_COMPANY_DISABLED === "true";
export const productDisabled = config.REACT_APP_PRODUCTS_DISABLED === "true";
export const prlDisabled = config.REACT_APP_PRL_DISABLED === "true";
export const payoutAccountDisabled = config.REACT_APP_PAYOUT_ACCOUNT_DISABLED === "true";
export const payoutRequestDisabled = config.REACT_APP_PAYOUT_REQUEST_DISABLED === "true";
export const payoutApprovalDisabled = config.REACT_APP_PAYOUT_APPROVAL_DISABLED === "true";
export const bulkPaymentDisabled = config.REACT_APP_BULK_PAYMENT_DISABLED === "true";
export const bulkCollectionDisabled = config.REACT_APP_BULK_PAYMENT_DISABLED === "true";
