import { Box } from "@mui/system";
import { useRedirect } from "react-admin";
import getPermittedServices from "../../utils/getPermittedServices";
import ServicePickerCard from "../components/ServicePickerCard";

const ServicePicker = ({ permissions }) => {
  const services = getPermittedServices(permissions);
  const redirect = useRedirect();

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
      {services.map((service) =>
        service ? (
          <Box>
            <ServicePickerCard service={service} redirect={redirect} />
          </Box>
        ) : null
      )}
    </Box>
  );
};

export default ServicePicker;
