import config from "config";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { getStorageToken } from "utils/storageUtils";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};

const apiUrl = config.REACT_APP_PROXY_BASE_URL;

const dataProvider = simpleRestProvider(apiUrl, httpClient);

const bulkCollectionDataProvider = {
  ...dataProvider
};

export default bulkCollectionDataProvider;
