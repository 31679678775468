interface GoogleMapProps {
  width?: string;
  height?: string;
  apiKey: string;
  longitude?: number;
  latitude?: number;
}
const GoogleMap = ({ width = "600", height = "450", apiKey, longitude, latitude }: GoogleMapProps) => {
  const center = `&center=${latitude},${longitude}`;

  return (
    <iframe
      width={width}
      height={height}
      style={{ border: 0 }}
      data-testid={"google-map"}
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/view?key=${apiKey}${center}&zoom=18`}
    ></iframe>
  );
};

export default GoogleMap;
