import {
  agentDisabled,
  bulkCollectionDisabled,
  bulkPaymentDisabled,
  companyDisabled,
  prlDisabled,
  productDisabled
} from "config/featureManagement";
import {
  AGENT_PAYMENTS_HISTORY_SERVICE,
  AGENT_PAYMENTS_HOME_SERVICE,
  AGENT_SERVICE,
  BULK_COLLECTION_SERVICE,
  BULK_PAYMENT_SERVICE,
  COMPANY_PRL_SERVICE,
  COMPANY_SERVICE,
  MERCHANT_SERVICE,
  PRL_SERVICE,
  PRODUCT_SERVICE,
  REMITTANCE_HISTORY_SERVICE,
  REMITTANCE_REPORTS_SERVICE,
  REMITTANCE_SEND_SERVICE
} from "constants/serviceNames";
import {
  BULKCOL_ACCESS,
  BULKPAY_ACCESS,
  ENTITY_READ_ANY,
  ENTITY_READ_OWN,
  KYC_BOM_ACCESS,
  MERCHANT_READ_OWN,
  PRL_BOM_ACCESS,
  PRL_PRODUCT_READ_ANY,
  PRL_PRODUCT_READ_OWN,
  PRL_READ_ANY,
  PRL_READ_OWN,
  ENABLE_AGENT_PAYMENT,
  ENABLE_AGENT_PAYMENT_HISTORY,
  ENABLE_AGENT_PAYMENT_TRANSACTION,
  REMIT_REPORT_ALL,
  REMIT_REPORT_OWN,
  REMIT_TRANSACT
  //REMIT_ACCESS
} from "constants/permissionsNames";
import findPermission from "./findPermission";
import { getStorageMerchantCCId } from "./storageUtils";

/**
 *
 * @param {String} service
 * @param {Array} permissions
 * @returns {Boolean} - Return boolean value which indicates if user has access to specific service
 */
const getServiceAccess = (service: string | undefined, permissions: string[]): boolean => {
  const merchantCCId = getStorageMerchantCCId();
  // Permissions
  const kycBomAccessPermission = findPermission(permissions, KYC_BOM_ACCESS);
  const entityReadOwnPermission = findPermission(permissions, ENTITY_READ_OWN);
  const entityReadAnyPermission = findPermission(permissions, ENTITY_READ_ANY);
  const prlProductReadAnyPermission = findPermission(permissions, PRL_PRODUCT_READ_ANY);
  const prlProductReadOwnPermission = findPermission(permissions, PRL_PRODUCT_READ_OWN);
  const prlReadAnyPermission = findPermission(permissions, PRL_READ_ANY);
  const prlReadOwnPermission = findPermission(permissions, PRL_READ_OWN);
  const prlBomAccessPermission = findPermission(permissions, PRL_BOM_ACCESS);
  const bulkpayAccessPermission = findPermission(permissions, BULKPAY_ACCESS);
  const bulkcolAccessPermission = findPermission(permissions, BULKCOL_ACCESS);
  const merchantReadOwnPermission = findPermission(permissions, MERCHANT_READ_OWN);
  const enableAgentPaymentPermission = findPermission(permissions, ENABLE_AGENT_PAYMENT);
  const enableAgentPaymentHistoryPermission = findPermission(permissions, ENABLE_AGENT_PAYMENT_HISTORY);
  const enableAgentPaymentTransactionPermission = findPermission(
    permissions,
    ENABLE_AGENT_PAYMENT_TRANSACTION
  );
  const remitTransactPermission = findPermission(permissions, REMIT_TRANSACT);
  const remitReportAllPermission = findPermission(permissions, REMIT_REPORT_ALL);
  const remitReportOwnPermission = findPermission(permissions, REMIT_REPORT_OWN);
  // Accesses
  const kycAccess =
    kycBomAccessPermission &&
    (entityReadOwnPermission || entityReadAnyPermission) &&
    (!companyDisabled || !agentDisabled);
  const productAccess =
    prlBomAccessPermission &&
    (prlProductReadAnyPermission || prlProductReadOwnPermission) &&
    !productDisabled;
  const prlAccess = prlBomAccessPermission && (prlReadAnyPermission || prlReadOwnPermission) && !prlDisabled;
  const bulkpaymentAccess = bulkpayAccessPermission && !bulkPaymentDisabled && merchantCCId;
  const bulkcollectionAccess = bulkcolAccessPermission && !bulkCollectionDisabled;
  const companyPrlAccess =
    merchantCCId && prlReadAnyPermission ? prlReadAnyPermission : merchantReadOwnPermission;
  const merchantAccess =
    getStorageMerchantCCId() && prlReadAnyPermission ? prlReadAnyPermission : merchantReadOwnPermission;
  //const remittanceAccess = REMIT_ACCESS;
  const remittanceSendAccess = remitTransactPermission;
  const remittanceReportAccess = remitReportAllPermission || remitReportOwnPermission;
  // merchantCCId && prlReadAnyPermission ? prlReadAnyPermission : merchantReadOwnPermission;
  const agentPaymentsAccess =
    enableAgentPaymentPermission &&
    enableAgentPaymentHistoryPermission &&
    enableAgentPaymentTransactionPermission;

  switch (service) {
    case AGENT_SERVICE:
    case COMPANY_SERVICE:
      return !!kycAccess;
    case PRODUCT_SERVICE:
      return !!productAccess;
    case PRL_SERVICE:
      return !!prlAccess;
    case BULK_PAYMENT_SERVICE:
      return !!bulkpaymentAccess;
    case BULK_COLLECTION_SERVICE:
      return !!bulkcollectionAccess;
    case MERCHANT_SERVICE:
      return !!merchantAccess;
      return !!merchantReadOwnPermission;
    case REMITTANCE_HISTORY_SERVICE:
    case REMITTANCE_SEND_SERVICE:
      return !!remittanceSendAccess;
    case REMITTANCE_REPORTS_SERVICE:
      return !!remittanceReportAccess;
    case COMPANY_PRL_SERVICE:
      return !!companyPrlAccess;
    case AGENT_PAYMENTS_HOME_SERVICE:
    case AGENT_PAYMENTS_HISTORY_SERVICE:
      return !!agentPaymentsAccess;
    default:
      return false;
  }
};

export default getServiceAccess;
