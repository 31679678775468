import { get } from "lodash";
import { useRecordContext } from "react-admin";
import formatCurrency from "utils/formatCurrency";

const CurrencyField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const value = get(record, source);
  return <div>{formatCurrency(value)}</div>;
};

export default CurrencyField;
