/*eslint-disable */

import { Box, Button, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import CardGrid from "components/material/Card/CardGrid";
import ButtonBack from "components/other/ButtonBack";
import BulkCollectChipStatus from "components/ra/List/BulkCollectionList/components/BulkCollectChipStatus";
// import config from "config";
// import { saveAs } from "file-saver";
import { DateField, useRecordContext, useTranslate } from "react-admin";
// import { getAuthToken } from "utils/storageUtils";
import BulkCollectValidationTable from "../components/BulkCollectValidationTable";

const styles = {
  tableBox: {
    gridRow: 5,
    gridColumn: "1 / 5"
  }
};

const BulkCollectionGeneralTab = (props) => {
  const { classes } = props;
  const record = useRecordContext(props);
  const t = useTranslate();
  // const notify = useNotify();

  const downloadAttachment = async (id, fileName) => {
    // try {
    //   let response;
    //   response = await fetch(`${config.REACT_APP_PROXY_BASE_URL}/bulk_collection/${id}/file?download=true`, {
    //     headers: {
    //       Authorization: `Bearer ${getAuthToken()}`
    //     }
    //   });
    //   response = await response.arrayBuffer();
    //   const blob = new Blob([response], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    //   });
    //   saveAs(blob, fileName);
    // } catch (error) {
    //   notify(error?.body?.message, { type: "error" });
    // }
  };

  const bulkPayInformationContent = [
    {
      [`${t("bulk_collection.bulk_collection_ref")}`]: record?.ref
    },
    {
      [`${t("bulk_collection.status")}`]: <BulkCollectChipStatus source="status" record={record} />
    },
    {
      [`${t("bulk_collection.notification_message_sms")}`]: (
        <Typography>{record?.sms_message || "SMS Notification"}</Typography>
      )
    },
    {
      [`${t("bulk_collection.started_at")}`]:
        record?.started_at.length <= 0 ? (
          t("bulk_collection.not_yet")
        ) : (
          <DateField showTime source="started_at" locales="fr-FR" />
        )
    },
    {
      [`${t("bulk_collection.ended_at")}`]:
        record?.ended_at.length <= 0 ? (
          t("bulk_collection.not_yet")
        ) : (
          <DateField showTime source="ended_at" locales="fr-FR" />
        )
    },
    {
      [`${t("bulk_collection.download_bulk_file")}`]: (
        <Button onClick={() => downloadAttachment(record?.id, record?.file?.filename)} color="primary">
          {t("bulk_pay.here")}
        </Button>
      )
    }
  ];
  return (
    <>
      <CardGrid
        variant="outlined"
        cardTitle={t("bulk_collection.bulk_collection_details")}
        content={bulkPayInformationContent}
        columns={3}
      >
        <Box className={classes.tableBox}>
          <BulkCollectValidationTable {...props} />
        </Box>
      </CardGrid>
      <Box style={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
        {/* <ButtonBack color="primary" variant="contained">
          {t("ra.action.back")}
        </ButtonBack> */}
      </Box>
    </>
  );
};

export default withStyles(styles)(BulkCollectionGeneralTab);
