import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { FC, useState } from "react";
import { usePermissions, useRecordContext, useTranslate } from "react-admin";
import { CheckOutlined, Close } from "@mui/icons-material";
import classnames from "classnames";
import findPermission from "utils/findPermission";
import { BulkCollectListApproveRejectTypes } from "../../types/bulkCollectionListTypes";

const styles = () => ({
  btn: {
    color: colors.cyan[600],
    fontSize: 14
  },
  infoText: {
    color: colors.cyan[600],
    fontWeight: "bold",
    fontSize: 14
  },
  showApproveButton: {
    padding: "8px 15px",
    marginRight: 55,
    backgroundColor: colors.green[500],
    color: colors.common.white,
    "&:hover": {
      color: colors.cyan[600]
    }
  },
  showRejectButton: {
    padding: "8px 15px",
    backgroundColor: colors.red[500],
    color: colors.common.white,
    "&:hover": {
      color: colors.cyan[600]
    }
  },
  disabled: {
    color: colors.common.white,
    backgroundColor: colors.grey["400"]
  }
});

const BulkCollectListApproveReject: FC<BulkCollectListApproveRejectTypes> = (props) => {
  const [openApprove, setOpenApprove] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const record = useRecordContext();
  const [reasonForRejection, setReasonForRejection] = useState("");
  // const username = JSON.parse(localStorage.getItem("username"));
  // const userId = JSON.parse(localStorage.getItem("MerchantCoreCompanyId"));
  const { permissions } = usePermissions();
  const bulkApprover = findPermission(permissions, "bulkpay_approver");

  const t = useTranslate();
  const { classes, show } = props;

  const openApproveDialog = () => {
    setOpenApprove(true);
  };
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const openRejectDialog = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };
  const clean = () => {
    setReasonForRejection("");
  };
  const onClickReject = (reasonForRejection: string, callback: () => void) => {
    // TODO: Add logic for Rejection
  };
  const onClickApprove = () => {
    // TODO: Add logic for Approval
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClickReject(reasonForRejection, clean);
  };
  return (
    <>
      {bulkApprover && (
        <Grid container direction="row">
          <Grid item>
            <Button
              disabled={record?.status !== "READY"}
              startIcon={<CheckOutlined />}
              className={classnames({
                [classes.showApproveButton]: show,
                [classes.disabled]: show && record?.status !== 2,
                [classes.btn]: !show
              })}
              onClick={openApproveDialog}
              variant="text"
            >
              {t("kyc.detail.action.approve")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={record?.status !== "READY"}
              startIcon={<Close />}
              className={classnames({
                [classes.showRejectButton]: show,
                [classes.disabled]: show && record?.status !== 2,
                [classes.btn]: !show
              })}
              onClick={openRejectDialog}
              variant="text"
            >
              {t("kyc.reject")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openApprove}
        onClose={handleCloseApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.infoText}>{t("bulk_pay.alert_approve_message")}</DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button variant="outlined" color="secondary" onClick={handleCloseApprove}>
            {t("ra.message.no")}
          </Button>
          <Button variant="contained" color="secondary" onClick={onClickApprove} autoFocus>
            {t("ra.message.yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReject}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.infoText}>{t("bulk_pay.alert_reject_message")} *</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              multiline
              minRows={4}
              variant="filled"
              fullWidth={true}
              value={reasonForRejection}
              onChange={(e) => setReasonForRejection(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleCloseReject}>
              {t("ra.action.close")}
            </Button>
            <Button type="submit" variant="contained" color="secondary" autoFocus>
              {t("bom.submit")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(BulkCollectListApproveReject);
