import { EntityObject, EntityResultObject, EntitySearchResultObject } from "api/types/kycTypes";
import config from "config";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import decodeJwt from "jwt-decode";
import { mapKeys } from "lodash";
import { stringify } from "query-string";
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams
} from "react-admin";
import {
  getStorageRefreshToken,
  getStorageToken,
  removeStorageMerchantCCId,
  setStorageMerchantCCId,
  setStoragePermissions,
  setStorageRefreshToken,
  setStorageToken
} from "../../utils/storageUtils";

const proxyUrl: string = config.REACT_APP_PROXY_BASE_URL;
const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = getStorageToken() || "";
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils.fetchJson(url, options);
};
interface GetListReturnType {
  data: EntitySearchResultObject;
  total: number;
}

interface GetOneReturnType {
  data: EntityResultObject;
}
const kycDataProvider = {
  getList: (resource: string, params: GetListParams): Promise<GetListReturnType> => {
    const { page, perPage } = params.pagination;
    const { filter } = params;
    const { field, order } = params.sort;
    let query;

    if (field !== "id") {
      query = {
        limit: perPage,
        page: page,
        sortField: `${field}`.replace("name.", ""),
        sortOrder: order,
        ...filter,
        createdAt: filter.createdAt && dayjs(filter.createdAt).format("YYYY-MM-DD")
      };
    } else {
      query = {
        limit: perPage,
        page: page,
        ...filter,
        createdAt: filter.createdAt && dayjs(filter.createdAt).format("YYYY-MM-DD")
      };
    }

    query = mapKeys(query, (value, key) => {
      if (key === "status") {
        return "status[]";
      }

      return key;
    });

    const url = `${proxyUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.entitySearchResults.map((value: EntityObject) => {
        return { ...value, id: value.entityId };
      }),
      total: json.max
    }));
  },

  switchAdminContext: async (resource: string, params: { org: string }) => {
    const { org } = params;

    const requestOptions = {
      method: "GET"
    };

    try {
      const response = await fetch(
        `${proxyUrl}/auth/switch?refresh_token=${getStorageRefreshToken()}&org=${org}`,
        requestOptions
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      const { access_token, refresh_token } = data;
      if (access_token && refresh_token) {
        const { roles, org }: { org?: string; roles: string[] } = decodeJwt(access_token);

        const hasBomAccess = !!roles?.find((item) => item === "bom_access");

        if (hasBomAccess) {
          setStoragePermissions(roles);
          setStorageToken(access_token);
          setStorageRefreshToken(refresh_token);
          if (org) {
            setStorageMerchantCCId(org);
          } else {
            removeStorageMerchantCCId();
          }
        } else {
          throw new Error("Unauthorized");
        }
      }
      return { data };
    } catch (error) {
      // TODO: Implement error handling
      throw new Error("Bad Request");
    }
  },
  getOne: (resource: string, params: GetOneParams): Promise<GetOneReturnType> => {
    const id = params.id as string;
    return httpClient(`${proxyUrl}/${resource}/${id}`).then(({ json }) => {
      return {
        data: { ...json.entity, id: json.entity.entityId }
      };
    });
  },

  getMany: (resource: string, params: GetManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${proxyUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${proxyUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      // eslint-disable-next-line
      // @ts-ignore: Object is possibly 'null'
      total: parseInt(headers.get("content-range").split("/").pop(), 10)
    }));
  },

  update: (resource: string, params: UpdateParams) =>
    httpClient(`${proxyUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${proxyUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: string, params: CreateParams) =>
    httpClient(`${proxyUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    })),

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${proxyUrl}/${resource}/${params.id}`, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: DeleteManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${proxyUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json }));
  },
  downloadPdf: async (resource: string, params: { id: string; data: { type: string } }) => {
    const { id, data } = params;
    const url = `${proxyUrl}/${resource}/${id}/download/pdf?type=${data?.type}`;
    const token = getStorageToken() || "";
    try {
      const request = new Request(url, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      });

      const response = await fetch(request);
      const blob = await response.blob();
      return { data: blob };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },
  downloadDocument: async (
    resource: string,
    params: { id: string; dataObjectId: string; dataObjectType: string }
  ) => {
    const { id, dataObjectId, dataObjectType } = params;
    const url = `${proxyUrl}/${resource}/${id}/data/${dataObjectId}/download`;
    const token = getStorageToken() || "";
    try {
      const request = new Request(url, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        })
      });
      const response = await fetch(request);
      const data = await response.blob();

      saveAs(data, dataObjectType.toLowerCase());

      return { data: [] };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },
  approveKycData: (
    resource: string,
    params: {
      id: string;
    }
  ) => {
    const { id } = params;
    const url = `${proxyUrl}/${resource}/${id}/approve`;
    return httpClient(url, {
      method: "PATCH"
    }).then(({ json }) => ({ data: json }));
  },
  rejectKycData: (resource: string, params: { id: string; data: { reason: string } }) => {
    const { id, data } = params;
    const url = `${proxyUrl}/${resource}/${id}/reject`;
    return httpClient(url, {
      method: "PATCH",
      body: JSON.stringify(data)
    }).then(({ json }) => ({ data: json }));
  }
};

export default kycDataProvider;
