import Tabs from "components/material/Tabs";
import KycCommentsTab from "components/ra/Show/kyc/tabs/KycCommentsTab";
import KycDetailsTab from "components/ra/Show/kyc/tabs/KycDetailsTab";
import KycDocumentsTab from "components/ra/Show/kyc/tabs/KycDocumentsTab";
import React from "react";
import { useTranslate } from "react-admin";

const KycDataShowLayout = (props: any) => {
  const translate = useTranslate();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event: Event, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <Tabs
      tabLabels={[
        `${translate("cc.tab.details")}`,
        `${translate("cc.tab.documents")}`,
        `${translate("cc.tab.comments")}`
      ]}
      onChange={handleChange}
      activeTabIndex={activeTabIndex}
    >
      <KycDetailsTab {...props} setActiveTabIndex={setActiveTabIndex} />
      <KycDocumentsTab {...props} />
      <KycCommentsTab {...props} />
    </Tabs>
  );
};

export default KycDataShowLayout;
