import {
  Box,
  Button,
  colors,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import config from "config";

import Card from "components/material/Card/CardBase";
import { AIRTIME, MOBILEMONEY } from "constants/bulkPaymentServiceTypes";
import { saveAs } from "file-saver";
import { useState } from "react";
import {
  Form,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate
} from "react-admin";
import { useMutation } from "react-query";
// import { Form } from "react-final-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Circle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import AlertGuide from "components/material/Alert/AlertGuide";
import Dropzone from "components/other/Dropzone/Dropzone";
import * as yup from "yup";
import { UPLOAD_ATTACHMENT } from "../../../constants/dataProviderTypes";
import BackButton from "../../other/ButtonBack";
import queryErrorHandler from "api/queryErrorHandler";
import TooltipHelp from "components/material/Tooltip/TooltipHelp";
import { DropzoneFileType } from "components/types/otherComponentTypes";
import { getStorageMerchantCCId, getStorageUsername } from "utils/storageUtils";

const useStyles = makeStyles({
  button: {
    color: colors.common.black,
    textTransform: "none"
  },

  inputLabels: {
    fontSize: 16,
    color: colors.cyan[600],
    paddingTop: 10,
    fontWeight: "bold"
  },

  input: {
    display: "none"
  },
  listIcon: {
    minWidth: 8,
    paddingRight: 12,
    color: colors.blue[300]
  },
  icon: {
    fontSize: 8
  },
  buttonHere: {
    color: colors.common.white,
    borderRadius: 3,
    opacity: 0.4,
    margin: "0 5px",
    fontSize: 18,
    height: 25,
    fontWeight: 700,
    backgroundColor: colors.grey[600],
    "&:hover": {
      color: colors.common.black,
      backgroundColor: colors.grey[600]
    }
  }
});
const serviceTypeList = [
  { mpay_serviceId: MOBILEMONEY, name: "Mobile Money" },
  { mpay_serviceId: AIRTIME, name: "Airtime" }
];

const templateUrl = `${config.REACT_APP_PROXY_BASE_URL}/template/bulk_payment`;

const BulkPaymentCreateForm = (props: { basePath?: string }) => {
  const { basePath } = props;
  const classes = useStyles();

  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const dataProvider = useDataProvider();
  const [attachments, setAttachments] = useState<DropzoneFileType[]>([]);
  const backToListPage = () => redirect("/bulk_payment", basePath);

  const [createBulkPayment, { isLoading: loadingCreateBulkPayment }] = useCreate();

  const username = getStorageUsername();
  const userId = getStorageMerchantCCId();

  //* Schema validation
  const schema = yup.object().shape({
    mpay_serviceId: yup.string().required(t("validation.required")),
    ref: yup.string().required(t("validation.required")),
    desciption: yup.string().optional()
  });

  const saveTemplate = () => {
    saveAs(templateUrl, `template.xlsx`);
  };
  const { mutate: mutateUploadAttachment } = useMutation(
    ["bulk_payment", UPLOAD_ATTACHMENT],
    ({ id, files }: { id: string | null; files: DropzoneFileType[] }) => {
      return dataProvider.uploadAttachment("bulk_payment", {
        data: { files },
        id: id
      });
    },
    {
      onSuccess: () => {
        notify(t("cc.notification.created_bulk_payment", { smart_count: 1 }), { type: "success" });
        backToListPage();
      },
      onError: (error) => {
        return queryErrorHandler({ error, notify });
      }
    }
  );

  const uploadAttachment = (props: { files: DropzoneFileType[]; id: string }) => {
    mutateUploadAttachment(props);
  };

  const handleDropAttachment = (file: DropzoneFileType[]) => {
    setAttachments(file);
  };
  const handleDeleteAttachment = (file: DropzoneFileType, index: number) => {
    const newFiles = [...attachments];
    newFiles.splice(index, 1);
    setAttachments(newFiles);
  };
  const handleUpdateAttachment = (labelValue: string, file: DropzoneFileType, index: number) => {
    const newFiles = [...attachments];
    newFiles[index].label = labelValue;
    setAttachments(newFiles);
  };

  const processCreateBulkPayment = async (values: Record<string, string>) => {
    if (attachments.length > 0) {
      const { mpay_serviceId, ref, desciption } = values;
      await createBulkPayment(
        "bulk_payment",
        {
          data: {
            mpay_created_by_name: username,
            mpay_created_by_uuid: userId,
            mpay_serviceId,
            ref,
            desciption
          }
        },
        {
          onSuccess: (data) => {
            uploadAttachment({ files: attachments, id: data?.id });
          },
          onError: (error) => {
            return queryErrorHandler({ error, notify });
          }
        }
      );
    }
  };

  const submit = async (values: Record<string, string>) => {
    await processCreateBulkPayment(values);
  };

  return (
    <>
      <Button variant="contained" sx={{ marginBottom: 2 }} onClick={saveTemplate}>
        {t("cc.button.download_template")}
      </Button>
      <Box sx={{ marginBottom: 3 }}>
        <AlertGuide
          service={"bulk_payment"}
          title={t("cc.alert.instruction.bulk_payment.title")}
          subtitle={`${t("cc.alert.instruction.bulk_payment.subtitle")}:`}
          type={"instruction"}
        >
          <List dense>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <Circle sx={{ fontSize: 7 }} />
                </ListItemIcon>
                <ListItemText primary={t(`cc.alert.instruction.bulk_payment.step_${num}`)} />
              </ListItem>
            ))}
          </List>
        </AlertGuide>
      </Box>

      <Form onSubmit={submit} resolver={yupResolver(schema)}>
        <Card cardTitle={t("cc.card.bulk_payment_request")}>
          <Grid container marginBottom={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("cc.typography.bulk_payment.step_1")}</Typography>
                  <Divider sx={{ margin: "10px 0" }} />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    data-testid="service-type"
                    choices={serviceTypeList}
                    optionText="name"
                    optionValue="mpay_serviceId"
                    label={t("resources.bulk_payment.fields.mpay_serviceId")}
                    source="mpay_serviceId"
                    fullWidth
                    resource="bulk"
                  />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={6}>
                  <Box style={{ display: "flex" }}>
                    <Typography className={classes.inputLabels} role="label" color={"red"}>
                      {t("cc.typography.bulk_payment.must_be_unique")}
                    </Typography>
                    <TooltipHelp
                      title={t("cc.tooltip.unique_reference")}
                      style={{ marginTop: 10, marginLeft: 8 }}
                    />
                  </Box>
                  <TextInput data-testid="payment-ref" source="ref" fullWidth />
                </Grid>
                <Grid item xs={12} />

                <Grid item xs={6}>
                  <TextInput
                    data-testid="description"
                    source={"desciption"}
                    multiline
                    minRows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">{t("cc.typography.bulk_payment.step_2")}</Typography>
              <Divider sx={{ margin: "10px 0" }} />

              <Dropzone
                isRequired
                multiple={false}
                maxFiles={1}
                supportedTypes={[
                  "text/csv",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ]}
                files={attachments}
                onDrop={handleDropAttachment}
                onDelete={handleDeleteAttachment}
                onUpdate={handleUpdateAttachment}
              />
            </Grid>
          </Grid>
        </Card>
        <Toolbar disableGutters sx={{ display: "flex", justifyContent: "flex-end" }}>
          <BackButton variant="outlined" color="secondary">
            <span>{t("ra.action.cancel")}</span>
          </BackButton>
          <SaveButton
            data-testid="submit-button"
            style={{ margin: "0px 15px" }}
            label={t("ra.action.save")}
            saving={loadingCreateBulkPayment}
          />
        </Toolbar>
      </Form>
    </>
  );
};
export default BulkPaymentCreateForm;
