import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import {
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  List,
  ResourceContextProvider,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate
} from "react-admin";
// import RemittanceDeliveryMethodField from "./components/RemittanceDeliveryMethodField";
import CurrencyField from "components/other/context/CurrencyField";
import { statusRemittanceHistory } from "constants/status";
import { BANK, MOBILE_MONEY, CASH_PICKUP } from "constants/remittanceDeliveryMethods";
import DatepickerRange from "components/other/Datepicker/DatepickerRange";
import ChipStatus from "components/other/context/StatusField";
import ExportButton from "components/ra/Button/ExportButton";
import ShowButton from "components/ra/Button/ShowButton";

const RemittanceHistoryList = ({ ...rest }) => {
  const t = useTranslate();
  const paymentStatusChoices = statusRemittanceHistory.map((status) => ({
    id: status,
    name: t(`resources.remittance.fields.${status}`.toLowerCase()).toUpperCase()
  }));

  const deliveryMethods = [MOBILE_MONEY, BANK, CASH_PICKUP];
  const deliveryMethodsChoices = deliveryMethods.map((method) => ({
    id: method,
    name: t(`resources.remittance.fields.${method}`.toLowerCase()).toUpperCase()
  }));

  const filters = [
    <TextInput source="senderLastName" label="Sender" data-testid="filter-sender-name" />,
    <TextInput source="receiverLastName" label="Receiver" data-testid="filter-receiver-name" />,
    <TextInput source="amountSend" label="Amount" data-testid="filter-sender-amount" />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="status"
      choices={paymentStatusChoices}
      data-testid="filter-paymentStatus"
    />,
    <SelectArrayInput
      sx={{ minWidth: 230 }}
      source="deliveryMethod"
      choices={deliveryMethodsChoices}
      data-testid="filter-paymentDeliveryMethod"
    />,
    <DatepickerRange source={"createdAt"} dataTestId={"filter-createdAt"} />
  ];

  const ListActions = () => {
    return (
      <TopToolbar sx={{ width: "100%" }}>
        <FilterButton />
        <ExportButton />
      </TopToolbar>
    );
  };

  return (
    <ResourceContextProvider value="remittance">
      <>
        <Toolbar
          variant={"regular"}
          disableGutters={true}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Breadcrumbs service={"history"} page={"list"} />
        </Toolbar>
        <List {...rest} filters={filters} actions={<ListActions />}>
          <Datagrid bulkActionButtons={false} data-testid="datagrid">
            <FunctionField
              label="Sender"
              sortBy="author.last_name"
              render={(record: any) => `${record?.senderLastName || ""} ${record.senderFirstName || ""}`}
            />
            <TextField source="senderDocumentNumber" label="Sender ID N&deg;" />
            <TextField source="receiverLastName" label="Receiver" />
            <CurrencyField source="amountSend" />
            {/* <RemittanceDeliveryMethodField source="deliveryMethod" /> */}
            <TextField source="deliveryMethod" label="Delivery Method" />
            <TextField source="ptn" label="PTN" />
            <TextField source="mtnc" label="MTNC" />
            <DateField source="createdAt" label="Processed at" locales="fr-FR" showTime />
            <ChipStatus source="status" />
            <ShowButton data-testid="show-button" />
          </Datagrid>
        </List>
      </>
    </ResourceContextProvider>
  );
};

export default RemittanceHistoryList;
