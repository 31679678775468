import { ExportButton as ExportBtn, ExportButtonProps } from "react-admin";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import getButtonWidth from "utils/getButtonWidth";
import { SxProps } from "@mui/material";

interface ButtonProps extends ExportButtonProps {
  customWidth?: "normal" | "large" | "fullWidth";
  minWidth?: string;
  variant?: "text" | "contained" | "outlined";
  sx?: SxProps;
}

const ExportButton: React.FC<ButtonProps> = ({
  minWidth = "150px",
  customWidth = "normal",
  sx,
  variant = "outlined",
  ...otherProps
}) => {
  return (
    <ExportBtn
      variant={variant}
      icon={<CloudUploadOutlinedIcon color="inherit" />}
      sx={{
        minWidth: getButtonWidth(customWidth),
        borderWidth: 1,
        ...sx
      }}
      {...(customWidth === "fullWidth" ? { fullWidth: true } : {})}
      size="medium"
      {...otherProps}
    />
  );
};

export default ExportButton;
