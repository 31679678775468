/*eslint-disable*/

declare global {
  var KYC: EnvVariablesProps;
}

type EnvVariablesProps = {
  REACT_APP_PROXY_BASE_URL: string;
  REACT_APP_USE_NODERED: string;
  REACT_APP_PRL_USE_MOCK_DATA: string;
  REACT_APP_PRODUCT_USE_MOCK_DATA: string;
  REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA: string;
  REACT_APP_BULK_PAYMENT_USE_MOCK_DATA: string;
  REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA: string;
  REACT_APP_MERCHANT_USE_MOCK_DATA: string;
  REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA: string;
  REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA: string;
  REACT_APP_BULK_COLLECTION_USE_MOCK_DATA: string;
  REACT_APP_KYC_AGENT_DISABLED: string;
  REACT_APP_KYC_COMPANY_DISABLED: string;
  REACT_APP_PRODUCTS_DISABLED: string;
  REACT_APP_PRL_DISABLED: string;
  REACT_APP_PAYOUT_ACCOUNT_DISABLED: string;
  REACT_APP_PAYOUT_REQUEST_DISABLED: string;
  REACT_APP_PAYOUT_APPROVAL_DISABLED: string;
  REACT_APP_BULK_PAYMENT_DISABLED: string;
  REACT_APP_BUSINESS_SECTOR_DISABLED: string;
  REACT_APP_BULK_COLLECTION_DISABLED: string;
};

const config: EnvVariablesProps = {
  REACT_APP_PROXY_BASE_URL:
    global.KYC?.REACT_APP_PROXY_BASE_URL !== "VARPROXYBASEURL"
      ? global.KYC?.REACT_APP_PROXY_BASE_URL
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PROXY_BASE_URL)
      : `${console.log("The key VARPROXYBASEURL in index.html should be replaced!")} ${
          global.KYC?.REACT_APP_PROXY_BASE_URL
        }`,
  REACT_APP_USE_NODERED:
    global.KYC?.REACT_APP_USE_NODERED !== "VARUSENODERED"
      ? global.KYC?.REACT_APP_USE_NODERED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_USE_NODERED)
      : `${console.log("The key VARUSENODERED in index.html should be replaced!")} ${
          global.KYC?.REACT_APP_USE_NODERED
        }`,

  REACT_APP_PRL_USE_MOCK_DATA:
    global.KYC?.REACT_APP_PRL_USE_MOCK_DATA !== "VARPRLMOCKMODE"
      ? global.KYC?.REACT_APP_PRL_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PRL_USE_MOCK_DATA)
      : `${console.log("The key VARPRLMOCKMODE in index.html should be replaced!")} ${
          global.KYC?.REACT_APP_PRL_USE_MOCK_DATA
        }`,

  REACT_APP_PRODUCT_USE_MOCK_DATA:
    global.KYC?.REACT_APP_PRODUCT_USE_MOCK_DATA !== "VARPRODUCTMOCKMODE"
      ? global.KYC?.REACT_APP_PRODUCT_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PRODUCT_USE_MOCK_DATA)
      : `${console.log("The key VARPRODUCTMOCKMODE in index.html should be replaced!")} ${
          global.KYC?.REACT_APP_PRODUCT_USE_MOCK_DATA
        }`,

  REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA:
    global.KYC?.REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA !== "VARPAYOUTREQUESTMOCKMODE"
      ? global.KYC?.REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA)
      : `${console.log("The key VARPAYOUTREQUESTMOCKMODE in index.html should be replaced!")}
        ${global.KYC?.REACT_APP_PAYOUT_REQUEST_USE_MOCK_DATA}`,

  REACT_APP_BULK_PAYMENT_USE_MOCK_DATA:
    global.KYC?.REACT_APP_BULK_PAYMENT_USE_MOCK_DATA !== "VARBULKPAYMENTUSEMOCKDATA"
      ? global.KYC?.REACT_APP_BULK_PAYMENT_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BULK_PAYMENT_USE_MOCK_DATA)
      : `${console.log("The key VARBULKPAYMENTUSEMOCKDATA in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BULK_PAYMENT_USE_MOCK_DATA}`,

  REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA:
    global.KYC?.REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA !== "VARPAYOUTAPPROVALMOCKMODE"
      ? global.KYC?.REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA)
      : `${console.log("The key VARPAYOUTAPPROVALMOCKMODE in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PAYOUT_APPROVAL_USE_MOCK_DATA}`,

  REACT_APP_MERCHANT_USE_MOCK_DATA:
    global.KYC?.REACT_APP_MERCHANT_USE_MOCK_DATA !== "VARMERCHANTMOCKMODE"
      ? global.KYC?.REACT_APP_MERCHANT_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_MERCHANT_USE_MOCK_DATA)
      : `${console.log("The key VARMERCHANTMOCKMODE in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_MERCHANT_USE_MOCK_DATA}`,

  REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA:
    global.KYC?.REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA !== "VARBUSINESSSECTROMOCKMODE"
      ? global.KYC?.REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA)
      : `${console.log("The key VARBUSINESSSECTROMOCKMODE in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BUSINESS_SECTOR_USE_MOCK_DATA}`,

  REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA:
    global.KYC?.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA !== "VARPAYOUTACCOUNTMOCKMODE"
      ? global.KYC?.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA)
      : `${console.log("The key VARPAYOUTACCOUNTMOCKMODE in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PAYOUT_ACCOUNT_USE_MOCK_DATA}`,

  REACT_APP_BULK_COLLECTION_USE_MOCK_DATA:
    global.KYC?.REACT_APP_BULK_COLLECTION_USE_MOCK_DATA !== "VARBULKCOLLECTIONUSEMOCKMODE"
      ? global.KYC?.REACT_APP_BULK_COLLECTION_USE_MOCK_DATA
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BULK_COLLECTION_USE_MOCK_DATA)
      : `${console.log("The key VARBULKCOLLECTIONUSEMOCKMODE in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BULK_COLLECTION_USE_MOCK_DATA}`,

  //  vars to Disable or Enable features to be visible
  REACT_APP_KYC_AGENT_DISABLED:
    global.KYC?.REACT_APP_KYC_AGENT_DISABLED !== "VARKYCAGENTDISABLED"
      ? global.KYC?.REACT_APP_KYC_AGENT_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_KYC_AGENT_DISABLED)
      : `${console.log("The key VARKYCAGENTDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_KYC_AGENT_DISABLED}`,

  REACT_APP_KYC_COMPANY_DISABLED:
    global.KYC?.REACT_APP_KYC_COMPANY_DISABLED !== "VARKYCCOMPANYDISABLED"
      ? global.KYC?.REACT_APP_KYC_COMPANY_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_KYC_COMPANY_DISABLED)
      : `${console.log("The key VARKYCCOMPANYDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_KYC_COMPANY_DISABLED}`,

  REACT_APP_PRODUCTS_DISABLED:
    global.KYC?.REACT_APP_PRODUCTS_DISABLED !== "VARPRODUCTSDISABLED"
      ? global.KYC?.REACT_APP_PRODUCTS_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PRODUCTS_DISABLED)
      : `${console.log("The key VARPRODUCTSDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PRODUCTS_DISABLED}`,

  REACT_APP_PRL_DISABLED:
    global.KYC?.REACT_APP_PRL_DISABLED !== "VARPRLDISABLED"
      ? global.KYC?.REACT_APP_PRL_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PRL_DISABLED)
      : `${console.log("The key VARPRLDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PRL_DISABLED}`,

  REACT_APP_PAYOUT_ACCOUNT_DISABLED:
    global.KYC?.REACT_APP_PAYOUT_ACCOUNT_DISABLED !== "VARPAYOUTACCOUNTDISABLED"
      ? global.KYC?.REACT_APP_PAYOUT_ACCOUNT_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_ACCOUNT_DISABLED)
      : `${console.log("The key VARPAYOUTACCOUNTDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PAYOUT_ACCOUNT_DISABLED}`,

  REACT_APP_PAYOUT_REQUEST_DISABLED:
    global.KYC?.REACT_APP_PAYOUT_REQUEST_DISABLED !== "VARPAYOUTREQUESTDISABLED"
      ? global.KYC?.REACT_APP_PAYOUT_REQUEST_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_REQUEST_DISABLED)
      : `${console.log("The key VARPAYOUTREQUESTDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PAYOUT_REQUEST_DISABLED}`,

  REACT_APP_PAYOUT_APPROVAL_DISABLED:
    global.KYC?.REACT_APP_PAYOUT_APPROVAL_DISABLED !== "VARPAYOUTAPPROVALDISABLED"
      ? global.KYC?.REACT_APP_PAYOUT_APPROVAL_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_PAYOUT_APPROVAL_DISABLED)
      : `${console.log("The key VARPAYOUTAPPROVALDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_PAYOUT_APPROVAL_DISABLED}`,

  REACT_APP_BULK_PAYMENT_DISABLED:
    global.KYC?.REACT_APP_BULK_PAYMENT_DISABLED !== "VARBULKPAYMENTDISABLED"
      ? global.KYC?.REACT_APP_BULK_PAYMENT_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BULK_PAYMENT_DISABLED)
      : `${console.log("The key VARBULKPAYMENTDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BULK_PAYMENT_DISABLED}`,

  REACT_APP_BUSINESS_SECTOR_DISABLED:
    global.KYC?.REACT_APP_BUSINESS_SECTOR_DISABLED !== "VARBUSINESSSECTORDISABLED"
      ? global.KYC?.REACT_APP_BUSINESS_SECTOR_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BUSINESS_SECTOR_DISABLED)
      : `${console.log("The key VARBUSINESSSECTORDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BUSINESS_SECTOR_DISABLED}`,

  REACT_APP_BULK_COLLECTION_DISABLED:
    global.KYC?.REACT_APP_BULK_COLLECTION_DISABLED !== "VARBULKCOLLECTIONDISABLED"
      ? global.KYC?.REACT_APP_BULK_COLLECTION_DISABLED
      : process.env.NODE_ENV !== "production"
      ? String(process.env.REACT_APP_BULK_COLLECTION_DISABLED)
      : `${console.log("The key VARBULKCOLLECTIONDISABLED in index.html should be replaced!")} 
        ${global.KYC?.REACT_APP_BULK_COLLECTION_DISABLED}`
};

export default config;
