import SearchIcon from "@mui/icons-material/Search";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  colors,
  Divider,
  Skeleton,
  Typography
} from "@mui/material";
import dataProvider from "api/dataProvider/agentPaymentsDataProvider";
import queryErrorHandler from "api/queryErrorHandler";
import { QueryFieldId, Service } from "api/types/agentPaymentsTypes";
import { AGENT_PAYMENTS_GET_SERVICE } from "constants/dataProviderTypes";
import { AGENT_PAYMENTS_HOME_SERVICE } from "constants/serviceNames";
import { useEffect } from "react";
import {
  Form,
  FormDataConsumer,
  regex,
  required,
  TextInput,
  useLocaleState,
  useNotify,
  useTranslate
} from "react-admin";
import { useFormState } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

type Props = {
  handleMove: (num: number) => void;
  setBillsSearchList: (data: any) => void;
};

const AgentPaymentsBillSearch = ({ handleMove, setBillsSearchList }: Props) => {
  const [locale] = useLocaleState();
  const t = useTranslate();
  const { id } = useParams();
  const notify = useNotify();

  const {
    mutate: mutateSearchBills,
    isLoading: isSearchBillsLoading,
    data
  } = useMutation(
    ["agent-payments", AGENT_PAYMENTS_HOME_SERVICE],
    ({ payload = {} }: { payload: any }) => {
      return dataProvider.searchBills({ payload, serviceId: service?.serviceId || "" });
    },
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      }
    }
  );

  const {
    data: service,
    isLoading,
    isFetching
  }: { data?: Service; isLoading: boolean; isFetching: boolean } = useQuery(
    ["agent-payments", AGENT_PAYMENTS_GET_SERVICE],
    () => dataProvider.getBillService({ serviceId: id || "" }),
    {
      onError: (error) => {
        queryErrorHandler({ error, notify });
      },
      refetchOnWindowFocus: false
    }
  );

  const defaultFields: { [key: string]: string } = {};
  service?.queryFields.map((field) => {
    defaultFields[field?.fieldId] = "";
  });

  const SubmitButton = () => {
    const { isValid, isDirty } = useFormState();
    return (
      <Button
        data-testid="submit-button"
        size="medium"
        startIcon={<SearchIcon />}
        variant="contained"
        type="submit"
        sx={{ fontWeight: "bold" }}
        disabled={!isValid || !isDirty}
      >
        {t(`resources.${["agent_payments"]}.fields.search`)}
      </Button>
    );
  };

  const onSubmit = (data: any) => {
    mutateSearchBills({ payload: data });
  };

  useEffect(() => {
    if (data !== undefined && data.length) {
      setBillsSearchList(data);
      handleMove(2);
    }
  }, [data]);

  const isServiceLoading = isLoading || isFetching;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSearchBillsLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 1
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
          <Box sx={{ display: "flex", alignItems: "center", width: 600 }}>
            <Box sx={{ marginRight: 4, minWidth: 100, maxWidth: 100 }}>
              {isServiceLoading ? (
                <Skeleton animation="wave" variant="rectangular" width={100} height={100} />
              ) : (
                <img
                  data-testid="service-image"
                  src={service?.logo?.url}
                  loading="lazy"
                  alt="service logo"
                  width="100%"
                />
              )}
            </Box>
            <Box width={"100%"}>
              {isServiceLoading ? (
                <Box>
                  <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={30} width="80%" />
                </Box>
              ) : (
                <>
                  <Typography data-testid="service-title" sx={{ fontWeight: "bold", fontSize: 26 }}>
                    {service?.title}
                  </Typography>
                  <Typography
                    data-testid="service-description"
                    sx={{ fontWeight: "bold", fontSize: 12, color: colors.grey[600] }}
                  >
                    {service?.description || "Pay your tax bill"}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {!isServiceLoading && (
          <Form mode="onBlur" onSubmit={onSubmit} defaultValues={defaultFields}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column", width: 600 }}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    let values: any = [];
                    values = Object.keys(formData).map((data, idx) => ({
                      name: data,
                      value: Object.values(formData)[idx],
                      disable: false,
                      ...service?.queryFields[idx]
                    }));
                    values = values.map((item: any) => {
                      if (item.value.length > 0) {
                        return {
                          ...item,
                          disable: false
                        };
                      } else {
                        return {
                          ...item,
                          disable: true
                        };
                      }
                    });
                    const allFieldsEmpty = Object.values(formData)?.every((item: any) => item.length === 0);

                    return (
                      <>
                        {service?.queryFields.map((field, idx: number) => (
                          <Box
                            data-testid="service-query-input"
                            key={idx}
                            sx={{ width: "100%", marginBottom: 2 }}
                          >
                            {field && (
                              <TextInput
                                data-testid={field?.fieldId}
                                {...rest}
                                id={QueryFieldId[field?.fieldId] || ""}
                                label={field?.label?.find((label) => label.lang === locale)?.value}
                                source={QueryFieldId[field?.fieldId]}
                                size="small"
                                disabled={values[idx]?.disable && !allFieldsEmpty}
                                sx={{ width: "100%" }}
                                variant="outlined"
                                validate={[
                                  regex(new RegExp(field?.regex || "")),
                                  !(values[idx]?.disable && !allFieldsEmpty) ? required() : () => undefined
                                ]}
                              />
                            )}
                            {service?.queryFields.length - 1 !== idx && service?.queryFields.length > 1 && (
                              <Divider>
                                <Chip
                                  label={t(`resources.${["agent_payments"]}.fields.or`)}
                                  variant="filled"
                                  color="primary"
                                />
                              </Divider>
                            )}
                          </Box>
                        ))}
                      </>
                    );
                  }}
                </FormDataConsumer>
                <SubmitButton />
              </Box>
            </Box>
          </Form>
        )}
      </Box>
    </>
  );
};

export default AgentPaymentsBillSearch;
