const bulkPaymentMockData = {
  bulk_payment: [
    {
      id: 168,
      ref: "filae",
      cnt_rows: 112,
      cnt_valid: 113,
      cnt_invalid: 114,
      cnt_error: 115,
      cnt_success: 116,
      cnt_pending: 117,
      cnt_total: 118,
      status: 2,
      uploaded_at: "2022-01-17T12:07:43Z",
      started_at: null,
      reviewed_at: null,
      ended_at: null,
      mpay_created_by_uuid: "CM10007",
      mpay_created_by_name: "311531",
      mpay_reviewed_by_uuid: "",
      mpay_reviewed_by_name: "",
      mpay_serviceId: "OrangeMoney",
      mpay_ccid: "CM10007",
      desciption: "",
      rejection_reason: "",
      file: null,
      payments: null
    },
    {
      id: 169,
      ref: "qwerty",
      cnt_rows: 111,
      cnt_valid: 112,
      cnt_invalid: 113,
      cnt_error: 114,
      cnt_success: 115,
      cnt_pending: 116,
      cnt_total: 117,
      status: 512,
      uploaded_at: "2022-09-17T12:12:54Z",
      started_at: "2021-02-22T10:48:56Z",
      reviewed_at: null,
      ended_at: "2021-05-22T11:03:35Z",
      mpay_created_by_uuid: "CM10007",
      mpay_created_by_name: "311531",
      mpay_reviewed_by_uuid: "",
      mpay_reviewed_by_name: "",
      mpay_serviceId: "MobileMoney",
      mpay_ccid: "CM10007",
      desciption: "good description",
      rejection_reason: "",
      file: null,
      payments: [
        {
          id: 6189,
          created_at: "2021-02-22T10:49:33Z",
          ptn: "99999161399151400068419346453123",
          processed_at: "2021-02-22T10:58:36Z",
          status: "Success",
          trid: "uuid1",
          amount: 100,
          paid_amount: "100.00",
          payItemId: "S-111-951-CMMTN-20051-10096-1",
          customer_name: "Jackie Chen",
          customer_reference: "ref277",
          error: "",
          service_number: "237671223456",
          mpay_serviceId: "",
          mpay_service_label: ""
        },
        {
          id: 6190,
          created_at: "2020-02-22T10:49:33Z",
          ptn: "99999161399151600067029405640456",
          processed_at: "2021-05-22T10:58:37Z",
          status: "Success",
          trid: "uuid2",
          amount: 100,
          paid_amount: "100.00",
          payItemId: "S-111-951-CMMTN-20051-10096-1",
          customer_name: "John Doe",
          customer_reference: "ref255",
          error: "",
          service_number: "237671223456",
          mpay_serviceId: "",
          mpay_service_label: ""
        },
        {
          id: 6191,
          created_at: "2019-02-22T10:49:33Z",
          ptn: "99999161399151700061726382436789",
          processed_at: "2021-08-22T10:58:38Z",
          status: "Success",
          trid: "uuid2",
          amount: 100,
          paid_amount: "100.00",
          payItemId: "S-111-951-CMMTN-20051-10096-1",
          customer_name: "Micky Mouse",
          customer_reference: "ref211",
          error: "",
          service_number: "237671223456",
          mpay_serviceId: "",
          mpay_service_label: ""
        }
      ]
    }
  ]
};

export default bulkPaymentMockData;
