import { useLocaleState } from "react-admin";
import Flag from "./components/Flag";
import getCountryNameFromCode from "./utils/getCountryNameFromCode";

const AutocompleteCountryOption = ({ option }: any) => {
  const [locale] = useLocaleState();

  return (
    <>
      <Flag code={option?.code?.toLowerCase()} />
      <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
        <div>{getCountryNameFromCode(option?.code, locale)}</div>
        <div>{option?.label}</div>
      </div>
    </>
  );
};

export default AutocompleteCountryOption;
