import { Typography, LinearProgress } from "@mui/material";
import { withStyles } from "@mui/styles";

import React from "react";
import { RaRecord, useGetOne, useRecordContext, UseRecordContextParams } from "react-admin";

const styles = {
  text: {
    fontSize: 14
  },
  progress: {
    width: 40
  }
};

const PayoutRequestCompanyName = (props: UseRecordContextParams<RaRecord>) => {
  const { classes } = props;
  const record = useRecordContext(props);

  const { data: merchantData, isFetching: merchantLoading } = useGetOne("merchant", {
    id: record?.coreCompanyId
  });

  return (
    <>
      {merchantLoading ? (
        <LinearProgress color="secondary" className={classes.progress} />
      ) : (
        <Typography className={classes.text}>{merchantData?.name}</Typography>
      )}
    </>
  );
};

export default withStyles(styles)(PayoutRequestCompanyName);
