import Breadcrumbs from "components/material/Breadcrumbs/Breadcrumbs";
import BomDeleteButton from "components/other/context/DeleteButton";
import Datepicker from "components/other/Datepicker";
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext,
  useTranslate
} from "react-admin";
import findPermission from "utils/findPermission";
import BulkpayStatus from "./components/BulkpayStatus";
import { Button, ButtonGroup, Skeleton, Stack, Toolbar } from "@mui/material";
import BulkPaymentListExpand from "components/ra/List/BulkPaymentList/components/BulkPaymentListExpand";
import config from "config";
import { saveAs } from "file-saver";
import ApproveRejectButton from "components/other/ApproveRejectButton";
import { useMutation } from "react-query";
import { DOWNLOAD_ATTACHMENT } from "constants/dataProviderTypes";
import queryErrorHandler from "api/queryErrorHandler";
import { ChevronLeft } from "@mui/icons-material";
import { useUtils } from "context/UtilsContext";
import BackButton from "components/other/ButtonBack";
import CreateButton from "components/ra/Button/CreateButton";
import ShowButton from "components/ra/Button/ShowButton";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

const BulkPaymentList = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const bulkApprover = findPermission(permissions, "bulkpay_approver");
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { merchantCCId } = useUtils();
  const templateUrl = `${config?.REACT_APP_PROXY_BASE_URL}/template/bulk_payment`;
  const saveTemplate = () => {
    saveAs(templateUrl, `template.xlsx`);
  };

  const { isLoading, isError } = useGetList(
    "bulk_payment",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "createdAt", order: "DESC" },
      filter: { merchantCCId }
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        queryErrorHandler({ error, notify });
      }
    }
  );

  const ListActions = () => {
    return (
      <TopToolbar sx={{ width: "100%" }}>
        <Button
          variant="contained"
          data-testid="download-template-button"
          onClick={saveTemplate}
          sx={{ marginRight: "auto" }}
        >
          {t("cc.button.download_template")}
        </Button>
        <FilterButton data-testid="filter-button" />
        <CreateButton label={t("cc.button.create_bulk_payment", { smart_count: 1 })} />
      </TopToolbar>
    );
  };

  const renderStatusText = (status: string) =>
    t(`resources.bulk_payment.fields.${status}`.toLowerCase()).toUpperCase();

  const filterList = [
    <TextInput source="ref" data-testid="filter-bulkPaymentRef" />,
    <SelectInput
      choices={[
        { id: "AIRTIME", name: "AIRTIME" },
        { id: "MOBILEMONEY", name: "MOBILE MONEY" }
      ]}
      source="mpay_serviceId"
      data-testid="filter-serviceType"
    />,
    <Datepicker source="started_at_from" dataTestId="filter-startedAt" />,
    <Datepicker source="started_at_to" dataTestId="filter-startedTo" />,
    <Datepicker source="ended_at_from" dataTestId="filter-endedAt" />,
    <Datepicker source="ended_at_to" dataTestId="filter-endedTo" />,
    <SelectInput
      source="status"
      choices={[
        { id: 1, name: renderStatusText("created") },
        { id: 2, name: renderStatusText("ready") },
        { id: 4, name: renderStatusText("approved") },
        { id: 32 || 128 || 256, name: renderStatusText("processing") },
        { id: 2048, name: renderStatusText("rejected") },
        { id: 1024, name: renderStatusText("validation_error") },
        { id: 512, name: renderStatusText("success") },
        { id: 16 || 64, name: renderStatusText("failed") }
      ]}
    />
  ];

  const DownloadButton = () => {
    const record = useRecordContext();
    const { mutate: mutateDownloadDocument } = useMutation(
      ["bulk_payment", DOWNLOAD_ATTACHMENT],
      () => {
        return dataProvider.downloadDocument("bulk_payment", {
          id: record?.id,
          data: record?.file
        });
      },
      {
        onSuccess: () => {
          notify("cc.notification.downloaded", { type: "success" });
        },
        onError: (error) => {
          return queryErrorHandler({ error, notify });
        }
      }
    );
    return (
      <Button data-testid={"download-button"} onClick={() => mutateDownloadDocument()}>
        <VerticalAlignBottomIcon />
      </Button>
    );
  };

  const ApproveRejectButtonGroup = () => {
    const record = useRecordContext();
    return (
      <ButtonGroup
        sx={{ marginLeft: 5 }}
        disabled={record?.status !== 2}
        data-testid={"approve-reject-button-group"}
      >
        <ApproveRejectButton buttonType="approve" />
        <ApproveRejectButton buttonType="reject" />
      </ButtonGroup>
    );
  };

  return (
    <>
      <Toolbar
        variant={"regular"}
        disableGutters={true}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Breadcrumbs service={"bulk_payment"} page={"list"} />
      </Toolbar>
      {isLoading ? (
        <Stack spacing={1}>
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      ) : isError && !isLoading ? (
        <BackButton sx={{ alignSelf: "flex-start", justifySelf: "flex-start" }} startIcon={<ChevronLeft />}>
          {t("ra.action.back")}
        </BackButton>
      ) : (
        <List
          // syncWithLocation
          emptyWhileLoading
          actions={<ListActions />}
          filters={filterList}
          resource="bulk_payment"
        >
          <Datagrid expand={<BulkPaymentListExpand />} bulkActionButtons={false}>
            <TextField source="ref" />
            <TextField source="mpay_serviceId" />
            {/* TODO: Create StatusField and reuse it in whole app*/}
            <BulkpayStatus source="status" />
            <DateField source="started_at" locales="fr-FR" />
            <DateField source="ended_at" locales="fr-FR" />
            {bulkApprover && <ApproveRejectButtonGroup />}
            <DownloadButton />
            <ShowButton />
            <BomDeleteButton forResource="bulk_payment" dataTestId="delete-button" />
          </Datagrid>
        </List>
      )}
    </>
  );
};

export default BulkPaymentList;
